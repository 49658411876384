import httpClient from '../../client'

import apiRoutes from '../constants'

const twoFactorCheck = async ({code}) => {
  try {
    const {data} = await httpClient.post(apiRoutes.TOKEN_CHECK, {
      code,
    })

    if (data['2fa']) {
      return data
    }

    throw data
  } catch (error) {
    return error
  }
}

export default twoFactorCheck
