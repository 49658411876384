// selectors.js

import {createSelector} from '@reduxjs/toolkit'

export const getCustomersSelector = (state) => state.customers

export const getCurrentCustomersPage = createSelector(getCustomersSelector, (customers) => customers?.page ?? 1)

export const getCustomersColumns = createSelector(getCustomersSelector, (customers) => customers?.columns ?? [])

export const hasLoadedOrdering = createSelector(
  getCustomersSelector,
  (customers) => !!customers?.filters?.ordering?.orders?.length,
)

export const customersOrders = createSelector(
  getCustomersSelector,
  (customers) => customers?.filters?.ordering?.orders ?? [],
)

export const getActiveOrder = createSelector(
  getCustomersSelector,
  (customers) => customers?.filters?.ordering?.activeSort,
)

export const showMap = createSelector(getCustomersSelector, (customers) => customers?.universalSearch?.showMap)

export const universalSearch = createSelector(getCustomersSelector, (customers) => customers?.universalSearch ?? {})

export const getCurrentLimit = createSelector(getCustomersSelector, (customers) => customers?.limit ?? 25)

export const getSearchParams = createSelector(getCustomersSelector, (customers) => ({
  hasAppliedFilters: customers?.universalSearch?.hasAppliedFilters,
  searchParams: customers?.universalSearch?.searchParams,
  showOnlyFavorites: customers?.universalSearch?.showOnlyFavorites,
}))
