import httpClient from '../../client'
import apiRoutes from '../constants'

const getListUsers = async (params) => {
  const queryStringValues = [`page=${params?.page ?? 1}`]

  if (params?.limit) {
    queryStringValues.push(`limit=${params.limit}`)
  }

  const queryStringStr = queryStringValues.join('&')
  try {
    const {data} = await httpClient.get(`${apiRoutes.GET_LIST_USERS}?${queryStringStr}`)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    return error
  }
}

export default getListUsers
