import httpClient from '../../client'

import apiRoutes from '../constants'

const updateProposal = async (proposal) => {
  try {
    const response = await httpClient.put(`${apiRoutes.GET_PROPOSALS}/${proposal.id}`, proposal)

    return response.data
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message)
    } else {
      throw error
    }
  }
}

export default updateProposal
