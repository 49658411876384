import {toAbsoluteUrl} from '_metronic/_helpers'
import configs from 'configs'
import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'

export function FooterCompact({today, footerClasses, footerContainerClasses}) {
  const {user} = useSelector((state) => state.auth, shallowEqual)

  return (
    <>
      <div
        className={`footer py-4 d-flex flex-lg-column  ${footerClasses}`}
        id="kt_footer"
        style={{backgroundColor: '#1D1D1D', color: '#fff'}}>
        <div
          className={`${footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          style={{fontSize: '12px'}}>
          <div className="text-white order-3">
            <span className="text-white font-weight-bold mr-2">{today}</span> ® Todos os direitos reservados.
          </div>
          <div className="nav nav-dark order-2">
            <span className="text-cm text-white" style={{color: '#fff', fontSize: 14}}></span>
          </div>{' '}
          <div className="nav nav-dark order-1">
            <img src={configs.assets.logo.main} style={{maxWidth: 160}} />
          </div>
        </div>
      </div>
    </>
  )
}
