import React, {useCallback, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
// import { useFormik } from 'formik';

import {DetailContainer, Icon, FileUploadLabel, FileUploadWarning, Title, Subtitle, ActionText, Label} from '../styles'

import {filesService} from 'app/services'

import {Circular as CircularLoading} from 'app/components'
import {Box, Container, Grid} from '@material-ui/core'
import {useDropzone} from 'react-dropzone'
import {useSnackbar} from 'notistack'
import {LabelM0} from 'app/modules/Proposals/pages/NewProposal/City/Details/styles'
import {LogoMunicipio} from 'app/modules/Cities/Pages/EditCitie/CityBasicInfo'

const Logo = ({info, handleChange, handleChangeLogo, onSubmit, submiting, setLoading}) => {
  const LogoUplodader = (props) => {
    const {enqueueSnackbar} = useSnackbar()

    const onDrop = useCallback(
      (files) => {
        if (files.length > 1) {
          enqueueSnackbar('Apenas um arquivo nessa área!', {
            variant: 'error',
          })
          return
        }

        props.setLoading(true)

        let filesArr = []
        files.forEach(async (file) => {
          try {
            if (file.size > 5000000) throw Error('O tamanho de cada arquivo é limitado a 5 MB.')
            const fileResponse = await filesService.sendFile({file})
            filesArr.push({
              type: 'image',
              name: fileResponse?.file?.original_name,
              path: fileResponse?.file?.path,
            })

            if (fileResponse?.file) {
              enqueueSnackbar('Logo pronto! Clique em salvar para efetivar a alteração', {
                variant: 'success',
              })

              props.handleChange(fileResponse.file.path)
            } else {
              throw new Error('Uploading error')
            }
          } catch (error) {
            enqueueSnackbar(error.message, {
              variant: 'error',
            })
          }
        })

        props.setLoading(false)
      },
      [enqueueSnackbar],
    )

    const {getRootProps, getInputProps} = useDropzone({
      accept: 'image/jpeg, image/png',
      onDrop,
    })

    return (
      <Box mb="63px" mt="50px">
        <Box marginBottom="20px" display="flex" flexDirection="column">
          <Title>INSERIR NOVO ARQUIVO DE IMAGEM</Title>
        </Box>
        <Box
          alignItems="center"
          borderRadius="20px"
          display="flex"
          flexDirection="column"
          marginBottom="20px"
          justifyContent="center"
          height="357px"
          mt="23px"
          {...getRootProps()}
          className="attachtment-dragger">
          <input {...getInputProps()} />
          <Box alignItems="center" display="flex" flexDirection="column">
            <Box alignItems="center" display="flex" flexDirection="column" marginBottom="10px">
              {props.loading ? (
                <>
                  <CircularLoading />
                </>
              ) : (
                <>
                  <Box marginBottom="10px">
                    <Icon className="fas fa-file-upload" />
                  </Box>
                  <FileUploadLabel>Arraste e solte aqui</FileUploadLabel>
                  <Button>Selecione o arquivo</Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flex={1} justifyContent="flex-end" marginBottom="20px">
          <FileUploadWarning>O tamanho de cada arquivo é limitado a 5 MB.</FileUploadWarning>
        </Box>
      </Box>
    )
  }

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>LOGOMARGA - ARQUIVO DE IMAGEM</Title>
          </Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="name">Imagem atual</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="name"
            id="name"
            type="text"
            value={info?.logo ? 'Logo' : null}
            readOnly
            disabled
            style={{maxWidth: 300}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <div style={{maxWidth: 600}}>
            <LogoUplodader setLoading={setLoading} handleChange={handleChangeLogo} />
          </div>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Logo
