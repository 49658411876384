import {toAbsoluteUrl} from '_metronic/_helpers'
import {getEnvVar} from 'lib/get-env'
import {isTenantCelepar} from 'lib/tenant-detector'

const configs = {
  app: {
    name: getEnvVar('app_name'),
  },
  assets: {
    logo: {
      main: toAbsoluteUrl(getEnvVar('asset_logo')),
      loader: toAbsoluteUrl(getEnvVar('asset_loader')),
    },
  },
  map: {
    bgColor: '#FFFFFF',
  },
}

if (isTenantCelepar()) {
  configs.map = {
    bgColor: '#20305E',
  }
}

export default configs
