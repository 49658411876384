import React, {useState} from 'react'
import {Box, Divider, Grid} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'
import IntlCurrencyInput from 'react-intl-currency-input'

import {Title, Container} from '../styles'
import ReactInputMask from 'react-input-mask'
import {LabelM0} from '../../NewProposal/City/Details/styles'

const categories = [
  {
    label: 'Implantação',
    value: 'implantation',
  },
  {
    label: 'Expansão',
    value: 'expansion',
  },
  {
    label: 'Reativação',
    value: 'reactivation',
  },
  {
    label: 'Diversificação',
    value: 'diversification',
  },
  {
    label: 'Busca por parceiros/investidores',
    value: 'search_partner_investor',
  },
]

const ProposalDescription = ({project, handleChange, onSubmit, submiting, category}) => {
  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>DESCRIÇAO DO PROJETO</Title>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="project_category">Categoria da oportunidade</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <select
            className="form-control"
            name="project_category"
            id="project_category"
            value={project.category}
            onChange={handleChange}
            style={{maxWidth: 300}}>
            {categories.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="project_total">Valor total do investimento (R$)</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <IntlCurrencyInput
            autoComplete="off"
            placeholder="Ex.: R$ 800.000,00"
            name="project_total"
            id="project_total"
            className="form-control"
            currency="BRL"
            style={{maxWidth: 600}}
            onChange={(ev, value) => handleChange({target: {value, name: 'project_total'}})}
            config={{
              locale: 'pt-BR',
              formats: {
                number: {
                  BRL: {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                },
              },
            }}
            defaultValue={project.total}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="project_required_investment">Valor de necessidade do investimento (R$)</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <IntlCurrencyInput
            autoComplete="off"
            placeholder="Ex.: R$ 800.000,00"
            name="project_required_investment"
            id="project_required_investment"
            className="form-control"
            currency="BRL"
            style={{maxWidth: 600}}
            onChange={(ev, value) => handleChange({target: {value, name: 'project_required_investment'}})}
            config={{
              locale: 'pt-BR',
              formats: {
                number: {
                  BRL: {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                },
              },
            }}
            defaultValue={project.required_investment}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="project_description">Descrição do projeto</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="project_description"
            id="project_description"
            as="textarea"
            onChange={handleChange}
            value={project.description}
            placeholder=""
            rows={5}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ProposalDescription
