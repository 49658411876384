import {Grid} from '@material-ui/core'
import React from 'react'
import {Paper, ContainerHeader, DetailContainer} from '../styles'

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

const StateAttachments = ({multimedia}) => {
  if (!multimedia) {
    return (
      <Paper>
        <ContainerHeader>Arquivos Sobre o Estado</ContainerHeader>
        <DetailContainer>
          <h3>Informação não preenchida pelo Estado</h3>
        </DetailContainer>
      </Paper>
    )
  }

  const grouped = groupBy(multimedia, 'type')

  return (
    <Paper>
      <ContainerHeader>Arquivos Sobre o Estado</ContainerHeader>
      <DetailContainer>
        <Grid container>
          <Grid item lg={4}>
            {grouped['image'] ? (
              <div>
                <span className="secundary-title">Fotos</span>
                <br />
                <br />
                <ul className="image-files" style={{display: 'flex'}}>
                  {grouped['image'].map((file) => (
                    <a href={file.path} target="_blank">
                      <li style={{backgroundImage: `url(${file.path})`, backgroundSize: 'cover', marginRight: 10}}></li>
                    </a>
                  ))}
                </ul>
              </div>
            ) : null}
          </Grid>
          <Grid item lg={4}>
            {grouped['document'] ? (
              <div>
                <span className="secundary-title">Documentos</span>
                <br />
                <br />
                <ul className="image-files" style={{display: 'flex'}}>
                  {grouped['document'].map((file) => (
                    <a href={file.path} target="_blank">
                      <li
                        style={{
                          backgroundImage: `url(https://i.imgur.com/ZmTELra.png)`,
                          backgroundSize: 'cover',
                          marginRight: 10,
                        }}></li>
                    </a>
                  ))}
                </ul>
              </div>
            ) : null}
          </Grid>
          <Grid item lg={4}>
            {grouped['video'] ? (
              <div>
                <span className="secundary-title">Videos</span>
                <br />
                <br />
                <ul className="image-files" style={{display: 'flex'}}>
                  {grouped['video'].map((file) => (
                    <a href={file.path} target="_blank">
                      <li
                        style={{
                          backgroundImage: `url(https://i.imgur.com/ZmTELra.png)`,
                          backgroundSize: 'cover',
                          marginRight: 10,
                        }}></li>
                    </a>
                  ))}
                </ul>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </DetailContainer>
    </Paper>
  )
}

export default StateAttachments
