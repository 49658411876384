import React, {useEffect, useState, useCallback, Fragment, useMemo} from 'react'
import {useParams} from 'react-router'
import {benefitsService, proposalService} from 'app/services'
import {useHistory} from 'react-router-dom'

import {useDispatch} from 'react-redux'
import {Tab, Tabs} from '@material-ui/core'
import {ContainerHeaderTabs, Paper} from './styles'
import {Box} from '@material-ui/core'
import {changeActualEntity} from 'app/modules/Companies/redux'
import {AnimateLoading} from '_metronic/_partials/controls'
import BasicInfo from './BasicInfo'
import ProposalContact from './ProposalContact'
import Attachments from './ProposalAttachments'
import ProposalDetailsAndData from './ProposalDetailsAndData'
import ProposalDescription from './ProposalDescription'
import ProposalRevision from './ProposalRevision'
import ProposalLocalizationPublic from './ProposalLocalizationPublic'
import {useSnackbar} from 'notistack'
import {setAvailableBenefits} from 'app/modules/Proposals/redux'
import {useFormik} from 'formik'
import ProposalContactPublic from './ProposalContactPublic'
import ProposalRevisionPublic from './ProposalRevisionPublic'
import {companiesService} from 'app/services'

import AttachmentIcon from 'app/components/images/Icons/Attachment'
import ConfirmIcon from 'app/components/images/Icons/Confirm'
import ContactAddressIcon from 'app/components/images/Icons/ContactAddress'
import DescriptionIcon from 'app/components/images/Icons/Description'
import DetailsIcon from 'app/components/images/Icons/Details'
import TypesIcon from 'app/components/images/Icons/Types'
import getCities from 'app/services/cities/getCities'
import ProposalDetailPublic from './ProposalDetailPublic'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function EditProposal() {
  const [loading, setLoading] = useState(false)
  const [proposal, setProposal] = useState()
  const [ranking] = useState(null)
  const [submiting, setSubmiting] = useState(false)
  const [loadingBenefits, setLoadingBenefits] = useState(true)
  const [tabNext, setTabNext] = useState(0)
  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar()

  const {proposalId} = useParams()

  const getBenefits = useCallback(async () => {
    try {
      const {data} = await benefitsService.getBenefits()

      dispatch(setAvailableBenefits(data))
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao buscar os beneficios', {
        variant: 'error',
      })
    } finally {
      setLoadingBenefits(false)
    }
  }, [dispatch, enqueueSnackbar])

  const history = useHistory()

  const formikEdit = useFormik({
    initialValues: {
      attachments: [],
      type: null,
      name: '',
      city: null,
      incentives: {},
      areaAlias: '',
      squareMeters: 0,
      estimatedValue: 0,
      details: '',
      restrictions: '',
      mapsUrl: '',
      link_with_city: true,
      company_document: '',
      share_capital: 0,
      business_name: '',
      main_activity: '',
      company: {},
      postal_code: '',
      street: '',
      number: '',
      district: '',
      address_city: null,
      use_company_address: false,
      contact: {
        name: '',
        cellphone: '',
        phone: '',
        role: '',
        email: '',
        site: '',
      },
      opportunity_type: null,
      required_investment: 0,
      total_investment: 0,
      private_proposal_description: '',
    },
  })

  const stringToNumber = (str) => {
    if (isNaN(str) === false) {
      return parseFloat(str)
    }
    var moeda = str.replace('R$ ', '')
    moeda = moeda.replace('.', '')
    moeda = moeda.replace(',', '.')
    return parseFloat(moeda)
  }

  const handleChangeForm = (ev) => {
    const fieldName = ev.target.name
    var value = ev.target.value

    const complexNumberFields = ['project_company_federal_tax_id', 'project_company_main_activity']

    switch (fieldName) {
      case 'project_description':
      case 'project_total':
      case 'project_required_investment':
      case 'project_category':
        var complexField = fieldName.replace('project_', '')

        setProposal((stt) => ({
          ...stt,
          project: {
            ...stt.project,
            [complexField]: value,
          },
        }))
        break

      case 'project_company_share_capital':
      case 'project_company_federal_tax_id':
      case 'project_company_main_activity':
      case 'project_company_name':
        var complexField = fieldName.replace('project_company_', '')

        if (complexNumberFields.indexOf(fieldName) !== -1) {
          value = value.replace(/\D/g, '')
        }

        setProposal((stt) => ({
          ...stt,
          project: {
            ...stt.project,
            company: {
              ...stt.project.company,
              [complexField]: value,
            },
          },
        }))
        break
      case 'project_company_zip':
      case 'project_company_street':
      case 'project_company_number':
      case 'project_company_neighborhood':
      case 'project_company_city':
      case 'project_company_city_id':
        var complexField = fieldName.replace('project_company_', '')

        if (fieldName === 'project_company_zip') {
          value = value.replace(/\D/g, '')
        }

        setProposal((stt) => ({
          ...stt,
          project: {
            ...stt.project,
            company: {
              ...stt.project.company,
              address: {
                ...stt.project.company.address,
                [complexField]: value,
              },
            },
          },
        }))
        break
      case 'contact_name':
      case 'contact_email':
      case 'contact_linkedin':
      case 'contact_phone':
      case 'contact_responsibility':
        var complexField = fieldName.replace('contact_', '')

        if (fieldName === 'contact_phone') {
          value = value.replace(/\D/g, '')
        }

        const contacts = proposal.contacts.length ? proposal.contacts : [{[complexField]: value}]

        setProposal((stt) => ({
          ...stt,
          contacts: contacts.map((contact) => {
            contact[complexField] = value
            return contact
          }),
        }))
        break
      default:
        setProposal({...proposal, [fieldName]: value})
    }
  }

  const searchCompanyDocument = useCallback(async () => {
    try {
      const response = await companiesService.getCompany({document: proposal.project.company.federal_tax_id})

      handleChangeForm({target: {name: 'project_company_name', value: response.name}})
      handleChangeForm({target: {name: 'project_company_share_capital', value: response.share_capital}})
      handleChangeForm({target: {name: 'project_company_main_activity', value: response.main_activity.code}})
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao buscar o documento da empresa', {
        variant: 'error',
      })
    }
  }, [proposal, enqueueSnackbar])

  const fetchProposal = useCallback(async () => {
    try {
      setLoading(true)
      var data = await proposalService.getProposal(proposalId)

      if (data.project?.company) {
        data.project.company.address = {
          zip: data.project?.company?.zip,
          street: data.project?.company?.street,
          number: data.project?.company?.number,
          neighborhood: data.project?.company?.neighborhood,
          city: data.project?.company?.city,
          state: data.project?.company?.state,
          city_id: data.project?.company?.city?.id,
        }
      }

      setProposal(data)
      setLoading(false)
      dispatch(changeActualEntity({id: data.id, name: 'Oportunidade #' + data.id}))
    } catch (e) {
      setLoading(false)
    }
  }, [dispatch, proposalId])

  const handleChangeBenefits = (incentive, data) => {
    const benefits = proposal.benefits || []
    const hasBenefit = benefits.some((ben) => ben.type === incentive.type)

    if (!hasBenefit) {
      const dt = data ? {...incentive, ...data} : incentive
      benefits.push(dt)
      setProposal({...proposal, benefits: benefits})
    } else {
      if (data) {
        setProposal({
          ...proposal,
          benefits: benefits.map((i) => {
            if (i.type == incentive.type) {
              i = {...i, ...data}
            }
            return i
          }),
        })
      } else {
        setProposal({
          ...proposal,
          benefits: benefits.filter((i) => i.type !== incentive.type),
        })
      }
    }
  }

  const searchZip = useCallback(
    async (zip) => {
      try {
        const postalCode = zip

        const cleanPostalCode = postalCode?.match(/\d+/g)?.join('')

        if (cleanPostalCode?.length === 8) {
          const response = await (await fetch(`https://viacep.com.br/ws/${cleanPostalCode}/json`)).json()

          if (response.erro) {
            enqueueSnackbar('CEP não encontrado', {
              variant: 'warning',
            })
          } else {
            handleChangeForm({target: {name: 'project_company_street', value: response.logradouro}})
            handleChangeForm({target: {name: 'project_company_neighborhood', value: response.bairro}})

            const city = await getCities({code: response.ibge})
            handleChangeForm({target: {name: 'project_company_city', value: city.data[0]}})
            handleChangeForm({target: {name: 'project_company_city_id', value: city.data[0].id}})
          }
        }
      } catch (error) {
        enqueueSnackbar('Ocorreu um erro ao buscar o endereco', {
          variant: 'warning',
        })
      }
    },
    [enqueueSnackbar],
  )

  const formatPrivateProposal = (proposal) => {
    const params = JSON.parse(JSON.stringify(proposal))
    params.responsible_id = params.responsible.id

    if (params.project && params.project.company.share_capital) {
      params.project.company.share_capital = stringToNumber(params.project.company.share_capital)
    }

    if (params.project && params.project.total) {
      params.project.total = stringToNumber(params.project.total)
    }

    if (params.project && params.project.required_investment) {
      params.project.required_investment = stringToNumber(params.project.required_investment)
    }

    if (params.project && params.project.company) {
      params.project.company.contact = {
        name: params.project.company.contact_name,
        email: params.project.company.contact_email,
        cell_phone: params.project.company.contact_cell_phone,
      }
    }

    params.multimedia = params.multimedia.map((file) => {
      file.path = file.original_path ? file.original_path : file.path
      return file
    })

    return params
  }

  const handleAddAttachment = (attachment) => {
    const mult = proposal.multimedia
    mult.push(attachment)
    setProposal({...proposal, multimedia: mult})
  }

  const removeAttachment = (attachmentId) => {
    const mult = proposal.multimedia.filter((file) => file.id !== attachmentId)
    setProposal({...proposal, multimedia: mult})
  }

  const sections = (type) => {
    const typeTabs = {
      company: [
        {
          icon: (color) => <DetailsIcon color={color} width={27} />,
          title: 'Detalhes e dados',
          step: 0,
        },
        {
          icon: (color) => <ContactAddressIcon color={color} width={27} />,
          title: 'Localização e Incentivos',
          step: 1,
        },
        {
          icon: (color) => <DescriptionIcon color={color} width={27} />,
          title: 'Descrição',
          step: 2,
        },
        {
          icon: (color) => <AttachmentIcon color={color} width={27} />,
          title: 'Anexos',
          step: 3,
        },
        {
          icon: (color) => <ConfirmIcon color={color} width={27} />,
          title: 'Revisão',
          step: 4,
        },
      ],
      city: [
        {
          icon: (color) => <ContactAddressIcon color={color} width={27} />,
          title: 'Localização e Incentivos',
          step: 0,
        },
        {
          icon: (color) => <DescriptionIcon color={color} width={27} />,
          title: 'Detalhamento',
          step: 1,
        },
        {
          icon: (color) => <AttachmentIcon color={color} width={27} />,
          title: 'Anexos',
          step: 2,
        },
        {
          icon: (color) => <ConfirmIcon color={color} width={27} />,
          title: 'Revisão',
          step: 3,
        },
      ],
    }

    return typeTabs[type] || []
  }

  const onSubmit = useCallback(
    async (next) => {
      try {
        if (next) {
          if (tabNext < 4) {
            setTabNext(tabNext + 1)
          }
        }
        setSubmiting(true)
        const data = await proposalService.updateProposal(formatPrivateProposal(proposal))

        if (data) {
          if (!next) {
            enqueueSnackbar('Oportunidade atualizada com sucesso!', {
              variant: 'success',
            })
            history.push('/proposals')
          }
        } else {
          throw data
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } finally {
        setSubmiting(false)
      }
    },
    [enqueueSnackbar, proposal, tabNext, setTabNext],
  )

  useEffect(() => {
    fetchProposal()
    getBenefits()
  }, [fetchProposal, getBenefits])

  if (loading || loadingBenefits) {
    return <AnimateLoading />
  }

  return proposal ? (
    <div className="d-flex flex-column container-Proposalmatch-table-cards">
      <BasicInfo {...proposal} ranking={ranking} />
      <Paper>
        {proposal.type === 'city' ? (
          <RenderMunicipal
            proposal={proposal}
            handleChangeForm={handleChangeForm}
            handleChangeBenefits={handleChangeBenefits}
            onSubmit={onSubmit}
            submiting={submiting}
            tabNext={tabNext}
            setTabNext={setTabNext}
            sections={sections}
            handleAddAttachment={handleAddAttachment}
            removeAttachment={removeAttachment}
            searchZip={searchZip}
          />
        ) : (
          <RenderPrivate
            proposal={proposal}
            handleChangeForm={handleChangeForm}
            searchCompanyDocument={searchCompanyDocument}
            onSubmit={onSubmit}
            submiting={submiting}
            tabNext={tabNext}
            setTabNext={setTabNext}
            sections={sections}
            handleAddAttachment={handleAddAttachment}
            removeAttachment={removeAttachment}
            searchZip={searchZip}
          />
        )}
      </Paper>
    </div>
  ) : null
}

const RenderPrivate = ({
  proposal,
  handleChangeForm,
  searchCompanyDocument,
  onSubmit,
  submiting,
  tabNext,
  sections,
  handleAddAttachment,
  removeAttachment,
  searchZip,
  setTabNext,
}) => {
  const [tab, setTab] = useState(0)

  const handleChange = (event, newValue) => {
    setTab(newValue)
    setTabNext(newValue)
  }

  useEffect(() => {
    if (tabNext) {
      setTab(tabNext)
    }
  }, [tabNext])

  return (
    <Fragment>
      <ContainerHeaderTabs>
        <Tabs value={tab} onChange={handleChange}>
          {sections(proposal.type).map((item) => {
            const color = item.step === tab ? '#1E7BE1' : '#C9C9C9'
            return (
              <Tab
                icon={item.icon(color)}
                className="tabicon"
                iconPosition="start"
                label={item.title}
                value={item.step}
              />
            )
          })}
        </Tabs>
      </ContainerHeaderTabs>
      <TabPanel value={tab} index={0}>
        <ProposalDetailsAndData
          {...proposal}
          handleChange={handleChangeForm}
          searchCompanyDocument={searchCompanyDocument}
          onSubmit={onSubmit}
          submiting={submiting}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ProposalContact
          {...proposal}
          handleChange={handleChangeForm}
          onSubmit={onSubmit}
          submiting={submiting}
          searchZip={searchZip}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ProposalDescription {...proposal} handleChange={handleChangeForm} onSubmit={onSubmit} submiting={submiting} />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <Attachments
          {...proposal}
          handleChange={handleChangeForm}
          onSubmit={onSubmit}
          submiting={submiting}
          handleAddAttachment={handleAddAttachment}
          removeAttachment={removeAttachment}
        />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <ProposalRevision
          proposal={proposal}
          handleChange={handleChangeForm}
          onSubmit={onSubmit}
          submiting={submiting}
        />
      </TabPanel>
    </Fragment>
  )
}

const RenderMunicipal = ({
  proposal,
  handleChangeForm,
  searchCompanyDocument,
  onSubmit,
  submiting,
  tabNext,
  sections,
  handleAddAttachment,
  removeAttachment,
  handleChangeBenefits,
  searchZip,
  setTabNext,
}) => {
  const [tab, setTab] = useState(0)

  const handleChange = (event, newValue) => {
    setTab(newValue)
    setTabNext(newValue)
  }

  useEffect(() => {
    if (tabNext) {
      setTab(tabNext)
    }
  }, [tabNext])

  return (
    <Fragment>
      <ContainerHeaderTabs>
        <Tabs value={tab} onChange={handleChange}>
          {sections(proposal.type).map((item) => {
            const color = item.step === tab ? '#1E7BE1' : '#C9C9C9'
            return (
              <Tab
                icon={item.icon(color)}
                className="tabicon"
                iconPosition="start"
                label={item.title}
                value={item.step}
              />
            )
          })}
        </Tabs>
      </ContainerHeaderTabs>
      <TabPanel value={tab} index={0}>
        <ProposalLocalizationPublic
          {...proposal}
          handleChange={handleChangeForm}
          handleChangeBenefits={handleChangeBenefits}
          onSubmit={onSubmit}
          submiting={submiting}
          setTabNext={setTabNext}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ProposalDetailPublic
          {...proposal}
          handleChange={handleChangeForm}
          handleChangeBenefits={handleChangeBenefits}
          onSubmit={onSubmit}
          submiting={submiting}
          searchZip={searchZip}
        />
      </TabPanel>

      <TabPanel value={tab} index={2}>
        <Attachments
          {...proposal}
          handleChange={handleChangeForm}
          onSubmit={onSubmit}
          submiting={submiting}
          handleAddAttachment={handleAddAttachment}
          removeAttachment={removeAttachment}
        />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <ProposalRevision
          proposal={proposal}
          handleChange={handleChangeForm}
          onSubmit={onSubmit}
          submiting={submiting}
        />
      </TabPanel>
    </Fragment>
  )
}

export default EditProposal
