import {Popover, Typography} from '@material-ui/core'
import {citiesSearchPopover} from 'app/components'
import GestoresPublicos from 'app/components/cities/Icons/GestoresPublicos'
import CitiesSearchCitie from 'app/components/cities/SearchCitie'
import CitySearchPopover from 'app/components/proposal/CitySearchPopover'
import SearchPopover from 'app/components/proposal/SearchPopover'
import {exportStatesCsv} from 'app/modules/States/redux'
import {setShowOnlyFavorites} from 'app/modules/States/redux'
import * as stateSelectors from 'app/modules/States/redux/selectors'
import objectPath from 'object-path'
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {Overlay} from 'react-bootstrap'
import {ArrowLeft} from 'react-feather'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'

import {useHtmlClassService} from '../../_core/MetronicLayout'
import {getBreadcrumbsAndTitle, useSubheader} from '../../_core/MetronicSubheader'
import {BreadCrumbs} from './components/BreadCrumbs'

const ButtonWrapper = styled.button`
  color: #ffffff;
  background-color: ${({active, disabled}) => (active || disabled ? '#1E7BE1' : '#1E7BE133')};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  border-color: transparent;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  position: relative;
  padding: 13px 15px;
  display: flex;
  align-item: center;
  justify-content: center;
  &:hover ${ButtonWrapper} {
    background-color: #1e7be1;
  }
`
const ActiveOptionIndication = styled.div`
  position: absolute;
  background-color: #ff8f88;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  right: 3px;
  top: 2px;
`

const IconContainer = styled.div`
  margin-right: 12px;
`

export function SubHeaderState(props) {
  const uiService = useHtmlClassService()
  const location = useLocation()
  const history = useHistory()
  const subheader = useSubheader()
  const popoverRef = useRef(null)
  const dispatch = useDispatch()

  const getExportToCsv = useSelector(stateSelectors.getExportToCsv)

  const clickExportCitiesCsv = () => {
    dispatch(exportStatesCsv())
  }

  const {hasAppliedFilters, showOnlyFavorites} = useSelector(stateSelectors.getSearchParams)

  const [popoverTarget, setPopoverTarget] = useState(null)
  const [showPopover, setShowPopover] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(uiService.config, 'subheader.mobile-toggle'),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses('subheader_container', true),
    }
  }, [uiService])

  const onStarClick = () => {
    const route = location.pathname.substring(1)
    if (route.includes('states')) {
      dispatch(setShowOnlyFavorites(!showOnlyFavorites))
    }
  }

  const onAddClick = () => {
    history.push(`${location.pathname.substring(1)}/new`)
  }

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname)
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname)
    const breadcrumbs = aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs
    subheader.setBreadcrumbs(breadcrumbs)
    subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title)
    // eslint-disable-next-line
  }, [location.pathname])

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader])

  const changeViewMode = (mode) => {
    props.onChangeViewMode(mode === 'list' ? 'card' : 'list')
  }

  const hideSearchIcons = useMemo(() => {
    const routes = ['states/new']

    return !routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const isState = useMemo(() => {
    const routes = ['states']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showPlusIcon = useMemo(() => {
    const routes = ['states']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showFavoriteIcon = useMemo(() => {
    const routes = ['states']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showGestores = useMemo(() => {
    const routes = ['states']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showBackIcon = !hideSearchIcons && !showPlusIcon

  console.log('subheader.breadcrumbs', subheader.breadcrumbs)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div id="kt_subheader" className={`subheader ${layoutProps.subheaderCssClasses}`}>
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex justify-content-between flex-wrap flex-sm-nowrap `}
        style={{alignItems: 'flex-end'}}>
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle">
              <span />
              SearchPopover
            </button>
          )}
          <div className="d-flex flex-column">
            <h2 className="page-title">{subheader.title}</h2>
            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
        </div>

        <div className="d-flex align-items-center my-2">
          {hideSearchIcons &&
            (showPlusIcon ? (
              <>
                <ButtonWrapper
                  active={props.showMap}
                  onClick={props.onMapClick}
                  style={{
                    fontSize: 18,
                    padding: '7px 15px',
                  }}>
                  <IconContainer>
                    <i className="fas fa-globe-americas" style={{color: '#fff'}} />
                  </IconContainer>
                  <span>Mapa</span>
                </ButtonWrapper>

                {/* <ButtonWrapper active={showPopover} ref={popoverRef} onClick={handleClick}>
                  <i aria-hidden="true" className="fa fa-filter" style={{color: '#fff'}} />
                  {hasAppliedFilters && <ActiveOptionIndication />}
                </ButtonWrapper>

                <Popover
                  id={'filters'}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}>
                  <CitySearchPopover onClose={handleClose} />
                </Popover> */}

                <ButtonWrapper onClick={() => changeViewMode(props.viewMode)}>
                  <i
                    className={`fa ${props.viewMode === 'list' ? 'fa-th city' : 'fa-list'}`}
                    aria-hidden="true"
                    style={{color: '#fff'}}></i>
                </ButtonWrapper>
              </>
            ) : (
              <>
                <ButtonWrapper
                  active={props.showMap}
                  onClick={() => history.goBack()}
                  style={{fontSize: 18, padding: '7px 10px'}}>
                  <IconContainer style={{marginRight: 5}}>
                    <ArrowLeft style={{color: '#fff'}} />
                  </IconContainer>
                  <span>Voltar</span>
                </ButtonWrapper>
              </>
            ))}
          {/* {showFavoriteIcon && (
            <ButtonWrapper onClick={onStarClick}>
              <i className="fa fa-star" aria-hidden="true" style={{color: showOnlyFavorites ? '#FFA800' : '#fff'}} />
            </ButtonWrapper>
          )} */}
          {/* {showGestores && (
            <>
              <ButtonWrapper onClick={() => history.push(`politicians`)}>
                <GestoresPublicos />
                <span style={{display: 'inline-block', fontSize: 16, fontFamily: 'Nunito', marginLeft: 12}}>
                  Gestores Públicos
                </span>
              </ButtonWrapper>
              <ButtonWrapper onClick={clickExportCitiesCsv} disabled={getExportToCsv}>
                {getExportToCsv ? (
                  ' . . . '
                ) : (
                  <i className="fa fa-download" aria-hidden="true" style={{color: '#fff'}} />
                )}
              </ButtonWrapper>
            </>
          )} */}
          {showPlusIcon && !isState && (
            <ButtonWrapper onClick={onAddClick}>
              <i className="fas fa-plus" aria-hidden="true" style={{color: '#fff'}}></i>
            </ButtonWrapper>
          )}
          {showBackIcon && (
            <ButtonWrapper
              active={props.showMap}
              onClick={() => history.goBack()}
              style={{fontSize: 18, padding: '7px 10px'}}>
              <IconContainer style={{marginRight: 5}}>
                <ArrowLeft style={{color: '#fff'}} />
              </IconContainer>
              <span>Voltar</span>
            </ButtonWrapper>
          )}
        </div>
      </div>
    </div>
  )
}
