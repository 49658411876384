import httpClient from '../../client'

import apiRoutes from '../constants'

const addFavorite = async (entity) => {
  try {
    await httpClient.post(apiRoutes.ADD_FAVORITE, entity)
  } catch (error) {
    console.log(`Error adding favorite with ${JSON.stringify(entity)}`, error)
    return error
  }
}

export default addFavorite
