import {Box, Tab, Tabs} from '@material-ui/core'
import LocationDisabled from 'app/components/location/LocationDisabled'
import LocationIncentives from 'app/components/location/LocationIncentives'
import LocationVocation from 'app/components/location/LocationVocation'
import {citieService, userService} from 'app/services'
import getCities from 'app/services/cities/getCities'
import getRanking from 'app/services/cities/getRanking'
import getCompanies from 'app/services/companies/getCompanies'
import {createLocation, deleteImage, deleteMultimedia, getLocation, updateLocation} from 'app/services/location'
import {removeFileFromList} from 'lib/file-helper'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'

import ShowcaseGenerator from '../../../../components/ShowcaseGenerator'
import getShowcase from '../../../../services/cities/getShowcase'
import Attachments from '../../../Proposals/pages/EditProposal/ProposalAttachments'
import {changeActualEntity} from '../../redux'
import BasicInfo from '../DetailCitie/BasicInfo'
import CityBasicInfo from './CityBasicInfo'
import CityContact from './CityContact'
import Location from './CityLocation'
import CityRevision from './CityRevision'
import DetailCitieSkeleton from './skeleton'
import {ContainerHeaderTabs, Paper} from './styles'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function EditCitie() {
  const tabsRef = useRef()
  const tabsCount = useMemo(() => tabsRef.current?.querySelectorAll('[role="tablist"] > [role="tab"]').length, [
    tabsRef.current,
  ])

  const [loading, setLoading] = useState(false)
  const [citie, setCitie] = useState()
  const [userList, setUserList] = useState([])
  const [location, setLocation] = useState({
    benefits: [],
    multimedia: [],
    vocations: [],
    images: [],
    contacts: [],
  })
  const [companies, setCompanies] = useState(null)
  const [ranking, setRanking] = useState(null)
  const [tab, setTab] = useState(0)
  const {enqueueSnackbar} = useSnackbar()
  const {citieId} = useParams()
  const [tabNext, setTabNext] = useState(0)
  const [submiting, setSubmiting] = useState(false)
  const [industrialAreaChanged, setInsdustrialAreaChanged] = useState(false)
  const [showcaseUrl, setShowcaseUrl] = useState()
  const [imagesToUpload, setImagesToUpload] = useState([])
  const [multimediaToUpload, setMultimediaToUpload] = useState([])

  const history = useHistory()
  const dispatch = useDispatch()

  const isLocationDisabled = !location?.id
  const isLocationEnabled = !isLocationDisabled

  const fetchCitie = useCallback(async () => {
    try {
      setLoading(true)
      const data = await citieService.getCitie(citieId)
      setCitie(data)
      dispatch(changeActualEntity(data))

      fetchLocation(data.code, data)
      fetchCompanies(data.code)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }, [citieId])

  const fetchLocation = async (city_code, city_data) => {
    try {
      setLoading(true)
      const data = await getLocation(city_code)
      if (!data.description && city_data) {
        data.description = city_data.wiki_description
      }

      setLocation({...data, images: [], vocations: data.vocations.map((i) => i.description)})
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const fetchCompanies = useCallback(async (code) => {
    try {
      setLoading(true)
      const data = await getCompanies({city_code: code})
      setCompanies(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }, [])

  const fetchShowcaseGenerator = useCallback(async () => {
    try {
      setLoading(true)
      setSubmiting(true)

      const data = await getShowcase(citieId)
      setShowcaseUrl(data.url)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
      setSubmiting(false)
    }
  }, [citieId])

  const fetchDeleteGalleryImage = async (image) => {
    await deleteImage(citie.code, image.id)
    removeImageFromCitie(image)
  }

  let complexField

  const handleChangeForm = (ev) => {
    const fieldName = ev.target.name
    var value = ev.target.value

    switch (fieldName) {
      case 'location_cityhall_street':
      case 'location_cityhall_zip':
      case 'location_cityhall_number':
      case 'location_cityhall_neighborhood':
      case 'location_cityhall_city':
      case 'location_cityhall_city_id':
        complexField = fieldName.replace('location_cityhall_', '')
        setLocation((stt) => ({
          ...stt,
          city_hall: {
            ...stt.city_hall,
            [complexField]: value,
          },
        }))
        break
      case 'location_contact_name':
      case 'location_contact_email':
      case 'location_contact_linkedin':
      case 'location_contact_phone':
      case 'location_contact_mobile_phone':
      case 'location_contact_mobile_phone_2':
      case 'location_contact_responsibility':
        complexField = fieldName.replace('location_contact_', '')

        if (['location_contact_phone'].indexOf(fieldName) !== -1) {
          value = value.replace(/\D/g, '')
        }

        // TODO
        // eslint-disable-next-line no-case-declarations
        const contacts = location.contacts.length ? location.contacts : [{[complexField]: value}]

        setLocation((stt) => ({
          ...stt,
          contacts: contacts.map((contact) => {
            contact[complexField] = value
            return contact
          }),
        }))
        break
      default:
        setLocation((st) => ({
          ...st,
          [fieldName]: value,
        }))
    }
  }

  const handleChangeLogo = (fileEntry) => {
    setLocation((st) => ({
      ...st,
      logo: fileEntry.url,
    }))
  }

  const handleAddImages = (image) => {
    setImagesToUpload((prevState) => [...prevState, image])
  }

  const handleRemoveImages = (image) => {
    const mult = [...location.images]
    const removeIdx = mult.findIndex((img) => img.id == image.id)

    mult.splice(removeIdx, 1)
    setLocation((st) => ({
      ...st,
      images: mult,
    }))
  }

  const handleClearLocationImages = (updatedImages = []) => {
    setImagesToUpload([])
    setLocation((st) => ({
      ...st,
      images: updatedImages,
    }))
  }
  const handleClearLocationMultimedia = (updatedMultimedia = []) => {
    setMultimediaToUpload([])
    setLocation((st) => ({
      ...st,
      multimedia: updatedMultimedia,
    }))
  }

  const updateCitieImages = (imagesList) => {
    if (imagesList && imagesList.length > 0) {
      setCitie((prevState) => {
        return {
          ...prevState,
          location: {
            ...(prevState?.location ?? {}),
            images: imagesList,
          },
        }
      })
    }
  }

  const removeImageFromCitie = (image) => {
    setCitie((prevState) => {
      const prevImages = [...(prevState.location?.images ?? [])]
      const removeIdx = prevImages.findIndex((img) => img.id == image.id)

      if (removeIdx !== -1 && prevImages[removeIdx]) {
        prevImages.splice(removeIdx, 1)
      }
      return {
        ...prevState,
        location: {
          ...(prevState?.location ?? {}),
          images: prevImages,
        },
      }
    })
  }

  const handleChange = (event, newValue) => {
    setTab(newValue)
    setTabNext(newValue)
  }

  const formartRequestLocation = (data, isUpdate) => {
    const params = JSON.parse(JSON.stringify(data))

    if (params.city_hall) {
      params.city_hall.city_id = params.city_hall.city.id
    }

    if (!industrialAreaChanged) {
      delete params.industrial_area
    }

    if (isUpdate) {
      if (params.vocations[0]?.id) {
        delete params.vocations
      }
    }

    params.images = imagesToUpload
    params.multimedia = multimediaToUpload

    return params
  }

  const handleDrawIndustrialArea = (ev) => {
    if (ev.features[0]?.geometry) {
      setInsdustrialAreaChanged(true)
      setLocation({
        ...location,
        industrial_area: ev.features[0] || null,
      })
    }
  }

  const handleChangeBenefits = (incentive, data) => {
    const benefits = location.benefits || []
    const hasBenefit = benefits.some((ben) => ben.type === incentive.type)

    if (!hasBenefit) {
      const dt = data ? {...incentive, ...data} : incentive
      benefits.push(dt)
      setLocation({...location, benefits: benefits})
    } else {
      if (data) {
        setLocation({
          ...location,
          benefits: benefits.map((i) => {
            if (i.type == incentive.type) {
              i = {...i, ...data}
            }
            return i
          }),
        })
      } else {
        setLocation({
          ...location,
          benefits: benefits.filter((i) => i.type !== incentive.type),
        })
      }
    }
  }

  const handleAddAttachment = (attachment) => {
    setMultimediaToUpload((prevState) => [...prevState, attachment])
  }

  /**
   * @param {LocationFileEntity} file
   */
  const handleRemoveAttachment = async (file) => {
    await deleteMultimedia(citie.code, file.id)
    setLocation((prevLocation) => ({
      ...location,
      multimedia: removeFileFromList(prevLocation.multimedia, file),
    }))
  }

  const handleChangeRegionalVocation = (incentive, data) => {
    const vocations = location.vocations ? location.vocations : []
    const hasVocation = vocations.some((ben) => ben === incentive)

    if (!hasVocation) {
      const dt = data ? {...incentive, ...data} : incentive
      vocations.push(dt)
      setLocation({...location, vocations})
    } else {
      if (data) {
        setLocation({
          ...location,
          cards: vocations.map((i) => {
            if (i == incentive) {
              i = {...i, ...data}
            }
            return i
          }),
        })
      } else {
        setLocation({
          ...location,
          vocations: vocations.filter((i) => i !== incentive),
        })
      }
    }
  }

  const handleEnableLocation = async () => {
    await createLocation(citie.code, formartRequestLocation(location))
    await fetchLocation(citie.code)
  }

  const searchZip = useCallback(
    async (zip) => {
      try {
        const postalCode = zip

        const cleanPostalCode = postalCode?.match(/\d+/g)?.join('')

        if (cleanPostalCode?.length === 8) {
          const response = await (await fetch(`https://viacep.com.br/ws/${cleanPostalCode}/json`)).json()

          if (response.erro) {
            enqueueSnackbar('CEP não encontrado', {
              variant: 'warning',
            })
          } else {
            handleChangeForm({target: {name: 'location_cityhall_street', value: response.logradouro}})
            handleChangeForm({target: {name: 'location_cityhall_neighborhood', value: response.bairro}})

            const city = await getCities({code: response.ibge})
            handleChangeForm({target: {name: 'location_cityhall_city', value: city.data[0]}})
            handleChangeForm({target: {name: 'location_cityhall_city_id', value: city.data[0].id}})
          }
        }
      } catch (error) {
        enqueueSnackbar('Ocorreu um erro ao buscar o endereco', {
          variant: 'warning',
        })
      }
    },
    [enqueueSnackbar],
  )

  const onSubmit = useCallback(
    async (next) => {
      try {
        if (next) {
          if (tabNext < tabsCount) {
            setTabNext(tabNext + 1)
          }
        }
        setSubmiting(true)

        const data = await updateLocation(citie.code, formartRequestLocation(location, true))

        handleClearLocationImages(data.images)
        handleClearLocationMultimedia(data.multimedia)
        if (data) {
          // const imagesFromLocation = data.city?.location?.images ?? [] // nao funfou por que nao tem a url completa aqui
          // updateCitieImages(imagesFromLocation)
          if (!next) {
            enqueueSnackbar('Localidade atualizada com sucesso!', {
              variant: 'success',
            })
            history.push('/cities')
          }
        } else {
          throw data
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } finally {
        setSubmiting(false)
      }
    },
    [enqueueSnackbar, location, citie, tabNext, setTabNext, fetchLocation],
  )

  useEffect(() => {
    userService.getListUsers().then((res) => setUserList(res.data))

    fetchCitie()
    if (citieId) {
      getRanking(citieId).then(setRanking)
    }
  }, [fetchCitie, citieId])

  useEffect(() => {
    if (tabNext) {
      setTab(tabNext)
    }
  }, [tabNext])

  if (loading && !citie) {
    return <DetailCitieSkeleton />
  }

  return (
    <div className="d-flex flex-column container-citymatch-table-cards">
      <BasicInfo {...citie} ranking={ranking} />

      {isLocationDisabled && (
        <Paper>
          <LocationDisabled handleEnableLocation={handleEnableLocation} />
        </Paper>
      )}

      {isLocationEnabled && (
        <Paper>
          <ContainerHeaderTabs>
            <Tabs value={tab} onChange={handleChange} ref={tabsRef}>
              <Tab label="Informações Básicas" className="tabicon" />
              <Tab label="Incentivos Disponíveis" className="tabicon" />
              <Tab label="Contatos" className="tabicon" />
              <Tab label="Área Industrial" className="tabicon" />
              <Tab label="Vocação Regional" className="tabicon" />
              <Tab label="Anexos" className="tabicon" />
              <Tab label="Revisão" className="tabicon" />
              <Tab label="Vitrine Econômica" className="tabicon" />
            </Tabs>
          </ContainerHeaderTabs>
          <TabPanel value={tab} index={0}>
            {citie && (
              <CityBasicInfo
                companies={companies}
                citie={citie} // foi necessario 😞 ......
                {...citie}
                location={location}
                imagesToUpload={imagesToUpload}
                userList={userList}
                //
                handleChange={handleChangeForm}
                handleChangeLogo={handleChangeLogo}
                handleAddImages={handleAddImages}
                onDeleteGalleryItem={fetchDeleteGalleryImage}
                onSubmit={onSubmit}
                submiting={submiting}
                setLoading={setLoading}
                loading={loading}
              />
            )}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <LocationIncentives
              title="INCENTIVOS OFERECIDOS PELO MUNICÍPIO"
              {...citie}
              location={location}
              handleChange={handleChangeForm}
              onSubmit={onSubmit}
              submiting={submiting}
              setLoading={setLoading}
              handleChangeBenefits={handleChangeBenefits}
            />
          </TabPanel>
          {/* <TabPanel value={tab} index={2} >
            <CityCompanies {...citie} />
          </TabPanel> */}
          <TabPanel value={tab} index={2}>
            <CityContact
              {...citie}
              title="ENDEREÇO DA SEDE DA PREFEITURA"
              location={location}
              handleChange={handleChangeForm}
              onSubmit={onSubmit}
              submiting={submiting}
              setLoading={setLoading}
              searchZip={searchZip}
            />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <Location
              {...citie}
              location={location}
              handleChange={handleChangeForm}
              onSubmit={onSubmit}
              submiting={submiting}
              setLoading={setLoading}
              handleDrawIndustrialArea={handleDrawIndustrialArea}
            />
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <LocationVocation
              title="VOCAÇÃO REGIONAL DO MUNICÍPIO"
              {...citie}
              location={location}
              handleChange={handleChangeForm}
              onSubmit={onSubmit}
              submiting={submiting}
              setLoading={setLoading}
              handleChangeRegionalVocation={handleChangeRegionalVocation}
            />
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <Attachments
              {...location}
              onSubmit={onSubmit}
              submiting={submiting}
              handleChange={handleChangeForm}
              handleAddAttachment={handleAddAttachment}
              removeAttachment={handleRemoveAttachment}
            />
          </TabPanel>
          <TabPanel value={tab} index={6}>
            <CityRevision
              city={citie}
              location={location}
              handleChange={handleChangeForm}
              onSubmit={onSubmit}
              submiting={submiting}
            />
          </TabPanel>
          <TabPanel value={tab} index={7}>
            <ShowcaseGenerator
              onGenerateShowcase={fetchShowcaseGenerator}
              submiting={submiting}
              showcaseUrl={showcaseUrl}
            />
          </TabPanel>
        </Paper>
      )}
    </div>
  )
}

export default EditCitie
