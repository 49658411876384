import {Popover} from '@material-ui/core'
import {ProposalsSearchPopover} from 'app/components'
import GestoresPublicos from 'app/components/cities/Icons/GestoresPublicos'
import CitiesSearchCitie from 'app/components/cities/SearchCitie'
import SearchPopover from 'app/components/proposal/SearchPopover'
import * as citiesSelectors from 'app/modules/Cities/redux/selectors'
import * as customersSelectors from 'app/modules/ManageCustomer/redux/selectors'
import {setShowOnlyFavorites} from 'app/modules/Proposals/redux'
import * as proposalSelectors from 'app/modules/Proposals/redux/selectors'
import * as statesSelectors from 'app/modules/States/redux/selectors'
import objectPath from 'object-path'
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {ArrowLeft} from 'react-feather'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import styled from 'styled-components'

import {useHtmlClassService} from '../../_core/MetronicLayout'
import {getBreadcrumbsAndTitle, useSubheader} from '../../_core/MetronicSubheader'
import {BreadCrumbs} from './components/BreadCrumbs'

const ButtonWrapper = styled.button`
  color: #ffffff;
  background-color: ${({active}) => (active ? '#1E7BE1' : '#1E7BE133')};
  border-color: transparent;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  position: relative;
  padding: 13px 15px;
  display: flex;
  align-item: center;
  justify-content: center;
  &:hover ${ButtonWrapper} {
    background-color: #1e7be1;
  }
`

const ActiveOptionIndication = styled.div`
  position: absolute;
  background-color: #ff8f88;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  right: 3px;
  top: 2px;
`

const IconContainer = styled.div`
  margin-right: 12px;
`

export function SubHeader(props) {
  const uiService = useHtmlClassService()
  const location = useLocation()
  const history = useHistory()
  const subheader = useSubheader()
  const popoverRef = useRef(null)
  const dispatch = useDispatch()
  const hasLocationFilter = window.location.hash && window.location.hash.search('type') !== -1

  const {hasAppliedFilters, showOnlyFavorites} = useSelector(proposalSelectors.getSearchParams)
  const customerHasAppliedFilters = useSelector(customersSelectors.getSearchParams)

  const {actualEntity} = useSelector(citiesSelectors.getSearchParams)
  const {actualEntity: actualEntityState} = useSelector(statesSelectors.getSearchParams)

  const [popoverTarget, setPopoverTarget] = useState(null)
  const [showPopover, setShowPopover] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const {admin} = useSelector(
    ({auth}) => ({
      admin: auth.user.roles.includes('ROLE_ADMIN'),
    }),
    shallowEqual,
  )

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(uiService.config, 'subheader.mobile-toggle'),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses('subheader_container', true),
    }
  }, [uiService])

  const onStarClick = () => {
    const route = location.pathname.substring(1)

    if (route.includes('proposals')) {
      dispatch(setShowOnlyFavorites(!showOnlyFavorites))
    }

    if (route.includes('proposals')) {
      dispatch(setShowOnlyFavorites(!showOnlyFavorites))
    }
  }

  const onAddClick = () => {
    history.push(`${location.pathname.substring(1)}/new`)
  }

  const onEditClick = () => {
    history.push(`/${location.pathname.substring(1)}/edit`)
  }

  const isPolitician = useMemo(() => {
    return location.pathname.substring(1).search('politicians') !== -1
  }, [location.pathname])

  const isCompany = useMemo(() => {
    return location.pathname.substring(1).search('companies') !== -1
  }, [location.pathname])

  const isCity = useMemo(() => {
    return location.pathname.substring(1).search('cities') !== -1
  }, [location.pathname])

  const isState = useMemo(() => {
    return location.pathname.substring(1).search('states') !== -1
  }, [location.pathname])

  const isProposal = useMemo(() => {
    return location.pathname.substring(1).search('proposals/') !== -1
  }, [location.pathname])

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname)
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname)
    const breadcrumbs = aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs
    subheader.setBreadcrumbs(breadcrumbs)
    subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title)

    if (isPolitician) {
      subheader.setTitle('Gestores Públicos')
      subheader.setBreadcrumbs([
        {
          title: 'Cidades',
          pathname: '/cities',
        },
        {
          title: 'Gestores Públicos',
          pathname: '/politicians',
        },
      ])
    }

    if (isCity && actualEntity) {
      subheader.setTitle(actualEntity.name)
      subheader.setBreadcrumbs([
        {
          title: 'Cidades',
          pathname: '/cities',
        },
        {
          title: actualEntity.name,
          pathname: '/cities/' + actualEntity.id,
        },
      ])
    }

    if (isState && actualEntityState) {
      subheader.setTitle(actualEntityState.name)
      subheader.setBreadcrumbs([
        {
          title: 'Estados',
          pathname: '/states',
        },
        {
          title: actualEntityState.name,
          pathname: '/states/' + actualEntityState.id,
        },
      ])
    }

    if (isCompany && actualEntity) {
      subheader.setTitle(actualEntity.name)
      subheader.setBreadcrumbs([
        {
          title: 'Empresas',
          pathname: '/companies',
        },
        {
          title: actualEntity.name,
          pathname: '/companies/' + actualEntity.id,
        },
      ])
    }

    if (isProposal && actualEntity) {
      subheader.setTitle(actualEntity.name)
      subheader.setBreadcrumbs([
        {
          title: 'Oportunidades',
          pathname: '/proposals',
        },
        {
          title: actualEntity.name,
          pathname: '/proposals/' + actualEntity.id,
        },
      ])
    }
    // eslint-disable-next-line
  }, [location.pathname, actualEntity, actualEntityState])

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader])

  const handlePopoverClick = (event) => {
    setPopoverTarget(event.target)
    setShowPopover(!showPopover)
  }

  const changeViewMode = (mode) => {
    props.onChangeViewMode(mode === 'list' ? 'card' : 'list')
  }

  const renderSearchModal = (overlayProps) => {
    const currentModule = location.pathname.substring(1)

    if (currentModule === 'proposals') {
      return <ProposalsSearchPopover onClose={() => hidePopOver()} {...overlayProps} />
    }

    if (currentModule === 'cities') {
      return <CitiesSearchCitie onClose={() => hidePopOver()} {...overlayProps} style={{width: 'auto'}} />
    }

    return null
  }

  const hidePopOver = () => {
    setPopoverTarget(null)
    setShowPopover(false)
  }

  const hideSearchIcons = useMemo(() => {
    const routes = ['proposals/new', 'manage-users', 'manage-customers']
    return !routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showPlusIcon = useMemo(() => {
    const routes = ['proposals', 'cities', 'manage-users', 'manage-customers']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showFavoriteIcon = useMemo(() => {
    const routes = ['proposals', 'cities']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showGestores = useMemo(() => {
    const routes = ['cities']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showEdit = useMemo(() => {
    let path = location.pathname.split('/')

    if (path.length !== 3) return false

    const routes = []

    if (path[1] === 'proposals' && admin) routes.push(`proposals/${path[2]}`)

    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname, admin])

  const showDownload = useMemo(() => {
    const routes = []
    let path = location.pathname.split('/')

    if (path.length === 3 && path[1] === 'proposals') routes.push(`proposals/${path[2]}`)

    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showBackIcon = !hideSearchIcons && !showPlusIcon

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div id="kt_subheader" className={`subheader ${layoutProps.subheaderCssClasses}`}>
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex justify-content-between flex-wrap flex-sm-nowrap `}
        style={{alignItems: 'flex-end'}}>
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle">
              <span />
              SearchPopover
            </button>
          )}
          <div className="d-flex flex-column">
            <h2 className="page-title">{subheader.title}</h2>
            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
        </div>

        <div className="d-flex align-items-center my-2">
          {hideSearchIcons &&
            (showPlusIcon ? (
              <>
                <ButtonWrapper
                  active={props.showMap}
                  onClick={props.onMapClick}
                  style={{
                    fontSize: 18,
                    padding: '7px 15px',
                  }}>
                  <IconContainer>
                    <i className="fas fa-globe-americas" style={{color: '#fff'}} />
                  </IconContainer>
                  <span>Mapa</span>
                </ButtonWrapper>
                {hasLocationFilter ? null : (
                  <ButtonWrapper active={showPopover} ref={popoverRef} onClick={handleClick}>
                    <i aria-hidden="true" className="fa fa-filter" style={{color: '#fff'}} />
                    {customerHasAppliedFilters && hasAppliedFilters && <ActiveOptionIndication />}
                  </ButtonWrapper>
                )}

                <Popover
                  id={'filters'}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}>
                  <SearchPopover onClose={handleClose} />
                </Popover>
                <ButtonWrapper onClick={() => changeViewMode(props.viewMode)}>
                  {props.viewMode === 'list' ? (
                    <i className="fa fa-th city" aria-hidden="true" style={{color: '#fff'}}>
                      {' '}
                    </i>
                  ) : (
                    <i className="fa fa-list" aria-hidden="true" style={{color: '#fff'}}></i>
                  )}
                </ButtonWrapper>
              </>
            ) : (
              <>
                <ButtonWrapper
                  active={props.showMap}
                  onClick={() => history.goBack()}
                  style={{fontSize: 18, padding: '7px 10px'}}>
                  <IconContainer style={{marginRight: 5}}>
                    <ArrowLeft style={{color: '#fff'}} />
                  </IconContainer>
                  <span>Voltar</span>
                </ButtonWrapper>
              </>
            ))}
          {showDownload && (
            <ReactToPrint
              trigger={() => {
                return (
                  <ButtonWrapper>
                    <i className="fa fa-download" aria-hidden="true" style={{color: '#fff'}} />
                  </ButtonWrapper>
                )
              }}
              content={() => document.getElementById('kt_body')}
            />
          )}
          {showFavoriteIcon && (
            <ButtonWrapper onClick={onStarClick}>
              <i className="fa fa-star" aria-hidden="true" style={{color: showOnlyFavorites ? '#FFA800' : '#fff'}} />
            </ButtonWrapper>
          )}
          {showGestores && (
            <ButtonWrapper onClick={onStarClick}>
              <GestoresPublicos />
              <span style={{display: 'inline-block', fontSize: 16, fontFamily: 'Nunito', marginLeft: 12}}>
                Gestores Públicos
              </span>
            </ButtonWrapper>
          )}
          {showPlusIcon && !isCity && (
            <ButtonWrapper onClick={onAddClick}>
              <i className="fas fa-plus" aria-hidden="true" style={{color: '#fff'}}></i>
            </ButtonWrapper>
          )}
          {showBackIcon && (
            <ButtonWrapper
              active={props.showMap}
              onClick={() => history.goBack()}
              style={{fontSize: 18, padding: '7px 10px'}}>
              <IconContainer style={{marginRight: 5}}>
                <ArrowLeft style={{color: '#fff'}} />
              </IconContainer>
              <span>Voltar</span>
            </ButtonWrapper>
          )}
          {showEdit && (
            <ButtonWrapper onClick={onEditClick}>
              <i className="fas fa-cog" aria-hidden="true" style={{color: '#fff'}}></i>
            </ButtonWrapper>
          )}
        </div>
      </div>
    </div>
  )
}
