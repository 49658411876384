import {useState, useCallback} from 'react'

import {useSnackbar} from 'notistack'

import {favoritesService} from 'app/services'

const useFavorite = ({id, is_favorite, entity, label}) => {
  const {enqueueSnackbar} = useSnackbar()

  const [isFavorite, setIsFavorite] = useState(is_favorite)
  const [loading, setLoading] = useState(false)

  const favoriteProposal = useCallback(async () => {
    try {
      setLoading(true)

      await favoritesService.addFavorite({
        entity,
        entity_id: entity === 'company' ? id : id.toString(),
      })

      setIsFavorite(true)

      enqueueSnackbar(`${label} adicionada aos favoritos`, {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(`Ocorreu um erro ao favoritar a ${label} selecionada`, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [enqueueSnackbar, id])

  const unfavoriteProposal = useCallback(async () => {
    try {
      setLoading(true)

      await favoritesService.removeFavorite({
        entity,
        entity_id: id,
      })

      setIsFavorite(false)

      enqueueSnackbar(`${label} removida dos favoritos`, {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(`Ocorreu um erro ao remover a ${label} dos favoritos`, {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [enqueueSnackbar, id])

  const action = useCallback(() => {
    if (isFavorite) {
      unfavoriteProposal(id)
    } else {
      favoriteProposal(id)
    }
  }, [favoriteProposal, id, isFavorite, unfavoriteProposal])

  return {
    action,
    loading,
    isFavorite,
  }
}

export default useFavorite
