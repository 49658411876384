import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useSelector, shallowEqual, connect, useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import {ModalProgressBar} from '../../../_metronic/_partials/controls'
import * as auth from '../Auth'
import * as reduxAuth from '../Auth/_redux/authRedux'
import {Button} from 'react-bootstrap'
import updateUser from 'app/services/user/updateUser'
import {useSnackbar} from 'notistack'
import {Select, MenuItem, TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {customerService} from 'app/services'

function NotificationConfiguration(props) {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar()

  const notificationCity = user.notification_types.includes('city')
  const notificationState = user.notification_types.includes('state')
  const notificationCompany = user.notification_types.includes('company')
  const [city, setCity] = useState(notificationCity)
  const [state, setState] = useState(notificationState)
  const [company, setCompany] = useState(notificationCompany)
  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState(null)
  const [customerValue, setCustomerValue] = useState([])

  useEffect(() => {
    const loadCustomers = async () => {
      try {
        setLoading(true)
        const {data} = await customerService.getListCustomers()
        setCustomers(data)
        setCustomerValue(data.filter((customer) => user.notification_customers.includes(customer.id)))
        setLoading(false)
      } catch (error) {
        console.error('Error loading customers:', error)
      }
    }

    loadCustomers()
  }, [])

  const initialValues = {
    city: notificationCity,
    state: notificationState,
    company: notificationCompany,
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      saveUser(values, setStatus, setSubmitting)
    },
    onReset: (values, {resetForm}) => {
      resetForm()
    },
  })

  const saveUser = async (values, setStatus, setSubmitting) => {
    try {
      setLoading(true)

      let notification_types = []
      if (city) notification_types.push('city')
      if (state) notification_types.push('state')
      if (company) notification_types.push('company')

      let notification_customers = []
      customerValue.forEach((customer) => {
        notification_customers.push(customer.id)
      })

      let updateBody = {
        roles: user.roles,
        notification_types: notification_types,
        notification_customers: notification_customers,
      }

      let objUser = await updateUser(user.id, updateBody)

      dispatch(reduxAuth.actions.setUser(objUser))

      enqueueSnackbar('Configurações atualizadas!', {
        variant: 'success',
      })

      setLoading(false)
      setSubmitting(false)
    } catch (err) {
      enqueueSnackbar('Ops, ocorreu um erro ao atualizar sua configuração, tente novamente!', {
        variant: 'error',
      })
      setLoading(false)
      setSubmitting(false)
    }
  }

  const handleChange = (event) => {
    const {name, value} = event.target
    if (name === 'city') setCity(value)
    if (name === 'state') setState(value)
    if (name === 'company') setCompany(value)
  }

  return (
    <form className="card card-custom card-stretch" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">Configuração de Notificações</h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">Configure suas opções de notificação</span>
        </div>
        <div className="card-toolbar">
          <Button
            type="submit"
            className="btn btn-success mr-2"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
          >
            Salvar alterações
            {formik.isSubmitting}
          </Button>
          <Link to="/user-profile/profile-overview" className="btn btn-secondary">
            Fechar
          </Link>
        </div>
      </div>

      <div className="form">
        <div className="card-body">
          <div className="mb-8">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">
                Escolha receber notificações de novas oportunidades por categoria:
              </h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Públicas</label>
            <div className="col-lg-9 col-xl-6">
              <Select
                labelId="select-label-notificacao-city"
                id="select-notificacao-city"
                value={city}
                label="Públicas"
                onChange={handleChange}
                name="city"
              >
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Estados</label>
            <div className="col-lg-9 col-xl-6">
              <Select
                labelId="select-label-notificacao-state"
                id="select-notificacao-state"
                value={state}
                label="Estados"
                onChange={handleChange}
                name="state"
              >
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Privadas</label>
            <div className="col-lg-9 col-xl-6">
              <Select
                labelId="select-label-notificacao-company"
                id="select-notificacao-company"
                value={company}
                label="Privadas"
                onChange={handleChange}
                name="company"
              >
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Clientes</label>
            <div className="col-lg-9 col-xl-6">
              <Autocomplete
                multiple
                value={customerValue}
                options={customers}
                getOptionLabel={(customer) => customer.name}
                noOptionsText="Nenhum item encontrado"
                onChange={(event, value) => {
                  event.target = {value: value?.id, name: 'customer'}
                  handleChange(event)
                  setCustomerValue(value)
                }}
                renderInput={(params) => <TextField {...params} label="Cliente" value={customer} />}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default connect(null, auth.actions)(NotificationConfiguration)
