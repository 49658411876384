import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createFilter from 'redux-persist-transform-filter'

import { storageService } from 'app/services'

export const availableColums = [
  {
    key: 'id',
    label: '#',
  },
  {
    key: 'name',
    label: 'Nome',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'is_active',
    label: 'Ativo',
  },
  // {
  //   key: 'customer_type',
  //   label: 'Tipo',
  // },
  {
    key: 'customer_name',
    label: 'Cliente',
  },
  {
    key: 'roles',
    label: 'Permissões',
  },
]

export const availableColumsLogs = [
  {
    key: 'id',
    label: '#',
  },
  {
    key: 'action',
    label: 'Ação',
  },
  {
    key: 'description',
    label: 'Descrição',
  },
  {
    key: 'occurred_at',
    label: 'Data',
  },
  {
    key: 'metadata',
    label: 'Parâmetros',
  },
]

export const initialState = {
  columns: {
    available: availableColums,
    active: availableColums,
  },
  filters: {
    ordering: {
      activeSort: null,
      orders: [],
    },
  },
  limit: {
    current: 25,
    available: [25, 50, 100],
  },
  page: 1,
  universalSearch: {
    hasAppliedFilters: false,
    searchParams: {},
    selectedFilters: {},
    showMap: true,
    viewMode: 'list',
  },
}

export const usersReducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeActiveColumns: (state, action) => {
      state.columns.active = action.payload
    },
    changePage: (state, action) => {
      state.page = action.payload
    },
    changeActiveSort: (state, action) => {
      state.filters.ordering.activeSort = action.payload
    },
    setAvailableOrdering: (state, action) => {
      state.filters.ordering.orders = action.payload
    },
    toogleMap: (state) => {
      state.universalSearch.showMap = !state.universalSearch.showMap
    },
    changeViewMode: (state, action) => {
      state.universalSearch.viewMode = action.payload
    },
    changeLimit: (state, action) => {
      state.limit.current = action.payload
    },
    changeSearchParams: (state, action) => {
      state.universalSearch.searchParams = action.payload
    },
    changeSelectedFilters: (state, action) => {
      state.universalSearch.selectedFilters = action.payload
    },
    changeActualEntity: (state, action) => {
      state.universalSearch.actualEntity = action.payload
    },
    setAppliedFilters: (state, action) => {
      state.universalSearch.hasAppliedFilters = action.payload
      state.page = 1
    },
    setShowOnlyFavorites: (state, action) => {
      state.universalSearch.showOnlyFavorites = action.payload
      state.page = 1
    },
    cleanSearchParams: (state) => {
      state.universalSearch.searchParams = initialState.universalSearch.searchParams
      state.universalSearch.selectedFilters = initialState.universalSearch.selectedFilters
      state.universalSearch.hasAppliedFilters = false
    },
  },
})

export const {
  changeActiveColumns,
  changeActiveSort,
  setShowOnlyFavorites,
  changePage,
  changeViewMode,
  setAvailableOrdering,
  toogleMap,
  changeLimit,
  cleanSearchParams,
  changeSearchParams,
  changeSelectedFilters,
  changeActualEntity,
  setAppliedFilters,
} = usersReducer.actions

export default persistReducer(
  {
    storage,
    key: storageService.storageKeys.STORAGE_KEYS.USERS,
    transforms: [createFilter('universalSearch.showMap', 'universalSearch.viewMode', 'columns', 'filters')],
  },
  usersReducer.reducer
)
