import styled from 'styled-components'

const injectBackgroundColor = ({status}) =>
  status === 'open' ? '#007BFF33' : status == 'lost' ? '#e91e6333' : '#52C44833'

const injectBorderColor = ({status}) => (status === 'open' ? '#1E7BE1' : status == 'lost' ? '#e91e63' : '#52C448')

const injectFontColor = ({status}) => (status === 'open' ? '#007BFF' : status == 'lost' ? '#e91e63' : '#52C448')

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const Icon = styled.div`
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  height: ${({height}) => height};
  width: ${({width}) => width};
  background: ${injectBackgroundColor};
  font-size: ${({iconSize}) => iconSize};
  div {
    border: 3px solid ${injectBorderColor};
    background-color: ${injectBackgroundColor};
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
`

export const IconContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: ${({height}) => height};
  width: ${({width}) => width};
  margin-right: 10px;
`

export const Label = styled.span`
  align-items: center;
  color: ${injectFontColor};
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
`
