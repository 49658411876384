import CustomerPagination from 'app/components/customers/Pagination'
import React from 'react'

import {availableColums} from '../../redux'
import TableHead from './Header'
import Row from './Row'
import SkeletonRow from './Row/skeleton'

const SKELETON_ITEMS = 10

const Table = ({loading, customers = [], total, limit, changePage, changeLimit, page, filters}) => {
  const columns = availableColums

  const renderCustomer = (customer) => <Row key={customer.id} {...customer} columns={columns} filters={filters} />

  return (
    <div className="container-citymatch-table">
      <div className="table-responsive" style={{overflowX: 'auto'}}>
        <table className="table position-relative" style={{minHeight: '150px'}}>
          <TableHead />
          {total === 0 && !loading ? (
            <p className="text-center position-absolute filter-info w-100 pt-10">Nenhum cliente encontrado</p>
          ) : (
            <tbody>
              {loading
                ? Array.from({length: SKELETON_ITEMS}).map((_, index) => <SkeletonRow key={index} columns={columns} />)
                : customers.map(renderCustomer)}
            </tbody>
          )}
        </table>
      </div>
      {!loading && (
        <CustomerPagination limit={limit} page={page} total={total} changePage={changePage} changeLimit={changeLimit} />
      )}
    </div>
  )
}

export {Table}
