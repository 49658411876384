import httpClient from '../../client'

import apiRoutes from '../constants'

import * as mocks from './mock'

// mockClient.onGet(apiRoutes.GET_USER_BY_TOKEN).reply(200, mocks.success);

const getUserByToken = async () => {
  try {
    const {data} = await httpClient.get(apiRoutes.GET_USER_BY_TOKEN)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log('Error calling getUserByToken', error)

    return error
  }
}

export default getUserByToken
