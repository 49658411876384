import React, {useCallback, useEffect, useState} from 'react'
import {Carousel, OverlayTrigger, Tooltip} from 'react-bootstrap'

import {
  BottomItem,
  BottomItemValue,
  CarouselImage,
  Container,
  ContainerHeader,
  DetailContainer,
  FavoriteIcon,
  IcentiveContainer,
  Paper,
} from '../styles'
import {useFavorite} from 'app/hooks'
import {Circular as CircularLoading} from 'app/components'
import {Box, Popover} from '@material-ui/core'
import getCompanies from 'app/services/companies/getCompanies'
import {HelpCircle} from 'react-feather'

const MOCK_IMAGES = [
  {path: 'https://paranaportal.uol.com.br/wp-content/uploads/2021/03/curitiba-aniversario-238-anos.jpg'},
  {path: 'https://www.melhoresdestinos.com.br/wp-content/uploads/2019/02/passagens-aereas-curitiba-capa2019-02.jpg'},
  {
    path:
      'https://tribunapr.uol.com.br/wp-content/uploads/2021/04/20130749/geada-frio-botanico-curitiba-aniele-nascimento-970x550.jpg',
  },
]

const BasicCitieInfo = ({
  id,
  code,
  name,
  location,
  status,
  type,
  counters,
  mayor,
  is_favorite,
  is_capital,
  updated_at,
  demographic_data,
  ranking,
  state,
  politicians,
  wiki_image,
  wiki_description,
  ...rest
}) => {
  const [totalCompanies, setTotalCompanies] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (code) {
      getCompanies({city_code: code}).then((res) => {
        setTotalCompanies(res.total)
      })
    }
  }, [id, code])

  const {action: handleCitieOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'city',
    label: 'Cidade',
  })

  const renderCaouselImage = useCallback(
    ({path}, idx) => (
      <Carousel.Item key={`k=${idx}`}>
        <CarouselImage src={`${path}`} alt="slide" />
      </Carousel.Item>
    ),
    [],
  )

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount)

  const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

  const findDemographicInfo = useCallback(
    (key, defaultParam = 'Não Informado', label = '') => {
      const info = demographic_data?.find(({indicator}) => indicator === key)?.value
      if (key === 'liberdade_economica' && !info) {
        return false
      }
      return info ? `${info}${label}` : `${defaultParam}${label}`
    },
    [demographic_data],
  )

  const renderIncentive = useCallback(
    ({type, description}, idx) => (
      <OverlayTrigger key={`k=${idx}`} placement="top" overlay={<Tooltip>{description ? description : type}</Tooltip>}>
        <IcentiveContainer key={`k=${type}`}>{type.toUpperCase()}</IcentiveContainer>
      </OverlayTrigger>
    ),
    [],
  )

  return (
    <Paper>
      <ContainerHeader>
        <Box display="flex">
          {name ? name.toUpperCase() : ''}
          {state ? ` - ${state.uf}` : ''}
          {is_capital ? <span style={{marginLeft: 25, color: '#007BFF'}}>CAPITAL DO ESTADO</span> : null}
        </Box>
        <Box>
          {loadingFavorite ? (
            <CircularLoading />
          ) : (
            <FavoriteIcon
              aria-hidden="true"
              className="fa fa-star"
              isFavorite={isFavorite}
              onClick={() => handleCitieOpportunity()}
            />
          )}
        </Box>
      </ContainerHeader>
      <DetailContainer>
        <Container style={{marginTop: 0}}>
          <Carousel style={{width: '48%'}}>
            {location?.images && location?.images.length > 0
              ? location.images.map(renderCaouselImage)
              : renderCaouselImage({path: wiki_image})}
          </Carousel>
          <Container width="48%" display="block">
            <Container display="block">
              <Container>
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Sobre o município</b>
                </label>
              </Container>
              <span style={{color: '#848484', fontSize: 14, fontFamily: 'Nunito'}}>
                {location ? location.description : wiki_description ? wiki_description : 'Sem conteúdo'}
              </span>
              <hr style={{width: '100%', borderTop: '1px solid #E3E3E3', marginBottom: 20, marginTop: 20}} />
            </Container>
            <Container style={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
              {mayor && mayor.meta.photo ? (
                <div
                  style={{
                    width: 160,
                    height: 160,
                    backgroundImage: `url(${mayor.meta.photo})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    border: '2px solid #257fe2',
                    marginRight: '15px',
                  }}
                  className="rounded-circle"></div>
              ) : null}
              {mayor ? (
                <Container
                  display="block"
                  width="auto"
                  style={{marginLeft: '1rem', marginTop: 0, alignItems: 'flex-start'}}>
                  <Container display="block" width="auto">
                    <Container width="auto">
                      <label>
                        <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Prefeito(a)</b>
                      </label>
                    </Container>
                    <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>
                      <a href={`/politicians/${mayor.id}`} style={{color: '#1E7BE1', fontWeight: 'bold'}}>
                        {mayor.name}
                      </a>{' '}
                      - {mayor.political_party.abbreviation}
                    </span>
                  </Container>
                  <Container display="block">
                    <Container>
                      <label>
                        <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Eleito em</b>
                      </label>
                    </Container>
                    <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>
                      {mayor.meta.electionYear}
                    </span>
                  </Container>
                </Container>
              ) : null}
            </Container>
            {mayor ? (
              <hr style={{width: '100%', borderTop: '1px solid #E3E3E3', marginBottom: 0, marginTop: 20}} />
            ) : null}
          </Container>
        </Container>
        <Container style={{alignItems: 'flex-start'}}>
          {location ? (
            <BottomItem>
              <label>
                <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Incentivos disponíveis ao investidor</b>
              </label>
              <Container style={{justifyContent: 'flex-start'}}>{location.benefits.map(renderIncentive)}</Container>
            </BottomItem>
          ) : null}
          {counters ? (
            <BottomItem>
              <label>
                <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Oportunidades Ativas</b>
              </label>
              <BottomItemValue>
                {ranking && ranking.proposals ? formatNumber(ranking.proposals.total.open) : 0}
              </BottomItemValue>
            </BottomItem>
          ) : null}
          <BottomItem>
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Empresas Ativas</b>
            </label>
            <BottomItemValue>{formatNumber(totalCompanies)}</BottomItemValue>
          </BottomItem>
          <BottomItem>
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Políticos Ativos</b>
            </label>
            <BottomItemValue>{politicians ?? 0}</BottomItemValue>
          </BottomItem>
          <BottomItem>
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Vocação Regional</b>
            </label>

            <BottomItemValue>
              {location?.vocations[0]?.description}{' '}
              {location?.vocations.length > 1 ? `+${location?.vocations.length - 1}` : null}{' '}
            </BottomItemValue>
          </BottomItem>
          <BottomItem>
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>População</b>
            </label>
            <BottomItemValue>{formatNumber(findDemographicInfo('populacao_estimada', 0))}</BottomItemValue>
          </BottomItem>

          {findDemographicInfo('liberdade_economica') ? (
            <BottomItem>
              <label>
                <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>LLE aprovada em</b>
                <HelpCircle
                  style={{width: 16, cursor: 'pointer', marginLeft: 5}}
                  onClick={handlePopoverOpen}
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                />
              </label>

              <BottomItemValue>
                {findDemographicInfo('liberdade_economica') ? findDemographicInfo('liberdade_economica') : 'Não'}
              </BottomItemValue>
            </BottomItem>
          ) : null}
        </Container>

        <Popover
          id="mouse-over-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <div style={{padding: 20, width: 310}}>
            A Lei da Liberdade Econômica (13.874/19), trata da proteção à livre iniciativa e ao livre exercício de
            atividades econômicas. Por meio dela, fica instituída a Declaração de Direitos de Liberdade Econômica, além
            de uma série de garantias para o livre mercado. Saiba mais em:{' '}
            <a
              target="_blank"
              href="https://www.gov.br/pt-br/campanhas/liberdade-economica/a-lei-de-liberdade-economica-nos-municipios">
              https://www.gov.br/pt-br/campanhas/liberdade-economica/a-lei-de-liberdade-economica-nos-municipios
            </a>
          </div>
        </Popover>
      </DetailContainer>
    </Paper>
  )
}

export default BasicCitieInfo
