import React from 'react'
import {Card} from 'react-bootstrap'

import Shimmer from 'react-loading-skeleton'

import {CitieHeader, CitieUf, Actions, MayorContainer, MayorName, DescriptionContainer} from '../CitieCard/styles'

const CardSkeleton = () => {
  return (
    <Card
      style={{
        maxWidth: '439px',
        margin: '1rem',
        padding: '1rem',
        flex: '1 0 25%',
      }}>
      <Card.Header style={{borderBottom: 'none'}} className="d-flex p-3 flex-wrap">
        <CitieHeader>
          <div style={{width: '100', display: 'flex', whiteSpace: 'nowrap'}}>
            <Shimmer width="70px" />
            <CitieUf>
              <span>
                <Shimmer width="30px" />
              </span>
            </CitieUf>
          </div>
          <Actions>
            <Shimmer width="25px" />
            <Shimmer width="25px" />
          </Actions>
        </CitieHeader>
        <MayorContainer>
          <Shimmer width="30px" />
        </MayorContainer>
        <MayorContainer>
          <Shimmer width="100%" />
        </MayorContainer>
        <MayorName>
          <Shimmer />
        </MayorName>
      </Card.Header>
      <Shimmer
        height="200px"
        style={{
          borderRadius: '10px',
          width: '95%',
          marginLeft: '0.7rem',
          marginRight: '0.5rem',
        }}
      />
      <Card.Body style={{padding: '0.75rem', paddingTop: '0.5rem'}}>
        <DescriptionContainer>
          <Shimmer />
          <Shimmer />
          <Shimmer />
        </DescriptionContainer>
        <hr />
        <MayorContainer style={{marginTop: '1rem'}}>
          <Shimmer width="100px" />
        </MayorContainer>
        <div className="d-flex justify-content-between">
          <Shimmer width="140px" height="5vh" />
          <Shimmer width="140px" height="5vh" />
        </div>
        <MayorContainer style={{marginTop: '1rem'}}>
          <Shimmer width="100px" />
        </MayorContainer>
        <div className="d-flex justify-content-start">
          <Shimmer width="30px" style={{marginLeft: '0.5rem'}} />
          <Shimmer width="30px" style={{marginLeft: '0.5rem'}} />
          <Shimmer width="30px" style={{marginLeft: '0.5rem'}} />
          <Shimmer width="30px" style={{marginLeft: '0.5rem'}} />
        </div>
        <div className="d-flex justify-content-between" style={{marginTop: '1rem'}}>
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <div className="d-flex justify-content-between">
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <div className="d-flex justify-content-between">
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <div className="d-flex justify-content-between">
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <div className="d-flex justify-content-between">
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <div className="d-flex justify-content-between">
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <div className="d-flex justify-content-between">
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <div className="d-flex justify-content-between">
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <div className="d-flex justify-content-between">
          <Shimmer width="100px" />
          <Shimmer width="100px" />
        </div>
        <Shimmer style={{float: 'right', width: '114px', marginTop: '1rem'}} height="39px" />
      </Card.Body>
    </Card>
  )
}

export default CardSkeleton
