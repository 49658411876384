import httpClient from '../../client'

import apiRoutes from '../constants'

const createUser = async (body) => {
  try {
    const {data} = await httpClient.post(`${apiRoutes.POST_USERS}`, {...body})
    if (data) {
      return data
    }
    throw data
  } catch (error) {
    throw Error(error.message)
  }
}

export default createUser
