import React from 'react'

import Pagination from '../Pagination'
import ProposalCard from './ProposalCard'
import Skeleton from './ProposalCard/skeleton'
import { changePage, changeLimit } from 'app/modules/Proposals/redux'

const SKELETON_ITEMS = 10

const ProposalCads = ({ limit, loading, page, proposals, total }) => {
  const renderProposal = (proposal) => <ProposalCard key={`k=${proposal.id}`} proposal={proposal} />

  return (
    <div className="container-citymatch-table-cards">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row flex-wrap" style={{ justifyContent: 'space-between' }}>
          {loading
            ? Array.from({ length: SKELETON_ITEMS }).map((_, index) => <Skeleton key={index} />)
            : proposals?.map(renderProposal)}
        </div>

        {total > 0 && !loading && (
          <Pagination
            actualLenght={proposals.length}
            limit={limit}
            loading={loading}
            page={page}
            total={total}
            changePage={changePage}
            changeLimit={changeLimit}
          />
        )}
      </div>
    </div>
  )
}

export default ProposalCads
