import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createFilter from 'redux-persist-transform-filter'

import { storageService } from 'app/services'

export const availableColums = [
  {
    key: 'id',
    label: '#',
  },
  {
    key: 'project_company_name',
    label: 'Nome do projeto',
  },
  {
    key: 'type',
    label: 'Tipo',
  },
  {
    key: 'project_total',
    label: 'Investimento',
  },
  {
    key: 'created_at',
    label: 'Criada em',
  },
  {
    key: 'city',
    label: 'Município',
  },
  {
    key: 'uf',
    label: 'UF',
  },
  {
    key: 'status',
    label: 'Status',
  },
]

export const initialState = {
  columns: {
    available: availableColums,
    active: availableColums,
  },
  filters: {
    ordering: {
      activeSort: null,
      orders: [],
    },
  },
  limit: {
    current: 25,
    available: [25, 50, 100],
  },
  page: 1,
  universalSearch: {
    hasAppliedFilters: false,
    actualZoom: 3,
    searchParams: {
      selectedStates: [],
      selectedCities: [],
      selectedStatus: [],
      selectedTypes: [],
      investmentStart: null,
      investmenteEnd: null,
      investimentRange: [100000, 1000000],
      startDate: null,
      endDate: null,
    },
    showMap: true,
    showOnlyFavorites: false,
    viewMode: 'list',
  },
  newProposal: {},
}

export const proposalsReducer = createSlice({
  name: 'proposals',
  initialState,
  reducers: {
    changeActiveColumns: (state, action) => {
      state.columns.active = action.payload
    },
    changePage: (state, action) => {
      state.page = action.payload
    },
    changeActiveSort: (state, action) => {
      state.filters.ordering.activeSort = action.payload
    },
    setAvailableOrdering: (state, action) => {
      state.filters.ordering.orders = action.payload
    },
    toogleMap: (state) => {
      state.universalSearch.showMap = !state.universalSearch.showMap
    },
    changeViewMode: (state, action) => {
      state.universalSearch.viewMode = action.payload
    },
    changeSearchParams: (state, action) => {
      state.universalSearch.searchParams = action.payload
    },
    changeCenterMap: (state, action) => {
      state.universalSearch.centerMap = action.payload
    },
    setAppliedFilters: (state, action) => {
      state.universalSearch.hasAppliedFilters = action.payload
      state.universalSearch.hasFiltersClear = false
      state.page = 1
    },
    setShowOnlyFavorites: (state, action) => {
      state.universalSearch.showOnlyFavorites = action.payload
      state.page = 1
    },
    cleanSearchParams: (state, action) => {
      state.universalSearch.searchParams = initialState.universalSearch.searchParams
      state.universalSearch.hasAppliedFilters = false
      state.universalSearch.actualZoom = 3
    },
    changeLimit: (state, action) => {
      state.limit.current = action.payload
    },
    changeActualZoom: (state, action) => {
      state.universalSearch.actualZoom = action.payload
    },
    setAvailableBenefits: (state, action) => {
      state.newProposal.benefits = action.payload
    },
  },
})

export const {
  changeActiveColumns,
  changeActualZoom,
  changeActiveSort,
  changePage,
  changeViewMode,
  setAvailableOrdering,
  toogleMap,
  changeSearchParams,
  changeCenterMap,
  cleanSearchParams,
  setAppliedFilters,
  setShowOnlyFavorites,
  changeLimit,
  setAvailableBenefits,
} = proposalsReducer.actions

export default persistReducer(
  {
    storage,
    key: storageService.storageKeys.STORAGE_KEYS.PROPOSALS,
    transforms: [createFilter('universalSearch.showMap', 'universalSearch.viewMode', 'columns', 'filters')],
  },
  proposalsReducer.reducer
)
