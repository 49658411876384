import React from 'react'

import Shimmer from 'react-loading-skeleton'

const SKELETON_STYLE = {
  borderRadius: '10px',
  marginBottom: '2rem',
}

const DetailProposalSkeleton = () => {
  return (
    <div className="d-flex flex-column">
      <Shimmer width="100%" height="300px" style={SKELETON_STYLE} />
      <Shimmer width="100%" height="300px" style={SKELETON_STYLE} />
      <Shimmer width="100%" height="300px" style={SKELETON_STYLE} />
      <Shimmer width="100%" height="300px" style={SKELETON_STYLE} />
      <Shimmer width="100%" height="300px" style={SKELETON_STYLE} />
    </div>
  )
}

export default DetailProposalSkeleton
