import React, {useState} from 'react'
import {Box, Divider, Grid} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'

import {Title, Container} from '../styles'
import ReactInputMask from 'react-input-mask'
import {LabelM0} from '../../NewProposal/City/Details/styles'

const ProposalContact = ({project, handleChange, contacts, onSubmit, submiting, searchZip}) => {
  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>ENDEREÇO DA OPORTUNIDADE</Title>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="name">CEP</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <ReactInputMask
            autoComplete="off"
            className="form-control"
            name="project_company_zip"
            id="project_company_zip"
            value={project.company?.address?.zip}
            onChange={(ev) => {
              handleChange(ev)
              searchZip(ev.target.value)
            }}
            placeholder="99.999-999"
            mask="99.999-999"
            style={{maxWidth: 135}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="name">Logradouro</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="project_company_street"
            id="project_company_street"
            type="text"
            onChange={handleChange}
            value={project.company?.address?.street}
            placeholder="Ex.: Rua Arnaldo"
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="name">Número</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="project_company_number"
            id="project_company_number"
            type="text"
            onChange={handleChange}
            value={project.company?.address?.number}
            placeholder=""
            style={{maxWidth: 100}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="name">Bairro</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="project_company_neighborhood"
            id="project_company_neighborhood"
            type="text"
            onChange={handleChange}
            value={project.company?.address?.neighborhood}
            placeholder=""
            style={{maxWidth: 400}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>UF</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <LabelM0>{project?.company?.address?.state?.uf}</LabelM0>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>Município</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <LabelM0>{project?.company?.address?.city?.name}</LabelM0>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}></Grid>
        <Grid item lg={9}>
          <Divider style={{maxWidth: 600, marginBottom: 30}} />
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>CONTATO DA OPORTUNIDADE </Title>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="contact_name">Nome para contato</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="contact_name"
            id="contact_name"
            type="text"
            onChange={handleChange}
            value={contacts[0]?.name}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="contact_phone">Celular</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="contact_phone"
            id="contact_phone"
            type="text"
            onChange={handleChange}
            value={contacts[0]?.phone}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="contact_responsibility">Cargo do contato</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="contact_responsibility"
            id="contact_responsibility"
            type="text"
            onChange={handleChange}
            value={contacts[0]?.responsibility}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="contact_email">E-mail</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="contact_email"
            id="contact_email"
            type="text"
            onChange={handleChange}
            value={contacts[0]?.email}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="contact_linkedin">Site</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="contact_linkedin"
            id="contact_linkedin"
            type="text"
            onChange={handleChange}
            value={contacts[0]?.linkedin}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ProposalContact
