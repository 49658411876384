import httpClient from '../../client'

import apiRoutes from '../constants'

const getCustomerById = async (id) => {
  try {
    const {data} = await httpClient.get(`${apiRoutes.GET_CUSTOMER_BY_ID}/${id}`)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    return error
  }
}

export default getCustomerById
