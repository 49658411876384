import httpClient from '../../client' // { mockClient }

import apiRoutes from '../constants'

import * as mocks from './mock'

// mockClient.onGet(apiRoutes.GET_USER_BY_ID).reply(200, mocks.success);

const getUserById = async (id) => {
  try {
    const { data } = await httpClient.get(`${apiRoutes.GET_USER_BY_ID}/${id}`)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log('Error calling getUserById', error)

    return error
  }
}

export default getUserById
