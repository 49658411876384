import httpClient from '../../client'
import apiRoutes from '../constants'

const sendFile = async ({file, customerId, onUploadProgress}) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    let queryParams = ''

    if (customerId) {
      queryParams = '?customer=' + customerId
    }

    const {data} = await httpClient.post(apiRoutes.SEND_FILE + queryParams, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling fileUpload with ${JSON.stringify(file)}`, error)

    return error
  }
}

export default sendFile
