import httpClient from '../../client'

import apiRoutes from '../constants'

import * as mocks from './mock'

// mockClient.onPost(apiRoutes.LOGIN_CHECK).reply(200, mocks.success);

const loginCheck = async ({username, password}) => {
  try {
    const {data} = await httpClient.post(apiRoutes.LOGIN_CHECK, {
      username,
      password,
    })

    if (data.token) {
      return data
    }

    throw data
  } catch (error) {
    console.log('Error calling AuthCheck', error)

    return error
  }
}

export default loginCheck
