/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useSelector, shallowEqual, connect, useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {ModalProgressBar} from '../../../_metronic/_partials/controls'
import * as auth from '../Auth'

function AccountInformation(props) {
  // Fields
  const [loading, setloading] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user, shallowEqual)
  useEffect(() => {}, [user])

  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true)
    const updatedUser = Object.assign(user, {
      username: values.username,
      email: values.email,
      language: values.language,
      timeZone: values.timeZone,
      communication: {
        email: values.communicationEmail,
        sms: values.communicationSMS,
        phone: values.communicationPhone,
      },
    })
    // user for update preparation
    dispatch(props.setUser(updatedUser))
    setTimeout(() => {
      setloading(false)
      setSubmitting(false)
    }, 1000)
  }
  // UI Helpers
  const initialValues = {
    username: user?.username ?? '',
    email: user?.email ?? '',
    customer: user?.customer?.name ?? '',
    language: user?.language ?? '',
    timeZone: user.timeZone ?? '',
    communicationEmail: user?.communication?.email ?? '',
    communicationSMS: user?.communication?.sms ?? '',
    communicationPhone: user?.communication?.phone ?? '',
  }
  const Schema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Wrong email format').required('Email is required'),
    language: Yup.string(),
    timeZone: Yup.string(),
    communicationEmail: Yup.bool(),
    communicationSMS: Yup.bool(),
    communicationPhone: Yup.bool(),
  })
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      saveUser(values, setStatus, setSubmitting)
    },
    onReset: (values, {resetForm}) => {
      resetForm()
    },
  })

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">Informações da conta</h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">Mude as configurações da conta</span>
        </div>
      </div>
      <div className="form">
        <div className="card-body">
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">E- mail</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  placeholder="Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses('email')}`}
                  disabled
                  name="email"
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Customer</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  placeholder="Customer"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses('customer')}`}
                  disabled
                  name="customer"
                  {...formik.getFieldProps('customer')}
                />
                {formik.touched.customer && formik.errors.customer ? (
                  <div className="invalid-feedback">{formik.errors.customer}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
export default connect(null, auth.actions)(AccountInformation)
