import React, {useCallback, useState} from 'react'
import {Box, Chip} from '@material-ui/core'
import {Button} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'
import {useSnackbar} from 'notistack'

import {filesService} from 'app/services'

import {Circular as CircularLoading} from 'app/components'

import {Container, Title, Icon, FileUploadLabel, FileUploadWarning, AttachedFilesLabel} from './styles'

const ProposalAttachments = ({formik, onNextStep, onPreviousStep}) => {
  const {enqueueSnackbar} = useSnackbar()

  const {
    setFieldValue,
    values: {attachments},
  } = formik

  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(
    async ([file]) => {
      try {
        if (file.size > 5000000) throw new Error('O tamanho de cada arquivo é limitado a 5 MB.')

        setLoading(true)

        const fileResponse = await filesService.sendFile({file})

        if (fileResponse?.file) {
          enqueueSnackbar('Anexo enviado!', {
            variant: 'success',
          })

          setFieldValue('attachments', [...attachments, fileResponse.file])
        } else {
          throw new Error('Uploading error')
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } finally {
        setLoading(false)
      }
    },
    [attachments, enqueueSnackbar, setFieldValue],
  )

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDrop,
  })

  const renderAttachment = (atachment) => {
    return (
      <Box key={atachment.original_name} marginBottom="10px" marginRight="10px">
        <Chip
          label={atachment.original_name}
          size="small"
          variant="outlined"
          style={{
            color: '#8C8C8C',
            borderRadius: 10,
            background: '#1E7BE10C',
            borderColor: '#1E7BE10C',
          }}
        />
      </Box>
    )
  }

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
        <Box marginBottom="40px">
          <Title>Anexos</Title>
        </Box>

        <Box marginBottom="30px">
          <label>
            <b>Inserir arquivos</b>
          </label>
          <p style={{color: '#868E96', fontSize: '12px', fontFamily: 'Nunito'}}>
            Deseja anexar alguma imagem, vídeo, apresentação ou documento sobre a oportunidade?
          </p>
        </Box>

        <Box
          alignItems="center"
          borderRadius="20px"
          display="flex"
          flexDirection="column"
          marginBottom="20px"
          justifyContent="center"
          height="357px"
          {...getRootProps()}
          className="attachtment-dragger">
          <input {...getInputProps()} />
          <Box alignItems="center" display="flex" flexDirection="column">
            <Box alignItems="center" display="flex" flexDirection="column" marginBottom="10px">
              {loading ? (
                <>
                  <CircularLoading />
                </>
              ) : (
                <>
                  <Box marginBottom="10px">
                    <Icon className="fas fa-file-upload" />
                  </Box>
                  <FileUploadLabel>Arraste e solte aqui</FileUploadLabel>
                  <Button>Selecione o arquivo</Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flex={1} justifyContent="flex-end" marginBottom="20px">
          <FileUploadWarning>O tamanho de cada arquivo é limitado a 5 MB.</FileUploadWarning>
        </Box>
        {attachments.length ? (
          <Box display="flex" flex={1} flexDirection="column" flexWrap="wrap">
            <Box marginBottom="10px">
              <AttachedFilesLabel>Arquivos já anexados</AttachedFilesLabel>
            </Box>
            <Box display="flex" flex={1}>
              {attachments.map(renderAttachment)}
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>
        <Button onClick={onNextStep} disabled={loading}>
          Próximo passo ⇀
        </Button>
      </Box>
    </Container>
  )
}

export default ProposalAttachments
