import React from 'react'

const Icon = ({color, width}) => (
  <svg
    id="icon_description"
    data-name="icon/description"
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : '42'}
    height="42"
    viewBox="0 0 42 42">
    <path id="bound" d="M0,0H42V42H0Z" fill="none" />
    <g id="shapes" transform="translate(3.5 5.25)">
      <path
        id="Vector"
        d="M21.851,26.25,18.9,31.6a1.75,1.75,0,0,1-2.99.121l-3.626-5.47H5.25A5.25,5.25,0,0,1,0,21V5.25A5.25,5.25,0,0,1,5.25,0h24.5A5.25,5.25,0,0,1,35,5.25V21a5.25,5.25,0,0,1-5.25,5.25Z"
        fill={color}
        opacity="0.3"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M1.75,0H17.5a1.75,1.75,0,0,1,0,3.5H1.75a1.75,1.75,0,0,1,0-3.5Zm0,7H10.5a1.75,1.75,0,0,1,0,3.5H1.75a1.75,1.75,0,0,1,0-3.5Z"
        transform="translate(5.25 7)"
        fill={color}
      />
    </g>
  </svg>
)

export default Icon
