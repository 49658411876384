export function validarCNPJ(rawCnpj) {
  const cnpj = rawCnpj.replace(/[^\d]+/g, '')

  if (cnpj.length !== 14 || /^(.)\1+$/.test(cnpj)) return false

  const cnpjInvalidosConhecidos = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ]
  if (cnpjInvalidosConhecidos.includes(cnpj)) return false

  function validarDigitosCNPJ(cnpj, posicao) {
    const numeros = cnpj.substring(0, posicao)
    const digitos = cnpj.substring(posicao)
    let soma = 0
    let pos = posicao - 7

    for (let i = posicao; i >= 1; i--) {
      soma += numeros.charAt(posicao - i) * pos--
      if (pos < 2) pos = 9
    }

    const resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    return resultado == digitos.charAt(0)
  }

  if (!validarDigitosCNPJ(cnpj, 12)) return false
  if (!validarDigitosCNPJ(cnpj, 13)) return false

  return true
}
