import getProposals from './getProposals'
import completeProposal from './completeProposal'
import reOpenProposal from './reOpenProposal'
import getProposal from './getProposal'
import createProposal from './createProposal'
import updateProposal from './updateProposal'
import lostProposal from './lostProposal'
import deleteProposal from './deleteProposal'

export default {
  completeProposal,
  getProposals,
  reOpenProposal,
  getProposal,
  createProposal,
  updateProposal,
  lostProposal,
  deleteProposal,
}
