export default {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  TwoFactor: '[TwoFactor] Action',
  Terms: '[Terms] Action',
  ChangePassword: '[ChangePassword] Action',
}
