import {Circular as CircularLoading} from 'app/components'
import {useFavorite} from 'app/hooks'
import React, {useCallback, useRef, useState} from 'react'
import {ListGroup, Overlay, Popover} from 'react-bootstrap'
import {Edit, Menu, Star} from 'react-feather'
import {useHistory} from 'react-router-dom'

import * as columnUtils from '../utils'
import {FavoriteIcon, StyledPopover} from './styles'

const Row = (props) => {
  const {
    columns,
    id,
    name,
    uf,
    coode,
    governor,
    proposals,
    vocations,
    benefits,
    is_favorite,
    has_permission_to_edit,
  } = props
  const popoverRef = useRef(null)

  const history = useHistory()

  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const goToStateDetails = useCallback(
    (stateId) => {
      history.push(`/states/${stateId}`)
    },
    [history],
  )
  const goToStateEdit = useCallback(
    (stateId) => {
      history.push(`/states/${stateId}/edit`)
    },
    [history],
  )

  const handlePopoverClick = useCallback((event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }, [])

  const formatCurrency = (amount, multiply) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      notation: 'compact',
    }).format(multiply ? multiply * amount : amount)

  const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

  return (
    <tr>
      {columnUtils.shouldRenderColumn({
        columnKey: 'name',
        stateColumns: columns,
      }) && (
        <td
          className="align-middle"
          style={{color: '#00428B', cursor: 'pointer', textTransform: 'uppercase', fontWeight: 'bold'}}
          onClick={() => goToStateDetails(id)}>
          {name}
        </td>
      )}

      {columnUtils.shouldRenderColumn({
        columnKey: 'benefits',
        stateColumns: columns,
      }) && <td className="align-middle">{benefits?.map((ben) => ben.toUpperCase()).join(', ')}</td>}

      {columnUtils.shouldRenderColumn({
        columnKey: 'vocations',
        stateColumns: columns,
      }) && <td className="align-middle">{vocations?.join(', ')}</td>}

      <td className="align-middle">
        <i className="fa fa-ellipsis-h" aria-hidden="true" onClick={handlePopoverClick} style={{cursor: 'pointer'}} />
        <Overlay
          show={showPopover}
          ref={popoverRef}
          target={popoverTarget}
          container={popoverRef.current}
          placement="bottom"
          rootClose={true}
          onHide={() => setShowPopover(false)}>
          <StyledPopover>
            <Popover.Content>
              <ListGroup variant="flush">
                <ListGroup.Item onClick={() => goToStateDetails(id)}>
                  <Menu className="icon-menu" /> Ver detalhes
                </ListGroup.Item>

                {has_permission_to_edit && (
                  <ListGroup.Item onClick={() => goToStateEdit(id)}>
                    <Edit className="icon-menu" /> Editar
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Popover.Content>
          </StyledPopover>
        </Overlay>
      </td>
    </tr>
  )
}

export default Row
