import {createSelector} from '@reduxjs/toolkit'

export const getStatesSelector = (state) => state.states

export const getCurrentStatesPage = createSelector(getStatesSelector, ({page}) => page)

export const getStatesColumns = createSelector(getStatesSelector, ({columns}) => columns)

export const hasLoadedOrdering = createSelector(getStatesSelector, ({filters}) => !!filters.ordering?.orders?.length)

export const statesOrders = createSelector(getStatesSelector, ({filters}) => filters.ordering.orders)

export const getActiveOrder = createSelector(getStatesSelector, ({filters}) => filters.ordering.activeSort)

export const showMap = createSelector(getStatesSelector, ({universalSearch}) => universalSearch.showMap)

export const universalSearch = createSelector(getStatesSelector, ({universalSearch}) => universalSearch)

export const getCurrentLimit = createSelector(getStatesSelector, ({limit}) => limit)

export const getExportToCsv = createSelector(getStatesSelector, ({exportToCsv}) => exportToCsv)

export const getSearchParams = createSelector(getStatesSelector, ({universalSearch}) => ({
  hasAppliedFilters: universalSearch.hasAppliedFilters,
  searchParams: universalSearch.searchParams,
  selectedFilters: universalSearch.selectedFilters,
  actualEntity: universalSearch.actualEntity,
  showOnlyFavorites: universalSearch.showOnlyFavorites,
}))
