import React, {useCallback} from 'react'
import {Box, Divider, Grid} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'
import Type from 'app/components/proposal/Type'
import IntlCurrencyInput from 'react-intl-currency-input'

import {Title, Container} from '../styles'
import StateCitySelect from 'app/components/form/StateCitySelect'
import {LabelM0} from '../../NewProposal/City/Details/styles'

import {useSelector} from 'react-redux'
import * as proposalsSelectors from 'app/modules/Proposals/redux/selectors'

const ProposalDetailPublic = (
  {type, benefits, name, project, handleChange, city, handleChangeBenefits, onSubmit, submiting},
  props,
) => {
  const availableIncentives = useSelector(proposalsSelectors.getAvailaleBenefits)

  const isSelected = useCallback((benefity) => Object.values(benefits).some((i) => i.type === benefity), [benefits])

  const renderIncentive = (incetive) => {
    return (
      <Box display="flex" key={incetive.type} marginBottom="15px">
        <Form.Check
          checked={isSelected(incetive.type)}
          onChange={() => handleChangeBenefits(incetive)}
          id={`checkbox-${incetive.type}`}
          type="checkbox"
        />
        <label
          for={`checkbox-${incetive.type}`}
          style={{color: '#8C8C8C', fontSize: '16px', fontFamily: 'Nunito', marginLeft: 10}}>
          {incetive.description}
        </label>
      </Box>
    )
  }

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        {benefits.map((benefity) => {
          if (benefity.type === 'terreno') {
            return (
              <>
                <Grid item lg={3}>
                  {' '}
                </Grid>
                <Grid item lg={9}>
                  <Box>
                    <Title>SOBRE TERRENO DA PROPRIEDADE</Title>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={3}
                  style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', textAlign: 'right'}}>
                  <LabelM0>Identificação da Área</LabelM0>
                </Grid>
                <Grid item lg={9}>
                  <Form.Control
                    autoComplete="off"
                    onChange={(ev) => handleChangeBenefits(benefity, {identification: ev.target.value})}
                    value={benefity.identification}
                    style={{maxWidth: 600}}
                    rows={3}
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', textAlign: 'right'}}>
                  <LabelM0>Área do Terreno</LabelM0>
                </Grid>
                <Grid item lg={9}>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    onChange={(ev) => handleChangeBenefits(benefity, {area: ev.target.value})}
                    value={benefity.area}
                    style={{maxWidth: 600}}
                    rows={3}
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', textAlign: 'right'}}>
                  <LabelM0>Valor estimado do terreno (R$)</LabelM0>
                </Grid>
                <Grid item lg={9}>
                  <IntlCurrencyInput
                    autoComplete="off"
                    className="form-control"
                    style={{maxWidth: 600}}
                    currency="BRL"
                    config={{
                      locale: 'pt-BR',
                      formats: {
                        number: {
                          BRL: {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        },
                      },
                    }}
                    onChange={(ev, value) => handleChangeBenefits(benefity, {estimated_value: value})}
                    value={benefity.estimated_value}
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', textAlign: 'right'}}>
                  <LabelM0>Detalhamento</LabelM0>
                </Grid>
                <Grid item lg={9}>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    required
                    onChange={(ev) => handleChangeBenefits(benefity, {description: ev.target.value})}
                    value={benefity.description}
                    style={{maxWidth: 600}}
                    rows={5}
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', textAlign: 'right'}}>
                  <LabelM0>Restrições</LabelM0>
                </Grid>
                <Grid item lg={9}>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    onChange={(ev) => handleChangeBenefits(benefity, {restrictions: ev.target.value})}
                    value={benefity.restrictions}
                    style={{maxWidth: 600}}
                    rows={5}
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', textAlign: 'right'}}>
                  <LabelM0>Link da área no google maps</LabelM0>
                </Grid>
                <Grid item lg={9}>
                  <Form.Control
                    autoComplete="off"
                    onChange={(ev) => handleChangeBenefits(benefity, {url_map: ev.target.value})}
                    value={benefity.url_map}
                    style={{maxWidth: 600}}
                    rows={3}
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                  }}></Grid>
                <Grid item lg={9}>
                  <Divider style={{maxWidth: 600, marginBottom: 30}} />
                </Grid>
              </>
            )
          } else {
            return (
              <>
                <Grid item lg={3}>
                  {' '}
                </Grid>
                <Grid item lg={9}>
                  <Box>
                    <Title>SOBRE INCENTIVOS EM {benefity.type.toUpperCase()}</Title>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={3}
                  style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', textAlign: 'right'}}>
                  <LabelM0>Detalhamento</LabelM0>
                </Grid>
                <Grid item lg={9}>
                  <Form.Control
                    autoComplete="off"
                    as="textarea"
                    required
                    onChange={(ev) => handleChangeBenefits(benefity, {description: ev.target.value})}
                    value={benefity.description}
                    style={{maxWidth: 600}}
                    rows={3}
                  />
                </Grid>

                <Grid
                  item
                  lg={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                  }}></Grid>
                <Grid item lg={9}>
                  <Divider style={{maxWidth: 600, marginBottom: 30}} />
                </Grid>
              </>
            )
          }
        })}

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ProposalDetailPublic
