import styled from 'styled-components'

export const Label = styled.label`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  text-align: left;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const InputContainer = styled.div`
  margin-bottom: 10px;
`

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`

export const ListItem = styled.li`
  list-style: none;
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`

export const SelectedListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const SelectedListItem = styled.div`
  align-items: center;
  text-align: left;
  margin-right: 10px;
  color: #8c8c8c;
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
  i {
    font-size: 10px;
    /* margin-top: 5px */
    margin-left: 5px;
    margin-right: 5px;
  }
`
