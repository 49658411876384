/**
 * @typedef {import('/typedefs').UploadedFileEntry}
 */

import {Box, Grid} from '@material-ui/core'
import FilePreview from 'app/components/files/FilePreview'
import {getFileType} from 'lib/file-helper'
import {useSnackbar} from 'notistack'
import PropTypes from 'prop-types'
import React, {useCallback, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'

import {SubTitleAttachments, Title} from '../../../modules/Cities/Pages/EditCitie/styles'
import {FileUploadLabel, FileUploadWarning} from '../../../modules/Proposals/pages/NewProposal/City/Attachments/styles'
import {filesService} from '../../../services'
import Icon from '../../images/Icons/Attachment'
import CircularLoading from '../../loading/Circular'

const UploadGaleria = (props) => {
  const {enqueueSnackbar} = useSnackbar()
  const createLoaderData = (totalFiles = 0) => ({
    isLoading: false,
    uploaded: 0,
    totalFiles,
  })
  const [loader, updateLoader] = useState(createLoaderData())
  const maxFileSizeInMega = props.maxFileSize
  const maxFileSizeInBytes = maxFileSizeInMega * 1000000

  const loadStart = (totalFiles = 0) => {
    updateLoader({...createLoaderData(totalFiles), isLoading: true})
  }
  const loadStop = () => {
    updateLoader((prevState) => ({...prevState, isLoading: false}))
  }
  const incUploadedCount = () => {
    updateLoader((prevState) => ({...prevState, uploaded: prevState.uploaded + 1}))
  }

  const {isLoading, totalFiles, uploaded} = loader

  const onDrop = useCallback(
    /** @param {File[]} files */
    (files) => {
      loadStart(files.length)

      const uploads = files.map(async (file) => {
        try {
          if (file.size > maxFileSizeInBytes) {
            throw Error(`O tamanho de cada arquivo é limitado a ${maxFileSizeInMega}MB.`)
          }

          const fileResponse = await filesService.sendFile({file, customerId: props.customerId})
          /**
           * @param {UploadedFileEntry} fileEntry
           */
          const fileEntry = {
            type: getFileType(file),
            name: fileResponse?.file?.original_name,
            path: fileResponse?.file?.path,
            url: fileResponse.url,
          }

          if (fileResponse?.file) {
            enqueueSnackbar('Arquivo enviado!', {
              variant: 'success',
            })

            props.handleChange(fileEntry)
          } else {
            throw new Error(fileResponse.message)
          }
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
          })
        } finally {
          incUploadedCount()
        }
      })

      Promise.allSettled(uploads).then(loadStop)
      // Promise.allSettled(uploads)
    },
    [enqueueSnackbar],
  )

  const {getRootProps, getInputProps} = useDropzone({
    accept: props.acceptTypes,
    onDrop,
  })

  return (
    <Box>
      <Box marginBottom="20px" display="flex" flexDirection="column">
        <Title>{props.title}</Title>
        {props.subtitle && <SubTitleAttachments>{props.subtitle}</SubTitleAttachments>}
      </Box>
      <Box
        alignItems="center"
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        marginBottom="20px"
        justifyContent="center"
        height="357px"
        mt="23px"
        {...getRootProps()}
        className="attachtment-dragger">
        <input {...getInputProps()} />
        <Box alignItems="center" display="flex" flexDirection="column">
          <Box alignItems="center" display="flex" flexDirection="column" marginBottom="10px">
            {isLoading ? (
              <Box display="flex" flexDirection="column" alignItems="center">
                <CircularLoading />
                <Box borderTop="10px">
                  Enviando arquivos {uploaded}/{totalFiles}
                </Box>
              </Box>
            ) : (
              <>
                <Box marginBottom="10px">
                  <Icon className="fas fa-file-upload" />
                </Box>
                <FileUploadLabel>Arraste e solte aqui</FileUploadLabel>
                <Button>Selecione o arquivo</Button>

                <Box marginTop="20px">
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    wrap="wrap"
                    spacing={2}
                    className="gallery-preview">
                    {props.imagesLocation.map((image) => (
                      <Grid key={image.id} item>
                        <FilePreview file={image} />
                      </Grid>
                    ))}
                    {uploaded > 0 && (
                      <Grid item xs={12} style={{textAlign: 'center'}}>
                        <FileUploadLabel>Clique em salvar para persistir as informações</FileUploadLabel>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" flex={1} justifyContent="flex-end" marginBottom="20px">
        <FileUploadWarning>O tamanho de cada arquivo é limitado a {maxFileSizeInMega}MB.</FileUploadWarning>
      </Box>
    </Box>
  )
}

UploadGaleria.defaultProps = {
  maxFileSize: 5,
  acceptTypes: 'image/jpg,image/jpeg,image/png',
  imagesLocation: [],
}
UploadGaleria.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  //
  handleChange: PropTypes.func.isRequired,
  imagesLocation: PropTypes.array.isRequired,
  /** in MB */
  maxFileSize: PropTypes.number,
  customerId: PropTypes.number,
  acceptTypes: PropTypes.string,
}
export default UploadGaleria
