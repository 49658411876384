import {Popover} from 'react-bootstrap'
import styled from 'styled-components'

export const CitieHeader = styled.div`
  width: 100%;
  display: flex;
  text-transform: uppercase;
  color: #00428b;
  font-family: 'Nunito', sans-serif;
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
`

export const CitieUf = styled.div`
  color: #8c8c8c;
  align-items: center;
  display: flex;

  &:before {
    border: 2px solid #8c8c8c;
    content: '';
    width: 2px;
    height: 2px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 6vw;
  justify-content: space-around;
`

export const MayorContainer = styled.div`
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0px;
  color: #383838;
`

export const MayorName = styled.div`
  text-align: left;
  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0px;
  color: #1d1d1d;
  text-transform: uppercase;
  margin-bottom: 20px;
`

export const DescriptionContainer = styled.div`
  width: 100%;
  max-height: 3.8em;
  line-height: 1.4em;
  overflow: hidden;
  text-align: left;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  color: #1d1d1d;
`

const injectJustify = ({justify}) => (justify ? justify : 'space-between')

export const Container = styled.div`
  display: flex;
  justify-content: ${injectJustify};
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`

export const StyledSpan = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #383838;
`

export const TaxDiv = styled.div`
  margin-left: 1rem;
  color: #1d1d1d;
  font-family: 'Nunito Sans';
  font-size: 14px;
  display: flex;
  align-items: center;
`

const injectColor = ({color}) => (color ? color : 'transparent')

export const QuantityDiv = styled.div`
  background-color: ${injectColor};
  min-width: 32px;
  height: 29px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  color: #2c6eb5;
  justify-content: center;
`

export const FavoriteIcon = styled.i`
  color: ${({isFavorite}) => (isFavorite ? '#FFA800' : '#C9C9C9')};
  cursor: pointer;
`

export const CounterContainer = styled.div`
  margin-bottom: 20px;
`
export const StyledPopover = styled(Popover)`
  width: 400px;
  overflow-y: scroll;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;

  .popover-body {
    padding: 0px !important;
  }
`
