import React, {useState, useCallback, useEffect} from 'react'
import {useSnackbar} from 'notistack'
import {companiesService} from 'app/services'
import {Box, Card, CardActions, CardHeader, Modal} from '@material-ui/core'
import {Graph} from 'react-d3-graph'
import {Paper, FormControl, FormLabel} from '@material-ui/core'
import {Button} from 'react-bootstrap'
import AutoCompletePartner from 'app/components/form/AutoCompletePartner'
import {Switch} from 'app/components/proposal/CitySearchPopover/styles'
import CompanyCard from './CompanyCard'
import CorporateConnectionDetails from './CorporateConnectionDetails'
export const stylesheet = [
  {
    selector: 'node',
    style: {
      label: 'data(name)',
    },
  },
]

const ListConnectionsPage = ({defaultFilters, defaultSort, hideSearch}) => {
  const {enqueueSnackbar} = useSnackbar()

  const queryParams = new URLSearchParams(window.location.search)

  const [partner, setPartner] = useState({
    name: queryParams.get('name'),
    document: queryParams.get('document'),
  })
  const [loading, setLoading] = useState(false)
  const [showCompanies, setShowCompanies] = useState(false)
  const [selectedConnection, setSelectedConnection] = useState(null)
  const [cc, showCompanyCard] = useState(null)

  const showCompany = (company) => {
    companiesService.getCompany({document: company.cnpj}).then(showCompanyCard)
  }

  const [graphData, setGraphData] = useState({
    nodes: [],
    links: [],
  })

  const myConfig = {
    automaticRearrangeAfterDropNode: true,
    collapsible: false,
    directed: false,
    focusAnimationDuration: 0.75,
    focusZoom: 1,
    freezeAllDragEvents: false,
    height: 800,
    highlightDegree: 1,
    highlightOpacity: 1,
    linkHighlightBehavior: false,
    maxZoom: 8,
    minZoom: 0.1,
    nodeHighlightBehavior: false,
    panAndZoom: false,
    staticGraph: false,
    staticGraphWithDragAndDrop: false,
    width: 1290,
    d3: {
      alphaTarget: 0.05,
      gravity: -190,
      linkLength: 120,
      linkStrength: 2,
      disableLinkForce: false,
    },
    node: {
      color: '#d3d3d3',
      fontColor: 'black',
      fontSize: 8,
      fontWeight: 'normal',
      highlightColor: 'SAME',
      highlightFontSize: 8,
      highlightFontWeight: 'normal',
      highlightStrokeColor: 'SAME',
      highlightStrokeWidth: 'SAME',
      labelProperty: 'name',
      mouseCursor: 'pointer',
      opacity: 1,
      renderLabel: true,
      size: 200,
      strokeColor: 'none',
      strokeWidth: 1.5,
      svg: '',
      symbolType: 'circle',
    },
    link: {
      color: '#d3d3d3',
      fontColor: 'black',
      fontSize: 8,
      fontWeight: 'normal',
      highlightColor: 'SAME',
      highlightFontSize: 8,
      highlightFontWeight: 'normal',
      labelProperty: 'label',
      mouseCursor: 'pointer',
      opacity: 1,
      renderLabel: false,
      semanticStrokeWidth: false,
      strokeWidth: 1.5,
      markerHeight: 6,
      markerWidth: 6,
      strokeDasharray: 0,
      strokeDashoffset: 0,
      strokeLinecap: 'butt',
    },
  }

  const onClickNode = function (nodeId) {
    const node = graphData.nodes.filter((node) => node.id === nodeId)[0] || null

    if (!node && !node.cpf) {
      return
    }

    if (node.type === 'company') {
      return showCompany(node)
    }

    setSelectedConnection({
      name: node.name,
      document: node.cpf,
    })
  }

  const onClickLink = function (source, target) {
    window.alert(`Clicked link between ${source} and ${target}`)
  }

  const fetchConnections = useCallback(
    async (searches) => {
      try {
        setLoading(true)

        if (!searches) {
          return
        }

        const {data} = await companiesService.getConnections({
          document: searches.document,
          name: searches.name,
          with_companies: searches.showCompanies ? 1 : 0,
        })

        if (!data.edges) {
          enqueueSnackbar('Nenhuma conexão encontrada', {
            variant: 'warning',
          })
          setLoading(false)
          return
        }
        setGraphData({
          nodes: data.nodes.map((node) => {
            if (node.image === 'partner_searched.png') {
              node.color = '#8ac349'
            } else if (node.image === 'indirect_partner.png') {
              node.color = '#01bcd4'
            } else if (node.image === 'partner.png') {
              node.color = '#01346d'
            } else {
              node.color = '#ddd'
            }
            return node
          }),
          links: data.edges,
        })

        setLoading(false)
      } catch (error) {
        setLoading(false)
        setGraphData({})

        enqueueSnackbar('Ocorreu um erro ao consultar conexões', {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar],
  )

  useEffect(() => {
    if (partner && partner?.name && partner?.document) {
      fetchConnections({
        name: partner.name,
        document: partner.document,
        showCompanies,
      })
    }
  }, [showCompanies, partner])

  return (
    <>
      {cc ? (
        <Modal
          style={{display: 'grid', overflow: 'auto'}}
          open={cc}
          onClose={() => showCompanyCard(null)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-descriptions">
          <Box
            width="100%"
            maxWidth="701px"
            minHeight="90vh"
            bgcolor="#ffffff"
            m="auto"
            p="20px"
            borderRadius="15px"
            display="flex"
            flexDirection="column">
            <CompanyCard company={cc} fetchConnections={fetchConnections} closeModal={() => showCompanyCard(null)} />
          </Box>
        </Modal>
      ) : null}

      {selectedConnection ? (
        <Modal
          style={{display: 'grid', overflow: 'auto'}}
          open={Boolean(selectedConnection)}
          onClose={() => setSelectedConnection(null)}
          aria-labelledby="simple-modal-title-2"
          aria-describedby="simple-modal-descriptions-2">
          <Box
            width="100%"
            maxWidth="701px"
            bgcolor="#ffffff"
            m="auto"
            p="20px"
            borderRadius="15px"
            display="flex"
            flexDirection="column">
            <CorporateConnectionDetails
              fetchConnections={fetchConnections}
              connectionInfo={selectedConnection}
              onClose={() => setSelectedConnection(null)}
            />
          </Box>
        </Modal>
      ) : null}

      <div className="d-flex flex-column container-citymatch-table-cards">
        <Paper className="city-politicians" style={{borderRadius: 10, marginBottom: 30}}>
          <Box p={'20px'} display={'flex'} flexWrap={'wrap'} alignItems="center" gridGap={'15px'}>
            <Box>Conexões societárias de:</Box>
            <Box flex={1}>
              <AutoCompletePartner
                onSelectPartner={(part) => {
                  setPartner(part)
                  fetchConnections(part)
                }}
              />
            </Box>
            <Box>
              <Button
                onClick={() => {
                  fetchConnections(partner)
                }}
                disabled={!partner || partner === ''}>
                NOVA BUSCA
              </Button>
            </Box>
          </Box>
        </Paper>
      </div>
      <Paper className="city-politicians" style={{borderRadius: 10, marginBottom: 30}}>
        <div style={{padding: 20, borderBottom: '1px solid #ddd'}}>
          <FormControl component="fieldset" className="form-fields">
            <FormLabel component="legend" style={{marginBottom: '0.5rem'}}>
              <Switch
                type="switch"
                label={'Mostrar Empresas'}
                id="with_companies"
                name="with_companies"
                value={showCompanies}
                checked={showCompanies}
                onChange={(ev) => {
                  console.log(ev.target.checked)
                  setShowCompanies(ev.target.checked)
                }}
              />
            </FormLabel>
          </FormControl>
        </div>
        <div>
          {graphData.nodes.length ? (
            <Graph
              id="graph-id" // id is mandatory
              data={graphData}
              config={myConfig}
              onClickNode={onClickNode}
            />
          ) : (
            <div
              style={{
                width: '100%',
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '23px',
                fontWeight: 'bold',
                color: '#003770',
              }}>
              <strong>Realize uma busca para mostrar o grafo.</strong>
            </div>
          )}
        </div>
      </Paper>
    </>
  )
}

export default ListConnectionsPage
