import {Popover} from '@material-ui/core'
import CustomersSearchCustomer from 'app/components/customers/SearchCustomer'
import {setShowOnlyFavorites} from 'app/modules/Cities/redux'
import * as customerselectors from 'app/modules/ManageCustomer/redux/selectors'
import objectPath from 'object-path'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {ArrowLeft} from 'react-feather'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import styled from 'styled-components'

import {useHtmlClassService} from '../../_core/MetronicLayout'
import {getBreadcrumbsAndTitle, useSubheader} from '../../_core/MetronicSubheader'
import {BreadCrumbs} from './components/BreadCrumbs'

const ButtonWrapper = styled.button`
  color: #ffffff;
  background-color: ${({active, disabled}) => (active || disabled ? '#1E7BE1' : '#1E7BE133')};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  border-color: transparent;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  position: relative;
  padding: 13px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #1e7be1;
  }
`

const ActiveOptionIndication = styled.div`
  position: absolute;
  background-color: #ff8f88;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  right: 3px;
  top: 2px;
`

const IconContainer = styled.div`
  margin-right: 12px;
`

export function SubHeaderCustomer(props) {
  const uiService = useHtmlClassService()
  const location = useLocation()
  const history = useHistory()
  const subheader = useSubheader()
  const popoverRef = useRef(null)
  const dispatch = useDispatch()

  const {hasAppliedFilters, showOnlyFavorites} = useSelector(customerselectors.getSearchParams)

  const [popoverTarget, setPopoverTarget] = useState(null)
  const [showPopover, setShowPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(uiService.config, 'subheader.mobile-toggle'),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses('subheader_container', true),
    }
  }, [uiService])

  const onStarClick = () => {
    const route = location.pathname.substring(1)
    if (route.includes('manage-customers')) {
      dispatch(setShowOnlyFavorites(!showOnlyFavorites))
    }
  }

  const onAddClick = () => {
    history.push(`${location.pathname.substring(1)}/new`)
  }

  useEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname)
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname)
    const breadcrumbs = aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs
    subheader.setBreadcrumbs(breadcrumbs)
    subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title)
    // eslint-disable-next-line
  }, [location.pathname])

  const hideSearchIcons = useMemo(() => {
    const routes = ['manage-customers/new']
    return !routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showPlusIcon = useMemo(() => {
    const routes = ['manage-customers']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div id="kt_subheader" className={`subheader ${layoutProps.subheaderCssClasses}`}>
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex justify-content-between flex-wrap flex-sm-nowrap`}
        style={{alignItems: 'flex-end'}}>
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle">
              <span />
              SearchPopover
            </button>
          )}
          <div className="d-flex flex-column">
            <h2 className="page-title">{subheader.title}</h2>
            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
        </div>

        <div className="d-flex align-items-center my-2">
          {hideSearchIcons &&
            (showPlusIcon ? (
              <>
                <ButtonWrapper active={showPopover} ref={popoverRef} onClick={handleClick}>
                  <i aria-hidden="true" className="fa fa-filter" style={{color: '#fff'}} />
                  {hasAppliedFilters && <ActiveOptionIndication />}
                </ButtonWrapper>

                <Popover
                  id={'filters'}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}>
                  <CustomersSearchCustomer onClose={handleClose} />
                </Popover>
                <ButtonWrapper onClick={onAddClick}>
                  <i className="fas fa-plus" aria-hidden="true" style={{color: '#fff'}}></i>
                </ButtonWrapper>
              </>
            ) : (
              <>
                <ButtonWrapper
                  active={props.showMap}
                  onClick={() => history.goBack()}
                  style={{fontSize: 18, padding: '7px 10px'}}>
                  <IconContainer style={{marginRight: 5}}>
                    <ArrowLeft style={{color: '#fff'}} />
                  </IconContainer>
                  <span>Voltar</span>
                </ButtonWrapper>
              </>
            ))}
        </div>
      </div>
    </div>
  )
}
