import { Box, Checkbox } from '@material-ui/core'
import moment from 'moment'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import * as columnUtils from '../utils'

export const RowLog = (props) => {
  const { action, description, occurred_at, metadata, columns } = props

  return (
    <tr>
      {columnUtils.shouldRenderColumn({ columnKey: 'action', stateColumns: columns }) && (
        <td className="align-middle" style={{ fontFamily: 'Nunito Sans', fontWeight: 'bold', fontSize: 14 }}>
          {action}
        </td>
      )}
      {columnUtils.shouldRenderColumn({ columnKey: 'description', stateColumns: columns }) && (
        <td className="align-middle">{description}</td>
      )}
      {columnUtils.shouldRenderColumn({ columnKey: 'occurred_at', stateColumns: columns }) && (
        <td className="align-middle">{moment(occurred_at).format('lll')}</td>
      )}
      {columnUtils.shouldRenderColumn({ columnKey: 'metadata', stateColumns: columns }) && (
        <td className="align-middle">
          {Object.keys(metadata).map((key) => {
            return (
              <Box key={key}>
                {key}: {typeof metadata[key] === 'object' ? JSON.stringify(metadata[key]) : metadata[key]}
              </Box>
            )
          })}
        </td>
      )}
    </tr>
  )
}

const Row = (props) => {
  const { name, email, columns, id, customer, onSelectChange } = props

  const { superadmin } = useSelector(
    ({ auth }) => ({
      superadmin: auth.user.roles.includes('ROLE_SUPERADMIN'),
    }),
    shallowEqual
  )

  const [active, setActive] = useState(false)

  const handleUserSelect = () => {
    setActive(!active)
    onSelectChange(id)
  }

  return (
    <tr>
      {columnUtils.shouldRenderColumn({ columnKey: 'delete', stateColumns: columns }) && (
        <td className="align-middle">
          <Checkbox checked={active} onChange={handleUserSelect} />
        </td>
      )}
      {columnUtils.shouldRenderColumn({ columnKey: 'name', stateColumns: columns }) && (
        <td className="align-middle" style={{ fontFamily: 'Nunito Sans', fontWeight: 'bold', fontSize: 14 }}>
          {name}
        </td>
      )}
      {columnUtils.shouldRenderColumn({ columnKey: 'email', stateColumns: columns }) && (
        <td className="align-middle">{email}</td>
      )}
      {columnUtils.shouldRenderColumn({ columnKey: 'customer_name', stateColumns: columns }) && superadmin && (
        <td className="align-middle">{customer.name}</td>
      )}
      <td className="align-middle">
        <Link to={`/manage-users/edit/${id}`}>EDITAR</Link>
      </td>
      <td className="align-middle">
        <Link to={`/manage-users/logs/${id}`}>LOGS</Link>
      </td>
    </tr>
  )
}

export default Row
