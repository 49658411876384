import httpClient from '../../client'

import apiRoutes from '../constants'

const readAll = async () =>
  await httpClient
    .post(`${apiRoutes.GET_NOTIFICATIONS}/readAll`)
    .then(({ data }) => data)
    .catch((error) => error)

export default readAll
