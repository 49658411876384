import React, {useState} from 'react'

import {useHistory} from 'react-router-dom'

import {FavoriteIcon} from './styles'
import {Circular as CircularLoading} from 'app/components'

import {useFavorite} from 'app/hooks'

import * as columnUtils from '../utils'

import moment from 'moment'

const Row = ({
  id,
  name,
  columns,
  born_in,
  is_favorite,
  party,
  birth_date,
  situation,
  scholarity,
  political_office,
  city,
  meta,
  email,
  showFavorite,
  ...rest
}) => {
  const history = useHistory()

  const [showDetails, setShowDetails] = useState(false)

  const capitalizeFirstLetter = (string) => {
    string = string.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'politician',
    label: 'Político',
  })

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount)

  const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

  return (
    <>
      <tr>
        {columnUtils.shouldRenderColumn({
          columnKey: 'id',
          stateColumns: columns,
        }) && (
          <th className="align-middle" scope="row">
            {id}
          </th>
        )}
        {columnUtils.shouldRenderColumn({
          columnKey: 'name',
          stateColumns: columns,
        }) && (
          <td
            className="align-middle"
            style={{color: '#00428B', cursor: 'pointer', textTransform: 'uppercase', fontWeight: 'bold'}}>
            {name}
          </td>
        )}
        {columnUtils.shouldRenderColumn({
          columnKey: 'party',
          stateColumns: columns,
        }) && <td className="align-middle">{party ? `${party.number} - ${party.description.toUpperCase()}` : ''}</td>}

        {columnUtils.shouldRenderColumn({
          columnKey: 'born_in',
          stateColumns: columns,
        }) && (
          <td className="align-middle" style={{fontWeight: 'bold'}}>
            {born_in ? born_in.state.uf : ''}
          </td>
        )}
        {columnUtils.shouldRenderColumn({
          columnKey: 'meta.electionYear',
          stateColumns: columns,
        }) && <td className="align-middle">{meta.electionYear}</td>}
        {columnUtils.shouldRenderColumn({
          columnKey: 'political_office',
          stateColumns: columns,
        }) && <td className="align-middle">{political_office.description}</td>}

        {columnUtils.shouldRenderColumn({
          columnKey: 'city',
          stateColumns: columns,
        }) && <td className="align-middle">{city ? city.name : ''}</td>}
        {columnUtils.shouldRenderColumn({
          columnKey: 'situation',
          stateColumns: columns,
        }) && (
          <td className="align-middle">
            {situation === 'APTO' ? (
              <span
                style={{
                  backgroundColor: '#C9F7F4',
                  color: '#1FC5BD',
                  padding: '2px 12px',
                  fontWeight: '600',
                  fontFamily: 'Nunito Sans',
                  fontSize: '14px',
                  borderRadius: 4,
                }}>
                {capitalizeFirstLetter(situation)}
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: '#ddd',
                  color: '#000',
                  fontWeight: '600',
                  padding: '2px 12px',
                  fontFamily: 'Nunito Sans',
                  fontSize: '14px',
                  borderRadius: 4,
                }}>
                {capitalizeFirstLetter(situation)}
              </span>
            )}
          </td>
        )}
        <td className="align-middle">
          <button
            className={`button-show-politician ${showDetails ? 'show' : ''}`}
            onClick={() => setShowDetails(!showDetails)}>
            <i />
          </button>
        </td>
        {showFavorite ? (
          <td className="align-middle">
            {loadingFavorite ? (
              <CircularLoading />
            ) : (
              <FavoriteIcon
                aria-hidden="true"
                className="fa fa-star"
                isFavorite={isFavorite}
                onClick={() => handleFavoriteOpportunity()}
              />
            )}
          </td>
        ) : (
          <td></td>
        )}
      </tr>
      {showDetails && (
        <tr>
          <td colSpan="10">
            <div>
              <div style={{borderBottom: '1px solid #E3E3E3'}} className="list-pol">
                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Ano Eleição</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>{meta.electionYear}</span>
                </div>

                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Escolaridade</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>{scholarity}</span>
                </div>

                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Ocupação</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>{meta.occupation}</span>
                </div>

                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Ocupação</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>{meta.occupation}</span>
                </div>
              </div>

              <div style={{borderBottom: '1px solid #E3E3E3'}} className="list-pol">
                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Situação</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>
                    {situation === 'APTO' ? 'Deferido' : 'Indeferido'}
                  </span>
                </div>

                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Data Nascimento</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>
                    {birth_date ? moment(birth_date).format('DD/MM/YYYY') : '-'}
                  </span>
                </div>

                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Tipo Agremiação</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>{meta.association}</span>
                </div>

                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Gênero</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>{meta.gender}</span>
                </div>
              </div>

              <div className="list-pol">
                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Tipo Agremiação</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>{meta.association}</span>
                </div>
                <div display="block">
                  <div>
                    <label>
                      <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Email</b>
                    </label>
                  </div>
                  <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>{email}</span>
                </div>
                <div display="block"></div>
                <div display="block"></div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

export default Row
