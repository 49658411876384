import {toAbsoluteUrl} from '_metronic/_helpers'
import {Box} from '@material-ui/core'
import {Circular as CircularLoading} from 'app/components'
import {useFavorite} from 'app/hooks'
import React, {useCallback, useEffect, useState} from 'react'
import {Carousel} from 'react-bootstrap'

import {
  BottomItem,
  BottomItemValue,
  CarouselImage,
  Container,
  ContainerHeader,
  DetailContainer,
  FavoriteIcon,
  IcentiveContainer,
  Image,
  Paper,
} from '../styles'

const BasicInfo = ({
  id,
  code,
  name,
  location,
  companies,
  status,
  type,
  counters,
  mainPolitician,
  is_favorite,
  updated_at,
  demographic_data,
  ranking,
  ...rest
}) => {
  const totalCompanies = companies?.total ?? 0

  const {action: handleCitieOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'state',
    label: 'Estado',
  })

  const renderCaouselImage = useCallback(
    ({url}, idx) => (
      <Carousel.Item key={`k=${idx}`}>
        <CarouselImage src={url} alt="slide" />
      </Carousel.Item>
    ),
    [],
  )

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount)

  const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

  const findDemographicInfo = useCallback(
    (key, defaultParam = 'Não Informado', label = '') => {
      const info = demographic_data?.find(({indicator}) => indicator === key)?.value
      return info ? `${info}${label}` : `${defaultParam}${label}`
    },
    [demographic_data],
  )

  const renderIncentive = useCallback(
    ({type}) => <IcentiveContainer key={`k=${type}`}>{type.toUpperCase()}</IcentiveContainer>,
    [],
  )

  return (
    <Paper>
      <ContainerHeader>
        <Box display="flex">{name?.toUpperCase() ?? ''}</Box>
        <Box>
          {loadingFavorite ? (
            <CircularLoading />
          ) : (
            <>
              {/* <FavoriteIcon
              aria-hidden="true"
              className="fa fa-star"
              isFavorite={isFavorite}
              onClick={() => handleCitieOpportunity()}
            /> */}
            </>
          )}
        </Box>
      </ContainerHeader>
      <DetailContainer>
        <Container style={{marginTop: 0}}>
          <Carousel style={{width: '48%'}}>
            {location?.images && location?.images.length > 0 ? location.images.map(renderCaouselImage) : null}
          </Carousel>
          <Container width="48%" display="block">
            {location && (
              <Container display="block">
                <Container>
                  <label>
                    <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Sobre o estado</b>
                  </label>
                </Container>
                <span style={{color: '#848484', fontSize: 14, fontFamily: 'Nunito'}}>
                  {location.description ?? null}
                </span>
                <hr style={{width: '100%', borderTop: '1px solid #E3E3E3', marginBottom: 20, marginTop: 20}} />
              </Container>
            )}
            <Container style={{alignItems: 'flex-start', justifyContent: 'flex-start'}}>
              {mainPolitician && mainPolitician.meta.photo && (
                <div
                  style={{
                    width: 160,
                    height: 160,
                    backgroundImage: `url(${mainPolitician.meta.photo})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    border: '2px solid #257fe2',
                    marginRight: '15px',
                  }}
                  className="rounded-circle"></div>
              )}
              {mainPolitician && (
                <Container
                  display="block"
                  width="auto"
                  style={{marginLeft: '1rem', marginTop: 0, alignItems: 'flex-start'}}>
                  <Container display="block">
                    <Container>
                      <label>
                        <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Prefeito(a)</b>
                      </label>
                    </Container>
                    <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>
                      <a href={`/politicians/${mainPolitician.id}`} style={{color: '#1E7BE1', fontWeight: 'bold'}}>
                        {mainPolitician.name}
                      </a>{' '}
                      - {mainPolitician.political_party.abbreviation}
                    </span>
                  </Container>
                  <Container display="block">
                    <Container>
                      <label>
                        <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Eleito em</b>
                      </label>
                    </Container>
                    <span style={{color: '#848484', fontFamily: 'Nunito Sans', fontSize: 14}}>
                      {mainPolitician.meta.electionYear}
                    </span>
                  </Container>
                </Container>
              )}
            </Container>
            {mainPolitician && (
              <hr style={{width: '100%', borderTop: '1px solid #E3E3E3', marginBottom: 0, marginTop: 20}} />
            )}
          </Container>
        </Container>
        <Container style={{alignItems: 'flex-start'}}>
          {location && (
            <BottomItem>
              <label>
                <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Incentivos disponíveis ao investidor</b>
              </label>
              <Container style={{justifyContent: 'flex-start'}}>{location.benefits.map(renderIncentive)}</Container>
            </BottomItem>
          )}
          {counters && (
            <BottomItem>
              <label>
                <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Oportunidades Ativas</b>
              </label>
              <BottomItemValue>
                {ranking && ranking.proposals ? formatNumber(ranking.proposals.total.open) : 0}
              </BottomItemValue>
            </BottomItem>
          )}
          <BottomItem>
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Empresas Ativas</b>
            </label>
            <BottomItemValue>{formatNumber(totalCompanies)}</BottomItemValue>
          </BottomItem>
          <BottomItem>
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Políticos Ativos</b>
            </label>
            <BottomItemValue>
              {ranking && ranking.politicians ? formatNumber(ranking.politicians.total) : 0}
            </BottomItemValue>
          </BottomItem>
          <BottomItem>
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Vocação Regional</b>
            </label>

            <BottomItemValue>{findDemographicInfo('vocacao_regional', 'Não Informada')}</BottomItemValue>
          </BottomItem>
          <BottomItem>
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>População</b>
            </label>

            <BottomItemValue>{formatNumber(findDemographicInfo('populacao_estimada', 0))}</BottomItemValue>
          </BottomItem>
        </Container>
      </DetailContainer>
    </Paper>
  )
}

export default BasicInfo
