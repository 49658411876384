import React from 'react'
import {Route, Switch} from 'react-router-dom'

import DetailCitie from './DetailCitie'
import EditCitie from './EditCitie'
import ListCities from './ListCities'

const CitiesPage = () => {
  return (
    <Switch>
      <Route path="/cities" exact component={ListCities} />
      <Route path="/cities/:citieId" exact component={DetailCitie} />
      <Route path="/cities/:citieId/edit" exact component={EditCitie} />
    </Switch>
  )
}

export default CitiesPage
