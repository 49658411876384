import {Badge, CircularProgress} from '@material-ui/core'
import {notificationsService} from 'app/services'
import {isProductionEnv} from 'lib/env-detector'
import {useSnackbar} from 'notistack'
import objectPath from 'object-path'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Dropdown, Nav, OverlayTrigger, Tab, Tooltip} from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {Link} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../../_helpers'
import {DropdownTopbarItemToggler} from '../../../../_partials/dropdowns'
import {useHtmlClassService} from '../../../_core/MetronicLayout'

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
}

export function UserNotificationsDropdown() {
  const {enqueueSnackbar} = useSnackbar()
  const bgImage = toAbsoluteUrl('/media/bg/bg-headers-citymatch.jpg')
  const [key, setKey] = useState('total')
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState(null)
  const [totals, setTotals] = useState({
    total: 0,
    cities: 0,
    states: 0,
    companies: 0,
  })
  const [oldTotal, setOldTotal] = useState(0)

  const [categories, setCategories] = useState([
    {
      id: 'city',
      label: 'Públicas',
    },
    {
      id: 'company',
      label: 'Privadas',
    },
    {
      id: 'total',
      label: 'Todas',
    },
    {
      id: 'read',
      label: 'Visualizadas',
    },
  ])

  const markAsRead = async (notifyId) =>
    notificationsService.markAsRead(notifyId).then(() => {
      getNotifications(category)
      setOldTotal(oldTotal - 1)
    })

  const readAll = async () =>
    notificationsService
      .readAll()
      .then(() => getNotifications(category))
      .catch(() => {
        enqueueSnackbar('Ocorreu um erro ao marcar todas as notificações como lidas', {
          variant: 'error',
        })
      })

  const getNotifications = useCallback(
    async (category, timedCall = false) => {
      if (!isProductionEnv()) {
        console.log('getNotifications pooling disabled in local environment')
        return
      }

      try {
        if (!timedCall) {
          setNotifications(notifications)
        }

        const {data, totals} = await notificationsService.getNotifications(category)

        if (oldTotal < totals.total) {
          enqueueSnackbar('Você tem novas notificações', {
            variant: 'success',
          })
        }

        setNotifications(data)
        setTotals(totals)
        setOldTotal(totals.total)
      } catch (error) {
        enqueueSnackbar('Ocorreu um erro ao consultar as notificações', {
          variant: 'error',
        })
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar, oldTotal],
  )

  useEffect(() => {
    getNotifications(category)
    const interval = setInterval(() => {
      getNotifications(category, true)
    }, 10000)

    return () => clearInterval(interval)
  }, [getNotifications, category])

  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, 'extras.notifications.layout') === 'offcanvas',
    }
  }, [uiService])

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <Badge badgeContent={totals.total} color="error">
            <span
              style={{
                color: '#fff',
                cursor: 'pointer',
                display: 'inline-block',
                fontSize: 17,
                fontFamily: 'Nunito Sans',
                padding: 13,
              }}>
              Notificações / Atualizações
            </span>
          </Badge>
          <div className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary" id="kt_quick_notifications_toggle">
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle as={DropdownTopbarItemToggler} id="kt_quick_notifications_toggle">
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="user-notification-tooltip">Notificações</Tooltip>}>
              <>
                <Badge badgeContent={totals.total} color="error">
                  <span
                    style={{
                      color: '#fff',
                      cursor: 'pointer',
                      display: 'inline-block',
                      fontSize: 13,
                      fontFamily: 'Nunito Sans',
                    }}>
                    Notificações / Atualizações
                  </span>
                </Badge>

                <div
                  className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary"
                  id="kt_quick_notifications_toggle">
                  <span className="svg-icon svg-icon-xl">
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')} />
                  </span>
                  <span className="pulse-ring" />
                </div>
              </>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              <div
                className="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"
                style={{backgroundImage: `url(${bgImage})`}}>
                <div className="d-flex justify-content-between gap-2 p-8">
                  <h4 className="d-flex flex-center text-white">{String(totals.total)} notificações novas</h4>

                  <button
                    className="btn btn-primary btn-text btn-sm font-weight-bold btn-font-md ml-2"
                    type="button"
                    onClick={() => readAll()}>
                    Marcar todas como lido
                  </button>
                </div>

                <Tab.Container defaultActiveKey={key}>
                  <Nav
                    as="ul"
                    className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white mt-3 px-8"
                    onSelect={(_key) => setKey(_key)}>
                    {categories.map((item, key) => (
                      <Nav.Item key={key} className="nav-item" as="li">
                        <Nav.Link
                          eventKey={item.id}
                          onClick={() => setCategory(item.id)}
                          className={`nav-link show ${key === item.id ? 'active' : ''}`}>
                          {item.label} ({totals[item.id] || 0})
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>

                  <Tab.Content className="tab-content">
                    {categories.map((item, key) => (
                      <Tab.Pane key={key} eventKey={item.id} className="p-8">
                        <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="scroll pr-7 mr-n7"
                          style={{
                            maxHeight: '300px',
                            position: 'relative',
                            marginLeft: 0,
                            marginRight: '0 !important',
                          }}>
                          {!loading && !notifications.length ? (
                            <div className="d-flex flex-center text-center text-muted min-h-200px">
                              Nenhuma nova notificação.
                            </div>
                          ) : null}
                          {loading ? (
                            <div className="d-flex flex-center text-center text-muted min-h-200px">
                              <CircularProgress />
                            </div>
                          ) : null}
                          {notifications.map((notification, index) => (
                            <div
                              key={`k=${index}`}
                              onClick={() => {
                                markAsRead(notification.id)
                              }}
                              className={'d-flex align-items-center'}
                              style={
                                notification.is_read
                                  ? {marginRight: 0, marginLeft: 0, padding: 15, marginBottom: '5px'}
                                  : {
                                      marginRight: 0,
                                      marginLeft: 0,
                                      background: '#fffbe3',
                                      padding: 15,
                                      border: '1px solid #ffd9b1',
                                      borderRadius: 5,
                                      cursor: 'pointer',
                                      marginBottom: '5px',
                                    }
                              }>
                              <div className="d-flex flex-row" style={{width: '100%'}}>
                                <div className="d-flex flex-column font-weight-bold">
                                  <span
                                    style={{
                                      fontSize: 16,
                                      fontFamily: 'Nunito Sans',
                                      fontWeight: 'bold',
                                      color: '#8C8C8C',
                                    }}>
                                    {notification.message}
                                  </span>

                                  <span
                                    style={{
                                      fontSize: 14,
                                      fontFamily: 'Nunito Sans',
                                      fontWeight: 'bold',
                                      color: '#8C8C8C',
                                    }}>
                                    {notification.created_by}
                                  </span>
                                </div>

                                {notification.link ? (
                                  <Link
                                    to={notification.link}
                                    className="btn btn-light-primary font-weight-bold"
                                    style={{
                                      fontSize: 15,
                                      fontFamily: 'Nunito Sans',
                                      fontWeight: 'bold',
                                      color: '#1D1D1D',
                                      marginLeft: 'auto',
                                    }}>
                                    Acessar
                                  </Link>
                                ) : (
                                  <span className="text-dark mb-1 font-size-lg">Titulo Notificação</span>
                                )}
                              </div>
                            </div>
                          ))}
                        </PerfectScrollbar>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )
}
