import httpClient from '../client'

const baseUrl = 'locations'

export const getLocation = async (code) => {
  const url = `${baseUrl}/${code}`
  const {data} = await httpClient.get(url)

  return data
}

export const createLocation = async (code, location) => {
  try {
    console.log(location)
    const response = await httpClient.post(`${baseUrl}/${code}/enable`, location)

    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message)
    } else {
      throw error
    }
  }
}

export const updateLocation = async (code, location) => {
  try {
    const response = await httpClient.put(`${baseUrl}/${code}/enable`, location)

    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message)
    } else {
      throw error
    }
  }
}

export const deleteImage = async (code, imageId) => {
  try {
    const response = await httpClient.delete(`${baseUrl}/${code}/gallery-image/${imageId}`, {data: {}})
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message)
    } else {
      throw error
    }
  }
}

export const deleteMultimedia = async (code, mediaId) => {
  try {
    const response = await httpClient.delete(`${baseUrl}/${code}/attachment/${mediaId}`, {data: {}})
    return response.data
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message)
    } else {
      throw error
    }
  }
}

export const getVocations = async () => {
  const url = 'regional-vocations'
  const {data} = await httpClient.get(url)
  return data
}
