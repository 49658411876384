import httpClient from '../../client'

import apiRoutes from '../constants'

const updateInfo = async (document, info) => {
  try {
    console.log(info)
    const cnpj = typeof document == 'string' ? document.replace(/\D/g, '') : document
    const {data} = await httpClient.put(apiRoutes.GET_COMPANIES + '/' + cnpj + '/info', info)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message)
    } else {
      throw error
    }
  }
}

export default updateInfo
