import React, { useState } from 'react'
import { Box, Modal } from '@material-ui/core'
import { availableColums, availableColumsLogs } from 'app/modules/ManageUser/redux'
import { shallowEqual, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Button } from 'react-bootstrap'
import { userService } from 'app/services'
import { useHistory } from 'react-router'
import * as columnUtils from '../utils'

const TableHead = ({ selectedUsers, setSelectedUsers }) => {
  const columns = availableColums
  const [isDeleting, setIsDeleting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { superadmin } = useSelector(
    ({ auth }) => ({
      superadmin: auth.user.roles.includes('ROLE_SUPERADMIN'),
    }),
    shallowEqual
  )

  const onDelete = async () => {
    setIsDeleting(true)

    await userService
      .inactivateMany(selectedUsers)
      .then(() => {
        enqueueSnackbar('Usuários removidos com sucesso', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Erro ao remover usuários', {
          variant: 'error',
        })
      })

    setSelectedUsers([])
    setIsOpen(false)
    setIsDeleting(false)

    history.go(0)
  }

  const handleDeleteAll = () => {
    setIsOpen(true)
  }

  return (
    <thead>
      <tr>
        {columnUtils.shouldRenderColumn({ columnKey: 'massDelete', stateColumns: columns }) && (
          <th scope="col">
            <button
              onClick={handleDeleteAll}
              className="btn d-flex align-items-center justify-content-center"
              style={{ padding: '0.65rem 0.8rem' }}
            >
              <i className="fa fa-trash" style={{ width: '14px', height: '16px' }}></i>
            </button>
          </th>
        )}

        {columnUtils.shouldRenderColumn({ columnKey: 'name', stateColumns: columns }) && (
          <th scope="col">
            <span className="text-uppercase">Nome</span>
          </th>
        )}

        {columnUtils.shouldRenderColumn({ columnKey: 'email', stateColumns: columns }) && (
          <th scope="col">
            <span className="text-uppercase">Email</span>
          </th>
        )}
        {columnUtils.shouldRenderColumn({ columnKey: 'customer_name', stateColumns: columns }) && superadmin && (
          <th scope="col">
            <span className="text-uppercase">Cliente</span>
          </th>
        )}
      </tr>

      <Modal
        open={isOpen}
        style={{ display: 'grid', overflow: 'auto' }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-descriptions"
      >
        <Box
          width={550}
          bgcolor="#ffffff"
          m="auto"
          p="20px"
          borderRadius="15px"
          display="flex"
          flexDirection="column"
          top="10rem"
          style={{ gap: '3rem' }}
        >
          <h3 className="font-weight-bolder">Deseja excluir os usuários selecionados?</h3>

          <div className="d-flex justify-content-between align-self-end" style={{ gap: '2rem' }}>
            <Button
              variant="danger"
              className="btn btn-danger"
              style={{ fontSize: '14px', fontWeight: 'bold' }}
              onClick={() => onDelete()}
              disabled={isDeleting}
            >
              Sim, excluir
            </Button>

            <Button className="btn btn-primary" onClick={() => setIsOpen(false)} disabled={isDeleting}>
              Cancelar
            </Button>
          </div>
        </Box>
      </Modal>
    </thead>
  )
}

export const TableHeadLogs = () => {
  const columns = availableColumsLogs

  return (
    <thead>
      <tr>
        {columnUtils.shouldRenderColumn({ columnKey: 'action', stateColumns: columns }) && (
          <th scope="col">
            <span className="text-uppercase">Ação</span>
          </th>
        )}
        {columnUtils.shouldRenderColumn({ columnKey: 'description', stateColumns: columns }) && (
          <th scope="col">
            <span className="text-uppercase">Descrição</span>
          </th>
        )}
        {columnUtils.shouldRenderColumn({ columnKey: 'occurred_at', stateColumns: columns }) && (
          <th scope="col">
            <span className="text-uppercase">Data</span>
          </th>
        )}
        {columnUtils.shouldRenderColumn({ columnKey: 'metadata', stateColumns: columns }) && (
          <th scope="col">
            <span className="text-uppercase">Parâmetros</span>
          </th>
        )}
      </tr>
    </thead>
  )
}

export default TableHead
