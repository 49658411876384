import React, {useCallback} from 'react'
import {Paper, ContainerHeader, DetailContainer} from '../styles'

import {Container} from './styles'

const CityContact = ({contacts}) => {
  const renderContact = useCallback(
    ({name, email, phone, mobile_phone, mobile_phone_2, linkedin, site, id}) => (
      <Container key={id}>
        <div style={{width: '100%'}}>
          <span className="secundary-title">Nome do contato</span>
          <br />
          <span className="text-description-footer-1">{name}</span>
        </div>

        <div style={{width: '100%'}}>
          <span className="secundary-title">Telefone</span>
          <br />
          {[phone, mobile_phone, mobile_phone_2].map((phone) => (
            <span className="text-description-footer-1">
              <a href={`tel:+55${phone}`}>{phone}</a>
            </span>
          ))}
        </div>

        <div style={{width: '100%'}}>
          <span className="secundary-title">E-mail</span>
          <br />
          <span className="text-description-footer-1">
            <a href={`mailto:${email}`} style={{color: '#1E7BE1'}}>
              {email}
            </a>
          </span>
        </div>

        <div style={{width: '100%'}}>
          <span className="secundary-title">Site</span>
          <br />
          <span className="text-description-footer-1">
            <a href={linkedin ? linkedin : site} target="_blank" style={{color: '#1E7BE1'}}>
              {linkedin ? linkedin : site}
            </a>
          </span>
        </div>
      </Container>
    ),
    [],
  )
  return (
    <Paper>
      <ContainerHeader>Contatos da Empresa</ContainerHeader>
      <DetailContainer style={{paddingBottom: '1rem'}}>{contacts?.map(renderContact)}</DetailContainer>
    </Paper>
  )
}

export default CityContact
