import httpClient from '../../client'

const getPoliticians = async ({limit, page, format, ...rest}) => {
  try {
    const {data} = await httpClient.get(`/politicians`, {
      params: {limit, page, ...rest},
    })

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling proposals with ${JSON.stringify({limit, page})}`, error)
    return error
  }
}

export const getPoliticiansByParam = async ({limit, page, format, mayor, ...rest}) => {
  try {
    const {data} = await httpClient.get(`/politicians/${mayor}`, {
      params: {limit, page, ...rest},
    })

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling proposals with ${JSON.stringify({limit, page})}`, error)
    return error
  }
}

export default getPoliticians
