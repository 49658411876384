import {citieService, proposalService} from 'app/services'
import getRanking from 'app/services/cities/getRanking'
import getPoliticalOfficesFromCity from 'app/services/politicians/getPoliticalOfficesFromCity'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'

import {changeActualEntity} from '../../redux'
import CityAttachments from './Attachments'
import BasicInfo from './BasicInfo'
import CityCompanies from './Companies'
import CityContact from './Contact'
import CountyInfo from './CountyInfo'
import LinkedProposals from './LinkedProposals'
import Location from './Location'
import CityPoliticians from './Politicians'
import DetailCitieSkeleton from './skeleton'

function DetailCitie() {
  const [loading, setLoading] = useState(false)
  const [citie, setCitie] = useState()
  const [ranking, setRanking] = useState(null)
  const [politicians, setPoliticians] = useState(0)
  const [coordinates, setCoordinates] = useState(null)
  const dispatch = useDispatch()

  const {citieId} = useParams()

  const fetchPolitical = () => {
    getPoliticalOfficesFromCity(citieId).then((e) => {
      let total = 0
      e.forEach((t) => {
        total += +t.total
      })
      setPoliticians(total)
    })
  }

  const fetchCitie = useCallback(async () => {
    try {
      setLoading(true)
      const data = await citieService.getCitie(citieId)

      if (data?.coordinates) {
        setCoordinates({
          type: 'FeatureCollection',
          features: [
            {
              geometry: {
                coordinates: [parseFloat(data.coordinates[0]), parseFloat(data.coordinates[1])],
                type: 'Point',
              },
              properties: {
                id: data.id,
                code: data.code,
                name: data.name,
                type: 'city',
                state_uf: data.state.uf,
              },
              type: 'Feature',
            },
          ],
        })
      }

      setCitie(data)
      setLoading(false)

      dispatch(changeActualEntity(data))
    } catch (e) {
      setLoading(false)
    }
  }, [citieId])

  const fetchProposalsGeojson = useCallback(async () => {
    if (!citie?.id) {
      return
    }

    try {
      setLoading(true)

      let queryParams = {
        page: 1,
        limit: 500,
        city: citie.id,
      }

      const proposalsResult = await proposalService.getProposals(queryParams)
      const {data} = proposalsResult

      const statusMap = {
        completed: 'Ganha / Concretizada',
        lost: 'Perdida',
        open: 'Ativa',
      }

      let coordinatesLocal = []

      data.forEach((feature) => {
        if (feature?.coordinates?.length > 0) {
          var statusDesc = statusMap[feature.status]
          let jsonGeo = {
            geometry: {
              coordinates: [],
              type: 'Point',
            },
            properties: {
              id: feature.id,
              name: feature.name,
              type: 'proposal',
              status: feature.status,
              status_desc: statusDesc,
            },
            type: 'Feature',
          }
          jsonGeo.geometry.coordinates.push(parseFloat(feature.coordinates[0]))
          jsonGeo.geometry.coordinates.push(parseFloat(feature.coordinates[1]))
          coordinatesLocal.push(jsonGeo)
        }
      })

      setCoordinates({type: 'FeatureCollection', features: coordinatesLocal})
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setCoordinates({type: 'FeatureCollection', features: []})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citie])

  useEffect(() => {
    fetchCitie()
    fetchPolitical()

    if (citieId) {
      getRanking(citieId).then(setRanking)
    }
  }, [fetchCitie, citieId])

  useEffect(() => {
    fetchProposalsGeojson()
  }, [fetchProposalsGeojson])

  if (loading) {
    return <DetailCitieSkeleton />
  }

  return (
    <div className="d-flex flex-column container-citymatch-table-cards">
      <BasicInfo {...citie} ranking={ranking} politicians={politicians} />
      <Location {...citie} newCoordinates={coordinates} />
      {ranking ? <LinkedProposals {...citie} ranking={ranking} /> : null}
      <CountyInfo {...citie} />
      {citie ? <CityCompanies code={citie.code} name={citie.name} state={citie.state} /> : null}
      {citie ? <CityPoliticians cityId={citie.id} showHeaderCity /> : null}
      {citie && <CityContact contacts={citie?.location?.contacts} />}
      {citie && <CityAttachments multimedia={citie.location?.multimedia ?? null} />}
    </div>
  )
}

export default DetailCitie
