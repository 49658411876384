import styled from 'styled-components'

const backgroundColors = ['#BEDDFF', '#BEFFC9', '#D6BEFF', '#FFBECB', '#3D91364C']

const injectRandomBgColor = () => backgroundColors[Math.floor(Math.random() * backgroundColors.length)]

export const ErrorContainer = styled.div`
  align-items: center;
  background-color: ${injectRandomBgColor};
  display: flex;
  font-size: 25px;
  height: ${({height}) => height};
  justify-content: center;
  width: ${({width}) => width};
  border-radius: ${({borderRadius}) => borderRadius};
`
