import styled from 'styled-components'

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: white;
  border: 1px solid #b5b5c3;
  border-radius: 50%;
`
export default IconContainer
