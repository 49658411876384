import React, {useCallback, useState} from 'react'
import {Box, Divider, Grid} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'
import Type from 'app/components/proposal/Type'

import {Title, Container} from '../styles'
import StateCitySelect from 'app/components/form/StateCitySelect'
import {LabelM0} from '../../NewProposal/City/Details/styles'

import {useSelector} from 'react-redux'
import * as proposalsSelectors from 'app/modules/Proposals/redux/selectors'

const ProposalLocalizationPublic = (
  {type, benefits, name, project, handleChange, city, handleChangeBenefits, onSubmit, submiting, setTabNext},
  props,
) => {
  const availableIncentives = useSelector(proposalsSelectors.getAvailaleBenefits)
  const [triggerChangeBenefits, setTriggerChangeBenefits] = useState(false)
  const isSelected = useCallback((benefity) => Object.values(benefits).some((i) => i.type === benefity), [benefits])

  const renderIncentive = (incetive) => {
    return (
      <Box display="flex" key={incetive.type} marginBottom="15px">
        <Form.Check
          checked={isSelected(incetive.type)}
          onChange={() => {
            handleChangeBenefits(incetive)
            setTriggerChangeBenefits(true)
          }}
          id={`checkbox-${incetive.type}`}
          type="checkbox"
        />
        <label
          for={`checkbox-${incetive.type}`}
          style={{color: '#8C8C8C', fontSize: '16px', fontFamily: 'Nunito', marginLeft: 10}}>
          {incetive.description}
        </label>
      </Box>
    )
  }

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>LOCALIZAÇÃO DA OPORTUNIDADE</Title>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>Tipo da oportunidade</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Type type={type} width="36px" height="36px" iconSize="17px" />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="name">Nome</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="name"
            id="name"
            type="text"
            onChange={handleChange}
            value={name}
            placeholder="Ex.: Fábrica de Papel Celulose"
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}></Grid>
        <Grid item lg={9}>
          <Box marginBottom="10px">
            {' '}
            <StateCitySelect
              showCustomerOnly
              changeCity={(city) =>
                handleChange({
                  target: {
                    name: 'city_id',
                    value: city.id,
                  },
                })
              }
              city={city}
              showSelects></StateCitySelect>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}></Grid>
        <Grid item lg={9}>
          <Divider style={{maxWidth: 600, marginBottom: 30}} />
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>INCENTIVOS DA OPORTUNIDADE</Title>
          </Box>
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box display="flex" flexDirection="column" mb="20px">
            {availableIncentives.map(renderIncentive)}
          </Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          {!triggerChangeBenefits ? (
            <Box display="flex" marginTop="70px" marginBottom="70px">
              <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
                <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                  SALVAR E CONTINUAR EDIÇÃO
                </Button>
              </Box>
              <Box>
                <Button onClick={() => onSubmit(false)} disabled={submiting}>
                  SALVAR E CONCLUIR EDIÇÃO
                </Button>
              </Box>
            </Box>
          ) : (
            <Box display="flex" marginTop="70px" marginBottom="70px">
              <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
                <Button variant="outline-primary" onClick={() => setTabNext(1)}>
                  AVANÇAR
                </Button>
              </Box>
              <Box></Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ProposalLocalizationPublic
