import httpClient from '../../client'

const getFile = async (path) => {
  try {
    const url = `/${path}`
    const {data} = await httpClient.get(url)
    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling proposal with ${JSON.stringify(path)}`, error)

    return error
  }
}

export default getFile
