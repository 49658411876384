import React, {useState, useCallback, useEffect} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {CircularProgress, TextField} from '@material-ui/core'

import getBenefits from 'app/services/benefits/getBenefits'

import {Label, Column, SelectedListContainer, SelectedListItem} from './styles'

const AutoCompleteBenefits = ({selectedItem, onSelectItem, onRemoveItem, selectedItems}) => {
  const [activities, setActivities] = useState([])
  const [inputText, setInputText] = useState(selectedItem?.name ?? '')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  selectedItems = selectedItems || []

  const getOptionLabel = ({type}) => `${type.toUpperCase()}`

  const onChange = (_, value) => {
    const isAdded = value?.type ? selectedItems.indexOf(value?.type) : false

    if (isAdded === -1 && value?.type) {
      onSelectItem(value.type)
      setInputText('')
    }
  }

  const onInputChange = (event) => {
    if (event) {
      setInputText(event.target?.value ? event.target?.value : '')
    }
  }

  const getOptionSelected = (option, value) => option.name === value.name

  const searchActivities = useCallback(async () => {
    setLoading(true)
    try {
      const {data} = await getBenefits({limit: 50})

      setActivities(data)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    searchActivities()
  }, [searchActivities])

  return (
    <Column>
      <Label>Incentivos</Label>
      <Autocomplete
        size="small"
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={activities}
        loading={loading}
        inputValue={inputText ? inputText : ''}
        noOptionsText="Nenhum item encontrado"
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionDisabled={(selected) => selected === selectedItem}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              placeholder: 'Escolher…',
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {selectedItems.length ? (
        <SelectedListContainer>
          {activities
            .filter(({id, description, type}) => selectedItems.indexOf(type) !== -1)
            .map(({id, type, description}) => (
              <SelectedListItem key={id}>
                {type.toUpperCase()}
                <i className="far fa-times-circle" style={{cursor: 'pointer'}} onClick={() => onRemoveItem(type)} />
              </SelectedListItem>
            ))}
        </SelectedListContainer>
      ) : null}
    </Column>
  )
}

export default AutoCompleteBenefits
