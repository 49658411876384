import React from 'react'
import {useDispatch} from 'react-redux'
import {TablePagination} from '@material-ui/core'

import {Container} from './styles'

import Skeleton from './skeleton'

const LIMIT = 25

const ProposalPagination = ({loading, page, total, limit = LIMIT, changeLimit, changePage}) => {
  const dispatch = useDispatch()

  const onChangePage = (_, page) => {
    dispatch(changePage(page))
  }

  const handleChangeRowsPerPage = (e) => {
    dispatch(changeLimit(e.target.value))
  }

  if (loading) {
    return <Skeleton />
  }

  return (
    <Container>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        labelRowsPerPage="Itens por página"
        count={total}
        rowsPerPage={limit}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        nextIconButtonText="Próxima Página"
        backIconButtonText="Página anterior"
      />
    </Container>
  )
}

export default ProposalPagination
