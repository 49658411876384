import {Grid} from '@material-ui/core'
import ListPoliticiansPage from 'app/modules/Politicians/Pages/ListPoliticians'
import getPoliticalOfficesFromCity from 'app/services/politicians/getPoliticalOfficesFromCity'
import getPoliticalPartiesFromCity from 'app/services/politicians/getPoliticalPartiesFromCity'

import React, {useEffect, useState} from 'react'

import {Paper, ContainerHeader, DetailContainer, IcentiveContainer} from '../styles'

const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

const StatePoliticians = ({stateId, showHeaderCity}) => {
  const [parties, setParties] = useState({total: 0, data: []})
  const [politicalOffices, setPoliticalOffices] = useState([])

  useEffect(() => {
    getPoliticalPartiesFromCity(stateId).then(setParties)

    getPoliticalOfficesFromCity(stateId).then(setPoliticalOffices)
  }, [stateId])

  return (
    <Paper className="state-politicians">
      <ContainerHeader>Políticos Eleitos</ContainerHeader>
      {showHeaderCity ? (
        <>
          <DetailContainer>
            <Grid container>
              <Grid item lg={6} style={{paddingRight: '40px'}}>
                <table className="table">
                  {politicalOffices.map((office, idx) => (
                    <tr key={idx}>
                      <td>
                        <label>
                          <b style={{fontSize: 14}}>{office.description}</b>
                        </label>
                      </td>{' '}
                      <td style={{textAlign: 'right'}}>{formatNumber(office.total, 0)}</td>
                    </tr>
                  ))}
                </table>
              </Grid>
              <Grid item lg={6} style={{paddingLeft: '40px'}}>
                <table className="table">
                  <tr>
                    <td>
                      <label>
                        <b style={{fontSize: 14}}>Total de Partidos Atuantes</b>
                      </label>
                    </td>
                    <td style={{textAlign: 'right'}}>{parties.total}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{borderBottom: 'none'}}>
                      <label>
                        <b style={{fontSize: 14}}>Partidos Atuantes</b>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{borderBottom: 'none', display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
                      {parties.data.map((party) => (
                        <IcentiveContainer key={party.id}>{party.abbreviation.toUpperCase()}</IcentiveContainer>
                      ))}
                    </td>
                  </tr>
                </table>
              </Grid>
            </Grid>
          </DetailContainer>
          <ContainerHeader>Principais Nomes Políticos do Estado</ContainerHeader>
        </>
      ) : null}

      <ListPoliticiansPage
        hideSearch
        defaultFilters={{state: stateId}}
        defaultSort={{'sort[importance]': 'asc'}}
        isDetailPage></ListPoliticiansPage>
    </Paper>
  )
}

export default StatePoliticians
