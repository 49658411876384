import {Box} from '@material-ui/core'
import {Circular as CircularLoading} from 'app/components'
import Type from 'app/components/proposal/Type'
import {useFavorite} from 'app/hooks'
import React, {useCallback, useRef, useState} from 'react'
import {Button, Card, ListGroup, Overlay, Popover} from 'react-bootstrap'
import {Edit, Menu, Star} from 'react-feather'
import {useHistory} from 'react-router-dom'

import {
  Actions,
  Container,
  CounterContainer,
  DescriptionContainer,
  FavoriteIcon,
  MayorContainer,
  MayorName,
  QuantityDiv,
  StateHeader,
  StateUf,
  StyledPopover,
  StyledSpan,
  TaxDiv,
} from './styles'

function StateCard(props) {
  const {
    id,
    name,
    uf,
    coode,
    governor,
    proposals,
    vocations,
    benefits,
    description,
    has_permission_to_edit,
    is_favorite,
    featured_image,
  } = props.state
  const privateCounter = proposals.private
  const publicCounter = proposals.public

  const popoverRef = useRef(null)
  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite: is_favorite,
    entity: 'state',
    label: 'Estado',
  })

  const handlePopoverClick = useCallback((event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }, [])

  const history = useHistory()

  const goToDetails = useCallback(() => history.push(`/states/${id}`), [history, id])
  const goToEdit = useCallback(() => history.push(`/states/${id}/edit`), [history, id])

  const formatCurrency = (amount, multiply) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      notation: 'compact',
    }).format(multiply ? multiply * amount : amount)

  const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

  return (
    <Card
      style={{
        maxWidth: '485px',
        marginRight: '10px',
        marginLeft: '10px',
        marginBottom: '20px',
        padding: '23px 31px',
        borderRadius: 10,
        flex: '1 0 25%',
      }}>
      <Card.Header style={{borderBottom: 'none', padding: 0}} className="d-flex flex-wrap">
        <StateHeader>
          <div style={{width: '100', display: 'flex', whiteSpace: 'nowrap', fontFamily: 'Nunito Sans'}}>
            <span style={{fontWeight: '600', color: '#000000'}}>{name}</span>
          </div>
          <Actions>
            <i
              className="fa fa-ellipsis-h"
              style={{cursor: 'pointer'}}
              onClick={handlePopoverClick}
              aria-hidden="true"></i>
            <Overlay
              show={showPopover}
              ref={popoverRef}
              target={popoverTarget}
              container={popoverRef.current}
              placement="bottom"
              rootClose={true}
              onHide={() => setShowPopover(false)}>
              <StyledPopover>
                <Popover.Content>
                  <ListGroup variant="flush">
                    <ListGroup.Item onClick={goToDetails}>
                      <Menu className="icon-menu" /> Ver detalhes
                    </ListGroup.Item>

                    {has_permission_to_edit && (
                      <ListGroup.Item onClick={goToEdit}>
                        <Edit className="icon-menu" />
                        Editar
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Popover.Content>
              </StyledPopover>
            </Overlay>
          </Actions>
        </StateHeader>
        <MayorContainer>Governador(a)</MayorContainer>
        <MayorName>
          {governor ? governor.name : 'Não Informado'}{' '}
          {governor ? ` - ${governor.political_party?.abbreviation ?? ''}` : null}
        </MayorName>
        {featured_image ? <Card.Img variant="top" src={featured_image} style={{borderRadius: '10px'}} /> : null}
      </Card.Header>
      <Card.Body style={{padding: 0}}>
        <CounterContainer>
          {description && <DescriptionContainer>{description}</DescriptionContainer>}
          <hr style={{borderTop: '1px solid #E3E3E3', marginTop: 20, marginBottom: 20}} />

          <MayorContainer>Oportunidades</MayorContainer>
          <Container justify="space-between">
            <Box display="flex" alignItems="center">
              <Type type="company" height="36px" width="36px" iconSize="15px" />
              <QuantityDiv color="#2C6EB533" style={{marginLeft: '10px'}}>
                {privateCounter}
              </QuantityDiv>
            </Box>
            <Box display="flex" alignItems="center">
              <Type type="city" height="36px" width="36px" iconSize="15px" />
              <QuantityDiv color="#38383833" style={{color: '#383838', marginLeft: '10px'}}>
                {publicCounter}
              </QuantityDiv>
            </Box>
          </Container>
        </CounterContainer>
        <Box display="flex" flexDirection="column">
          <Box marginBottom="8px">
            <MayorContainer>Incentivos disponíveis ao investidor</MayorContainer>
          </Box>
          {benefits ? (
            <Box display="flex" flexDirection="row">
              {benefits.map((benefit, key) => (
                <TaxDiv key={key} title={benefit}>
                  <i className="fa fa-check" aria-hidden="true" style={{color: '#BEDDFF'}}></i>
                  <span style={{marginLeft: '0.5rem'}}>{benefit.toUpperCase()}</span>
                </TaxDiv>
              ))}
            </Box>
          ) : (
            <Box display="flex" flexDirection="row">
              Nenhum benefício disponível
            </Box>
          )}
        </Box>
        <hr />
        <Button variant="primary" style={{float: 'right', marginTop: '25px'}} onClick={goToDetails}>
          DETALHES
        </Button>
      </Card.Body>
    </Card>
  )
}

export default StateCard
