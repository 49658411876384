import React, { useState } from 'react'
import TableHead, { TableHeadLogs } from './Header'
import TableRow, { RowLog } from './Row'
import SkeletonRow from './Row/skeleton'
import { availableColums, availableColumsLogs } from '../../redux'

const SKELETON_ITEMS = 10

export const TableLogs = ({ loading, logs }) => {
  const columns = availableColumsLogs

  const renderLog = (log) => <RowLog key={`k=${log.id}`} {...log} columns={columns} />

  return (
    <table className="table">
      <TableHeadLogs />
      <tbody>
        {loading
          ? Array.from({ length: SKELETON_ITEMS }).map((_, index) => <SkeletonRow key={index} />)
          : logs?.map(renderLog)}
      </tbody>
    </table>
  )
}

const Table = ({ loading, loadingCustomers, users, customers }) => {
  const columns = availableColums
  const [selectedUsers, setSelectedUsers] = useState([])

  const handleUserSelectChange = (id) => {
    setSelectedUsers((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id)
      }

      return [...prev, id]
    })
  }

  const renderUser = (user) => (
    <TableRow
      key={`k=${user.id}`}
      {...user}
      customers={customers}
      columns={columns}
      onSelectChange={handleUserSelectChange}
    />
  )

  return (
    <table className="table">
      <TableHead selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />

      <tbody>
        {loading && loadingCustomers
          ? Array.from({ length: SKELETON_ITEMS }).map((_, index) => <SkeletonRow key={index} />)
          : users?.map(renderUser)}
      </tbody>
    </table>
  )
}

export { Table }
