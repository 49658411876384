import React from 'react'

import Shimmer from 'react-loading-skeleton'

import BasicInfoLoading from './BasicInfo/loading'
import LinkedProposalsLoading from './LinkedProposals/loading'
import CountyInfoLoading from './CountyInfo/loading'

const SKELETON_STYLE = {
  borderRadius: '10px',
  marginBottom: '2rem',
}

const DetailCitieSkeleton = () => {
  return (
    <div className="d-flex flex-column">
      <BasicInfoLoading />
      <Shimmer width="100%" height="300px" style={SKELETON_STYLE} />
      <LinkedProposalsLoading />
      <CountyInfoLoading />
      <Shimmer width="100%" height="300px" style={SKELETON_STYLE} />
    </div>
  )
}

export default DetailCitieSkeleton
