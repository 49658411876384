import Map from 'app/components/map/Map'
import ProposalCards from 'app/components/proposal/ProposalCards'
import {ProposalTable} from 'app/components/proposal/Table'
import * as proposalSelectors from 'app/modules/Proposals/redux/selectors'
import {proposalService} from 'app/services'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import Shimmer from 'react-loading-skeleton'
import {useDispatch, useSelector} from 'react-redux'

// import { setAvailableOrdering, changeActualZoom } from '../../redux'
import {setAvailableOrdering} from '../../redux'

const ListProposalsPage = () => {
  const {enqueueSnackbar} = useSnackbar()

  const dispatch = useDispatch()

  const currentPage = useSelector(proposalSelectors.getCurrentProposalsPage)
  const hasLoadedOrdering = useSelector(proposalSelectors.hasLoadedOrdering)
  const {showMap, viewMode} = useSelector(proposalSelectors.universalSearch)
  const {hasAppliedFilters, searchParams, showOnlyFavorites} = useSelector(proposalSelectors.getSearchParams)
  const activeOrder = useSelector(proposalSelectors.getActiveOrder)
  const limit = useSelector(proposalSelectors.getCurrentLimit)
  const [fetchedOnce, setFetchedOnce] = useState(false)
  const [proposals, setProposals] = useState([])
  const [total, setTotal] = useState(0)
  const [totalWithoutFilter, setTotalWithoutFilter] = useState(0)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [coordinates, setCoordinates] = useState(null)
  const [currentPosition, setCurrentPosition] = useState(null)
  const [moved, setMoved] = useState(false)
  // const [zoomIn, setZoomIn] = useState(4)
  const [drawnArea, setDrawnArea] = useState(null)

  const mountSorting = (selectedOrder) => ({
    [`sort[${selectedOrder.key}]`]: selectedOrder.order.toLowerCase(),
  })

  const changeCenterMap = (center) => {
    setMoved(true)
    setCurrentPosition(center)
  }

  const changeDrawnArea = (drawnArea) => {
    setDrawnArea(drawnArea)
  }

  const mountQueryParams = (params) => {
    var filtersUrl = {}
    if (window.location.hash) {
      var urlHash = window.location.hash.split('=')
      if (urlHash) {
        var fillters = urlHash[1] ? urlHash[1].split('&') : []

        fillters.forEach((filter) => {
          var fff = filter.split(':')
          filtersUrl[fff[0]] = fff[1]
        })
      }
    }

    let queryParams = {
      limit: limit.current,
    }

    if (currentPosition && moved) {
      queryParams['coordinates_polygon'] = currentPosition
      setMoved(false)
    }

    if (drawnArea) {
      queryParams.coordinates_polygon = drawnArea
    }

    if (showOnlyFavorites) {
      queryParams.only_favorites = 1
    }

    if (hasAppliedFilters) {
      if (params.endDate) {
        queryParams.end_date = params.endDate
      }

      if (params.startDate) {
        queryParams.start_date = params.startDate
      }

      if (params.selectedStates?.length) {
        queryParams.ufs = params.selectedStates.map(({id}) => id).join(',')
      }

      if (params.selectedCities?.length) {
        queryParams.city = params.selectedCities.map(({id}) => id).join(',')
      }

      if (params.selectedStatus?.length) {
        queryParams.status = params.selectedStatus.join(',')
      }

      if (params.selectedTypes?.length) {
        queryParams.type = params.selectedTypes.join(',')
      }

      var range = []

      if (params.investmentStart > 0) {
        range.push(params.investmentStart)
      }

      if (params.investmentEnd > 0) {
        range.push(params.investmentEnd)
      }

      if (range.length) {
        queryParams.investimentRange = range.join(',')
      }
    }

    if (activeOrder) {
      queryParams = {...queryParams, ...mountSorting(activeOrder)}
    }

    if (filtersUrl) {
      queryParams = {...queryParams, ...filtersUrl}
    }

    return queryParams
  }

  const fetchProposalsGeojson = useCallback(async () => {
    try {
      setLoading(true)

      let coordinatesLocal = []

      let queryParams = {
        page: 1,
        ...filters,
        ...mountQueryParams(searchParams),
        limit: 10000,
      }

      const proposalsResult = await proposalService.getProposals(queryParams)

      !fetchedOnce && setFetchedOnce(true)

      const {data} = proposalsResult

      const statusMap = {
        completed: 'Ganha / Concretizada',
        lost: 'Perdida',
        open: 'Ativa',
      }

      data.forEach((feature) => {
        if (feature?.coordinates?.length > 0) {
          var statusDesc = statusMap[feature.status]
          let jsonGeo = {
            geometry: {
              coordinates: [],
              type: 'Point',
            },
            properties: {
              id: feature.id,
              name: feature.name,
              type: 'proposal',
              status: feature.status,
              status_desc: statusDesc,
            },
            type: 'Feature',
          }
          jsonGeo.geometry.coordinates.push(parseFloat(feature.coordinates[0]))
          jsonGeo.geometry.coordinates.push(parseFloat(feature.coordinates[1]))
          coordinatesLocal.push(jsonGeo)
        }
      })

      const addMarginToRepeatedCoordinates = (coordinates) => {
        const updatedCoordinates = coordinates.map((feature) => {
          const repeated = coordinates.filter(
            (f) =>
              f.geometry.coordinates[0] === feature.geometry.coordinates[0] &&
              f.geometry.coordinates[1] === feature.geometry.coordinates[1],
          )
          if (repeated.length > 1) {
            const marginLng = (Math.random() * 2 - 1) * 0.0001
            const marginLat = (Math.random() * 2 - 1) * 0.0001
            const [longitude, latitude] = feature.geometry.coordinates
            return {
              ...feature,
              geometry: {
                ...feature.geometry,
                coordinates: [longitude + marginLng, latitude + marginLat],
              },
            }
          }
          return feature
        })
        return updatedCoordinates
      }

      const updatedCoordinatesLocal = addMarginToRepeatedCoordinates(coordinatesLocal)

      setCoordinates({type: 'FeatureCollection', features: updatedCoordinatesLocal})
    } catch (error) {
      setCoordinates({type: 'FeatureCollection', features: []})
    } finally {
      setLoading(false)
    }
  }, [
    activeOrder,
    currentPosition,
    currentPage,
    dispatch,
    enqueueSnackbar,
    filters,
    hasAppliedFilters,
    limit,
    showOnlyFavorites,
    searchParams,
  ])

  const fetchProposals = useCallback(async () => {
    try {
      setLoading(true)

      let queryParams = {
        page: currentPage,
        ...filters,
        ...mountQueryParams(searchParams),
      }

      const {
        data,
        total,
        available_ordering: availableOrdering,
        total_without_filter,
      } = await proposalService.getProposals(queryParams)

      setTotalWithoutFilter(total_without_filter)
      setTotal(total)
      setProposals(data)

      if (!hasLoadedOrdering) {
        dispatch(setAvailableOrdering(availableOrdering))
      }
    } catch (error) {
      setProposals([])

      enqueueSnackbar('Ocorreu um erro ao consultar as oportunidades', {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [
    activeOrder,
    currentPosition,
    currentPage,
    dispatch,
    enqueueSnackbar,
    filters,
    hasAppliedFilters,
    limit,
    showOnlyFavorites,
    searchParams,
  ])

  useEffect(() => {
    fetchProposals()
    fetchProposalsGeojson()
  }, [activeOrder, fetchProposals, fetchProposalsGeojson])

  const renderVisualizationMode = () => {
    if (viewMode === 'card') {
      return <ProposalCards loading={loading} limit={limit} page={currentPage} proposals={proposals} total={total} />
    }

    return (
      <ProposalTable
        limit={limit}
        loading={loading}
        page={currentPage}
        proposals={proposals}
        reloadList={() => fetchProposals()}
        total={total}
        totalWithoutFilter={totalWithoutFilter}
      />
    )
  }

  return (
    <>
      {showMap && (
        <>
          {(!coordinates || !fetchedOnce) && (
            <Shimmer className="w-100" height="500px" style={{marginBottom: '40px', borderRadius: '20px'}} />
          )}

          {coordinates && fetchedOnce && (
            <Map
              lg
              centerMap={null}
              changeCenterMap={changeCenterMap}
              coordinates={coordinates}
              currentPage={currentPage}
              changeFilter={setFilters}
              loading={loading}
              changeDrawnArea={changeDrawnArea}
              mapType="proposals"
            />
          )}
        </>
      )}

      <div className="d-flex flex-column">{renderVisualizationMode()}</div>
    </>
  )
}

export default ListProposalsPage
