import React from 'react'

const GestoresPublicos = () => {
  return (
    <svg id="icon" xmlns="http://www.w3.org/2000/svg" width="19.2" height="17.28" viewBox="0 0 19.2 17.28">
      <defs>
        <clipPath id="clip-path">
          <rect id="Retângulo_261" data-name="Retângulo 261" width="19.2" height="17.28" fill="none" />
        </clipPath>
      </defs>
      <g id="person_search" data-name="person search" clip-path="url(#clip-path)">
        <path
          id="Caminho_392"
          data-name="Caminho 392"
          d="M7.84,7.68A3.84,3.84,0,1,0,4,3.84,3.84,3.84,0,0,0,7.84,7.68m0-5.76A1.92,1.92,0,1,1,5.92,3.84,1.92,1.92,0,0,1,7.84,1.92"
          transform="translate(-0.16)"
          fill="#fff"
        />
        <path
          id="Caminho_393"
          data-name="Caminho 393"
          d="M1.92,13.84c.192-.672,3.168-1.92,5.76-1.92A5.664,5.664,0,0,1,7.968,10C5.376,9.9,0,11.248,0,13.84v1.92H9.12a5.664,5.664,0,0,1-1.152-1.92Z"
          transform="translate(0 -0.4)"
          fill="#fff"
        />
        <path
          id="Caminho_394"
          data-name="Caminho 394"
          d="M17.1,13.751a3.744,3.744,0,0,0,.576-1.92,3.936,3.936,0,1,0-1.92,3.264l2.5,2.5,1.344-1.344-2.5-2.5m-3.264,0a1.92,1.92,0,1,1,1.92-1.92,1.92,1.92,0,0,1-1.92,1.92"
          transform="translate(-0.392 -0.311)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default GestoresPublicos
