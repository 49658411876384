import React from 'react'

import Shimmer from 'react-loading-skeleton'

import {Card} from 'react-bootstrap'

import {InfoContainer, ProposalName, Actions, Container, LogoContainer} from './styles'

const Skeleton = () => (
  <Card
    style={{
      maxWidth: '434px',
      marginRight: '10px',
      marginLeft: '10px',
      marginBottom: '20px',
      flex: '1 0 25%',
    }}>
    <Card.Header
      className="d-flex pl-4 pr-4 justify-content-between"
      style={{borderBottom: 'none', paddingBottom: '0px'}}>
      <InfoContainer>
        <LogoContainer>
          <Shimmer height="57px" width="57px" style={{borderRadius: '50%'}} />
        </LogoContainer>
        <div className="d-flex flex-column pl-1 w-75">
          <ProposalName>
            <Shimmer />
          </ProposalName>
          <div className="d-flex justify-content-start w-100 align-items-center" style={{height: '10px'}}>
            <span className="text-muted">
              #<Shimmer width="30px" />
            </span>
            <span className="text-muted ml-7">
              <Shimmer width="30px" />-<Shimmer width="30px" />
            </span>
          </div>
        </div>
      </InfoContainer>
      <Actions>
        <Shimmer width="30px" />
        <Shimmer width="30px" />
      </Actions>
    </Card.Header>
    <Card.Body className="pl-5 pr-5">
      <Card.Title className="text-muted" style={{fontSize: '1rem'}}>
        <Shimmer />
        <Shimmer />
        <Shimmer />
      </Card.Title>
      <Container>
        <div className="d-flex justify-content-between w-100">
          <Shimmer width="70px" />
          <Shimmer width="70px" />
          <Shimmer width="70px" />
        </div>
      </Container>
      <Container>
        <div className="d-flex justify-content-between w-100">
          <Shimmer width="70px" />
          <Shimmer width="70px" />
          <Shimmer width="70px" />
        </div>
      </Container>
    </Card.Body>
    <Card.Footer>
      <Shimmer style={{float: 'right', width: '114px', marginTop: '1rem'}} height="39px" />
    </Card.Footer>
  </Card>
)

export default Skeleton
