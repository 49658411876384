import {Box, Grid} from '@material-ui/core'
import {Container} from 'app/modules/Cities/Pages/EditCitie/styles'
import React, {useMemo} from 'react'
import {Button} from 'react-bootstrap'
import useShowcaseSnippets from './useShowcaseSnippets'

const ShowcaseGenerator = ({onGenerateShowcase, submiting, showcaseUrl}) => {
  const snippets = useShowcaseSnippets(showcaseUrl)

  return (
    <Container style={{padding: 0}} direction="column">
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item sm={12}>
          <Box marginTop="70px">
            <Box>
              <Button onClick={onGenerateShowcase} disabled={submiting}>
                ATUALIZAR VITRINE ECONÔMICA
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Box marginBottom="70px">
            {showcaseUrl && (
              <Box>
                <a href={showcaseUrl} target="_blank" rel="noreferrer">
                  <label>Visualizar vitrine</label>
                </a>
                <br />
                <br />
                <pre>{showcaseUrl}</pre>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {snippets.map((snippet, idx) => (
        <details key={idx}>
          <summary>{snippet.title}</summary>
          {snippet.node}
        </details>
      ))}
    </Container>
  )
}

export default ShowcaseGenerator
