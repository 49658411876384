const ENV_PREFIX = 'REACT_APP_'
const envs = process.env

const getRaw = (envName) => envs?.[envName.toUpperCase()]?.replace(/^\s+|\s+$/g, '')

export const getEnvVar = (env, defaultValue = '') => {
  let envName = env.toUpperCase().replace(ENV_PREFIX, '')
  envName = `${ENV_PREFIX}${envName}`

  return envs?.[envName] ?? defaultValue
}

export const getNodeEnv = () => getRaw('NODE_ENV').toLowerCase()
export const getPublicUrl = () => getRaw('PUBLIC_URL')
