import axios from 'axios'

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`
export const REGISTER_URL = 'api/auth/register'
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_CITY_MATCH_API_URL}/reset-password`
export const CHANGE_PASSWORD = `${process.env.REACT_APP_CITY_MATCH_API_URL}/change-password`
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`
export const TWOFACTOR_URL = `${process.env.REACT_APP_API_URL}/2fa-check`

export function login(email, password) {
  return axios.post(LOGIN_URL, {email, password})
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, {email, fullname, username, password})
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, {email})
}

export function changePassword(token, password, confirm_password) {
  return axios.post(CHANGE_PASSWORD, {token, password, confirm_password})
}

export function twoFactor(code) {
  return axios.post(TWOFACTOR_URL, {code})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL)
}
