import React, {Fragment, Suspense} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'

import {ContentRoute, LayoutSplashScreen} from '../_metronic/layout'
import TermsModal from './components/modal/TermsModal'
import CitiesPage from './modules/Cities/Pages'
import CompaniesPage from './modules/Companies/pages'
import CorporateConnectionsPage from './modules/CorporateConnections/Pages'
import CustomerPage from './modules/Customer/Pages'
import ManageUserPage from './modules/ManageUser/pages'
import ListLogs from './modules/ManageUser/pages/ListLogs'
import PoliticiansPage from './modules/Politicians/Pages'
import ProposalsPage from './modules/Proposals/pages'
import StatesPage from './modules/States/Pages'
import UserProfilePage from './modules/UserProfile/UserProfilePage'
import ManageCustomerPage from './modules/ManageCustomer/pages'

export default function BasePage() {
  const {admin} = useSelector(
    ({auth}) => ({
      admin: auth.user.roles.includes('ROLE_ADMIN'),
    }),
    shallowEqual,
  )

  return (
    <Fragment>
      <TermsModal />
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/cities" />
          }
          <Route exact path="/user-profile/logs" component={ListLogs} />
          <ContentRoute path="/user-profile" component={UserProfilePage} />
          <ContentRoute path="/states" component={StatesPage} />
          <ContentRoute path="/cities" component={CitiesPage} />
          <ContentRoute path="/proposals" component={ProposalsPage} />
          <ContentRoute path="/companies" component={CompaniesPage} />
          <ContentRoute path="/politicians" component={PoliticiansPage} />
          <ContentRoute path="/corporate-connections" component={CorporateConnectionsPage} />
          {admin && (
            <>
              <ContentRoute path="/customer" component={CustomerPage} />
              <ContentRoute path="/manage-customers" component={ManageCustomerPage} />
              <ContentRoute path="/manage-users" component={ManageUserPage} />
            </>
          )}
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    </Fragment>
  )
}
