import {createSlice} from '@reduxjs/toolkit'
import {storageService} from 'app/services'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createFilter from 'redux-persist-transform-filter'

export const availableColums = [
  {
    key: 'id',
    label: '#',
  },
  {
    key: 'social_reason',
    label: 'Razão Social',
  },
  {
    key: 'fanstasy_name',
    label: 'Nome fantasia',
  },
  {
    key: 'cnae_primary',
    label: 'CNAE primário',
  },
  {
    key: 'foundation',
    label: 'Fundação',
  },
  {
    key: 'social_capital',
    label: 'Capital Social',
  },
  {
    key: 'city',
    label: 'Município',
  },
  {
    key: 'uf',
    label: 'UF',
  },
]

export const initialState = {
  columns: {
    available: availableColums,
    active: availableColums,
  },
  filters: {
    ordering: {
      activeSort: {
        key: 'capital_social',
        order: 'DESC',
      },
      orders: [],
    },
  },
  limit: {
    current: 25,
    available: [25, 50, 100],
  },
  page: 1,
  universalSearch: {
    hasAppliedFilters: false,
    searchParams: {},
    selectedFilters: {},
    showMap: true,
    viewMode: 'list',
  },
}

export const companiesReducer = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    changeActiveColumns: (state, action) => {
      state.columns.active = action.payload
    },
    changePage: (state, action) => {
      state.page = action.payload
    },
    changeActiveSort: (state, action) => {
      state.filters.ordering.activeSort = action.payload
    },
    setAvailableOrdering: (state, action) => {
      state.filters.ordering.orders = action.payload
    },
    toogleMap: (state) => {
      state.universalSearch.showMap = !state.universalSearch.showMap
    },
    changeViewMode: (state, action) => {
      state.universalSearch.viewMode = action.payload
    },
    changeLimit: (state, action) => {
      state.limit.current = action.payload
    },
    changeSearchParams: (state, action) => {
      state.universalSearch.searchParams = action.payload
    },
    changeSelectedFilters: (state, action) => {
      state.universalSearch.selectedFilters = action.payload
    },
    changeActualEntity: (state, action) => {
      state.universalSearch.actualEntity = action.payload
    },
    setAppliedFilters: (state, action) => {
      state.universalSearch.hasAppliedFilters = action.payload
      state.page = 1
    },
    setShowOnlyFavorites: (state, action) => {
      state.universalSearch.showOnlyFavorites = action.payload
      state.page = 1
    },
    cleanSearchParams: (state) => {
      state.universalSearch.searchParams = initialState.universalSearch.searchParams
      state.universalSearch.selectedFilters = initialState.universalSearch.selectedFilters
      state.universalSearch.hasAppliedFilters = false
    },
  },
})

export const {
  changeActiveColumns,
  changeActiveSort,
  setShowOnlyFavorites,
  changePage,
  changeViewMode,
  setAvailableOrdering,
  toogleMap,
  changeLimit,
  cleanSearchParams,
  changeSearchParams,
  changeSelectedFilters,
  changeActualEntity,
  setAppliedFilters,
} = companiesReducer.actions

export default persistReducer(
  {
    storage,
    key: storageService.storageKeys.STORAGE_KEYS.COMPANIES,
    transforms: [createFilter('universalSearch.showMap', 'universalSearch.viewMode', 'columns', 'filters')],
  },
  companiesReducer.reducer,
)
