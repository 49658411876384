import {Box, Modal} from '@material-ui/core'
import ContactAddressIcon from 'app/components/images/Icons/ContactAddress'
import {changeActualEntity} from 'app/modules/Cities/redux'
import {customerService, userService} from 'app/services'
import {useFormik} from 'formik'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {Column, FormContainer, Row, SidePanel, SidePanelItem, SidePanelTitle, SuccessIcon} from './styles'
import * as UserSteps from './User'

const steps = {
  NEW_USER: 'NEW_USER',
  SUCCESS: 'SUCCESS',
}

const NewUser = () => {
  const {enqueueSnackbar} = useSnackbar()

  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [isCreating, setisCreating] = useState(false)
  const [currentStep, setCurrentStep] = useState(steps.NEW_USER)
  const [filledSteps, setFilledSteps] = useState([])
  const history = useHistory()
  const [formData, setFormData] = useState(false)
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    dispatch(changeActualEntity({id: 'new', name: 'Novo usuário'}))
  }, [dispatch])

  useEffect(() => {
    const fetchCustomers = async () => {
      const {data} = await customerService.getListCustomers()
      setCustomers(data.filter((customer) => customer.is_active))
    }

    fetchCustomers()
  }, [])

  const onCreate = async () => {
    setisCreating(true)

    try {
      const data = await userService.createUser({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        property: {
          phone: formData.property.phone,
          mobile_phone: formData.property.mobile_phone,
          linkedin: formData.property.linkedin,
          facebook: formData.property.facebook,
        },
        roles: formData.roles,
        customer_id: formData.customer,
      })

      if (data) {
        setCurrentStep(steps.SUCCESS)
        enqueueSnackbar('Usuário cadastrado com sucesso!', {
          variant: 'success',
        })
      } else {
        throw data
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    }

    setIsOpen(false)
    setisCreating(false)
  }

  const onSubmit = useCallback(
    async (values) => {
      try {
        const {status} = await userService.emailUser({
          name: values.name,
          email: values.email,
          password: values.password,
          property: {
            phone: values.property.phone,
            mobile_phone: values.property.mobile_phone,
            linkedin: values.property.linkedin,
            facebook: values.property.facebook,
          },
          roles: values.roles,
          customer_id: values.customer,
        })

        if (status === 204) {
          setFormData(values)
          setIsOpen(true)
        } else {
          setCurrentStep(steps.SUCCESS)
          enqueueSnackbar('Usuário cadastrado com sucesso!', {
            variant: 'success',
          })
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar],
  )

  const formikUser = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      property: {
        phone: '',
        mobile_phone: '',
        linkedin: '',
        facebook: '',
      },
      roles: [],
      customer: '',
    },
    onSubmit,
  })

  const sections = useMemo(() => {
    return [
      {
        icon: (color) => <ContactAddressIcon color={color} />,
        title: 'Informações básicas',
        step: steps.NEW_USER,
      },
    ]
  }, [])

  const renderSideItem = (item, index) => {
    const color = item.step === currentStep || filledSteps.includes(item.step) ? '#1E7BE1' : '#C9C9C9'

    return (
      <SidePanelItem key={index} active={item.step === currentStep}>
        <div style={{marginRight: '20px', width: '35px', height: '45px'}}>{item.icon(color)}</div>
        <Column>
          <SidePanelTitle active={item.step === currentStep || filledSteps.includes(item.step)}>
            {item.title}
          </SidePanelTitle>
        </Column>
      </SidePanelItem>
    )
  }

  const renderForm = () => {
    switch (currentStep) {
      case steps.NEW_USER:
        return <UserSteps.Basic formik={formikUser} customers={customers} />
      default:
        return null
    }
  }

  return (
    <form className="d-flex flex-column bg-white roundeds" style={{minHeight: '868px'}}>
      {currentStep === steps.SUCCESS ? (
        <Box alignItems="center" display="flex" flex={1} flexDirection="column" height="500px" justifyContent="center">
          <Box marginBottom="20px">
            <Box marginBottom="20px">
              <SuccessIcon className="far fa-check-circle" />
            </Box>
            Usuário cadastrado
          </Box>
          <Box marginBottom="30px">
            <Button
              onClick={() => {
                formikUser.resetForm()
                setCurrentStep(steps.NEW_USER)
              }}>
              Cadastrar novo usuário
            </Button>
          </Box>

          <Box>
            <Button
              onClick={() => {
                formikUser.resetForm()
                setFilledSteps([])
                history.push('/manage-users')
              }}
              variant="outline-primary">
              Ver usuários
            </Button>
          </Box>
        </Box>
      ) : (
        <Row>
          <SidePanel>{sections.map(renderSideItem)}</SidePanel>
          <FormContainer>
            <form onSubmit={formikUser.handleSubmit}>{renderForm()}</form>
          </FormContainer>
        </Row>
      )}

      <Modal
        open={isOpen}
        style={{display: 'grid', overflow: 'auto'}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-descriptions">
        <Box
          width={550}
          bgcolor="#ffffff"
          m="auto"
          p="20px"
          borderRadius="15px"
          display="flex"
          flexDirection="column"
          top="10rem"
          style={{gap: '3rem'}}>
          <h3 className="font-weight-bolder">
            Já existe um usuário com o e-mail informado, porém está inativo. Deseja reativar este usuário?
          </h3>

          <div className="d-flex justify-content-between align-self-end" style={{gap: '2rem'}}>
            <Button
              variant="primary"
              className="btn btn-primary"
              style={{fontSize: '14px', fontWeight: 'bold'}}
              onClick={() => onCreate()}
              disabled={isCreating}>
              Sim, reativar
            </Button>

            <Button
              variant="secondary"
              className="btn btn-secondary"
              onClick={() => setIsOpen(false)}
              disabled={isCreating}
              style={{fontWeight: 'bold'}}>
              Não, cancelar
            </Button>
          </div>
        </Box>
      </Modal>
    </form>
  )
}

export default NewUser
