import React, {useCallback, useMemo} from 'react'
import {Box} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'
import {useSelector} from 'react-redux'
import IntlCurrencyInput from 'react-intl-currency-input'

import * as proposalsSelectors from 'app/modules/Proposals/redux/selectors'

import {Container, Title, SubTitle} from './styles'

const ProposalDetails = ({formik, onNextStep, onPreviousStep}) => {
  const {
    handleChange,
    setFieldValue,
    values: {areaAlias, details, estimatedValue, incentives, mapsUrl, restrictions, squareMeters},
  } = formik

  const availableIncentives = useSelector(proposalsSelectors.getAvailaleBenefits)

  const isValid = useMemo(() => Object.values(incentives).filter((inc) => !inc.description).length === 0, [incentives])

  const onChangeTerrenoDetails = useCallback(
    (key) => (e) => {
      var terreno = {...(incentives.terreno || {})}
      terreno[key] = e.target.value

      setFieldValue('incentives', {...incentives, terreno: terreno})
    },
    [incentives, setFieldValue],
  )

  const onChangeIncentiveDetails = useCallback(
    (key) => (e) => {
      setFieldValue('incentives', {...incentives, [key]: {description: e.target.value}})
    },
    [incentives, setFieldValue],
  )

  const findIncentiveByKey = useCallback((key) => availableIncentives.find(({type}) => type === key), [
    availableIncentives,
  ])

  const getIncentiveDescription = useCallback(
    (key) => {
      return findIncentiveByKey(key).description.toUpperCase() || ''
    },
    [findIncentiveByKey],
  )

  const getTerrenoValue = useCallback((key) => incentives['terreno'][key], [incentives])

  const getIncentiveValue = useCallback((key) => incentives[key]['description'], [incentives])

  const renderIncentiveDetails = useCallback(() => {
    const items = Object.keys(incentives).sort(function (a, b) {
      return b - a
    })

    return items.map((key) =>
      key === 'terreno' ? (
        <>
          <Box marginBottom="30px">
            <SubTitle>Sobre TERRENO DE PROPRIEDADE DO MUNICÍPIO</SubTitle>
          </Box>
          <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between" marginBottom="30px">
            <Box display="flex" flex={1} flexDirection="column" flexGrow={2} marginRight="20px">
              <label>
                <b>Identificação da Área</b>
              </label>
              <Form.Control
                autoComplete="off"
                placeholder="Ex.: lote final Rua Anhanduí - Centro"
                name="identification"
                onChange={onChangeTerrenoDetails('identification')}
                value={getTerrenoValue('identification')}
                style={{width: '100%'}}
              />
            </Box>
            <Box display="flex" flex={1} flexDirection="column" flexGrow={1}>
              <label>
                <b>Área total aproximada (m2)</b>
              </label>
              <Form.Control
                autoComplete="off"
                placeholder="Ex: 1.200"
                name="area"
                onChange={onChangeTerrenoDetails('area')}
                value={getTerrenoValue('area')}
                type="number"
                style={{width: '100%'}}
              />
            </Box>
          </Box>
          <Box display="flex" flex={1} flexDirection="row" marginBottom="30px">
            <Box display="flex" flex={1} flexDirection="column">
              <label>
                <b>Valor estimado do terreno (R$)</b>
              </label>
              <IntlCurrencyInput
                autoComplete="off"
                className="form-control"
                style={{width: '100%'}}
                currency="BRL"
                config={{
                  locale: 'pt-BR',
                  formats: {
                    number: {
                      BRL: {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      },
                    },
                  },
                }}
                onChange={(ev, value) =>
                  onChangeTerrenoDetails('estimated_value')({target: {value, name: 'required_investment'}})
                }
                value={getTerrenoValue('estimated_value')}
              />
            </Box>
          </Box>
          <Box display="flex" flex={1} flexDirection="row" marginBottom="30px">
            <Box display="flex" flex={1} flexDirection="column">
              <label>
                <b>Detalhamento</b>
              </label>
              <Form.Control
                autoComplete="off"
                as="textarea"
                name="description"
                onChange={onChangeTerrenoDetails('description')}
                value={getTerrenoValue('description')}
                style={{width: '100%'}}
                rows={3}
              />
            </Box>
          </Box>
          <Box display="flex" flex={1} flexDirection="row" marginBottom="30px">
            <Box display="flex" flex={1} flexDirection="column">
              <label>
                <b>Indique restrições, se houver</b>
              </label>

              <Form.Control
                autoComplete="off"
                as="textarea"
                name="restrictions"
                onChange={onChangeTerrenoDetails('restrictions')}
                value={getTerrenoValue('restrictions')}
                style={{width: '100%'}}
                rows={3}
              />
            </Box>
          </Box>
          <Box display="flex" flex={1} flexDirection="row" marginBottom="30px">
            <Box display="flex" flex={1} flexDirection="column">
              <label>
                <b>Link da área no Google Maps</b>
              </label>

              <Form.Control
                autoComplete="off"
                name="url_map"
                onChange={onChangeTerrenoDetails('url_map')}
                value={getTerrenoValue('url_map')}
                style={{width: '100%'}}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Box key={key} display="flex" flex={1} flexDirection="column" marginTop="20px" marginBottom="20px">
          <Box marginBottom="30px">
            <SubTitle>Sobre {getIncentiveDescription(key)}</SubTitle>
          </Box>

          <Box display="flex" flex={1} flexDirection="column">
            <label>
              <b>Detalhamento</b>
            </label>

            <Form.Control
              autoComplete="off"
              as="textarea"
              name="restrictions"
              onChange={onChangeIncentiveDetails(key)}
              value={getIncentiveValue(key)}
              style={{width: '100%'}}
              rows={3}
            />
          </Box>
        </Box>
      ),
    )
  }, [getIncentiveDescription, getIncentiveValue, incentives, onChangeIncentiveDetails])

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
        <Box marginBottom="40px">
          <Title>Detalhamento dos incentivos</Title>
        </Box>

        {renderIncentiveDetails()}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>
        <Button onClick={onNextStep} disabled={!isValid}>
          Próximo passo ⇀
        </Button>
      </Box>
    </Container>
  )
}

export default ProposalDetails
