/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../_metronic/_helpers'

export function ProfileCard() {
  const user = useSelector(({auth}) => auth.user, shallowEqual)

  useEffect(() => {
    return () => {}
  }, [user])

  return (
    <>
      {user && (
        <div className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px" id="kt_profile_aside">
          <div className="card card-custom card-stretch">
            <div className="card-body pt-4">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: user.photo
                        ? `url(${user?.photo})`
                        : `url(${toAbsoluteUrl('/avatar/male_blue.png')})`,
                    }}></div>
                </div>
                <div>
                  <a href="#" className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                    {user?.name ?? ''}
                  </a>
                  <div className="text-muted">{user?.occupation ?? ''}</div>
                </div>
              </div>
              <div className="py-9">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">Tipo:</span>
                  <span className="text-muted text-hover-primary">{user?.customer?.type ?? ''}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">Customer:</span>
                  <span className="text-muted text-hover-primary">{user?.customer?.name ?? ''}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span className="font-weight-bold mr-2">Email:</span>
                  <span className="text-muted text-hover-primary">{user?.email ?? ''}</span>
                </div>
              </div>

              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <div className="navi-item mb-2">
                  <NavLink to="/user-profile/personal-information" className="navi-link py-4" activeClassName="active">
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}></SVG>{' '}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Informações pessoais</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink to="/user-profile/account-information" className="navi-link py-4" activeClassName="active">
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')}></SVG>{' '}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Informações da conta</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink to="/user-profile/change-password" className="navi-link py-4" activeClassName="active">
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Shield-user.svg')}></SVG>{' '}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Alterar senha</span>
                  </NavLink>
                </div>
                <div className="navi-item mb-2">
                  <NavLink to="/user-profile/notification-configuration" className="navi-link py-4" activeClassName="active">
                    <span className="navi-icon mr-2">
                      <span className="svg-icon">
                        <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Compiling.svg')}></SVG>{' '}
                      </span>
                    </span>
                    <span className="navi-text font-size-lg">Configuração de Notificações</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
