import httpClient from '../../client'

import apiRoutes from '../constants'

const changePassword = async (body) => {
  try {
    const {data} = await httpClient.post(apiRoutes.CHANGE_PASSWORD, body)

    if (data.token) {
      return data
    }

    throw data
  } catch (error) {
    throw error
  }
}

export default changePassword
