import {availableColums} from 'app/modules/ManageCustomer/redux'

export const isColumnActive = ({column, stateColumns}) => stateColumns.some(({key}) => key === column.key)

export const shouldRenderColumn = ({columnKey, stateColumns}) => {
  const column = availableColums.find(({key}) => columnKey === key)

  if (!column) {
    console.warn(`Column ${columnKey} not found`)
    return true
  }
  return isColumnActive({column, stateColumns})
}
