class StyleControl {
  constructor({callback}) {
    this._clicked = false
    this._callback = callback
  }

  onAdd(map) {
    this._map = map
    let _this = this

    this._btn = document.createElement('button')
    var i = document.createElement('i')
    i.className = 'fa fa-map'

    var divButtons = document.createElement('div')
    divButtons.className = 'div-buttons div-hide'

    this._btn.append(i)

    divButtons.append(this._btn)

    this._btn.className = 'mapboxgl-ctrl-styles'
    this._btn.type = 'button'

    divButtons.onclick = function () {
      divButtons.className = _this._clicked === false ? 'div-buttons div-show' : 'div-buttons div-hide'
      _this._clicked = !_this._clicked
    }

    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl'

    var styles = document.createElement('div')
    styles.className = 'style-type-list'

    var buttonTerra = document.createElement('button')
    buttonTerra.className = 'button-terra'
    buttonTerra.title = 'Estático'
    buttonTerra.onclick = function () {
      _this._map.setStyle('mapbox://styles/kffr/ckrjrrnnz6xyo19oguraw3egj')
    }

    styles.append(buttonTerra)

    var buttonRota = document.createElement('button')
    buttonRota.title = 'Rota'
    buttonRota.className = 'button-rota'

    buttonRota.onclick = function () {
      _this._map.setStyle('mapbox://styles/kffr/ckrjtk48p2f8517pl5oz5glwa')
    }

    styles.append(buttonRota)

    var buttonMap = document.createElement('button')
    buttonMap.className = 'button-mapa'
    buttonMap.title = 'Satélite'
    buttonMap.onclick = function () {
      _this._map.setStyle('mapbox://styles/kffr/ckrjtlot8107o17me4w6roehb')
    }

    styles.append(buttonMap)
    /*		
        var buttonVector = document.createElement("button");
        buttonVector.className = 'button-vector';
        buttonVector.title = "Vetor";
        buttonVector.onclick = function () {
			_this._callback({is_vector: true});
            _this._map.setStyle('mapbox://styles/kffr/ckrjrrnnz6xyo19oguraw3egj');
        }

        styles.append(buttonVector);
		*/

    divButtons.append(styles)

    this._container.appendChild(divButtons)

    return this._container
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default StyleControl
