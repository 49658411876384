import {useState, useCallback} from 'react'

import {useSnackbar} from 'notistack'

import {proposalService} from 'app/services'

const useUpdateOpportunity = ({id, status: opportunityStatus, callback}) => {
  const {enqueueSnackbar} = useSnackbar()

  const [status, setStatus] = useState(opportunityStatus)
  const [loading, setLoading] = useState(false)

  const completeProposal = useCallback(
    async (proposalId) => {
      try {
        setLoading(true)

        await proposalService.completeProposal({id: proposalId})

        setStatus('completed')

        enqueueSnackbar(`Oportunidade concluída!`, {
          variant: 'success',
        })

        if (callback) callback()
      } catch (error) {
        var message = error?.response?.data?.message
          ? error.response.data.message
          : 'Ocorreu um erro ao tentar perder a ganhar / concreticar a oportunidade #' + proposalId
        enqueueSnackbar(message, {variant: 'error'})
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar],
  )

  const lostProposal = useCallback(
    async (proposalId) => {
      try {
        setLoading(true)

        await proposalService.lostProposal({id: proposalId})

        setStatus('lost')

        enqueueSnackbar(`Oportunidade perdida!`, {
          variant: 'success',
        })

        if (callback) callback()
      } catch (error) {
        var message = error?.response?.data?.message
          ? error.response.data.message
          : 'Ocorreu um erro ao tentar perder a oportunidade #' + proposalId
        enqueueSnackbar(message, {variant: 'error'})
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar],
  )

  const reOpenProposal = useCallback(
    async (proposalId) => {
      try {
        setLoading(true)

        await proposalService.reOpenProposal({id: proposalId})

        setStatus('open')

        enqueueSnackbar(`Oportunidade re-ativada!`, {
          variant: 'success',
        })

        if (callback) callback()
      } catch (error) {
        var message = error?.response?.data?.message
          ? error.response.data.message
          : 'Ocorreu um erro ao tentar re-ativar a oportunidade #' + proposalId
        enqueueSnackbar(message, {variant: 'error'})
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar],
  )

  const deleteProposal = useCallback(
    async (proposalId) => {
      try {
        setLoading(true)

        if (!window.confirm('Tem certeza? Essa ação não poderá ser desfeita')) {
          return false
        }

        await proposalService.deleteProposal({id: proposalId})

        enqueueSnackbar(`Oportunidade removida!`, {
          variant: 'success',
        })

        if (callback) callback()
      } catch (error) {
        var message = error?.response?.data?.message
          ? error.response.data.message
          : 'Ocorreu um erro ao tentar excluir a oportunidade #' + proposalId
        enqueueSnackbar(message, {variant: 'error'})
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar],
  )

  const action = useCallback(
    (newStatus) => {
      if (newStatus === 'delete') {
        return deleteProposal(id)
      }

      if (newStatus && newStatus === 'lost') {
        lostProposal(id)
      } else if (status === 'open') {
        completeProposal(id)
      } else {
        reOpenProposal(id)
      }
    },
    [completeProposal, id, reOpenProposal, status],
  )

  return {
    action,
    loading,
    status,
  }
}

export default useUpdateOpportunity
