import React, {useCallback} from 'react'
import {Carousel} from 'react-bootstrap'
import {
  Paper,
  ContainerHeader,
  DetailContainer,
  FavoriteIcon,
  Container,
  BottomItem,
  BottomItemValue,
  IcentiveContainer,
} from '../styles'

import Shimmer from 'react-loading-skeleton'

const MOCK_INCENTIVES = ['IPTU', 'ITBI', 'ISS', 'OUTROS']

const BasicCitieInfo = () => {
  const renderIncentive = useCallback(
    (label, idx) => (
      <IcentiveContainer key={`k=${idx}`}>
        <Shimmer key={`k=${idx}`} width="30px" />
      </IcentiveContainer>
    ),
    [],
  )

  return (
    <Paper>
      <ContainerHeader>
        <div />
        <FavoriteIcon aria-hidden="true" className="fa fa-star" />
      </ContainerHeader>
      <DetailContainer>
        <Container>
          <Carousel style={{width: '48%'}}>
            <Shimmer height="350px" />
          </Carousel>
          <Container width="48%" display="block">
            <Container display="block">
              <Container>
                <strong>
                  <Shimmer />
                </strong>
              </Container>
              <span>
                <Shimmer />
                <Shimmer />
                <Shimmer />
                <Shimmer />
                <Shimmer />
                <Shimmer />
              </span>
              <hr style={{width: '100%'}} />
            </Container>
            <Container>
              <Shimmer height="80px" width="80px" style={{borderRadius: '80px'}} />
              <Container display="block" style={{marginLeft: '1rem'}}>
                <Container>
                  <strong>
                    <Shimmer />
                  </strong>
                </Container>
                <Container>
                  <Shimmer />
                </Container>
                <Container display="block">
                  <Container>
                    <strong>
                      <Shimmer />
                    </strong>
                  </Container>
                  <span>
                    <Shimmer />
                  </span>
                </Container>
                <Container display="block">
                  <Container>
                    <strong>
                      <Shimmer />
                    </strong>
                  </Container>
                  <span>
                    <Shimmer />
                  </span>
                </Container>
              </Container>
            </Container>
            <hr style={{width: '100%'}} />
          </Container>
        </Container>
        <Container>
          <BottomItem>
            <Shimmer />
            <Container>{MOCK_INCENTIVES.map(renderIncentive)}</Container>
          </BottomItem>
          <BottomItem>
            <Shimmer />
            <BottomItemValue>
              <Shimmer />
            </BottomItemValue>
          </BottomItem>
          <BottomItem>
            <Shimmer />
            <BottomItemValue>
              <Shimmer />
            </BottomItemValue>
          </BottomItem>
          <BottomItem>
            <Shimmer />
            <BottomItemValue>
              <Shimmer />
            </BottomItemValue>
          </BottomItem>
          <BottomItem>
            <Shimmer />
            <BottomItemValue>
              <Shimmer />
            </BottomItemValue>
          </BottomItem>
          <BottomItem>
            <Shimmer />
            <BottomItemValue>
              <Shimmer />
            </BottomItemValue>
          </BottomItem>
        </Container>
      </DetailContainer>
    </Paper>
  )
}

export default BasicCitieInfo
