import React, {useEffect, useState} from 'react'

import {
  Box,
  Collapse,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {Button} from 'react-bootstrap'

import moment from 'moment'

import clsx from 'clsx'
import ConexoesSocietariasIcone from 'app/modules/Companies/components/Icon/ConexoesSocietariasIcone'

const useStyles = makeStyles((theme) => ({
  companyName: {
    color: '#269AF4',
    fontSize: 24,
    fontFamily: 'Montserrat, SemiBold',
    letterSpacing: 0.24,
  },
  header: {
    cursor: 'pointer',
    height: 110,
    overflow: 'hidden',
  },
  padding: {
    paddingBottom: 18,
    paddingTop: 29,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #f7f7f7',
  },
  card: {
    borderRadius: 0,
    backgroundColor: '#F7F7F7',
    maxWidth: 706,
  },
  badge: {
    'backgroundColor': '#00DA7E',
    'borderRadius': 29,
    'color': '#FFFFFF',
    'fontSize': 14,
    'fontFamily': 'Montserrat, SemiBold',
    'letterSpacing': 0.14,
    'textTransform': 'uppercase',
    'width': 165,
    'height': 29,
    '&.inactive': {
      backgroundColor: '#b3b3b3',
      color: '#000',
    },
  },
  description: {
    color: '#202020',
    fontSize: 16,
    fontFamily: 'Montserrat, Medium',
    letterSpacing: 0.16,
  },
  descriptionMargin: {
    marginBottom: 10,
  },
  subTitle: {
    color: '#8492AF',
    fontSize: 16,
    fontFamily: 'Montserrat, SemiBold',
    letterSpacing: 0.16,
    marginBottom: 3,
  },
  table: {
    minWidth: 600,
  },
}))

const StatusBadge = ({status, statusDescription}) => {
  const classes = useStyles()

  const active = status === 'ATIVA'

  return (
    <Box
      alignItems="center"
      className={clsx(classes.badge, !active && 'inactive')}
      display="flex"
      justifyContent="center">
      Empresa {statusDescription}
    </Box>
  )
}

const CardRow = ({children}) => {
  const classes = useStyles()

  return <Box className={clsx(classes.row, classes.padding)}>{children}</Box>
}

const CompanyCard = ({company, fetchConnections, closeModal}) => {
  const [showDetails, setShowDetails] = useState(true)
  const classes = useStyles()

  const convertDate = (date) => date * 1000

  return (
    <Paper className={classes.card}>
      <Box
        alignItems="center"
        className={clsx(classes.header, classes.padding)}
        display="flex"
        flexDirection="row"
        justifyContent="space-between">
        <Box display="flex" flexGrow={1}>
          <h1 className={classes.companyName}>{company.name}</h1>
        </Box>
        <Box display="flex" flexGrow={2} justifyContent="flex-end">
          <StatusBadge statusDescription={company.situacao_descricao} status={company.status_reason} />
        </Box>
      </Box>
      <Collapse in={showDetails}>
        <CardRow>
          <>
            <h3 className={classes.subTitle}>ÚLTIMA ATUALIZAÇÃO</h3>
            <p className={classes.description}>{`${moment(company.status_at).format('DD/MM/YYYY [às] hh:mm:ss')}`}</p>
          </>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>NÚMERO DE INSCRIÇÃO</h3>
              <p className={classes.description}>{company.id}</p>
            </Box>
            <Box>
              <h3 className={classes.subTitle}>DATA DE FUNDAÇÃO</h3>
              <p className={classes.description}>{`${moment(company.open_at).format('DD/MM/YYYY [às] hh:mm:ss')}`}</p>
            </Box>
          </Box>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>NOME EMPRESARIAL</h3>
              <p className={classes.description}>{company.name}</p>
            </Box>
            <Box>
              <h3 className={classes.subTitle}>NOME FANTASIA</h3>
              <p className={classes.description}>{company.business_name || '-'}</p>
            </Box>
          </Box>
        </CardRow>
        <CardRow>
          <Box>
            <h3 className={classes.subTitle}>ATIVIDADE ECONÔMICA PRIMÁRIA</h3>
            <p className={classes.description}>
              {company.main_activity.code} - {company.main_activity.text}
            </p>
          </Box>
        </CardRow>
        <CardRow>
          <Box>
            <h3 className={classes.subTitle}>ATIVIDADE ECONÔMICA SECUNDÁRIA</h3>
            {company.other_activities
              ? company.other_activities.map((activity) => (
                  <p className={clsx(classes.description, classes.descriptionMargin)}>
                    {activity.code} - {activity.text}
                  </p>
                ))
              : '-'}
          </Box>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>LOGRADOURO</h3>
              <p className={classes.description}>{`${company.street_type} ${company.street_name}` || '-'}</p>
            </Box>
            <Box>
              <h3 className={classes.subTitle}>NÚMERO</h3>
              <p className={classes.description}>{company.street_number || ''}</p>
            </Box>
            <Box>
              <h3 className={classes.subTitle}>COMPLEMENTO</h3>
              <p className={classes.description}>{company.subunit || ''}</p>
            </Box>
          </Box>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>CEP</h3>
              <p className={classes.description}>{company.postal_code || '-'}</p>
            </Box>
            <Box>
              <h3 className={classes.subTitle}>BAIRRO</h3>
              <p className={classes.description}>{company.district || ''}</p>
            </Box>
          </Box>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>CIDADE</h3>
              <p className={classes.description}>{`${company.city_code} - ${company.city}` || '-'}</p>
            </Box>
            <Box>
              <h3 className={classes.subTitle}>UF</h3>
              <p className={classes.description}>{company.state || ''}</p>
            </Box>
          </Box>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>ENDEREÇO ELETRÔNICO</h3>
              <p className={classes.description}>{company.email || '-'}</p>
            </Box>
          </Box>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>TELEFONES</h3>
              {company.phone
                ? Object.values(company.phone).map(({ddd, phone}) => (
                    <p className={clsx(classes.description, classes.descriptionMargin)}>
                      {ddd} - {phone}
                    </p>
                  ))
                : '-'}
            </Box>
          </Box>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>CAPITAL SOCIAL</h3>
              <p className={classes.description}>
                {new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(company.share_capital) ||
                  '-'}
              </p>
            </Box>
          </Box>
        </CardRow>
        <CardRow>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <h3 className={classes.subTitle}>QUADRO DE SÓCIOS E ADMINISTRADORES</h3>
              {company.socios ? (
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">NOME</TableCell>
                        <TableCell align="left">QUALIFICAÇÃO</TableCell>
                        <TableCell align="left">CONEXÕES</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {company.socios.map((partner) => (
                        <TableRow key={partner.id}>
                          <TableCell align="left">{partner.nome_socio}</TableCell>
                          <TableCell align="left">{`${partner.codigo_qualificacao}`}</TableCell>
                          <TableCell align="left">
                            <ConexoesSocietariasIcone
                              style={{cursor: 'pointer'}}
                              title={'Abrir Conexões Societárias de ' + partner.nome_socio}
                              color="#1e7be1"
                              onClick={() => {
                                fetchConnections({
                                  name: partner.nome_socio,
                                  document: partner.documento_socio,
                                  showCompanies: 1,
                                })
                                closeModal()
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                '-'
              )}
            </Box>
          </Box>
        </CardRow>
      </Collapse>
    </Paper>
  )
}

export default CompanyCard
