import getCities from './getCities'
import getCitie from './getCitie'

import httpClient from '../client'

const getBrGeojson = async () => {
  try {
    const {data} = await httpClient.get('br.geojson')
    return data
  } catch (error) {
    return error
  }
}

const getCityByCode = async (code) => {
  try {
    const {data} = await httpClient.get('/cities?code=' + code)
    return data.data[0]
  } catch (error) {
    return error
  }
}

const getStateGeojson = async (id) => {
  try {
    const {data} = await httpClient.get('/states/' + id + '.geojson')
    return data
  } catch (error) {
    return error
  }
}

export default {getCities, getCitie, getBrGeojson, getStateGeojson, getCityByCode}
