import getUserById from './getUserById'
import getUserByToken from './getUserByToken'
import getListUsers from './getListUsers'
import getLogs from './getLogs'
import exportLogs from './exportLogs'
import updateUser from './updateUser'
import createUser from './createUser'
import { emailUser } from './emailUser'
import { inactivateMany } from './inactivateMany'


export default {
  getUserById,
  getUserByToken,
  getListUsers,
  getLogs,
  exportLogs,
  updateUser,
  createUser,
  emailUser,
  inactivateMany,
}
