import {put, takeLatest} from 'redux-saga/effects'

import {userService} from 'app/services'

import actionTypes from './actionTypes'

export const actions = {
  login: (authToken) => ({type: actionTypes.Login, payload: {authToken}}),
  logout: (payload = {}) => ({type: actionTypes.Logout, payload: {hasExpiredToken: payload?.hasExpiredToken || false}}),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: {user},
  }),
  fulfillUser: (user) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user) => ({type: actionTypes.SetUser, payload: {user}}),
  twofactor: (twoFactor) => ({type: actionTypes.TwoFactor, payload: {twoFactor}}),
  terms: (terms) => ({type: actionTypes.Terms, payload: {terms}}),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Logout, function* logoutSaga() {
    yield localStorage.clear()
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const user = yield userService.getUserByToken()

      yield put(actions.fulfillUser(user))
    } catch (error) {
      console.log('Error fetching userInfo', error)

      yield put(actions.fulfillUser(undefined))
    }
  })
}
