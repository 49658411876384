import httpClient from '../../client'

const getPoliticalPartiesFromCity = async (cityId) => {
  try {
    const {data} = await httpClient.get(`/cities/${cityId}/political-parties`)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    return error
  }
}

export default getPoliticalPartiesFromCity
