import React from 'react'
import Shimmer from 'react-loading-skeleton'

import {Paper, ContainerHeader, DetailContainer} from '../styles'

const LoadingLinkedProposals = () => {
  return (
    <Paper>
      <ContainerHeader>
        <Shimmer />
      </ContainerHeader>
      <DetailContainer>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <span className="text-muted"></span>
              </th>
              <th scope="col">
                <span className="text-muted d-flex justify-content-center text-center">
                  <Shimmer />
                </span>
              </th>
              <th scope="col">
                <span className="text-muted d-flex justify-content-center text-center">
                  <Shimmer />
                  <br />
                  <Shimmer />
                </span>
              </th>
              <th scope="col">
                <span className="text-muted d-flex justify-content-center text-center">
                  <Shimmer />
                  <br />
                  <Shimmer />
                </span>
              </th>
              <th scope="col">
                <span className="text-muted d-flex justify-content-center text-center">
                  <Shimmer />
                  <br />
                  <Shimmer />
                </span>
              </th>
              <th scope="col">
                <span className="text-muted d-flex justify-content-center text-center">
                  <Shimmer />
                  <br />
                  <Shimmer />
                </span>
              </th>
              <th scope="col">
                <span className="text-muted"></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle">
                <Shimmer width="50px" height="50px" style={{borderRadius: '50px'}} />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <Shimmer width="50px" height="50px" style={{borderRadius: '50px'}} />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
              <td className="align-middle text-center">
                <Shimmer />
              </td>
            </tr>
          </tbody>
        </table>
      </DetailContainer>
    </Paper>
  )
}

export default LoadingLinkedProposals
