import axios from 'axios'

import store from '../../../redux/store'
import {actions as authActions} from 'app/modules/Auth/_redux/authRedux'

const authToken = () => {
  try {
    const {auth} = store.getState()

    return auth.authToken
  } catch (error) {
    return null
  }
}

const addAuthHeaders = (headers = {}) => {
  const sessionToken = authToken()

  if (sessionToken) {
    headers.Authorization = `Bearer ${sessionToken}`
  }

  return headers
}

const handleUnauthorizedRequest = (error) => {
  if (error.response.status === 401) {
    store.dispatch(authActions.logout({hasExpiredToken: true}))
  }
  return Promise.reject(error)
}

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_CITY_MATCH_API_URL,
  //baseURL: 'http://localhost:8081',
  headers: addAuthHeaders(),
})

// apiInstance.defaults.validateStatus = function () {
//   return true;
// };

apiInstance.interceptors.request.use((config) => ({
  ...config,
  headers: addAuthHeaders(config.headers),
}))

apiInstance.interceptors.response.use((response) => response, handleUnauthorizedRequest)

export default apiInstance
