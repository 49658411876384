import httpClient from '../../client'

import apiRoutes from '../constants'

const userChangePassword = async (body) => {
  try {
    const {data} = await httpClient.post(apiRoutes.USER_CHANGE_PASSWORD, body)
    return data
  } catch (error) {
    throw error
  }
}

export default userChangePassword
