import {createSelector} from '@reduxjs/toolkit'

export const getCitiesSelector = (state) => state.cities

export const getCurrentCitiesPage = createSelector(getCitiesSelector, ({page}) => page)

export const getCitiesColumns = createSelector(getCitiesSelector, ({columns}) => columns)

export const hasLoadedOrdering = createSelector(getCitiesSelector, ({filters}) => !!filters.ordering?.orders?.length)

export const citiesOrders = createSelector(getCitiesSelector, ({filters}) => filters.ordering.orders)

export const getActiveOrder = createSelector(getCitiesSelector, ({filters}) => filters.ordering.activeSort)

export const showMap = createSelector(getCitiesSelector, ({universalSearch}) => universalSearch.showMap)

export const universalSearch = createSelector(getCitiesSelector, ({universalSearch}) => universalSearch)

export const getCurrentLimit = createSelector(getCitiesSelector, ({limit}) => limit)

export const getExportToCsv = createSelector(getCitiesSelector, ({exportToCsv}) => exportToCsv)

export const getSearchParams = createSelector(getCitiesSelector, ({universalSearch}) => ({
  hasAppliedFilters: universalSearch.hasAppliedFilters,
  searchParams: universalSearch.searchParams,
  selectedFilters: universalSearch.selectedFilters,
  actualEntity: universalSearch.actualEntity,
  showOnlyFavorites: universalSearch.showOnlyFavorites,
}))
