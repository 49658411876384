import httpClient from '../../client'

import apiRoutes from '../constants'

const getConnectionInfo = async ({document, name}) => {
  try {
    const {data} = await httpClient.get(apiRoutes.GET_INFORMATION, {
      params: {
        document: document,
        name: name,
      },
    })

    return {data}
  } catch (error) {
    throw error.data.message
  }
}

export default getConnectionInfo
