import {Box, Tab, Tabs} from '@material-ui/core'
import LocationIncentives from 'app/components/location/LocationIncentives'
import LocationVocation from 'app/components/location/LocationVocation'
import ShowcaseGenerator from 'app/components/ShowcaseGenerator'
import LinkedProposals from 'app/modules/Cities/Pages/EditCitie/LinkedProposals'
import Attachments from 'app/modules/Proposals/pages/EditProposal/ProposalAttachments'
import StateAttachments from 'app/modules/States/Pages/DetailState/Attachments'
import StateCompanies from 'app/modules/States/Pages/DetailState/Companies'
import StateContact from 'app/modules/States/Pages/DetailState/Contact'
import DemographicData from 'app/modules/States/Pages/DetailState/DemographicData'
import Location from 'app/modules/States/Pages/DetailState/Location'
import StatePoliticians from 'app/modules/States/Pages/DetailState/Politicians'
import StateEditBasicInfo from 'app/modules/States/Pages/EditState/BasicInfo'
import {userService} from 'app/services'
import getCompanies from 'app/services/companies/getCompanies'
import {createLocation, deleteImage, getLocation, updateLocation} from 'app/services/location'
import getRanking from 'app/services/states/getRanking'
import getState from 'app/services/states/getState'
import getStates from 'app/services/states/getStates'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'

import getShowcase from '../../../../services/states/getShowcase'
import CityRevision from '../../../Cities/Pages/EditCitie/CityRevision'
import {changeActualEntity} from '../../redux'
import DetailStateSkeleton from './skeleton'
import {ContainerHeaderTabs, Paper} from './styles'

function DetailState() {
  const [loading, setLoading] = useState(false)
  const [stateResp, setStateResp] = useState()
  const state = stateResp?.state

  const [userList, setUserList] = useState([])
  const [location, setLocation] = useState({
    benefits: [],
    multimedia: [],
    vocations: [],
    images: [],
    contacts: [],
  })
  const [companies, setCompanies] = useState(null)
  const [ranking, setRanking] = useState(null)
  const [tab, setTab] = useState(0)
  const {enqueueSnackbar} = useSnackbar()
  const {stateId} = useParams()
  const [tabNext, setTabNext] = useState(0)
  const [submiting, setSubmiting] = useState(false)
  const [industrialAreaChanged, setInsdustrialAreaChanged] = useState(false)
  const [showcaseUrl, setShowcaseUrl] = useState()

  const history = useHistory()
  const dispatch = useDispatch()

  const fetchState = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getState(stateId)
      setStateResp(data)
      dispatch(changeActualEntity(data.state))

      fetchLocation(data.state.code, data)
      fetchCompanies(data.state.uf)
    } catch (e) {
      setLoading(false)
    }
  }, [stateId])

  const fetchLocation = async (stateCode, stateData) => {
    try {
      setLoading(true)
      const data = await getLocation(stateCode)
      if (!data.description && stateData) {
        data.description = stateData.wiki_description
      }

      setLocation({...data, vocations: data.vocations.map((i) => i.description)})
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const fetchCompanies = useCallback(async (uf) => {
    try {
      setLoading(true)
      const data = await getCompanies({state: uf})
      setCompanies(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }, [])

  const fetchShowcaseGenerator = useCallback(async () => {
    try {
      setLoading(true)
      setSubmiting(true)

      const data = await getShowcase(stateId)
      setShowcaseUrl(data.url)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
      setSubmiting(false)
    }
  }, [stateId])

  const fetchDeleteGalleryImage = async (image) => {
    await deleteImage(state.code, image.id)
    // await new Promise((r) => setTimeout(r, 1500))
    removeImageFromState(image)
  }

  let complexField

  const handleChangeForm = (ev) => {
    const fieldName = ev.target.name
    var value = ev.target.value

    switch (fieldName) {
      case 'location_cityhall_street':
      case 'location_cityhall_zip':
      case 'location_cityhall_number':
      case 'location_cityhall_neighborhood':
      case 'location_cityhall_city':
      case 'location_cityhall_city_id':
        complexField = fieldName.replace('location_cityhall_', '')
        setLocation((stt) => ({
          ...stt,
          city_hall: {
            ...stt.city_hall,
            [complexField]: value,
          },
        }))
        break
      case 'location_contact_name':
      case 'location_contact_email':
      case 'location_contact_linkedin':
      case 'location_contact_phone':
      case 'location_contact_mobile_phone':
      case 'location_contact_mobile_phone_2':
      case 'location_contact_responsibility':
        complexField = fieldName.replace('location_contact_', '')

        if (['location_contact_phone'].indexOf(fieldName) !== -1) {
          value = value.replace(/\D/g, '')
        }

        // TODO
        // eslint-disable-next-line no-case-declarations
        const contacts = location.contacts.length ? location.contacts : [{[complexField]: value}]

        setLocation((stt) => ({
          ...stt,
          contacts: contacts.map((contact) => {
            contact[complexField] = value
            return contact
          }),
        }))
        break
      default:
        setLocation((st) => ({
          ...st,
          [fieldName]: value,
        }))
    }
  }

  const handleChangeLogo = (path) => {
    setLocation((st) => ({
      ...st,
      logo: path,
    }))
  }

  const handleAddImages = (image) => {
    const mult = location.images
    mult.push(image)
    setLocation((st) => ({
      ...st,
      images: mult,
    }))
  }

  const handleRemoveImages = (image) => {
    const mult = [...location.images]
    const removeIdx = mult.findIndex((img) => img.id == image.id)

    mult.splice(removeIdx, 1)
    setLocation((st) => ({
      ...st,
      images: mult,
    }))
  }

  const handleClearLocationImages = () => {
    setLocation((st) => ({
      ...st,
      images: [],
    }))
  }

  const removeImageFromState = (image) => {
    setLocation((prevState) => {
      const prevImages = [...(prevState.images ?? [])]
      const removeIdx = prevImages.findIndex((img) => img.id == image.id)

      if (removeIdx !== -1 && prevImages[removeIdx]) {
        prevImages.splice(removeIdx, 1)
      }
      return {
        ...prevState,
        images: prevImages,
      }
    })
  }

  const handleChange = (event, newValue) => {
    setTab(newValue)
    setTabNext(newValue)
  }

  const formartRequestLocation = (data, isUpdate) => {
    const params = JSON.parse(JSON.stringify(data))

    if (params.city_hall) {
      params.city_hall.city_id = params.city_hall.city.id
    }

    if (!industrialAreaChanged) {
      delete params.industrial_area
    }

    if (isUpdate) {
      if (params.vocations[0]?.id) {
        delete params.vocations
      }
    }

    return params
  }

  const handleDrawIndustrialArea = (ev) => {
    if (ev.features[0]?.geometry) {
      setInsdustrialAreaChanged(true)
      setLocation({
        ...location,
        industrial_area: ev.features[0] || null,
      })
    }
  }

  const handleChangeBenefits = (incentive, data) => {
    const benefits = location.benefits || []
    const hasBenefit = benefits.some((ben) => ben.type === incentive.type)

    if (!hasBenefit) {
      const dt = data ? {...incentive, ...data} : incentive
      benefits.push(dt)
      setLocation({...location, benefits: benefits})
    } else {
      if (data) {
        setLocation({
          ...location,
          benefits: benefits.map((i) => {
            if (i.type == incentive.type) {
              i = {...i, ...data}
            }
            return i
          }),
        })
      } else {
        setLocation({
          ...location,
          benefits: benefits.filter((i) => i.type !== incentive.type),
        })
      }
    }
  }

  const handleAddAttachment = (attachment) => {
    const mult = location.multimedia
    mult.push(attachment)
    setLocation({...location, multimedia: mult})
  }

  const removeAttachment = (attachmentId) => {
    const mult = location.multimedia.filter((file) => file.id !== attachmentId)
    setLocation({...location, multimedia: mult})
  }

  const handleChangeRegionalVocation = (incentive, data) => {
    const vocations = location.vocations ? location.vocations : []
    const hasVocation = vocations.some((ben) => ben === incentive)

    if (!hasVocation) {
      const dt = data ? {...incentive, ...data} : incentive
      vocations.push(dt)
      setLocation({...location, vocations})
    } else {
      if (data) {
        setLocation({
          ...location,
          cards: vocations.map((i) => {
            if (i == incentive) {
              i = {...i, ...data}
            }
            return i
          }),
        })
      } else {
        setLocation({
          ...location,
          vocations: vocations.filter((i) => i !== incentive),
        })
      }
    }
  }

  const searchZip = useCallback(
    async (zip) => {
      try {
        const postalCode = zip

        const cleanPostalCode = postalCode?.match(/\d+/g)?.join('')

        if (cleanPostalCode?.length === 8) {
          const response = await (await fetch(`https://viacep.com.br/ws/${cleanPostalCode}/json`)).json()

          if (response.erro) {
            enqueueSnackbar('CEP não encontrado', {
              variant: 'warning',
            })
          } else {
            handleChangeForm({target: {name: 'location_cityhall_street', value: response.logradouro}})
            handleChangeForm({target: {name: 'location_cityhall_neighborhood', value: response.bairro}})

            const city = await getStates({code: response.ibge})
            handleChangeForm({target: {name: 'location_cityhall_city', value: city.data[0]}})
            handleChangeForm({target: {name: 'location_cityhall_city_id', value: city.data[0].id}})
          }
        }
      } catch (error) {
        enqueueSnackbar('Ocorreu um erro ao buscar o endereco', {
          variant: 'warning',
        })
      }
    },
    [enqueueSnackbar],
  )

  const onSubmit = useCallback(
    async (next) => {
      try {
        if (next) {
          if (tabNext < 5) {
            setTabNext(tabNext + 1)
          }
        }
        setSubmiting(true)

        var data = null

        if (!location.id) {
          data = await createLocation(state.code, formartRequestLocation(location))
          fetchLocation(state.code)
        } else {
          data = await updateLocation(state.code, formartRequestLocation(location, true))
        }

        handleClearLocationImages()
        if (data) {
          // const imagesFromLocation = data.city?.location?.images ?? [] // nao funfou por que nao tem a url completa aqui
          // updateCitieImages(imagesFromLocation)
          if (!next) {
            enqueueSnackbar('Localidade atualizada com sucesso!', {
              variant: 'success',
            })
            history.push('/cities')
          }
        } else {
          throw data
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } finally {
        setSubmiting(false)
      }
    },
    [enqueueSnackbar, location, state, tabNext, setTabNext, fetchLocation],
  )

  useEffect(() => {
    userService.getListUsers().then((res) => setUserList(res.data))

    fetchState()
    if (stateId) {
      // getRanking(stateId).then(setRanking)
    }
  }, [fetchState, stateId])

  useEffect(() => {
    if (tabNext) {
      setTab(tabNext)
    }
  }, [tabNext])

  if (loading || !state) {
    return <DetailStateSkeleton />
  }

  return (
    <div className="d-flex flex-column container-citymatch-table-cards">
      <StateEditBasicInfo
        {...state}
        mainPolitician={stateResp?.governor}
        location={location}
        ranking={ranking}
        companies={companies}
      />
      {/* {stateResp?.coordinates && <Location {...location} newCoordinates={stateResp.coordinates} />} */}
      {/* {ranking ? <LinkedProposals {...stateResp} ranking={ranking} /> : null} */} {/* em breve */}
      <DemographicData demographicData={stateResp.demographic_data} />
      <StateCompanies name={state.name} uf={state.uf} />
      <StatePoliticians stateId={state.id} showHeaderCity />
      {location?.contacts && <StateContact contacts={location?.contacts} />}
      {location?.multimedia && <StateAttachments multimedia={location?.multimedia} />}
    </div>
  )
}

export default DetailState
