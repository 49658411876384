import React from 'react'

const Icon = ({color, width}) => (
  <svg
    id="icon_anexos"
    data-name="icon/anexos"
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : '42'}
    height="42"
    viewBox="0 0 42 42">
    <path id="bound" d="M0,0H42V42H0Z" fill="none" />
    <g id="shapes" transform="translate(7 3.5)">
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M3.25,0H17.039a2.625,2.625,0,0,1,1.7.621L27.07,7.672a2.625,2.625,0,0,1,.93,2v21.97C28,34.779,27.964,35,24.75,35H3.25C.036,35,0,34.779,0,31.646V3.354C0,.221.036,0,3.25,0Zm8.781,24.063a1.656,1.656,0,0,0,1.174-.5l6.708-6.708A1.66,1.66,0,0,0,17.566,14.5l-5.534,5.534-2.18-2.18A1.66,1.66,0,0,0,7.5,20.205l3.354,3.354A1.656,1.656,0,0,0,12.031,24.063Z"
        fill={color}
        opacity="0.3"
      />
      <path
        id="check_path"
        data-name="check path"
        d="M5.031,10.063a1.656,1.656,0,0,1-1.174-.5L.5,6.205a1.621,1.621,0,0,1,0-2.348,1.621,1.621,0,0,1,2.348,0l2.18,2.18L10.566.5a1.621,1.621,0,0,1,2.348,0,1.621,1.621,0,0,1,0,2.348L6.205,9.559A1.656,1.656,0,0,1,5.031,10.063Z"
        transform="translate(7 14)"
        fill={color}
      />
    </g>
  </svg>
)

export default Icon
