import React, {useCallback} from 'react'
import {Paper, ContainerHeader, DetailContainer} from '../styles'

import {Container} from './styles'

const StateContact = ({contacts}) => {
  const renderContact = useCallback(
    ({name, email, phone, linkedin, id}) => (
      <Container key={id}>
        <div style={{width: '100%'}}>
          <span className="secundary-title">Nome do contato</span>
          <br />
          <span className="text-description-footer-1">{name}</span>
        </div>

        <div style={{width: '100%'}}>
          <span className="secundary-title">Telefone</span>
          <br />
          <span className="text-description-footer-1">
            <a href={`tel:+55${phone}`}>{phone}</a>
          </span>
        </div>

        <div style={{width: '100%'}}>
          <span className="secundary-title">E-mail</span>
          <br />
          <span className="text-description-footer-1">
            <a href={`mailto:${email}`} style={{color: '#1E7BE1'}}>
              {email}
            </a>
          </span>
        </div>

        <div style={{width: '100%'}}>
          <span className="secundary-title">Site</span>
          <br />
          <span className="text-description-footer-1">
            <a href={linkedin} target="_blank" style={{color: '#1E7BE1'}}>
              {linkedin}
            </a>
          </span>
        </div>
      </Container>
    ),
    [],
  )

  if (!contacts) {
    return (
      <Paper>
        <ContainerHeader>Contato do estado</ContainerHeader>
        <DetailContainer style={{paddingBottom: '1rem'}}>
          <h3>Informação não preenchida pelo Estado</h3>
        </DetailContainer>
      </Paper>
    )
  }

  return (
    <Paper>
      <ContainerHeader>Contato do estado</ContainerHeader>
      <DetailContainer style={{paddingBottom: '1rem'}}>{contacts?.map(renderContact)}</DetailContainer>
    </Paper>
  )
}

export default StateContact
