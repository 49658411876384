import httpClient from '../client'
import getRanking from './getRanking'
import getShowcase from './getShowcase'
import getState from './getState'
import getStates from './getStates'

const getStateByCode = async (code) => {
  try {
    const {data} = await httpClient.get('/states')
    return data.data.filter((item) => item.code == code)[0]
  } catch (error) {
    return error
  }
}

export default {getState, getStates, getStateByCode, getShowcase, getRanking}
