import httpClient from '../../client'

import apiRoutes from '../constants'

const getConnections = async ({document, name, with_companies}) => {
  try {
    const {data} = await httpClient.get(apiRoutes.GET_CONNECTIONS, {
      params: {
        document: document,
        name: name,
        with_companies: with_companies ?? 0,
      },
    })

    return {data}
  } catch (error) {
    throw error.data.message
  }
}

export default getConnections
