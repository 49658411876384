class FavoriteControl {
  constructor({callback}) {
    this._favorite = false
    this._callback = callback
  }

  onAdd(map) {
    this._map = map
    let _this = this

    this._btn = document.createElement('button')
    var i = document.createElement('i')
    i.className = 'fa fa-star'

    this._btn.append(i)

    this._btn.className = 'mapboxgl-ctrl-favorite'
    this._btn.type = 'button'

    this._btn.onclick = function () {
      _this._favorite = !_this._favorite

      if (_this._favorite === true) {
        _this._map.setFilter('pointer', ['in', 'is_favorite', _this._favorite])
        // _this._callback({is_favorite: true});
      } else {
        _this._map.setFilter('pointer', ['has', 'is_favorite'])
        // _this._callback({is_favorite: null});
      }
    }

    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl'
    this._container.appendChild(this._btn)

    return this._container
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default FavoriteControl
