import React from 'react'
import {Route, Switch} from 'react-router-dom'

import ListProposals from './ListProposals'
import DetailProposal from './DetailProposal'
import NewProposal from './NewProposal'
import EditProposal from './EditProposal'

const ProposalsPage = () => {
  return (
    <Switch>
      <Route path="/proposals" exact component={ListProposals} />
      <Route path="/proposals/new" exact component={NewProposal} />
      <Route path="/proposals/:proposalId" exact component={DetailProposal} />
      <Route path="/proposals/:proposalId/edit" exact component={EditProposal} />
    </Switch>
  )
}

export default ProposalsPage
