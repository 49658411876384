/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, Switch, Redirect} from 'react-router-dom'
import {ContentRoute} from '../../../../_metronic/layout'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import TwoFactor from './TwoFactorPage'
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import ChangePassword from './ChangePassword'

import routes, {BASE_URL} from './routes'

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login">
          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path={routes.LOGIN} component={Login} />
                <ContentRoute path={routes.TWOFACTOR} component={TwoFactor} />
                <ContentRoute path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
                <ContentRoute path={routes.CHANGE_PASSWORD} component={ChangePassword} />
                <Redirect from={BASE_URL} exact={true} to={routes.LOGIN} />
                <Redirect to={routes.LOGIN} />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">&copy; 2021 CityMatch</div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacidade
                </Link>
                <Link to="/terms" className="text-dark-75 text-hover-primary ml-4">
                  Legal
                </Link>
                <Link to="/terms" className="text-dark-75 text-hover-primary ml-4">
                  Contato
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  )
}
