export const FILE_TYPES = {
  image: {
    name: 'image',
    extensions: ['jpg', 'jpeg', 'png'],
    mimes: ['image'],
  },
  document: {
    name: 'document',
    extensions: ['pdf'],
    mimes: ['application/pdf'],
  },
  video: {
    name: 'video',
    extensions: ['mp4'],
    mimes: ['video'],
  },
}
/**
 * @param {File} file
 * @returns {string}
 */
export const getFileType = (file) => {
  let type = file.type

  if (isFileImage(file)) {
    type = FILE_TYPES.image.name
  } else if (isFilePdf(file)) {
    type = FILE_TYPES.document.name
  } else if (isFileVideo(file)) {
    type = FILE_TYPES.video.name
  }

  return type
}

/**
 * @param {File|UploadedFileEntry} file
 * @return boolean
 */
export const isFileImage = (file) =>
  isOfType(file.type, FILE_TYPES.image.mimes) || hasExtension(getExtension(file.name), FILE_TYPES.image.extensions)

/**
 * @param {File|UploadedFileEntry} file
 * @return boolean
 */
export const isFilePdf = (file) =>
  isOfType(file.type, FILE_TYPES.document.mimes) ||
  hasExtension(getExtension(file.name), FILE_TYPES.document.extensions)

/**
 * @param {File|UploadedFileEntry} file
 * @return boolean
 */
export const isFileVideo = (file) =>
  isOfType(file.type, FILE_TYPES.video.mimes) || hasExtension(getExtension(file.name), FILE_TYPES.video.extensions)

/**
 * @param {string} fileType
 * @param {string[]} availableTypes
 * @returns boolean
 */
const isOfType = (fileType, availableTypes) => availableTypes.find((type) => type.search(fileType) !== -1) !== undefined

/**
 * @param {string} ext
 * @param {string[]} availableExtensions
 * @returns boolean
 */
const hasExtension = (ext, availableExtensions) => availableExtensions.includes(ext)

/**
 * @param {string} fileName
 * @returns string
 */
const getExtension = (fileName) => fileName.substring(fileName.lastIndexOf('.') + 1) ?? ''

/**
 * @param {File|UploadedFileEntry} file
 * @returns string
 */
export const getFileUrl = (file) => {
  if (file.url) {
    return file.url
  }

  if (file.path.search('http') !== -1) {
    return file.path
  }

  return ''
}

/**
 * @param {*[]} files
 * @param {*} file
 * @param {string} sourceIdProp
 * @param {string} targetIdProp
 * @returns {*[]}
 */
export const removeFileFromList = (files, file, sourceIdProp = 'id', targetIdProp = 'id') => {
  const prevFiles = [...(files ?? [])]
  const removeIdx = prevFiles.findIndex((file) => file[targetIdProp] == file[sourceIdProp])

  if (removeIdx !== -1 && prevFiles[removeIdx]) {
    prevFiles.splice(removeIdx, 1)
  }
  return prevFiles
}
