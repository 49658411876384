import React, {useCallback, useState, useRef, useEffect} from 'react'
import Status from 'app/components/proposal/Status'
import Type from 'app/components/proposal/Type'
import {Card, Button, Popover, Overlay, ListGroup} from 'react-bootstrap'

import {format} from 'date-fns'
import {useHistory} from 'react-router-dom'

import fileService from 'app/services/files'

import {useUpdateOpportunity, useFavorite} from 'app/hooks'

import {ProposalLogo} from '../../Logo'
import {Circular as CircularLoading} from '../../../loading'

import {
  InfoContainer,
  ProposalName,
  Actions,
  Container,
  IconDetail,
  RightContent,
  Bottom,
  LogoContainer,
  FavoriteIcon,
  StyledPopover,
} from './styles'
import {
  CheckCircle,
  Circle,
  XCircle,
  Download,
  Edit,
  Hash,
  Menu,
  MoreHorizontal,
  Share,
  Star,
  Trash,
} from 'react-feather'
import {IconButton} from '@material-ui/core'

function CardProposal({proposal}) {
  const {
    name,
    description,
    id,
    type,
    created_at,
    is_favorite,
    status,
    location,
    customer,
    project,
    multimedia,
    city,
  } = proposal

  const history = useHistory()

  const popoverRef = useRef(null)

  const {action: updateOpportunityStatus, loading: loadingUpdate, status: updatedStatus} = useUpdateOpportunity({
    id,
    status,
  })

  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'proposal',
    label: 'Proposta',
  })

  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  useEffect(() => {
    setShowPopover(false)
  }, [loadingUpdate])

  const onDetailClick = useCallback(() => {
    history.push(`/proposals/${id}`)
  }, [history, id])

  const handlePopoverClick = (event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount)

  const goToProposalDetails = useCallback(
    (proposalId) => {
      history.push(`/proposals/${proposalId}`)
    },
    [history],
  )

  const [logoUrl, setLogoUrl] = useState('')
  const [loading, setLoading] = useState(true)

  const getFile = useCallback(async (path) => {
    try {
      setLoading(true)
      const {data} = await fileService.getFile(path)
      setLogoUrl(data)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (multimedia && multimedia.length && multimedia[0].type && multimedia[0].type === 'image') {
      getFile(multimedia[0].path)
    }
  }, [getFile, multimedia])

  const cityName = city ? city?.name : city?.name
  const uf = city ? city?.state?.uf : city?.state?.uf

  return (
    <Card
      style={{
        maxWidth: '485px',
        marginRight: '10px',
        marginLeft: '10px',
        marginBottom: '20px',
        padding: '23px 31px',
        borderRadius: 10,
        flex: '1 0 25%',
      }}>
      <Card.Header
        className="d-flex justify-content-between"
        style={{borderBottom: 'none', paddingBottom: '0px', padding: 0}}>
        <InfoContainer>
          <LogoContainer>
            <ProposalLogo borderRadius="50%" name={name} url={logoUrl} showFirstLetter height="57px" width="57px" />
          </LogoContainer>
          <div className="d-flex flex-column w-75">
            <ProposalName>{name}</ProposalName>
            <div
              className="d-flex justify-content-start w-100 align-items-center"
              style={{fontSize: 14, fontFamily: 'Nunito Sans', letterSpacing: 0, color: '#8C8C8C', opacity: 1}}>
              <Hash style={{height: 14, color: '#BEDDFF'}} /> <span> {id}</span>
              {cityName ? (
                <IconDetail className="fa fa-map-marker ml-7" style={{color: '#BEDDFF', fontSize: 12}} />
              ) : null}
              {cityName ? (
                <>
                  <span> {cityName}</span>{' '}
                  <small style={{fontSize: 5, display: 'inline-block', marginRight: 3, marginLeft: 3}}>•</small>
                  <span>{uf}</span>
                </>
              ) : null}
            </div>
          </div>
        </InfoContainer>
        <Actions style={{textAlign: 'center'}}>
          <Button onClick={handlePopoverClick} ref={popoverRef} className="button-icon">
            <MoreHorizontal style={{color: '#C9C9C9'}} />
          </Button>
          <Overlay
            show={showPopover}
            target={popoverTarget}
            container={popoverRef.current}
            placement="bottom"
            rootClose={true}
            onHide={() => setShowPopover(false)}>
            <StyledPopover>
              <Popover.Content>
                <ListGroup variant="flush">
                  <ListGroup.Item onClick={() => goToProposalDetails(id)}>
                    <Menu className="icon-menu" /> Ver detalhes
                  </ListGroup.Item>

                  {proposal.has_permission_to_edit && (
                    <ListGroup.Item onClick={() => updateOpportunityStatus()}>
                      {updatedStatus === 'open' ? (
                        <CheckCircle className="icon-menu" />
                      ) : (
                        <Circle className="icon-menu" />
                      )}
                      {updatedStatus === 'open' ? 'Concluir oportunidade' : 'Re-abrir oportunidade'}
                    </ListGroup.Item>
                  )}

                  {proposal.has_permission_to_edit && (
                    <ListGroup.Item onClick={() => updateOpportunityStatus('lost')} style={{color: 'red'}}>
                      <XCircle className="icon-menu lost" style={{color: 'red'}} />
                      {'Marcar como perdida'}
                    </ListGroup.Item>
                  )}

                  {/* <ListGroup.Item>
                    <Share className="icon-menu" /> Compatilhar
                  </ListGroup.Item> */}

                  <ListGroup.Item onClick={() => handleFavoriteOpportunity()}>
                    <Star className="icon-menu" />
                    {isFavorite ? 'Remover dos favoritos' : 'Salvar em favoritos'}
                  </ListGroup.Item>

                  {proposal.has_permission_to_edit && (
                    <ListGroup.Item onClick={() => history.push(`/proposals/${id}/edit`)}>
                      <Edit className="icon-menu" />
                      Editar
                    </ListGroup.Item>
                  )}

                  {/* <ListGroup.Item>
                    <Download className="icon-menu" /> Download
                  </ListGroup.Item> */}

                  {proposal.has_permission_to_edit && (
                    <ListGroup.Item>
                      <Trash className="icon-menu" /> Excluir
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Popover.Content>
            </StyledPopover>
          </Overlay>
          {loadingFavorite ? (
            <CircularLoading />
          ) : (
            <FavoriteIcon
              aria-hidden="true"
              className="fa fa-star"
              isFavorite={isFavorite}
              onClick={() => handleFavoriteOpportunity()}
            />
          )}
        </Actions>
      </Card.Header>
      <Card.Body style={{marginTop: 30, padding: 0}}>
        <Card.Title style={{fontSize: 14, color: '#848484'}}>{description}</Card.Title>
        <Container>
          <div className="d-flex align-items-center justify-content-start w-50">
            <IconDetail className="fa fa-database fa-2x" style={{fontSize: 24, marginRight: 17}} aria-hidden="true" />
            <div>
              <p className="h6">Investimento</p>
              <div className="tt">
                {project?.required_investment ? formatCurrency(project.required_investment) : 'Não Informado'}
              </div>
            </div>
          </div>
          <RightContent>
            <p className="h6">Tipo</p>
            {type && <Type type={type} />}
          </RightContent>
        </Container>
        <Container>
          <div className="d-flex align-items-center justify-content-start w-50">
            <IconDetail className="fa fa-calendar fa-2x" aria-hidden="true" style={{fontSize: 24, marginRight: 17}} />
            <div>
              <p className="h6">Data de criação</p>
              <div className="tt">{format(new Date(created_at), 'dd/MM/yyyy')}</div>
            </div>
          </div>
          <RightContent>
            <p className="h6">Status</p>
            {loadingUpdate ? <CircularLoading /> : <Status status={updatedStatus} />}
          </RightContent>
        </Container>
      </Card.Body>
      <Card.Footer>
        <Bottom>
          <span>
            Cadastrada por <a href="javascript:void(0);">{customer.name}</a>
          </span>
          <Button variant="primary" style={{backgroundColor: '#1E7BE1'}} onClick={onDetailClick}>
            DETALHES
          </Button>
        </Bottom>
      </Card.Footer>
    </Card>
  )
}

export default CardProposal
