import React, {useState} from 'react'
import {Box, Grid, Divider} from '@material-ui/core'
import {Add} from '@material-ui/icons'
import {Button, Form} from 'react-bootstrap'
import InputMask from 'react-input-mask'
import {useSnackbar} from 'notistack'

import {Title, Container, LabelAdd} from '../styles'
import StateCitySelect from 'app/components/form/StateCitySelect'
import {LabelM0} from 'app/modules/Proposals/pages/NewProposal/City/Details/styles'

const Contact = ({
  street_name,
  street_type,
  street_number,
  subunit,
  city,
  state,
  postal_code,
  district,
  info: {contacts},

  handleChange,
  searchZip,
  onSubmit,
  submiting,
}) => {
  const {enqueueSnackbar} = useSnackbar()

  const [contact, setContact] = useState({
    contact: {
      phone: [],
      cellPhone: [],
    },
    address: {},
  })

  const isValid = () => {
    if (!contacts[0]?.name) {
      return true
    }

    if (!contacts[0]?.responsibility || !contacts[0]?.email || !contacts[0]?.mobile_phone) {
      enqueueSnackbar('Preencha os seguintes dados: Nome, Cargo, Email e Celular', {
        variant: 'error',
      })

      return false
    }

    return true
  }

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>CONTATO PRINCIPAL</Title>
          </Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="company_contact_name">Contato direto</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="company_contact_name"
            id="company_contact_name"
            type="text"
            required
            value={contacts[0]?.name}
            onChange={handleChange}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="company_contact_responsibility">Cargo/função</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="company_contact_responsibility"
            id="company_contact_responsibility"
            type="text"
            required
            value={contacts[0]?.responsibility}
            onChange={handleChange}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="company_contact_phone">Telefone</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <InputMask
            autoComplete="off"
            className="form-control"
            name="company_contact_phone"
            value={contacts[0]?.phone}
            onChange={handleChange}
            required
            placeholder="(99) 9999 9999"
            mask="(99) 9999 9999"
            style={{maxWidth: 200}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="company_contact_mobile_phone">Celular 1</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <InputMask
            autoComplete="off"
            className="form-control"
            name="company_contact_mobile_phone"
            value={contacts[0]?.mobile_phone}
            onChange={handleChange}
            placeholder="(99) 99999 9999"
            mask="(99) 99999 9999"
            style={{maxWidth: 200}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="company_contact_mobile_phone_2">Celular 2</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <InputMask
            autoComplete="off"
            className="form-control"
            name="company_contact_mobile_phone_2"
            value={contacts[0]?.mobile_phone_2}
            onChange={handleChange}
            placeholder="(99) 99999 9999"
            mask="(99) 99999 9999"
            style={{maxWidth: 200}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="company_contact_email">E-mail</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="company_contact_email"
            id="company_contact_email"
            type="email"
            required
            value={contacts[0]?.email}
            onChange={handleChange}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="company_contact_linkedin">Site</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="company_contact_linkedin"
            id="company_contact_linkedin"
            type="text"
            value={contacts[0]?.site}
            onChange={handleChange}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Divider />
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>ENDEREÇO DA EMPRESA</Title>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="company_address_postal_code">CEP</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <InputMask
            autoComplete="off"
            className="form-control"
            name="company_address_postal_code"
            id="company_address_postal_code"
            value={postal_code}
            onChange={(ev) => {
              handleChange(ev)
              searchZip(ev.target.value)
            }}
            readOnly
            placeholder="99.999-999"
            mask="99.999-999"
            style={{maxWidth: 135}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="company_address_street">Logradouro</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="company_address_street"
            id="company_address_street"
            type="text"
            readOnly
            onChange={handleChange}
            value={street_name}
            placeholder="Ex.: Rua Arnaldo"
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="company_address_street_number">Número</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="company_address_street_number"
            id="company_address_street_number"
            type="text"
            onChange={handleChange}
            value={street_number}
            placeholder=""
            readOnly
            style={{maxWidth: 100}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="company_address_district">Bairro</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="company_address_district"
            id="company_address_district"
            type="text"
            onChange={handleChange}
            value={district}
            placeholder=""
            readOnly
            style={{maxWidth: 400}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>UF</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <LabelM0>{state}</LabelM0>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>Município</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <LabelM0>{city}</LabelM0>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Divider />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button
                variant="outline-primary"
                onClick={() => {
                  console.log(isValid())
                  if (isValid()) {
                    onSubmit(true)
                  }
                }}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  console.log(isValid())
                  if (isValid()) {
                    onSubmit(false)
                  }
                }}
                disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Contact
