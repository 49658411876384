import {MenuItem, Select} from '@material-ui/core'
import {Pagination} from '@material-ui/lab'
import React, {useEffect} from 'react'

import Skeleton from './skeleton'
import {Container, LimitContainer, LimitLabel, TotalsCounter} from './styles'

const CustomerPagination = ({
  actualLength,
  limit = {current: 25, available: [25, 50, 100]},
  loading = false,
  page = 1,
  total = 0,
  changePage,
  changeLimit,
}) => {
  const {current: currentLimit = 25, available: availableLimits = [25, 50, 100]} = limit
  const totalPages = Math.ceil(total / currentLimit)

  useEffect(() => {
    // When total or currentLimit changes, ensure page is valid
    if (totalPages > 0 && page > totalPages) {
      changePage(totalPages) // Adjust to the last page if current page is out of bounds
    }
  }, [totalPages, page, changePage])

  const onChangePage = (event, value) => {
    changePage(value)
  }

  const onChangeLimit = (e) => {
    changeLimit(e.target.value)
  }

  const goToPreviousPage = () => {
    if (page > 1) {
      changePage(page - 1)
    }
  }

  const goToNextPage = () => {
    if (page < totalPages) {
      changePage(page + 1)
    }
  }

  if (loading) {
    return <Skeleton />
  }

  return (
    <Container>
      <Pagination count={totalPages} page={page} onChange={onChangePage} showFirstButton showLastButton />
      <Container>
        <LimitContainer>
          {availableLimits && (
            <>
              <LimitLabel>Exibir</LimitLabel>
              <Select labelId="limit" value={currentLimit} onChange={onChangeLimit}>
                {availableLimits.map((item) => (
                  <MenuItem key={`k=${item}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </LimitContainer>
        <TotalsCounter>
          Página {page} de {totalPages}
        </TotalsCounter>
      </Container>
    </Container>
  )
}

export default CustomerPagination
