import React, {useState} from 'react'
import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  Input,
  ListSubheader,
  Modal,
} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'
import {userService} from 'app/services'
import {Container, Title, Label} from './styles'
import {useSnackbar} from 'notistack'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'
import CardSkeleton from '../CardSkeleton'
import {CELLPHONE_MASK, PHONE_MASK} from 'lib/masks'
import InputMask from 'react-input-mask'

const permission = ['ROLE_ADMIN', 'ROLE_USER']

const steps = {
  EDIT_USER: 'EDIT_USER',
  SUCCESS: 'SUCCESS',
}

export const EditUserBasic = ({formik, customers, setCurrentStep, loadingUser, loadingCustomer}) => {
  const {
    handleChange,
    setFieldValue,
    values: {email, name, property, roles, customer, password},
  } = formik

  const {id} = useParams()
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  let ctype = ''
  let customer_options = []
  if (customers) {
    customers.forEach((customer, id) => {
      if (customer.type !== ctype) {
        ctype = customer.type
        customer_options.push(
          <ListSubheader key={id}>
            {customer.type === 'city'
              ? 'Cidade'
              : customer.type === 'state'
              ? 'Governo'
              : customer.type === 'company'
              ? 'Company'
              : ''}
          </ListSubheader>
        )
      }
      customer_options.push(
        <MenuItem key={customer.id} value={customer.id}>
          {customer.name}
        </MenuItem>
      )
    })
  }

  const {enqueueSnackbar} = useSnackbar()

  const onDelete = async () => {
    setIsDeleting(true)

    await userService
      .updateUser(id, {is_active: false})
      .then(() => {
        enqueueSnackbar('Usuário removido com sucesso', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(`Não foi possível remover o usuário ${name}, tente novamente`, {
          variant: 'error',
        })
      })

    history.replace('/manage-users')

    setIsOpen(false)
    setIsDeleting(false)
  }

  const onSubmit = async () => {
    setIsSubmitting(true)

    const {name, email, password, property, roles, customer} = formik.values

    try {
      const data = await userService.updateUser(parseInt(id, 10), {
        name: name,
        email: email,
        password: password,
        property: {
          phone: property.phone,
          mobile_phone: property.mobile_phone,
          linkedin: property.linkedin,
          facebook: property.facebook,
        },
        roles: roles,
        customer_id: customer.id,
      })
      if (data) {
        setCurrentStep(steps.SUCCESS)
        enqueueSnackbar('Usuário atualizado com sucesso!', {
          variant: 'success',
        })
      } else {
        throw data
      }

      setIsSubmitting(false)
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })

      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Container style={{padding: 0}}>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Box marginBottom="20px">
            <Title>Editar usuário</Title>
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Nome</Label>
            {loadingUser ? (
              <CardSkeleton />
            ) : (
              <Form.Control
                placeholder="Ex.: Example example"
                name="name"
                onChange={handleChange}
                value={name}
                autoComplete="off"
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Email</Label>
            {loadingUser ? (
              <CardSkeleton />
            ) : (
              <Form.Control
                placeholder="Ex.: example@example.com.br"
                name="email"
                onChange={handleChange}
                value={email}
                autoComplete="off"
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Senha</Label>
            {loadingUser ? (
              <CardSkeleton />
            ) : (
              <Form.Control
                placeholder="Digite a nova senha ou deixe em branco para manter a senha atual"
                name="password"
                type="password"
                onChange={handleChange}
                value={password}
                autoComplete="off"
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Telefone</Label>
            {loadingUser ? (
              <CardSkeleton />
            ) : (
              <InputMask
                autoComplete="off"
                className="form-control"
                name="property.phone"
                onChange={handleChange}
                value={property.phone}
                placeholder="Ex.: (41) 9813-6874"
                mask={PHONE_MASK}
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Celular</Label>
            {loadingUser ? (
              <CardSkeleton />
            ) : (
              <InputMask
                autoComplete="off"
                className="form-control"
                name="property.mobile_phone"
                onChange={handleChange}
                value={property.mobile_phone}
                placeholder="Ex.: (41) 99813-6874"
                mask={CELLPHONE_MASK}
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Linkedin</Label>
            {loadingUser ? (
              <CardSkeleton />
            ) : (
              <Form.Control
                placeholder="Ex.: https://perfil.no.linkedin/"
                name="property.linkedin"
                onChange={handleChange}
                value={property.linkedin}
                autoComplete="off"
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Facebook</Label>
            {loadingUser ? (
              <CardSkeleton />
            ) : (
              <Form.Control
                placeholder="Ex.: https://perfil.no.facebook/"
                name="property.facebook"
                onChange={handleChange}
                value={property.facebook}
                autoComplete="off"
              />
            )}
          </Box>

          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Cliente</Label>
            {loadingCustomer ? (
              <CardSkeleton />
            ) : (
              <FormControl color="secondary">
                <InputLabel id="customer-label">Cliente</InputLabel>
                <Select
                  labelId="customer-label"
                  id="customer"
                  name="customer.id"
                  value={customer.id}
                  onChange={handleChange}
                  style={{width: '100%'}}
                >
                  {customer_options}
                </Select>
              </FormControl>
            )}
          </Box>

          {loadingUser ? (
            <CardSkeleton />
          ) : (
            <FormControl color="secondary">
              <InputLabel id="demo-roles-label">Permissões</InputLabel>
              <Select
                labelId="roles-label"
                id="roles"
                multiple
                value={roles}
                onChange={(e) => {
                  setFieldValue('roles', e.target.value)
                }}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                style={{width: '100%'}}
              >
                {permission.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={roles.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Button
            type="button"
            variant="danger"
            className="btn btn-danger"
            style={{fontSize: '14px', fontWeight: 'bold'}}
            onClick={() => setIsOpen(true)}
            disabled={!name || loadingUser || loadingCustomer || isSubmitting}
          >
            Excluir usuário
          </Button>

          <Button type="submit" onClick={onSubmit} disabled={!name || loadingUser || loadingCustomer || isSubmitting}>
            Confirmar mudanças
          </Button>
        </Box>
      </Container>

      <Modal
        open={isOpen}
        style={{display: 'grid', overflow: 'auto'}}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-descriptions"
      >
        <Box
          width={550}
          bgcolor="#ffffff"
          m="auto"
          p="20px"
          borderRadius="15px"
          display="flex"
          flexDirection="column"
          top="10rem"
          style={{gap: '3rem'}}
        >
          <h3 className="font-weight-bolder">Deseja excluir o usuário {name}?</h3>

          <div className="d-flex justify-content-between align-self-end" style={{gap: '2rem'}}>
            <Button
              variant="danger"
              className="btn btn-danger"
              style={{fontSize: '14px', fontWeight: 'bold'}}
              onClick={() => onDelete()}
              disabled={isDeleting}
            >
              Sim, excluir
            </Button>

            <Button className="btn btn-primary" onClick={() => setIsOpen(false)} disabled={isDeleting}>
              Cancelar
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}
