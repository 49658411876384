import {Box, CircularProgress} from '@material-ui/core'
import AttachmentIcon from 'app/components/images/Icons/Attachment'
import ConfirmIcon from 'app/components/images/Icons/Confirm'
import ContactAddressIcon from 'app/components/images/Icons/ContactAddress'
import DescriptionIcon from 'app/components/images/Icons/Description'
import DetailsIcon from 'app/components/images/Icons/Details'
import MapIcon from 'app/components/images/Icons/Map'
import TypesIcon from 'app/components/images/Icons/Types'
import * as userSelectors from 'app/modules/Auth/_redux/selectors'
import {changeActualEntity} from 'app/modules/Cities/redux'
import {setAvailableBenefits} from 'app/modules/Proposals/redux'
import {benefitsService, proposalService} from 'app/services'
import {useFormik} from 'formik'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import * as CityFormSteps from './City'
import * as CompanyFormSteps from './Company'
import {
  Column,
  FormContainer,
  Row,
  SidePanel,
  SidePanelItem,
  SidePanelStep,
  SidePanelTitle,
  SuccessIcon,
} from './styles'
import Type from './Type'

const steps = {
  OPPORTUNITY_TYPE: 'OPPORTUNITY_TYPE',
  /**
   * City steps
   */
  LOCATION_INCENTIVES_CITY: 'LOCATION_INCENTIVES_CITY',
  CONTACTS_CITY: 'CONTACTS_CITY',
  DETAILS_CITY: 'DETAILS_CITY',
  ATTACHMENTS_CITY: 'ATTACHMENTS_CITY',
  CONFIRM_CITY: 'CONFIRM_CITY',
  /**
   * Company steps
   */
  LOCATION_INCENTIVES_COMPANY: 'LOCATION_INCENTIVES_COMPANY',
  CONTACTS_COMPANY: 'CONTACTS_COMPANY',
  DETAILS_COMPANY: 'DETAILS_COMPANY',
  DESCRIPTION_COMPANY: 'DESCRIPTION_COMPANY',
  ATTACHMENTS_COMPANY: 'ATTACHMENTS_COMPANY',
  CONFIRM_COMPANY: 'CONFIRM_COMPANY',

  SUCCESS: 'SUCCESS',
}

const ListProposalsPage = () => {
  const {enqueueSnackbar} = useSnackbar()

  const user = useSelector(userSelectors.getUser)

  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(steps.OPPORTUNITY_TYPE)
  const [filledSteps, setFilledSteps] = useState([])
  const [loadingBenefits, setLoadingBenefits] = useState(true)
  const [proposal, setProposal] = useState(null)
  const history = useHistory()

  const getBenefits = useCallback(async () => {
    try {
      const {data} = await benefitsService.getBenefits()

      dispatch(setAvailableBenefits(data))
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao buscar os beneficios', {
        variant: 'error',
      })
    } finally {
      setLoadingBenefits(false)
    }
  }, [dispatch, enqueueSnackbar])

  useEffect(() => {
    getBenefits()
    dispatch(changeActualEntity({id: 'new', name: 'Nova Oportunidade'}))
  }, [getBenefits])

  const formatBenefits = useCallback((benefits) => {
    const keys = Object.keys(benefits)

    return keys.map((key) => {
      const benefit = {
        type: key,
        ...benefits[key],
      }
      if (benefit.type === 'terreno') {
        benefit.area = parseFloat(benefit.area)
        benefit.estimated_value = stringToNumber(benefit.estimated_value)
      }

      return benefit
    })
  }, [])

  const stringToNumber = useCallback((str) => {
    if (isNaN(str) === false) {
      return Number(str)
    }
    return Number(
      str
        .replace(/[^0-9,.-]+/g, '')
        .replace('.', '')
        .replace(',', '.'),
    )
  }, [])

  const formatPublicProposal = useCallback(
    (proposal) => {
      return {
        city_id: proposal.city.id,
        name: proposal.name,
        description: proposal.details,
        responsible_id: user.id,
        address: {
          zip: proposal.address.zip,
          street: proposal.address.street,
          number: proposal.address.number,
          neighborhood: proposal.address.neighborhood,
          city_id: proposal.city.id,
        },
        contacts: [
          {
            name: proposal.contact.name,
            email: proposal.contact.email,
            cell_phone: proposal.contact.cellphone,
            phone: proposal.contact.phone,
            responsibility: proposal.contact.role,
            site: proposal.contact.site,
          },
        ],
        benefits: [...formatBenefits(proposal.incentives)],
        multimedia: proposal.attachments.map((attachment) => ({
          ...attachment,
          type: 'image',
          name: 'Banner',
        })),
        visibility: 'restrict',
        type: 'city',
      }
    },
    [formatBenefits, stringToNumber, user.email, user.id, user.name, user.property],
  )

  const formatPrivateProposal = useCallback(
    (proposal) => {
      const params = {
        name: proposal.areaAlias,
        description: proposal.details,
        responsible_id: user.id,
        contacts: [
          {
            name: proposal.contact.name,
            email: proposal.contact.email,
            cell_phone: proposal.contact.cellphone,
            phone: proposal.contact.phone,
            responsibility: proposal.contact.role,
            site: proposal.contact.site,
          },
        ],
        project: {
          total: proposal.required_investment,
          required_investment: proposal.total_investment,
          description: proposal.private_proposal_description,
          category: proposal.project_category,
          company: {
            name: proposal.company.business_name,
            federal_tax_id: proposal.company_document,
            main_activity: proposal.company.main_activity.code,
            share_capital: stringToNumber(proposal.company.share_capital),
            contact: {
              name: proposal.contact.name,
              email: proposal.contact.email,
              cell_phone: proposal.contact.cellphone,
              phone: proposal.contact.phone,
              role: proposal.contact.role,
              site: proposal.contact.site,
            },
          },
        },
        multimedia: proposal.attachments.map((attachment) => ({
          ...attachment,
          type: 'image',
          name: 'Banner',
        })),
        visibility: 'restrict',
        type: 'company',
      }

      if (proposal.use_company_address) {
        params.project.company.address = {
          zip: proposal.company.postal_code,
          street: proposal.company.street_name,
          number: proposal.company.street_number,
          neighborhood: proposal.company.district,
        }
      } else if (proposal.no_address) {
        params.project.company.address = null
      } else {
        params.project.company.address = {
          zip: proposal.postal_code,
          street: proposal.street,
          number: proposal.number,
          neighborhood: proposal.district,
          city_id: proposal.address_city.id,
        }
      }

      console.log(params)

      if (proposal.city) {
        params.city_id = proposal.city.id
      }

      return params
    },
    [stringToNumber, user.email, user.id, user.name, user.property],
  )

  const onSubmit = useCallback(
    async (values) => {
      try {
        const data = await proposalService.createProposal(
          values.type === 'city' ? formatPublicProposal(values) : formatPrivateProposal(values),
        )

        if (data) {
          setProposal(data)
          setCurrentStep(steps.SUCCESS)
          enqueueSnackbar('Oportunidade cadastrada com sucesso!', {
            variant: 'success',
          })
        } else {
          throw data
        }
      } catch (error) {
        var message = error?.response?.data?.message
          ? error.response.data.message
          : 'Ocorreu um erro ao tentar cadastrar a oportunidade'
        enqueueSnackbar(message, {variant: 'error'})
      }
    },
    [enqueueSnackbar, formatPrivateProposal, formatPublicProposal],
  )

  const formikCity = useFormik({
    initialValues: {
      attachments: [],
      type: null,
      name: '',
      city: null,
      address: {
        zip: null,
        street: null,
        number: null,
        neighborhood: null,
        city_id: null,
      },
      incentives: {},
      areaAlias: '',
      squareMeters: 0,
      estimatedValue: 0,
      details: '',
      restrictions: '',
      mapsUrl: '',
      link_with_city: true,
      company_document: '',
      share_capital: 0,
      business_name: '',
      main_activity: '',
      company: {},
      postal_code: '',
      street: '',
      number: '',
      district: '',
      address_city: null,
      use_company_address: false,
      no_address: false,
      contact: {
        name: '',
        cellphone: '',
        phone: '',
        role: '',
        email: '',
        site: '',
      },
      opportunity_type: null,
      required_investment: 0,
      total_investment: 0,
      private_proposal_description: '',
    },
    onSubmit,
  })

  const sections = useMemo(() => {
    if (formikCity.values.type === 'city') {
      return [
        {
          icon: (color) => <TypesIcon color={color} />,
          title: 'Tipo',
          step: steps.OPPORTUNITY_TYPE,
        },
        {
          icon: (color) => <MapIcon color={color} />,
          title: 'Localização e Incentivos',
          step: steps.LOCATION_INCENTIVES_CITY,
        },
        {
          icon: (color) => <ContactAddressIcon color={color} />,
          title: 'Contato',
          step: steps.CONTACTS_CITY,
        },
        {
          icon: (color) => <DetailsIcon color={color} />,
          title: 'Detalhamento',
          step: steps.DETAILS_CITY,
        },
        {
          icon: (color) => <AttachmentIcon color={color} />,
          title: 'Anexos',
          step: steps.ATTACHMENTS_CITY,
        },
        {
          icon: (color) => <ConfirmIcon color={color} />,
          title: 'Revisão',
          step: steps.CONFIRM_CITY,
        },
      ]
    }

    return [
      {
        icon: (color) => <TypesIcon color={color} />,
        title: 'Tipo',
        step: steps.OPPORTUNITY_TYPE,
      },
      {
        icon: (color) => <DetailsIcon color={color} />,
        title: 'Detalhes e dados',
        step: steps.DETAILS_COMPANY,
      },
      {
        icon: (color) => <MapIcon color={color} />,
        title: 'Localização',
        step: steps.LOCATION_INCENTIVES_COMPANY,
      },
      {
        icon: (color) => <ContactAddressIcon color={color} />,
        title: 'Contato',
        step: steps.CONTACTS_COMPANY,
      },
      {
        icon: (color) => <DescriptionIcon color={color} />,
        title: 'Descrição',
        step: steps.DESCRIPTION_COMPANY,
      },
      {
        icon: (color) => <AttachmentIcon color={color} />,
        title: 'Anexos',
        step: steps.ATTACHMENTS_COMPANY,
      },
      {
        icon: (color) => <ConfirmIcon color={color} />,
        title: 'Revisão',
        step: steps.CONFIRM_COMPANY,
      },
    ]
  }, [formikCity.values.type])

  const renderSideItem = (item, index) => {
    const color = item.step === currentStep || filledSteps.includes(item.step) ? '#1E7BE1' : '#C9C9C9'

    return (
      <SidePanelItem active={item.step === currentStep} key={index}>
        <div style={{marginRight: '20px', width: '35px', height: '45px'}}>{item.icon(color)}</div>
        <Column>
          <SidePanelTitle active={item.step === currentStep || filledSteps.includes(item.step)}>
            {item.title}
          </SidePanelTitle>
          <SidePanelStep active={item.step === currentStep} isFilled={filledSteps.includes(item.step)}>
            Passo {index + 1}
          </SidePanelStep>
        </Column>
      </SidePanelItem>
    )
  }

  const onNextStep = useCallback(
    (nextStep) => {
      setFilledSteps([...filledSteps, currentStep])
      setCurrentStep(nextStep)
    },
    [currentStep, filledSteps],
  )

  const onPreviousStep = useCallback((previousStep) => {
    setCurrentStep(previousStep)
  }, [])

  const renderForm = () => {
    switch (currentStep) {
      case steps.OPPORTUNITY_TYPE:
        return (
          <Type
            formik={formikCity}
            onNextStep={() => {
              if (formikCity.values.type === 'city') {
                onNextStep(steps.LOCATION_INCENTIVES_CITY)
              } else {
                onNextStep(steps.DETAILS_COMPANY)
              }
            }}
          />
        )

      /**
       * City
       */
      case steps.LOCATION_INCENTIVES_CITY:
        return (
          <CityFormSteps.Location
            formik={formikCity}
            onNextStep={() => onNextStep(steps.CONTACTS_CITY)}
            onPreviousStep={() => onPreviousStep(steps.OPPORTUNITY_TYPE)}
          />
        )
      case steps.CONTACTS_CITY:
        return (
          <CityFormSteps.Contact
            formik={formikCity}
            onNextStep={() => onNextStep(steps.DETAILS_CITY)}
            onPreviousStep={() => onPreviousStep(steps.LOCATION_INCENTIVES_CITY)}
          />
        )
      case steps.DETAILS_CITY:
        return (
          <CityFormSteps.Details
            formik={formikCity}
            onNextStep={() => onNextStep(steps.ATTACHMENTS_CITY)}
            onPreviousStep={() => onPreviousStep(steps.CONTACTS_CITY)}
          />
        )
      case steps.ATTACHMENTS_CITY:
        return (
          <CityFormSteps.Attachments
            formik={formikCity}
            onNextStep={() => onNextStep(steps.CONFIRM_CITY)}
            onPreviousStep={() => onPreviousStep(steps.DETAILS_CITY)}
          />
        )
      case steps.CONFIRM_CITY:
        return (
          <CityFormSteps.Confirm
            formik={formikCity}
            onFinish={onSubmit}
            onPreviousStep={() => onPreviousStep(steps.ATTACHMENTS_CITY)}
          />
        )

      /**
       * Company
       */
      case steps.DETAILS_COMPANY:
        return (
          <CompanyFormSteps.Details
            formik={formikCity}
            onNextStep={() => onNextStep(steps.LOCATION_INCENTIVES_COMPANY)}
            onPreviousStep={() => onPreviousStep(steps.OPPORTUNITY_TYPE)}
          />
        )
      case steps.LOCATION_INCENTIVES_COMPANY:
        return (
          <CompanyFormSteps.Location
            formik={formikCity}
            onNextStep={() => onNextStep(steps.CONTACTS_COMPANY)}
            onPreviousStep={() => onPreviousStep(steps.DETAILS_COMPANY)}
          />
        )
      case steps.CONTACTS_COMPANY:
        return (
          <CompanyFormSteps.Contact
            formik={formikCity}
            onNextStep={() => onNextStep(steps.DESCRIPTION_COMPANY)}
            onPreviousStep={() => onPreviousStep(steps.LOCATION_INCENTIVES_COMPANY)}
          />
        )
      case steps.DESCRIPTION_COMPANY:
        return (
          <CompanyFormSteps.Description
            formik={formikCity}
            onNextStep={() => onNextStep(steps.ATTACHMENTS_COMPANY)}
            onPreviousStep={() => onPreviousStep(steps.CONTACTS_COMPANY)}
          />
        )
      case steps.ATTACHMENTS_COMPANY:
        return (
          <CompanyFormSteps.Attachments
            formik={formikCity}
            onNextStep={() => onNextStep(steps.CONFIRM_COMPANY)}
            onPreviousStep={() => onPreviousStep(steps.DESCRIPTION_COMPANY)}
          />
        )
      case steps.CONFIRM_COMPANY:
        return (
          <CompanyFormSteps.Confirm
            formik={formikCity}
            onFinish={onSubmit}
            onPreviousStep={() => onPreviousStep(steps.ATTACHMENTS_COMPANY)}
          />
        )

      default:
        return null
    }
  }

  if (loadingBenefits) {
    return (
      <div className="d-flex flex-column bg-white roundeds" style={{minHeight: '868px'}}>
        <Box alignItems="center" display="flex" flex={1} justifyContent="center">
          <CircularProgress />
        </Box>
      </div>
    )
  }

  return (
    <form className="d-flex flex-column bg-white roundeds" style={{minHeight: '868px'}}>
      {currentStep === steps.SUCCESS ? (
        <Box alignItems="center" display="flex" flex={1} flexDirection="column" height="500px" justifyContent="center">
          <Box marginBottom="20px">
            <Box marginBottom="20px">
              <SuccessIcon className="far fa-check-circle" />
            </Box>
            Oportunidade cadastrada!
          </Box>

          <Box marginBottom="30px">
            <Button
              onClick={() => {
                formikCity.resetForm()
                setFilledSteps([])
                history.push(`/proposals/${proposal.id}`)
              }}
              variant="outline-primary">
              Ver oportunidade #{proposal.id}
            </Button>
          </Box>

          <Box marginBottom="30px">
            <Button
              onClick={() => {
                formikCity.resetForm()
                setCurrentStep(steps.OPPORTUNITY_TYPE)
                setFilledSteps([])
              }}>
              Cadastrar nova oportunidade
            </Button>
          </Box>

          <Box>
            <Button
              onClick={() => {
                formikCity.resetForm()
                setFilledSteps([])
                history.push('/proposals')
              }}
              variant="outline-primary">
              Ver oportunidades
            </Button>
          </Box>
        </Box>
      ) : (
        <Row>
          <SidePanel>{sections.map(renderSideItem)}</SidePanel>
          <FormContainer>{renderForm()}</FormContainer>
        </Row>
      )}
    </form>
  )
}

export default ListProposalsPage
