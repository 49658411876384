import httpClient from '../../client'

import apiRoutes from '../constants'

const markAsRead = async (id) =>
  await httpClient
    .post(`${apiRoutes.GET_NOTIFICATIONS}/${id}/read`)
    .then(({ data }) => data)
    .catch((error) => error)

export default markAsRead
