import {MenuItem, Select} from '@material-ui/core'
import {Pagination} from '@material-ui/lab'
import React from 'react'
import {useDispatch} from 'react-redux'

import Skeleton from './skeleton'
import {Container, LimitContainer, LimitLabel, TotalsCounter} from './styles'

const ProposalPagination = ({actualLenght, limit, loading, page, total, changePage, changeLimit}) => {
  const dispatch = useDispatch()

  const {current: currentLimit, available: availableLimits} = limit

  const totalPages = Math.ceil(total / currentLimit)

  const isFirstPage = page === 1
  const lastPage = Math.round(total / currentLimit)

  const itemsPerPage = Array.from({length: 5})
    .fill('')
    // eslint-disable-next-line array-callback-return
    .map((_, index) => {
      if (!isFirstPage) {
        if (index === 0) return page - 2
        if (index === 1) return page - 1
      }
      if (index === 2) return page
      if (!isLastPage) {
        if (index === 3) return page + 1
        if (index === 4) return page + 2
      }
    })
    .filter(Boolean)

  const isLastPage = page === lastPage

  const onChangePage = (page) => {
    dispatch(changePage(page))
  }

  const onChangeLimit = (e) => {
    dispatch(changeLimit(e.target.value))
  }

  const goToPreviousPage = (_page) => {
    if (_page > 1) {
      dispatch(changePage(_page - 1))
    }
  }

  const goToNextPage = (_page) => {
    if (_page < lastPage) {
      dispatch(changePage(_page + 1))
    }
  }

  if (loading) {
    return <Skeleton />
  }

  return (
    <Container>
      <Pagination
        count={totalPages}
        showFirstButton
        showLastButton
        page={page}
        onChange={(ev, clickPage) => onChangePage(clickPage)}
      />

      {/* <Pagination>
                <Pagination.First 
                    disabled={isFirstPage}
                    onClick={() => onChangePage(1)}
                />
                <Pagination.Prev
                    onClick={() => goToPreviousPage(page)}
                />
                {itemsPerPage.map(_page => (
                    <Pagination.Item
                        onClick={() => onChangePage(_page)}
                        key={`k=${_page}`}
                        active={_page === page}
                    >
                        {_page}
                    </Pagination.Item>
                ))}
                <Pagination.Next
                    onClick={() => goToNextPage(page)}
                    disabled={isLastPage}
                />
                <Pagination.Last 
                    disabled={isLastPage}
                    onClick={() => onChangePage(lastPage)}
                />
        </Pagination> */}
      <Container>
        <LimitContainer>
          <LimitLabel>Exibir</LimitLabel>
          <Select labelId="limit" value={currentLimit} onChange={onChangeLimit}>
            {availableLimits &&
              availableLimits.map((item) => (
                <MenuItem key={`k=${item}`} value={item}>
                  {item}
                </MenuItem>
              ))}
          </Select>
        </LimitContainer>
        <TotalsCounter>
          Página {page} de {totalPages}
        </TotalsCounter>
      </Container>
    </Container>
  )
}

export default ProposalPagination
