import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useLocation} from 'react-router'
import {NavLink} from 'react-router-dom'

import {checkIsActive} from '../../../../_helpers'

const MENU_ITEMS = [
  {route: 'cities', title: 'Cidades'},
  {route: 'companies', title: 'Empresas'},
  {route: 'proposals', title: 'Oportunidades'},
]

function getMenuItemActive(url, location) {
  return checkIsActive(location, url) ? 'menu-item-active' : ''
}

function MenuEntry({title, route, layoutProps}) {
  const location = useLocation()
  return (
    <li className={`menu-item menu-item-rel ${getMenuItemActive(`/${route}`, location)}`}>
      <NavLink className="menu-link" to={`/${route}`}>
        <span className="menu-text text-uppercase">{title}</span>
        {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
      </NavLink>
    </li>
  )
}

export function HeaderMenu({layoutProps}) {
  const location = useLocation()

  const {admin} = useSelector(
    ({auth}) => ({
      admin: auth.user.roles.includes('ROLE_ADMIN'),
    }),
    shallowEqual,
  )

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*  <li
          className={`menu-item menu-item-rel ${getMenuItemActive('/cities', location)} ${getMenuItemActive(
            '/states',
            location,
          )}`}
          data-menu-toggle="hover">
          <div className="menu-link">
            <span className="menu-text text-uppercase">Regiões</span>
            <i className="menu-arrow" style={{display: 'flex'}} />
          </div>
        
          <ul className={'menu-submenu menu-nav'} style={{backgroundColor: 'transparent'}}>
            <MenuEntry title="Estados" route="states" layoutProps={layoutProps} />
            <MenuEntry title="Cidades" route="cities" layoutProps={layoutProps} />
          </ul>
        </li> */}

        {MENU_ITEMS.map(({route, title}) => (
          <MenuEntry key={title} title={title} route={route} layoutProps={layoutProps} />
        ))}
      </ul>
    </div>
  )
}
