import React from 'react'
import {withStyles, Accordion, AccordionSummary, AccordionDetails, Box} from '@material-ui/core'
import {ContainerHeader, DetailContainer} from '../styles'
import {Container, Paper} from './styles'
import {DoubleArrow} from '@material-ui/icons'

const formatCurrency = (amount) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)

const Accordion2 = withStyles({
  root: {
    'border': 'none',
    'minHeight': '34px !important',
    'boxShadow': 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion)

const AccordionSummary2 = withStyles({
  root: {
    backgroundColor: '#fff',
    color: '#8C8C8C',
    fontSize: 16,
    fontWeight: 600,
    padding: 0,
    minHeight: '34px !important',
    margin: '0px !important',
    fontFamily: 'Nunito Sans, sans-serif',
    textTransform: 'uppercase',
    borderTop: '1px solid #BEDDFF',
    marginBottom: -1,
  },
  content: {
    '&$expanded': {
      fontWeight: 'bold',
      color: '#00428B',
      borderTop: '1px solid #fff',
      margin: '0',
    },
  },
  expanded: {
    borderTop: '1px solid #fff',
  },
})(AccordionSummary)

const AccordionDetails2 = withStyles((theme) => ({
  root: {
    padding: '10px 0px',
  },
}))(AccordionDetails)

const Benefits = ({benefits, address}) => {
  const [expanded, setExpanded] = React.useState('panel0')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Container>
      <Paper>
        <ContainerHeader>
          <span>Descrição dos incentivos</span>
        </ContainerHeader>
        <DetailContainer>
          {benefits.map((benefit, key) => (
            <Accordion2 key={key} square expanded={expanded === 'panel' + key} onChange={handleChange(`panel${key}`)}>
              <AccordionSummary2 aria-controls={`panel${key}d-content`} id={`panel${key}d-header`}>
                <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                  <Box flex={11}>{benefit.type_description}</Box>
                  <Box flex={1}>
                    <Box
                      style={{
                        transform: expanded === 'panel' + key ? 'rotate(90deg)' : 'rotate(0deg)',
                        width: 'fit-content',
                      }}>
                      <DoubleArrow />
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary2>
              <AccordionDetails2 style={{display: 'block'}}>
                {benefit.type === 'terreno' ? (
                  <>
                    <div>
                      <span className="secundary-title">Identificação da área</span>
                      <br />
                      <p style={{color: '#383838', fontSize: 14, fontFamily: 'Nunito Sans'}}>
                        {benefit.identification}
                      </p>
                    </div>

                    <div>
                      <span className="secundary-title">Área total aproximada (em m²)</span>
                      <br />
                      <p style={{color: '#383838', fontSize: 14, fontFamily: 'Nunito Sans'}}>{benefit.area}</p>
                    </div>

                    <div>
                      <span className="secundary-title">Valor estimado do terreno</span>
                      <br />
                      <p style={{color: '#383838', fontSize: 14, fontFamily: 'Nunito Sans'}}>
                        {formatCurrency(benefit.estimated_value)}
                      </p>
                    </div>

                    <div>
                      <span className="secundary-title">Descrição</span>
                      <br />
                      <p style={{color: '#383838', fontSize: 14, fontFamily: 'Nunito Sans'}}>{benefit.description}</p>
                    </div>

                    <div>
                      <span className="secundary-title">Restrições</span>
                      <br />
                      <p style={{color: '#383838', fontSize: 14, fontFamily: 'Nunito Sans'}}>{benefit.restrictions}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <span className="secundary-title">Descrição</span>
                    <br />
                    <p style={{color: '#383838', fontSize: 14, fontFamily: 'Nunito Sans'}}>{benefit.description}</p>
                  </>
                )}
              </AccordionDetails2>
            </Accordion2>
          ))}
        </DetailContainer>
      </Paper>
      <Paper>
        <ContainerHeader>Endereço da Oportunidade</ContainerHeader>
        {address ? (
          <DetailContainer>
            <div className="d-flex justify-content-between w-100 mb-5">
              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">CEP</span>
                <br />
                <span className="text-description-footer-1">{address.zip}</span>
              </div>

              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">Logradouro</span>
                <br />
                <span className="text-description-footer-1">{address.street}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 mb-5">
              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">Número</span>
                <br />
                <span className="text-description-footer-1">{address.number}</span>
              </div>

              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">Bairro</span>
                <br />
                <span className="text-description-footer-1">{address.neighborhood}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between w-100">
              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">UF</span>
                <br />
                <span className="text-description-footer-1">{address.state.uf}</span>
              </div>
              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">Município</span>
                <br />
                <span className="text-description-footer-1">{address.city.name}</span>
              </div>
            </div>
          </DetailContainer>
        ) : (
          <DetailContainer>
            <span className="text-description-footer-1">Sem endereço cadastrado</span>
          </DetailContainer>
        )}
      </Paper>
    </Container>
  )
}

export default Benefits
