import React, {useEffect, useState, useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useSnackbar} from 'notistack'
import {useFormik} from 'formik'
import {Box} from '@material-ui/core'
import {Button} from 'react-bootstrap'
import {changeActualEntity} from 'app/modules/Cities/redux'
import {useParams} from 'react-router'
import {userService, customerService} from 'app/services'
import ContactAddressIcon from 'app/components/images/Icons/ContactAddress'

import {Column, FormContainer, Row, SidePanel, SidePanelItem, SidePanelTitle, SuccessIcon} from './styles'

import * as UserSteps from './User'
import {useHistory} from 'react-router-dom'

const steps = {
  EDIT_USER: 'EDIT_USER',
  SUCCESS: 'SUCCESS',
}

export const EditUser = () => {
  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingCustomer, setLoadingCustomer] = useState(true)
  const [filledSteps, setFilledSteps] = useState([])
  const [currentStep, setCurrentStep] = useState(steps.EDIT_USER)
  const {id} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()
  const history = useHistory()
  const [customers, setCustomers] = useState([])

  const formikUser = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      property: {
        phone: '',
        mobile_phone: '',
        linkedin: '',
        facebook: '',
      },
      roles: [],
      customer: '',
    },
    onSubmit,
  })

  const fetchCustomers = async () => {
    setLoadingCustomer(true)

    await customerService.getListCustomers().then((d) => {
      setCustomers(d.data.filter((customer) => customer.is_active))
      setLoadingCustomer(false)
    })
  }

  const fetchUser = async () => {
    try {
      setLoadingUser(true)

      const data = await userService.getUserById(parseInt(id, 10))

      const userData = {
        name: data.name,
        email: data.email,
        property: {
          phone: data.property.phone,
          mobile_phone: data.property.mobile_phone,
          linkedin: data.property.linkedin,
          facebook: data.property.facebook,
        },
        roles: data.roles,
        customer: data.customer,
      }

      formikUser.setValues(userData)
      setLoadingUser(false)
    } catch (error) {
      setLoadingUser(false)

      enqueueSnackbar('Ocorreu um erro ao buscar usuário!', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    dispatch(changeActualEntity({id: 'edit', name: 'Editar usuário'}))

    fetchUser()
    fetchCustomers()
  }, [])

  const onSubmit = useCallback(async () => {}, [enqueueSnackbar])

  const sections = useMemo(() => {
    return [
      {
        icon: (color) => <ContactAddressIcon color={color} />,
        title: 'Informações básicas',
        step: steps.EDIT_USER,
      },
    ]
  }, [])

  const renderSideItem = (item) => {
    const color = item.step === currentStep || filledSteps.includes(item.step) ? '#1E7BE1' : '#C9C9C9'

    return (
      <SidePanelItem active={item.step === currentStep}>
        <div style={{marginRight: '20px', width: '35px', height: '45px'}}>{item.icon(color)}</div>
        <Column>
          <SidePanelTitle active={item.step === currentStep || filledSteps.includes(item.step)}>
            {item.title}
          </SidePanelTitle>
        </Column>
      </SidePanelItem>
    )
  }

  const renderForm = () => {
    switch (currentStep) {
      case steps.EDIT_USER:
        return (
          <UserSteps.Basic
            formik={formikUser}
            customers={customers}
            setCurrentStep={setCurrentStep}
            loadingUser={loadingUser}
            loadingCustomer={loadingCustomer}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="d-flex flex-column bg-white roundeds" style={{minHeight: '868px'}}>
      {currentStep === steps.SUCCESS ? (
        <Box alignItems="center" display="flex" flex={1} flexDirection="column" height="500px" justifyContent="center">
          <Box marginBottom="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Box marginBottom="20px">
              <SuccessIcon className="far fa-check-circle" />
            </Box>
            <h3 className="font-weight-bolder">Usuário editado</h3>
          </Box>

          <Box>
            <Button
              onClick={() => {
                formikUser.resetForm()
                setFilledSteps([])
                history.push('/manage-users')
              }}
              variant="outline-primary"
            >
              Ver usuários
            </Button>
          </Box>
        </Box>
      ) : (
        <Row>
          <SidePanel>
            {sections.map((section, id) => {
              return (
                <div key={id} onClick={() => setCurrentStep(section.step)}>
                  {renderSideItem(section)}
                </div>
              )
            })}
          </SidePanel>
          <FormContainer>
            <form onSubmit={formikUser.handleSubmit}>{renderForm()}</form>
          </FormContainer>
        </Row>
      )}
    </div>
  )
}
