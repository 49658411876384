import React from 'react'
import {Paper, ContainerHeader, DetailContainer} from '../styles'
import {useHistory} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import Map from 'app/components/map/Map'

const ProposaLocation = ({coordinates, city, newCoordinates}) => {
  const history = useHistory()
  console.log(coordinates, newCoordinates)
  return (
    <Paper id="location">
      <ContainerHeader>
        Localização da Oportunidade
        <Button
          variant="outline-primary"
          onClick={() => history.push(`/cities/${city.id}`)}
          style={{color: '#1E7BE1', borderColor: '#1E7BE1'}}>
          VER CIDADE
        </Button>
      </ContainerHeader>
      <DetailContainer style={{padding: 0}}>
        {coordinates && coordinates.length > 0 && (
          <Map
            customStyle={{
              borderRadius: 0,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              width: '100%',
              margin: 0,
            }}
            coordinates={newCoordinates}
            zoomIn={16}
            scrollZoomDisable
            noFitBounds
          />
        )}
      </DetailContainer>
    </Paper>
  )
}

export default ProposaLocation
