import React, {useCallback, useEffect, useState} from 'react'
import {Card, Carousel} from 'react-bootstrap'

import {
  Paper,
  ContainerHeader,
  DetailContainer,
  Image,
  FavoriteIcon,
  CarouselImage,
  Container,
  BottomItem,
  BottomItemValue,
  IcentiveContainer,
} from '../styles'
import {useFavorite} from 'app/hooks'
import {Circular as CircularLoading} from 'app/components'
import {format} from 'date-fns'
import {Box, Grid} from '@material-ui/core'

const formatCurrency = (amount) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)

const BasicInfo = ({
  id,
  name,
  business_name,
  cnpj,
  main_activity,
  city,
  state,
  share_capital,
  open_at,
  status,
  info,
  ...rest
}) => {
  return (
    <Paper>
      <ContainerHeader>
        <Box display="flex">{name ? name.toUpperCase() : ''}</Box>
      </ContainerHeader>
      <DetailContainer>
        <Grid container spacing={5}>
          <Grid
            item
            lg={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <div
              style={{
                width: 300,
                height: 300,
                border: '1px solid rgb(201, 201, 201)',
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img style={{maxWidth: '90%'}} alt="Logo" src={info?.logo ?? '/media/logos/city-match/logo.png'} />
            </div>
          </Grid>

          <Grid item lg={4}>
            <Card.Header
              className="d-flex justify-content-between"
              style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
              <div className="d-flex flex-column w-100">
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Razão Social</b>
                </label>
                <span style={{fontFamily: 'Nunito', fontSize: 16}}>{name}</span>
              </div>
            </Card.Header>

            <Card.Header
              className="d-flex justify-content-between"
              style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
              <div className="d-flex flex-column w-100">
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>CNPJ</b>
                </label>
                <span style={{fontFamily: 'Nunito', fontSize: 16}}>{cnpj}</span>
              </div>
            </Card.Header>

            <Card.Header
              className="d-flex justify-content-between"
              style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
              <div className="d-flex flex-column w-100">
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Cidade Sede</b>
                </label>
                <span style={{fontFamily: 'Nunito', fontSize: 16}}>{`${city} • ${state}`}</span>
              </div>
            </Card.Header>

            <Card.Header
              className="d-flex justify-content-between"
              style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
              <div className="d-flex flex-column w-100">
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Fundação</b>
                </label>
                <span style={{fontFamily: 'Nunito', fontSize: 16}}>{format(new Date(open_at), 'dd/MM/yyyy')}</span>
              </div>
            </Card.Header>
          </Grid>

          <Grid item lg={4}>
            <Card.Header
              className="d-flex justify-content-between"
              style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
              <div className="d-flex flex-column w-100">
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Nome Fantasia</b>
                </label>
                <span style={{fontFamily: 'Nunito', fontSize: 16}}>{business_name}</span>
              </div>
            </Card.Header>

            <Card.Header
              className="d-flex justify-content-between"
              style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
              <div className="d-flex flex-column w-100">
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>CNAE Primario</b>
                </label>
                <span style={{fontFamily: 'Nunito', fontSize: 16}}>
                  {main_activity ? `${main_activity.code} - ${main_activity.text}` : 'Não Informada'}
                </span>
              </div>
            </Card.Header>

            <Card.Header
              className="d-flex justify-content-between"
              style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
              <div className="d-flex flex-column w-100">
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Capital Social</b>
                </label>
                <span style={{fontFamily: 'Nunito', fontSize: 16}}>{formatCurrency(share_capital)}</span>
              </div>
            </Card.Header>

            <Card.Header
              className="d-flex justify-content-between"
              style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
              <div className="d-flex flex-column w-100">
                <label>
                  <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Situação</b>
                </label>
                <span style={{fontFamily: 'Nunito', fontSize: 16}}>{status}</span>
              </div>
            </Card.Header>
          </Grid>
        </Grid>
      </DetailContainer>
    </Paper>
  )
}

export default BasicInfo
