import React, {useMemo} from 'react'
import {Link} from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import {shallowEqual, useSelector} from 'react-redux'
import objectPath from 'object-path'
import {useHtmlClassService} from '../../../_core/MetronicLayout'
import {toAbsoluteUrl} from '../../../../_helpers'
import {DropdownTopbarItemToggler} from '../../../../_partials/dropdowns'

import * as selectors from 'app/modules/Auth/_redux/selectors'

export function UserProfileDropdown() {
  const user = useSelector(selectors.getUser)

  const [firstName, secondName] = user.name.split(' ')

  const {admin} = useSelector(
    ({auth}) => ({
      admin: auth.user.roles.includes('ROLE_ADMIN'),
    }),
    shallowEqual,
  )

  const {superadmin} = useSelector(
    ({auth}) => ({
      superadmin: auth.user.roles.includes('ROLE_SUPERADMIN'),
    }),
    shallowEqual,
  )

  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, 'extras.user.dropdown.style') === 'light',
    }
  }, [uiService])

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div className={'btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto'}>
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Oi,</span>{' '}
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {firstName} {secondName}
          </span>
          <span className="symbol symbol-35">
            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{firstName[0]}</span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md mr-3 flex-shrink-0">
                  <img src={user.photo ?? toAbsoluteUrl('/avatar/male_blue.png')} alt={user.name} />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user.name}</div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Meu Perfil</div>
                <div className="text-muted">Detalhes da Conta</div>
              </div>
            </div>
          </Link>

          <div className="navi-separator mt-3"></div>

          <Link to="/user-profile/logs" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Minhas atividades</div>
                <div className="text-muted">Registros de atividades na plataforma</div>
              </div>
            </div>
          </Link>

          <div className="navi-separator mt-3"></div>

          <Link to="/proposals" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Minhas oportunidades</div>
                <div className="text-muted">Oportunidades que tenho vínculo</div>
              </div>
            </div>
          </Link>

          <div className="navi-separator mt-3"></div>

          {(admin || superadmin) && (
            <>
              <Link to="/customer" className="navi-item px-8 cursor-pointer">
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <i className="flaticon2-calendar-3 text-success" />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">Whitelabel</div>
                    <div className="text-muted">Mudança no visual</div>
                  </div>
                </div>
              </Link>
            </>
          )}

          {superadmin && (
            <>
              <div className="navi-separator mt-3"></div>
              <Link to="/manage-customers" className="navi-item px-8 cursor-pointer">
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <i className="flaticon2-calendar-3 text-success" />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">Clientes</div>
                    <div className="text-muted">Gerenciar clientes</div>
                  </div>
                </div>
              </Link>
            </>
          )}

          {(admin || superadmin) && (
            <>
              <div className="navi-separator mt-3"></div>
              <Link to="/manage-users" className="navi-item px-8 cursor-pointer">
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <i className="flaticon2-calendar-3 text-success" />
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer">Usuários</div>
                    <div className="text-muted">Gerenciar usuários</div>
                  </div>
                </div>
              </Link>

              <div className="navi-separator mt-3"></div>
            </>
          )}

          <div className="navi-footer  px-8 py-5">
            <Link to="/logout" className="btn btn-light-primary font-weight-bold">
              Sair
            </Link>
            <p>Solução em nuvem hospedada por AWS.</p>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
