import styled from 'styled-components'

export const Label = styled.label`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  text-align: left;
`

export const InputLabel = styled.label`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  text-align: left;
`
