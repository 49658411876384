import {Checkbox} from '@material-ui/core'
import {changeActiveColumns, changeActiveSort} from 'app/modules/Cities/redux'
import * as selectors from 'app/modules/Cities/redux/selectors'
import React, {useCallback, useRef, useState} from 'react'
import {ListGroup, Overlay, Popover} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import * as columnUtils from '../utils'
import {ActionIcon, OrderContainer} from './styles'

const StyledPopover = styled(Popover)`
  max-height: 300px;
  overflow-y: scroll;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  min-width: 164px;

  .popover-body {
    padding: 0px !important;
    padding-left: 1rem !important;
  }
`

const TableHead = () => {
  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const dispatch = useDispatch()

  const popoverRef = useRef(null)

  const columns = useSelector(selectors.getCitiesColumns)
  const orders = useSelector(selectors.citiesOrders)
  const activeOrder = useSelector(selectors.getActiveOrder)

  const onChangeOrdering = useCallback(
    ({columnKey}) => {
      let updatedOrder = 'ASC'

      if (columnKey === activeOrder?.key) {
        updatedOrder = activeOrder?.order === 'DESC' ? 'ASC' : 'DESC'
      }

      dispatch(
        changeActiveSort({
          key: columnKey,
          order: updatedOrder,
        }),
      )
    },
    [activeOrder, dispatch],
  )

  const handlePopoverClick = useCallback((event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }, [])

  const onToogleColumn = useCallback(
    (selectedColumn, isActive) => {
      let updatedColumns = [...columns.active]

      if (isActive) {
        updatedColumns = updatedColumns.filter(({key}) => key !== selectedColumn.key)
      } else {
        const columnIndex = columns.available.map(({key}) => key).indexOf(selectedColumn.key)

        updatedColumns.splice(columnIndex, 0, selectedColumn)
      }

      dispatch(changeActiveColumns(updatedColumns))
    },
    [columns.active, columns.available, dispatch],
  )

  const renderPopOverItem = useCallback(
    (column) => {
      const isActive = columnUtils.isColumnActive({
        column,
        stateColumns: columns,
      })

      return (
        <ListGroup.Item
          key={`k=${column.key}`}
          onClick={() => onToogleColumn(column, isActive)}
          style={{cursor: 'pointer', padding: '0.5rem'}}>
          <Checkbox checked={isActive} />
          {column.label}
        </ListGroup.Item>
      )
    },
    [columns, onToogleColumn],
  )

  const renderColumn = useCallback(
    ({key: columnKey, label}) => {
      const fieldOrder = columnUtils.fieldOrder({
        columnKey,
        availableOrders: orders,
      })

      if (fieldOrder) {
        const isOrderActive = activeOrder?.key === fieldOrder
        let chevron = 'down'

        if (activeOrder?.key === fieldOrder) {
          chevron = activeOrder?.order === 'DESC' ? 'down' : 'up'
        }

        return (
          <th key={columnKey} scope="col">
            <OrderContainer isOrderActive={isOrderActive} onClick={() => onChangeOrdering({columnKey})}>
              <span className="text-uppercase">{label}</span>
              <i className={`fas fa-chevron-${chevron}`}></i>
            </OrderContainer>
          </th>
        )
      }

      return (
        <th scope="col">
          <span className="text-uppercase">{label}</span>
        </th>
      )
    },
    [activeOrder, onChangeOrdering, orders],
  )

  return (
    <thead>
      <tr>
        {columnUtils.shouldRenderColumn({
          columnKey: 'id',
          stateColumns: columns,
        }) && renderColumn({key: 'id', label: '#'})}

        {columnUtils.shouldRenderColumn({
          columnKey: 'name',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'name',
            label: 'Município',
          })}
        {columnUtils.shouldRenderColumn({
          columnKey: 'state',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'state',
            label: 'UF',
          })}
        {columnUtils.shouldRenderColumn({
          columnKey: 'population',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'population',
            label: 'População',
          })}
        {columnUtils.shouldRenderColumn({
          columnKey: 'ideb',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'ideb',
            label: 'IDEB',
          })}
        {columnUtils.shouldRenderColumn({
          columnKey: 'idhm',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'idhm',
            label: 'idhm',
          })}
        {columnUtils.shouldRenderColumn({
          columnKey: 'profits',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'profits',
            label: 'RECEITAS',
          })}
        {columnUtils.shouldRenderColumn({
          columnKey: 'expenses',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'expenses',
            label: 'DESPESAS',
          })}
        {columnUtils.shouldRenderColumn({
          columnKey: 'pib',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'pib',
            label: 'PIB PER CAPITA',
          })}
        {/*columnUtils.shouldRenderColumn({
            columnKey: "counters.proposals.private",
            stateColumns: columns
          }) &&
            renderColumn({
              key: "counters.proposals.private",
              label: "Oportunidades (Privadas)"
            })*/}
        {/*columnUtils.shouldRenderColumn({
              columnKey: "counters.proposals.public",
              stateColumns: columns
            }) &&
              renderColumn({
                key: "counters.proposals.public",
                label: "Oportunidades (Públicas)"
              })*/}
        {columnUtils.shouldRenderColumn({
          columnKey: 'location.vocations',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'location.vocations',
            label: 'Vocação Regional',
          })}
        {columnUtils.shouldRenderColumn({
          columnKey: 'location.benefits',
          stateColumns: columns,
        }) &&
          renderColumn({
            key: 'location.benefits',
            label: 'Benefícios',
          })}
        <th scope="col">
          <span className="text-uppercase">Ações</span>
        </th>
        <th scope="col" ref={popoverRef}>
          <ActionIcon className="fa fa-ellipsis-v" aria-hidden="true" onClick={handlePopoverClick} />
          <Overlay
            show={showPopover}
            ref={popoverRef}
            target={popoverTarget}
            container={popoverRef.current}
            placement="bottom"
            rootClose={true}
            onHide={() => setShowPopover(false)}>
            <StyledPopover id="popover-basic">
              <Popover.Content>
                <ListGroup variant="flush">{columns.available.map(renderPopOverItem)}</ListGroup>
              </Popover.Content>
            </StyledPopover>
          </Overlay>
        </th>
      </tr>
    </thead>
  )
}

export default TableHead
