import React from 'react'

import {Paper, ContainerHeader, DetailContainer, Container} from '../styles'
import {DetailList} from './styles'

import Shimmer from 'react-loading-skeleton'

const CountyInfoLoading = () => {
  return (
    <Paper>
      <ContainerHeader>
        <Shimmer />
      </ContainerHeader>
      <DetailContainer>
        <Container>
          <DetailList>
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
          </DetailList>
          <DetailList>
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
            <Container>
              <Shimmer />
              <span>
                <Shimmer />
              </span>
            </Container>
            <hr style={{width: '100%', margin: '0px'}} />
          </DetailList>
        </Container>
      </DetailContainer>
    </Paper>
  )
}

export default CountyInfoLoading
