import React, {useCallback, useState} from 'react'
import {Box, Grid} from '@material-ui/core'
import {Button} from 'react-bootstrap'

import {Title, Paper} from '../styles'

import {FileUploadLabel, FileUploadWarning} from 'app/modules/Proposals/pages/NewProposal/City/Attachments/styles'
import Icon from 'app/components/images/Icons/Attachment'
import CircularLoading from 'app/components/loading/Circular'
import {useSnackbar} from 'notistack'
import {customerService, filesService, userService} from 'app/services'
import {useDropzone} from 'react-dropzone'
import {useFormik} from 'formik'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as auth from '../../../Auth/_redux/authRedux'

const EditCustomer = (props) => {
  const [loading, setLoading] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const {user} = useSelector(
    ({auth}) => ({
      user: auth,
    }),
    shallowEqual,
  )

  const [form, setForm] = useState({
    logo: null,
    background_banner: null,
  })

  const initialValues = {
    logo: null,
    background_banner: null,
  }

  const onSubmit = async ({setSubmitting}) => {
    try {
      setLoading(true)

      await customerService.saveCustomer(user.user.customer.id, form)

      setLoading(false)
      const getUser = await userService.getUserByToken()
      dispatch(auth.actions.setUser(getUser))

      dispatch(auth.actions.twofactor(true))

      if (getUser.customer?.background_banner) {
        document.body.style.backgroundImage = `url(${getUser.customer.background_banner})`
      }

      enqueueSnackbar('Atualizado!', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Erro ao salvar!', {
        variant: 'warning',
      })

      setLoading(false)
      setSubmitting(false)
    }
  }

  const removeWhiteLabel = async ({setSubmitting}) => {
    try {
      setLoading(true)

      await customerService.saveCustomer(user.user.customer.id, {
        logo: '',
        background_banner: '',
      })

      setLoading(false)
      const getUser = await userService.getUserByToken()
      dispatch(auth.actions.setUser(getUser))

      dispatch(auth.actions.twofactor(true))

      document.body.style.backgroundImage = 'url(/media/bg/bg-headers-citymatch.jpg)'

      enqueueSnackbar('Atualizado!', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar('Erro ao salvar!', {
        variant: 'warning',
      })

      setLoading(false)
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  return (
    <Paper>
      <Box marginBottom="60px" maxWidth="654px" m="auto" pt="50px" width="100%">
        <Grid container direction="column">
          <Box mb="20px" display="grid">
            <button
              onClick={removeWhiteLabel}
              style={{width: 'fit-content'}}
              className="btn btn-primary font-weight-bold px-9 py-4 ml-auto"
              disabled={formik.isSubmitting}>
              <span>Voltar a configuração original</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <LogoCustomer form={form} setForm={setForm} />
            <BackgroundBanner form={form} setForm={setForm} />
            <Box mt="50px" ml="auto" mb="20px" display="flex">
              <Box mr="20px">
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 m-auto my-3 mx-4"
                  {...formik.getFieldProps('checked')}
                  disabled={formik.isSubmitting}>
                  <span>Salvar</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Box>
    </Paper>
  )
}

const LogoCustomer = (props) => {
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(
    async ([file]) => {
      try {
        if (file.size > 5000000) throw Error('O tamanho de cada arquivo é limitado a 5 MB.')

        setLoading(true)

        const fileResponse = await filesService.sendFile({file})

        if (fileResponse?.file) {
          enqueueSnackbar('Anexo enviado!', {
            variant: 'success',
          })

          props.setForm((form) => {
            return {
              ...form,
              logo: fileResponse?.file.path,
            }
          })
        } else {
          throw new Error('Uploading error')
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar, props],
  )

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
  })

  return (
    <Box mb="63px">
      <Box marginBottom="20px" display="flex" flexDirection="column">
        <Title>LOGOMARCA</Title>
      </Box>
      <Box
        alignItems="center"
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        marginBottom="20px"
        justifyContent="center"
        height="357px"
        mt="23px"
        {...getRootProps()}
        className="attachtment-dragger">
        <input {...getInputProps()} />
        <Box alignItems="center" display="flex" flexDirection="column">
          <Box alignItems="center" display="flex" flexDirection="column" marginBottom="10px">
            {loading ? (
              <>
                <CircularLoading />
              </>
            ) : (
              <>
                <Box marginBottom="10px">
                  <Icon className="fas fa-file-upload" />
                </Box>
                <FileUploadLabel>Arraste e solte aqui</FileUploadLabel>
                <Button>Selecione o arquivo</Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box>{props.form.logo && <img style={{maxWidth: 300}} src={props.form.logo} alt="logo_upload" />}</Box>
      <Box display="flex" flex={1} justifyContent="flex-end" marginBottom="20px">
        <FileUploadWarning>O tamanho de cada arquivo é limitado a 5 MB.</FileUploadWarning>
      </Box>
    </Box>
  )
}

const BackgroundBanner = (props) => {
  const {enqueueSnackbar} = useSnackbar()

  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(
    async ([file]) => {
      try {
        if (file.size > 5000000) throw Error('O tamanho de cada arquivo é limitado a 5 MB.')

        setLoading(true)

        const fileResponse = await filesService.sendFile({file})

        if (fileResponse?.file) {
          enqueueSnackbar('Anexo enviado!', {
            variant: 'success',
          })

          props.setForm((form) => {
            return {
              ...form,
              background_banner: fileResponse?.file.path,
            }
          })
        } else {
          throw new Error('Uploading error')
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } finally {
        setLoading(false)
      }
    },
    [enqueueSnackbar, props],
  )

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
  })

  return (
    <Box mb="63px">
      <Box marginBottom="20px" display="flex" flexDirection="column">
        <Title>BANNER CUSTOMIZADO</Title>
      </Box>
      <Box
        alignItems="center"
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        marginBottom="20px"
        justifyContent="center"
        height="357px"
        mt="23px"
        {...getRootProps()}
        className="attachtment-dragger">
        <input {...getInputProps()} />
        <Box alignItems="center" display="flex" flexDirection="column">
          <Box alignItems="center" display="flex" flexDirection="column" marginBottom="10px">
            {loading ? (
              <>
                <CircularLoading />
              </>
            ) : (
              <>
                <Box marginBottom="10px">
                  <Icon className="fas fa-file-upload" />
                </Box>
                <FileUploadLabel>Arraste e solte aqui</FileUploadLabel>
                <Button>Selecione o arquivo</Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box>{props.form.banner && <img style={{maxWidth: 300}} src={props.form.banner} alt="banner_upload" />}</Box>
      <Box display="flex" flex={1} justifyContent="flex-end" marginBottom="20px">
        <FileUploadWarning>O tamanho de cada arquivo é limitado a 5 MB.</FileUploadWarning>
      </Box>
    </Box>
  )
}

export default EditCustomer
