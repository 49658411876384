/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {Layout} from '../_metronic/layout'
import BasePage from './BasePage'
import {Logout, AuthPage} from './modules/Auth'
import TwoFactorPage from './modules/Auth/pages/TwoFactorPage'
//  import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
  const {isAuthorized, twoFactor} = useSelector(
    ({auth}) => ({
      isAuthorized: auth.user != null,
      twoFactor: auth.twoFactor,
    }),
    shallowEqual,
  )

  return (
    <Switch>
      {!isAuthorized || !twoFactor ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
          <Route path="/twofactor" component={TwoFactorPage} />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  )
}
