import {Box, Grid} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import PropTypes from 'prop-types'
import React, {useCallback, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useDropzone} from 'react-dropzone'

import {Title} from '../../../modules/Cities/Pages/EditCitie/styles'
import {FileUploadLabel, FileUploadWarning} from '../../../modules/Proposals/pages/NewProposal/City/Attachments/styles'
import {filesService} from '../../../services'
import Icon from '../../images/Icons/Attachment'
import CircularLoading from '../../loading/Circular'

const UploadLogo = (props) => {
  const [loading, setLoading] = useState(false)
  const [uploaded, setUploaded] = useState()
  const {enqueueSnackbar} = useSnackbar()

  const maxFileSizeInMega = props.maxFileSize
  const maxFileSizeInBytes = maxFileSizeInMega * 1000000

  const onDrop = useCallback(
    async (files) => {
      if (files.length > 1) {
        enqueueSnackbar('Apenas um arquivo nessa área!', {
          variant: 'error',
        })
        return
      }
      const file = files[0]

      setLoading(true)

      try {
        if (file.size > maxFileSizeInBytes)
          throw Error(`O tamanho de cada arquivo é limitado a ${maxFileSizeInMega}MB.`)
        const fileResponse = await filesService.sendFile({file, customerId: props.customerId})
        const fileEntry = {
          type: 'image',
          name: fileResponse.file.original_name,
          path: fileResponse.file.path,
          url: fileResponse.url,
        }
        setUploaded(fileEntry)

        if (fileResponse.file) {
          enqueueSnackbar('Logo pronto! Clique em salvar para efetivar a alteração', {
            variant: 'success',
          })

          props.handleChange(fileEntry)
        } else {
          throw new Error('Uploading error')
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      }

      setLoading(false)
    },
    [enqueueSnackbar],
  )

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
  })

  useEffect(() => {
    if (!props.currentLogo) {
      return
    }

    setUploaded({
      type: 'image',
      name: '',
      path: '',
      url: props.currentLogo,
    })
  }, [props.currentLogo])

  return (
    <Box>
      <Box marginBottom="20px" display="flex" flexDirection="column">
        <Title>{props.title}</Title>
      </Box>
      <Box
        alignItems="center"
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        marginBottom="20px"
        justifyContent="center"
        height="357px"
        mt="23px"
        {...getRootProps()}
        className="attachtment-dragger">
        <input {...getInputProps()} />
        <Box alignItems="center" display="flex" flexDirection="column">
          <Box alignItems="center" display="flex" flexDirection="column" marginBottom="10px">
            {loading ? (
              <>
                <CircularLoading />
              </>
            ) : (
              <>
                <Box marginBottom="10px">
                  <Icon className="fas fa-file-upload" />
                </Box>
                <FileUploadLabel>Arraste e solte aqui</FileUploadLabel>
                <Button>Selecione o arquivo</Button>

                <Box marginTop="20px">
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    wrap="wrap"
                    spacing={2}
                    className="gallery-preview">
                    {uploaded && (
                      <>
                        <Grid item className="file-item-preview">
                          <img src={uploaded.url} width="100" height="100" className="file-item-img" />
                        </Grid>
                        <Grid item xs={12} style={{textAlign: 'center'}}>
                          <FileUploadLabel>Clique em salvar para persistir as informações</FileUploadLabel>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" flex={1} justifyContent="flex-end" marginBottom="20px">
        <FileUploadWarning>O tamanho de cada arquivo é limitado a {maxFileSizeInMega}MB.</FileUploadWarning>
      </Box>
    </Box>
  )
}

UploadLogo.defaultProps = {
  maxFileSize: 5,
}
UploadLogo.propTypes = {
  title: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  /** in MB */
  maxFileSize: PropTypes.number,
  customerId: PropTypes.number,
  currentLogo: PropTypes.string,
}

export default UploadLogo
