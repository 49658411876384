import React from 'react'
import {useSelector} from 'react-redux'
import Shimmer from 'react-loading-skeleton'
import * as selectors from 'app/modules/Cities/redux/selectors'

import TableHead from './Header'
import TableRow from './Row'
import SkeletonRow from './Row/skeleton'
import ProposalPagination from 'app/components/proposal/Pagination'
import {changePage, changeLimit} from 'app/modules/Cities/redux'

const SKELETON_ITEMS = 10

const CitieTable = ({limit, loading, page, cities, total, totalWithoutFilter}) => {
  const columns = useSelector(selectors.getCitiesColumns)

  const renderCitie = (citie) => <TableRow key={`k=${citie.id}`} {...citie} columns={columns} />

  return (
    <div className="container-citymatch-table">
      <div className="table-responsive" style={{ overflowX: 'auto' }}>
        {loading ? (
          <Shimmer width="370px" height="21px" />
        ) : (
          <div className="filter-info">
            {totalWithoutFilter === total ? (
              <p>Total de cidades ativas na base: {Intl.NumberFormat('pt-BR').format(totalWithoutFilter)}</p>
            ) : (
              <p>
                Total de cidades filtradas na base: {Intl.NumberFormat('pt-BR').format(total)} /{' '}
                {Intl.NumberFormat('pt-BR').format(totalWithoutFilter)}
              </p>
            )}
          </div>
        )}

        <table className="table position-relative" style={{ minHeight: '150px' }}>
          <TableHead />

          {total === 0 && !loading ? (
            <p className="text-center position-absolute filter-info w-100 pt-10">Nenhuma cidade encontrada</p>
          ) : (
            <tbody>
              {loading
                ? Array.from({ length: SKELETON_ITEMS }).map((_, index) => (
                    <SkeletonRow key={index} columns={columns} />
                  ))
                : cities.map(renderCitie)}
            </tbody>
          )}
        </table>
      </div>

      {total > 0 && !loading && (
        <ProposalPagination limit={limit} page={page} total={total} changePage={changePage} changeLimit={changeLimit} />
      )}
    </div>
  )
}

export default CitieTable
