import React from 'react'
import {Route, Switch} from 'react-router-dom'
import DetailCompany from './DetailCompany'
import ListCompanies from './ListCompanies'
import EditCompany from './EditCompany'

const CompaniesPage = () => {
  return (
    <Switch>
      <Route path="/companies" exact component={ListCompanies} />
      <Route path="/companies/:companyId" exact component={DetailCompany} />
      <Route path="/companies/:companyId/edit" exact component={EditCompany} />
    </Switch>
  )
}

export default CompaniesPage
