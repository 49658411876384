import React from 'react'
import {Box} from '@material-ui/core'
import {Button} from 'react-bootstrap'

import Type from 'app/components/proposal/Type'

import {Container, Title, Sutitle} from './styles'

const SelectProposalType = ({formik, onNextStep}) => {
  const {
    setFieldValue,
    values: {type},
  } = formik

  const onChange = (selected) => () => {
    setFieldValue('type', selected)
  }

  return (
    <Container style={{padding: 0}}>
      <Box marginBottom="40px">
        <Title>Tipo da oportunidade</Title>
        <Sutitle>Selecione o tipo da oportunidade:</Sutitle>
      </Box>
      <Box display="flex" flexDirection="row">
        <Box alignItems="center" display="flex" flexDirection="row" marginRight="38px">
          <Box marginRight="10px">
            <input
              className="input-proposal-radio"
              checked={type === 'city'}
              onChange={onChange('city')}
              type="radio"
              name="type"
              value={type}
            />
          </Box>
          <Type iconSize="18px" width="36px" height="36px" type="city" />
        </Box>
        <Box alignItems="center" display="flex" flexDirection="row">
          <Box marginRight="10px">
            <input
              className="input-proposal-radio"
              checked={type === 'company'}
              onChange={onChange('company')}
              type="radio"
              name="type"
              value={type}
            />
          </Box>
          <Type iconSize="18px" width="36px" height="36px" type="company" />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" position="absolute" bottom={0} right={0}>
        <Button onClick={onNextStep} disabled={!type}>
          Próximo passo ⇀
        </Button>
      </Box>
    </Container>
  )
}

export default SelectProposalType
