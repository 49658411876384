import httpClient from '../../client'

import apiRoutes from '../constants'

const createProposal = async (proposal) => {
  try {
    const response = await httpClient.post(`${apiRoutes.GET_PROPOSALS}`, proposal)

    console.log(response.status)
    console.log(response)

    return response.data
  } catch (error) {
    throw error
  }
}

export default createProposal
