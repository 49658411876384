export const SITE_MASK = /^(https?:\/\/)?(www\.)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i

export const EMAIL_MASK = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i

const FIRST_DIGIT = /[1-9]/i

const NINE = /9/i

const DIGIT = /[0-9]/

export const CELLPHONE_MASK = [
  '(',
  FIRST_DIGIT,
  DIGIT,
  ')',
  ' ',
  NINE,
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '-',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
]

export const PHONE_MASK = [
  '(',
  FIRST_DIGIT,
  DIGIT,
  ')',
  ' ',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
  '-',
  DIGIT,
  DIGIT,
  DIGIT,
  DIGIT,
]
