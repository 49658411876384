import httpClient from '../../client'
import apiRoutes from '../constants'

const getState = async (stateId) => {
  try {
    const {data} = await httpClient.get(`${apiRoutes.GET_STATE}/${stateId}`)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling state with ${JSON.stringify(stateId)}`, error)
    return error
  }
}

export default getState
