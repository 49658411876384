import styled from 'styled-components'
import {Form} from 'react-bootstrap'

export const Label = styled.label`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  text-align: left;
`

export const Check = styled(Form.Check)`
  color: #8c8c8c;
  label {
    margin-top: 3px;
  }
`
