import React, {useState, useCallback, useEffect} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {CircularProgress, TextField} from '@material-ui/core'

import {citieService} from 'app/services'

import {Label, Column, SelectedListContainer, SelectedListItem} from './styles'

const AutoCompleteCity = ({selectedCity, onSelectCity, onRemoveCity, selectedCities, selectedStates}) => {
  const [cities, setCities] = useState([])
  const [inputText, setInputText] = useState(selectedCity?.name ?? '')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(open && cities?.length === 0)
  selectedStates = selectedStates || []

  const getOptionLabel = ({name, state: {uf}}) => `${name} - ${uf}`

  const onChange = (_, value) => {
    if (selectedCities) {
      const isAdded = selectedCities.find(({code}) => value?.code === code)

      if (!isAdded && value) {
        onSelectCity(value)
        setInputText('')
      }
    } else {
      onSelectCity(value)
      setInputText(value.name)
    }
  }

  const onInputChange = (event) => {
    if (event) {
      setInputText(event.target.value)
    }
  }

  const getOptionSelected = (option, value) => option.name === value.name

  const searchCities = useCallback(async () => {
    setLoading(true)
    try {
      const {data} = await citieService.getCities({
        limit: selectedStates.length > 0 ? 800 : 30,
        name: inputText,
        ufs: selectedStates.map((state) => state.id).join(','),
        sort: {name: 'asc'},
      })

      setCities(data)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }, [inputText, selectedStates])

  useEffect(() => {
    if (inputText || selectedStates.length > 0) {
      searchCities()
    }
  }, [inputText, selectedStates])

  return selectedStates.length ? (
    <Column>
      <Label>Cidades(s)</Label>
      <Autocomplete
        size="small"
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={cities}
        loading={loading}
        inputValue={inputText}
        noOptionsText="Nenhum item encontrado"
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionDisabled={(selected) =>
          selectedCities?.some((state) => state.id === selected.id) || selected === selectedCity?.id
        }
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              placeholder: 'Escolher…',
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {selectedCities ? (
        <SelectedListContainer>
          {selectedCities.map(({id, name}) => (
            <SelectedListItem key={id}>
              {name}
              <i className="far fa-times-circle" onClick={() => onRemoveCity(id)} />
            </SelectedListItem>
          ))}
        </SelectedListContainer>
      ) : null}
    </Column>
  ) : null
}

export default AutoCompleteCity
