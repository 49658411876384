import {Box} from '@material-ui/core'
import StateCitySelect from 'app/components/form/StateCitySelect'
import getCities from 'app/services/cities/getCities'
import {useSnackbar} from 'notistack'
import React, {useCallback, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import InputMask from 'react-input-mask'

import {Container, Title} from './styles'

const SelectProposalType = ({formik, onNextStep, onPreviousStep}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [cityFound, setCityFound] = useState(null)
  const [validSite, setValidSite] = useState(false)
  const [validEmail, setValidEmail] = useState(false)

  const {
    handleChange,
    setFieldValue,
    values: {address_city, postal_code, street, number, district, use_company_address, no_address, company},
  } = formik

  const searchCep = useCallback(
    async (e) => {
      try {
        const postalCode = e.target.value

        const cleanPostalCode = postalCode?.match(/\d+/g)?.join('')

        setFieldValue('postal_code', postalCode)

        if (cleanPostalCode?.length === 8) {
          setLoading(true)

          const response = await (await fetch(`https://viacep.com.br/ws/${cleanPostalCode}/json`)).json()

          if (response.erro) {
            enqueueSnackbar('Nenhum CEP encontrado', {
              variant: 'warning',
            })
          } else {
            setFieldValue('street', response.logradouro)
            setFieldValue('district', response.bairro)

            getCities({code: response.ibge}).then((res) => {
              setCityFound(res.data[0])
              setFieldValue('address_city', res.data[0])
              setLoading(false)
            })
          }
          setLoading(false)
        }
      } catch (error) {
        enqueueSnackbar('Ocorreu um erro ao buscar o endereco', {
          variant: 'warning',
        })
      }
    },
    [enqueueSnackbar, setFieldValue],
  )

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" marginBottom="60px">
        <Box marginBottom="20px">
          <Title>Endereço da oportunidade</Title>
        </Box>

        {company ? (
          <Box display="flex" flex={1} flexDirection="row" marginBottom="30px">
            <Box display="flex">
              <Form.Check
                name="no_address"
                checked={no_address}
                onChange={handleChange}
                id="no_address"
                type="checkbox"
              />
              <label
                htmlFor="no_address"
                style={{color: '#8C8C8C', fontSize: 14, fontFamily: 'Nunito Sans', cursor: 'pointer'}}>
                Não desejo informar endereço no momento.
              </label>
            </Box>
          </Box>
        ) : null}

        {no_address ? null : (
          <>
            <Box display="flex" flex={1} flexDirection="row" marginBottom="30px">
              <Box display="flex" flex={1} flexGrow={2} flexDirection="column" marginRight="20px">
                <Form.Label>
                  <b>CEP*</b>
                </Form.Label>
                <InputMask
                  autoComplete="off"
                  className="form-control"
                  name="postal_code"
                  onChange={searchCep}
                  disabled={loading}
                  value={postal_code}
                  placeholder="01212-312"
                  mask="99999-999"
                />
              </Box>
              <Box display="flex" flex={1} flexGrow={3} flexDirection="column" marginRight="20px">
                <Form.Label>
                  <b>Rua*</b>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  name="street"
                  onChange={handleChange}
                  value={street}
                  disabled={loading}
                  placeholder="Ex.: Av. Marechal Deororo"
                />
              </Box>
              <Box display="flex" flex={1} flexGrow={1} flexDirection="column">
                <Form.Label>
                  <b>Número</b>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  name="number"
                  onChange={handleChange}
                  value={number}
                  disabled={loading}
                  placeholder="Ex.: S/N"
                />
              </Box>
            </Box>
            <Box display="flex" flex={1} flexDirection="row">
              <Box display="flex" flex={1} flexGrow={2} flexDirection="column" marginRight="20px">
                <Form.Label>
                  <b>Bairro*</b>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  name="district"
                  onChange={handleChange}
                  value={district}
                  disabled={loading}
                  placeholder="Ex.: Capão Raso"
                />
              </Box>
              <Box display="flex" flex={1} flexGrow={2} flexDirection="column">
                <StateCitySelect
                  disabled={loading}
                  city={cityFound}
                  changeCity={(cid) => setFieldValue('address_city', cid)}></StateCitySelect>
              </Box>
            </Box>
          </>
        )}
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>
        <Button
          onClick={onNextStep}
          disabled={loading || (no_address ? false : !address_city || !postal_code || !street || !district)}>
          Próximo passo ⇀
        </Button>
      </Box>
    </Container>
  )
}

export default SelectProposalType
