import {Box, Chip, Divider, Grid} from '@material-ui/core'
import FilePreview from 'app/components/files/FilePreview'
import UploadGaleria from 'app/components/files/UploadGaleria'
import {useSnackbar} from 'notistack'
import React, {useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'

import {Container, SubTitleAttachments, Title, TitleAttachments} from '../styles'

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

const Attachment = ({
  multimedia,
  customer,
  handleChange,
  onSubmit,
  submiting,
  handleAddAttachment,
  removeAttachment,
  hiddenButton,
}) => {
  const {enqueueSnackbar} = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [isDeletingFileId, setDeletingFileId] = useState()

  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  const toggle = (file) => {
    setSelectedImage(file)
    setOpen(!open)
  }

  const grouped = useMemo(() => groupBy([...multimedia], 'type'), [multimedia])

  const handleFileUpload = (fileEntry) => {
    setUploadedFiles((prevState) => [...prevState, fileEntry])
    handleAddAttachment(fileEntry)
  }

  /**
   * @param {LocationFileEntity} fileEntry
   */
  const handleRemoveAttachment = async (fileEntry) => {
    setDeletingFileId(fileEntry.id)
    await removeAttachment?.(fileEntry)
    setDeletingFileId()
  }

  const renderAttachment = (atachment) => {
    return (
      <Box key={atachment.file} marginBottom="10px" marginRight="10px">
        <Chip
          label={atachment.name}
          size="small"
          variant="outlined"
          style={{
            color: '#8C8C8C',
            borderRadius: 10,
            background: '#1E7BE10C',
            borderColor: '#1E7BE10C',
          }}
        />
      </Box>
    )
  }

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>ARQUIVOS ANEXADOS</Title>
          </Box>
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box maxWidth={600}>
            <Box marginBottom="20px">
              <Title></Title>
            </Box>
            <Box mb="15px">
              <TitleAttachments>Fotos</TitleAttachments>
            </Box>
            <Box display="flex" gridGap="10px" flexWrap="wrap" mb="30px">
              {grouped.image ? (
                grouped.image.map((file) => (
                  <FilePreview
                    key={file.id}
                    file={file}
                    isDeleting={isDeletingFileId === file.id}
                    onDelete={handleRemoveAttachment}
                  />
                ))
              ) : (
                <Box display="flex" alignItems="center" gridGap="10px">
                  <SubTitleAttachments>Nenhuma imagem foi enviada ainda.</SubTitleAttachments>
                </Box>
              )}
            </Box>
            <Divider />
          </Box>
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box maxWidth={600}>
            <Box marginBottom="20px">
              <Title></Title>
            </Box>
            <Box mb="15px">
              <TitleAttachments>Vídeos</TitleAttachments>
            </Box>
            <Box display="flex" gridGap="10px" flexWrap="wrap" mb="30px">
              {grouped.video ? (
                grouped.video.map((file) => (
                  <FilePreview
                    key={file.id}
                    file={file}
                    isDeleting={isDeletingFileId === file.id}
                    onDelete={handleRemoveAttachment}
                  />
                ))
              ) : (
                <Box display="flex" alignItems="center" gridGap="10px">
                  <SubTitleAttachments>Nenhum video foi enviado ainda.</SubTitleAttachments>
                </Box>
              )}
            </Box>
            <Divider />
          </Box>
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box maxWidth={600}>
            <Box marginBottom="20px">
              <Title></Title>
            </Box>
            <Box mb="15px">
              <TitleAttachments>Documentos</TitleAttachments>
            </Box>
            <Box display="flex" gridGap="10px" flexWrap="wrap" mb="30px">
              {grouped.document ? (
                grouped.document.map((file) => (
                  <FilePreview
                    key={file.id}
                    file={file}
                    displayName={true}
                    isDeleting={isDeletingFileId === file.id}
                    onDelete={handleRemoveAttachment}
                  />
                ))
              ) : (
                <Box display="flex" alignItems="center" gridGap="10px">
                  <SubTitleAttachments>Nenhum documento foi enviado ainda.</SubTitleAttachments>
                </Box>
              )}
            </Box>
            <Divider />
          </Box>
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box maxWidth={600}>
            <Box>
              <UploadGaleria
                title="INSERIR NOVOS ARQUIVOS"
                subtitle="Deseja anexar alguma imagem, vídeo, apresentação ou documento sobre a oportunidade?"
                imagesLocation={uploadedFiles}
                handleChange={handleFileUpload}
                maxFileSize={100}
                customerId={customer?.id}
                acceptTypes="image/*,video/*,application/*,.pdf"
              />
            </Box>
          </Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            {hiddenButton ? null : (
              <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
                <Button variant="outline-primary" onClick={() => onSubmit(true)} disabled={loading}>
                  {' '}
                  SALVAR E CONTINUAR EDIÇÃO
                </Button>
              </Box>
            )}
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting || loading}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Attachment
