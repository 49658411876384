import {Circular as CircularLoading} from 'app/components/loading'
import {ProposalLogo} from 'app/components/proposal/Logo'
import {
  Actions,
  Bottom,
  Container,
  FavoriteIcon,
  IconDetail,
  InfoContainer,
  LogoContainer,
  ProposalName,
  RightContent,
  StyledPopover,
} from 'app/components/proposal/ProposalCards/ProposalCard/styles'
import {useFavorite, useUpdateOpportunity} from 'app/hooks'
import {format} from 'date-fns'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Button, Card, ListGroup, Overlay, Popover} from 'react-bootstrap'
import {CheckCircle, Circle, Edit, Menu, MoreHorizontal, Star} from 'react-feather'
import {useHistory} from 'react-router-dom'

function CompanyCard({
  name,
  business_name,
  cnpj,
  main_activity,
  share_capital,
  id,
  status,
  type,
  open_at,
  is_favorite,
  city,
  state,
  has_permission_to_edit,
}) {
  const history = useHistory()

  const popoverRef = useRef(null)

  const {action: updateOpportunityStatus, loading: loadingUpdate, status: updatedStatus} = useUpdateOpportunity({
    id,
    status,
  })

  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'proposal',
    label: 'Proposta',
  })

  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount)

  useEffect(() => {
    setShowPopover(false)
  }, [loadingUpdate])

  const onDetailClick = useCallback(() => {
    history.push(`/companies/${id}`)
  }, [history, id])

  const handlePopoverClick = (event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }

  const goToProposalDetails = useCallback(
    (proposalId) => {
      history.push(`/companies/${proposalId}`)
    },
    [history],
  )

  const [loading, setLoading] = useState(true)

  const cityName = city ? city?.name : city?.name
  const uf = city ? city?.state?.uf : city?.state?.uf

  return (
    <Card
      style={{
        maxWidth: '485px',
        marginRight: '10px',
        marginLeft: '10px',
        marginBottom: '20px',
        padding: '23px 31px',
        borderRadius: 10,
        flex: '1 0 25%',
      }}>
      <Card.Header
        className="d-flex justify-content-between"
        style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
        <InfoContainer>
          <LogoContainer>
            <ProposalLogo borderRadius="50%" name={name} url={''} showFirstLetter height="57px" width="57px" />
          </LogoContainer>
          <div className="d-flex flex-column w-75">
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Razão Social</b>
            </label>
            <ProposalName
              style={{
                color: '#1E7BE1',
                fontSize: 18,
                fontFamily: 'Nunito Sans',
                fontWeight: 'normal',
              }}>
              {name}
            </ProposalName>
            <div
              className="d-flex justify-content-start w-100 align-items-center"
              style={{fontSize: 14, fontFamily: 'Nunito Sans', letterSpacing: 0, color: '#8C8C8C', opacity: 1}}>
              {cityName ? (
                <IconDetail className="fa fa-map-marker ml-7" style={{color: '#BEDDFF', fontSize: 12}} />
              ) : null}

              {cityName ? (
                <>
                  <span> {cityName}</span>{' '}
                  <small style={{fontSize: 5, display: 'inline-block', marginRight: 3, marginLeft: 3}}>•</small>
                  <span>{uf}</span>
                </>
              ) : null}
            </div>
          </div>
        </InfoContainer>
        <Actions style={{textAlign: 'center'}}>
          <Button onClick={handlePopoverClick} ref={popoverRef} className="button-icon">
            <MoreHorizontal style={{color: '#C9C9C9'}} />
          </Button>
          <Overlay
            show={showPopover}
            target={popoverTarget}
            container={popoverRef.current}
            placement="bottom"
            rootClose={true}
            onHide={() => setShowPopover(false)}>
            <StyledPopover>
              <Popover.Content>
                <ListGroup variant="flush">
                  <ListGroup.Item onClick={() => goToProposalDetails(id)}>
                    <Menu className="icon-menu" /> Detalhes
                  </ListGroup.Item>

                  {/* <ListGroup.Item>
                                        <Share className="icon-menu" /> Compatilhar
                                    </ListGroup.Item> */}

                  <ListGroup.Item onClick={() => handleFavoriteOpportunity()}>
                    <Star className="icon-menu" />
                    {isFavorite ? 'Remover dos favoritos' : 'Salvar em favoritos'}
                  </ListGroup.Item>

                  {
                    <ListGroup.Item onClick={() => history.push(`/companies/${id}/edit`)}>
                      <Edit className="icon-menu" />
                      Editar
                    </ListGroup.Item>
                  }
                  {/*
                                    <ListGroup.Item>
                                        <Download className="icon-menu" /> Download
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Trash className="icon-menu" /> Excluir
                                    </ListGroup.Item> */}
                </ListGroup>
              </Popover.Content>
            </StyledPopover>
          </Overlay>
          {loadingFavorite ? (
            <CircularLoading />
          ) : (
            <FavoriteIcon
              aria-hidden="true"
              className="fa fa-star"
              isFavorite={isFavorite}
              onClick={() => handleFavoriteOpportunity()}
            />
          )}
        </Actions>
      </Card.Header>

      <Card.Header
        className="d-flex justify-content-between"
        style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
        <div className="d-flex flex-column w-100">
          <label>
            <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>Nome Fantasia</b>
          </label>
          <ProposalName style={{fontSize: 16, height: 'inherit'}}>{business_name}</ProposalName>
        </div>
      </Card.Header>

      <Card.Header
        className="d-flex justify-content-between"
        style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
        <div className="d-flex flex-row w-100" style={{justifyContent: 'space-between'}}>
          <div className="d-flex">
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>
                Oportunidades <br /> Ativas
              </b>
            </label>
            <div
              style={{
                height: 43,
                marginLeft: 10,
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Nunito',
                fontSize: 26,
                background: '#C3EDBF',
                borderRadius: 10,
                padding: '8px 12px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              0
            </div>
          </div>
          <div className="d-flex">
            <label>
              <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>
                Oportunidades <br /> Ganhas / Concretizadas
              </b>
            </label>
            <div
              style={{
                height: 43,
                marginLeft: 10,
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Nunito',
                fontSize: 26,
                background: '#BEDDFF',
                borderRadius: 10,
                padding: '8px 12px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              0
            </div>
          </div>
        </div>
      </Card.Header>

      <Card.Header
        className="d-flex justify-content-between"
        style={{padding: 0, paddingBottom: 20, marginBottom: 20, borderBottom: '1px solid #EBEDF3'}}>
        <div className="d-flex flex-column w-100">
          <label>
            <b style={{fontSize: 14, fontFamily: 'Nunito Sans'}}>CNAE Primario</b>
          </label>
          <span style={{fontFamily: 'Nunito', fontSize: 16}}>
            {main_activity ? `${main_activity.code} - ${main_activity.text}` : 'Não Informada'}
          </span>
        </div>
      </Card.Header>

      <Card.Body style={{marginTop: 30, padding: 0}}>
        <Container>
          <div className="d-flex align-items-center justify-content-start w-50">
            <IconDetail className="fa fa-database fa-2x" style={{fontSize: 24, marginRight: 17}} aria-hidden="true" />
            <div>
              <p className="h6">CNPJ</p>
              <div className="tt">{cnpj}</div>
            </div>
          </div>
          <RightContent>
            <div className="d-flex align-items-center justify-content-start w-50">
              <IconDetail className="fa fa-calendar fa-2x" style={{fontSize: 24, marginRight: 17}} aria-hidden="true" />
              <div>
                <p className="h6">Fundação</p>
                <div className="tt">{format(new Date(open_at), 'dd/MM/yyyy')}</div>
              </div>
            </div>
          </RightContent>
        </Container>
        <Container>
          <div className="d-flex align-items-center justify-content-start w-50">
            <IconDetail className="fa fa-money fa-2x" aria-hidden="true" style={{fontSize: 24, marginRight: 17}} />
            <div>
              <p className="h6">Capital social</p>
              <div className="tt">{formatCurrency(share_capital)}</div>
            </div>
          </div>
          <RightContent>
            <div className="d-flex align-items-center justify-content-start w-50">
              <IconDetail className="fa fa-circle fa-2x" style={{fontSize: 24, marginRight: 17}} aria-hidden="true" />
              <div>
                <p className="h6">Situação</p>
                <div className="tt">{status}</div>
              </div>
            </div>
          </RightContent>
        </Container>
      </Card.Body>

      <Card.Footer style={{display: 'flex'}}>
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
          <i className="fa fa-map-marker-alt" style={{color: '#cde5ff', marginRight: 5}}></i>
          <span>{`${city} • ${state}`}</span>
        </div>
        <Bottom style={{width: '100%'}}>
          {/* <span>Cadastrada por <a href="javascript:void(0);">{customer.name}</a></span> */}
          <Button variant="primary" style={{backgroundColor: '#1E7BE1'}} onClick={onDetailClick}>
            DETALHES
          </Button>
        </Bottom>
      </Card.Footer>
    </Card>
  )
}

export default CompanyCard
