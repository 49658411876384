import httpClient from '../../client'
import apiRoutes from '../constants'

const getRanking = async (stateId) => {
  try {
    const url = `${apiRoutes.GET_STATES}/${stateId}/ranking`
    const {data} = await httpClient.get(url)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling state ranking with ${JSON.stringify(stateId)}`, error)
    return error
  }
}

export default getRanking
