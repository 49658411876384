import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import AccountInformation from './AccountInformation'
import ChangePassword from './ChangePassword'
import PersonaInformation from './PersonaInformation'
import EmailSettings from './EmailSettings'
import {ProfileCard} from './components/ProfileCard'
import {useSubheader} from '_metronic/layout'
import NotificationConfiguration from './NotificationConfiguration'

export default function UserProfilePage() {
  const suhbeader = useSubheader()
  suhbeader.setTitle('Perfil do usuário')

  return (
    <div className="d-flex flex-row">
      <ProfileCard></ProfileCard>
      <div className="flex-row-fluid ml-lg-8">
        <Switch>
          <Redirect from="/user-profile" exact={true} to="/user-profile/personal-information" />
          <Route path="/user-profile/account-information" component={AccountInformation} />
          <Route path="/user-profile/change-password" component={ChangePassword} />
          <Route path="/user-profile/email-settings" component={EmailSettings} />
          <Route path="/user-profile/personal-information" component={PersonaInformation} />
          <Route path="/user-profile/notification-configuration" component={NotificationConfiguration} />
        </Switch>
      </div>
    </div>
  )
}
