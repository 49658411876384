import httpClient from '../../client'

import apiRoutes from '../constants'

const getPartners = async ({name}) => {
  try {
    const {data} = await httpClient.get(apiRoutes.GET_PARTNERS, {
      params: {
        q: name,
      },
    })

    return data
  } catch (error) {
    throw error.data.message
  }
}

export default getPartners
