import httpClient from '../../client'

import apiRoutes from '../constants'

const removeFavorite = async (entity) => {
  try {
    await httpClient.delete(apiRoutes.ADD_FAVORITE, {data: entity})
  } catch (error) {
    console.log(`Error adding favorite with ${JSON.stringify(entity)}`, error)
    return error
  }
}

export default removeFavorite
