import {Box} from '@material-ui/core'
import IconContainer from 'app/components/images/Icons/IconContainer'
import CircularLoading from 'app/components/loading/Circular'
import {TitleVideo} from 'app/modules/Proposals/pages/EditProposal/styles'
import {getFileUrl, isFileImage, isFilePdf, isFileVideo} from 'lib/file-helper'
import PropTypes from 'prop-types'
import React from 'react'

const FilePreview = (props) => {
  const {
    /**
     * @type {UploadedFileEntry} file
     */
    file,
    isDeleting,
    displayName,
    /**
     * @type {(file: UploadedFileEntry)=>void} onDelete
     */
    onDelete,
  } = props
  const allowDelete = typeof onDelete === 'function'

  const handleDelete = () => {
    if (!allowDelete) {
      return
    }
    onDelete(file)
  }

  const renderPreview = () => {
    let comp = null
    const fileUrl = getFileUrl(file)

    if (isFileImage(file)) {
      comp = <img src={fileUrl} width="100" height="100" className="file-item-img" />
    } else if (isFilePdf(file)) {
      comp = (
        <Box
          width="100px"
          height="100px"
          borderRadius="10px"
          style={{
            backgroundImage: 'url(https://i.imgur.com/ZmTELra.png)',
            backgroundSize: 'cover',
          }}
        />
      )
    } else if (isFileVideo(file)) {
      comp = (
        <p>
          <strong>{file.name}</strong>
        </p>
      )
    }

    return comp
  }

  return (
    <div className="file-item-preview">
      {allowDelete && !isDeleting && (
        <IconContainer style={{position: 'absolute', top: '-10px', left: 0}}>
          <i className="fas fa-trash file-item-icon-delete" onClick={handleDelete} />
        </IconContainer>
      )}

      {renderPreview(file)}
      {displayName && <TitleVideo>{file.name}</TitleVideo>}

      {isDeleting && (
        <div className="file-item-delete-loader">
          <CircularLoading />
        </div>
      )}
    </div>
  )
}

FilePreview.defaultProps = {
  displayName: false,
}

FilePreview.propTypes = {
  /**
   * @type {UploadedFileEntry} file
   */
  file: PropTypes.object.isRequired,
  /**
   * @type {(file: UploadedFileEntry|LocationFileEntity)=>void} onDelete
   */
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  displayName: PropTypes.bool,
}

export default FilePreview
