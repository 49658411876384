/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import SVG from 'react-inlinesvg'
import {ModalProgressBar} from '../../../_metronic/_partials/controls'
import {toAbsoluteUrl} from '../../../_metronic/_helpers'
import * as auth from '../Auth'
import {Button} from 'react-bootstrap'
import userChangePassword from 'app/services/auth/userChangePassword'
import {useSnackbar} from 'notistack'

function ChangePassword() {
  const [loading, setloading] = useState(false)
  const [isError, setisError] = useState(false)
  const {enqueueSnackbar} = useSnackbar()

  // Methods
  const savePassword = async (values, setStatus, setSubmitting) => {
    setloading(true)
    setisError(false)
    try {
      let body = {
        password: values.password,
        confirm_password: values.cPassword,
      }
      await userChangePassword(body)
      enqueueSnackbar('Senha atualizada!', {
        variant: 'success',
      })
      setloading(false)
      setSubmitting(false)
    } catch (e) {
      setloading(false)
      setSubmitting(false)
      enqueueSnackbar('Ops, ocorreu um erro ao atualizar a senha, tente novamente!', {
        variant: 'error',
      })
    }
  }

  const initialValues = {
    password: '',
    cPassword: '',
  }

  const Schema = Yup.object().shape({
    password: Yup.string().required('Nova senha é obrigatório!'),
    cPassword: Yup.string()
      .required('Necessário validar senha!')
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], 'Senhas não conferem'),
      }),
  })
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      savePassword(values, setStatus, setSubmitting)
    },
    onReset: (values, {resetForm}) => {
      resetForm()
    },
  })

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">Mudar senha</h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">Mude a senha da sua conta</span>
        </div>
        <div className="card-toolbar">
          <Button
            type="submit"
            className="btn btn-success mr-2"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
          >
            Salvar alterações
            {formik.isSubmitting}
          </Button>
          <Link to="/user-profile/profile-overview" className="btn btn-secondary">
            Fechar
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Alert */}
          {isError && (
            <div
              className="alert alert-custom alert-light-danger fade show mb-10"
              role="alert"
              style={{alignItems: 'center'}}
            >
              <div className="alert-icon">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Info-circle.svg')}></SVG>{' '}
                </span>
              </div>
              <div>Ops, ocorreu um erro ao atualizar sua senha, tente novamente!</div>
              <div className="alert-close" onClick={() => setisError(false)}>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">
                    <i className="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">Nova senha</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Nova senha"
                className={`form-control form-control-lg form-control-solid ${getInputClasses('password')}`}
                name="password"
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">Confirme a nova senha</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Confirme a nova senha"
                className={`form-control form-control-lg form-control-solid ${getInputClasses('cPassword')}`}
                name="cPassword"
                {...formik.getFieldProps('cPassword')}
              />
              {formik.touched.cPassword && formik.errors.cPassword ? (
                <div className="invalid-feedback">{formik.errors.cPassword}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* end::Form */}
    </form>
  )
}

export default connect(null, auth.actions)(ChangePassword)
