import {Box, Chip} from '@material-ui/core'
import * as proposalsSelectors from 'app/modules/Proposals/redux/selectors'
import React, {useCallback} from 'react'
import {Button} from 'react-bootstrap'
import {useSelector} from 'react-redux'

import {BlockTitle, BlockValue, Container, Title} from './styles'

const formatCurrency = (amount, multiply) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(multiply ? multiply * amount : amount)

const ProposalConfirm = ({formik, onPreviousStep}) => {
  const availableIncentives = useSelector(proposalsSelectors.getAvailaleBenefits)

  const {
    values: {
      attachments,
      incentives,
      city,
      areaAlias,
      business_name,
      share_capital,
      company_document,
      main_activity,
      use_company_address,
      postal_code,
      street,
      number,
      district,
      company,
      contact,
      details,
      total_investment,
      required_investment,
      project_category,
      private_proposal_description,
    },
  } = formik

  const findIncentiveByKey = useCallback((key) => availableIncentives.find(({type}) => type === key), [
    availableIncentives,
  ])

  const getIncentiveDescription = useCallback(
    (key) => {
      return findIncentiveByKey(key).description || ''
    },
    [findIncentiveByKey],
  )

  const getIncentiveValue = useCallback((key) => incentives[key], [incentives])

  const categoryMap = {
    implantation: 'Implantação',
    expansion: 'Expansão',
    reactivation: 'Reativação',
    diversification: 'Diversificação',
    search_partner_investor: 'Busca por parceiros/investidores',
  }

  const renderIncentives = useCallback(() => {
    const incentivesKeys = Object.keys(incentives)

    return incentivesKeys.map((key) => (
      <Box
        borderBottom="1px solid #BEDDFF"
        display="flex"
        marginBottom="20px"
        paddingBottom="20px"
        flexDirection="row"
        key={key}>
        <Box>
          <BlockTitle>Sobre {getIncentiveDescription(key)}</BlockTitle>
          <Box display="flex" flexDirection="column">
            <BlockValue>{getIncentiveValue(key)}</BlockValue>
          </Box>
        </Box>
      </Box>
    ))
  }, [getIncentiveDescription, getIncentiveValue, incentives])

  const renderAddress = useCallback(() => {
    if (use_company_address) {
      return (
        <Box display="flex" flexDirection="column">
          <BlockValue>
            <b style={{fontWeight: 'bold'}}>CEP:</b> {company.postal_code}
          </BlockValue>
          <BlockValue>
            <b style={{fontWeight: 'bold'}}>Rua:</b> {company.street_name}
          </BlockValue>
          <BlockValue>
            <b style={{fontWeight: 'bold'}}>Número:</b> {company.street_number}
          </BlockValue>
          <BlockValue>
            <b style={{fontWeight: 'bold'}}>Bairro:</b> {company.district}
          </BlockValue>
          <BlockValue>
            <b style={{fontWeight: 'bold'}}>Estado:</b> {company.state}
          </BlockValue>
          <BlockValue>
            <b style={{fontWeight: 'bold'}}>Município:</b> {company.city}
          </BlockValue>
        </Box>
      )
    }

    return (
      <Box display="flex" flexDirection="column">
        <BlockValue>
          <b style={{fontWeight: 'bold'}}>CEP:</b> {postal_code}
        </BlockValue>
        <BlockValue>
          <b style={{fontWeight: 'bold'}}>Rua:</b> {street}
        </BlockValue>
        <BlockValue>
          <b style={{fontWeight: 'bold'}}>Número:</b> {number}
        </BlockValue>
        <BlockValue>
          <b style={{fontWeight: 'bold'}}>Bairro:</b> {district}
        </BlockValue>
        {city ? (
          <>
            <BlockValue>
              <b style={{fontWeight: 'bold'}}>Estado:</b> {city.state.uf}
            </BlockValue>
            <BlockValue>
              <b style={{fontWeight: 'bold'}}>Município:</b> {city.name}
            </BlockValue>
          </>
        ) : null}
      </Box>
    )
  }, [
    city,
    company.city,
    company.district,
    company.postal_code,
    company.state,
    company.street_name,
    company.street_number,
    district,
    number,
    postal_code,
    street,
    use_company_address,
  ])

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" flex={1} Chip marginBottom="20px">
        <Box marginBottom="40px">
          <Title>Revisão de dados</Title>
        </Box>
        <Box
          borderBottom="1px solid #BEDDFF"
          display="flex"
          flexDirection="column"
          marginBottom="20px"
          paddingBottom="20px">
          <BlockTitle>Tipo da oportunidade</BlockTitle>
          <BlockValue>Privada</BlockValue>
        </Box>
        <Box
          borderBottom="1px solid #BEDDFF"
          display="flex"
          business_name
          marginBottom="20px"
          paddingBottom="10px"
          flexDirection="row"
          justifyContent="space-between">
          <Box width="100%">
            <BlockTitle>Detalhes da oportunidade</BlockTitle>
            <Box display="flex" flexDirection="column">
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Nome:</b> {areaAlias}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Vinculada a:</b>{' '}
                {city ? `${city.name} - ${city.state.uf}` : 'Não Informado'}
              </BlockValue>
            </Box>
          </Box>
          <Box width="100%">
            <BlockTitle>Dados da empresa</BlockTitle>
            <Box display="flex" flexDirection="column">
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Razão Social:</b> {business_name}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Capital Social:</b> {share_capital}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>CNPJ:</b> {company_document}
              </BlockValue>
              <BlockValue style={{maxWidth: '376px'}}>
                <b style={{fontWeight: 'bold'}}>Atividade Econômica (CNAE Primário):</b> {main_activity}
              </BlockValue>
            </Box>
          </Box>
        </Box>
        <Box
          borderBottom="1px solid #BEDDFF"
          display="flex"
          marginBottom="20px"
          paddingBottom="10px"
          flexDirection="row"
          justifyContent="space-between">
          <Box width="100%">
            <BlockTitle>Endereço da oportunidade</BlockTitle>
            {renderAddress()}
          </Box>
          <Box width="100%">
            <BlockTitle>Contato</BlockTitle>
            <Box display="flex" flexDirection="column">
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Nome para contato:</b> {contact.name}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Cargo:</b> {contact.role}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Celular:</b> {contact.cellphone}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Telefone:</b> {contact.phone}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>E-mail:</b> {contact.email}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Site:</b> {contact.site}
              </BlockValue>
            </Box>
          </Box>
        </Box>
        <Box
          borderBottom="1px solid #BEDDFF"
          display="flex"
          marginBottom="20px"
          paddingBottom="20px"
          flexDirection="row">
          <Box>
            <BlockTitle>Descrição do projeto</BlockTitle>
            <Box display="flex" flexDirection="column">
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Categoria da oportunidade:</b>{' '}
                {categoryMap[project_category] ? categoryMap[project_category] : ' - '}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Valor total do investimento a ser realizado:</b>{' '}
                {formatCurrency(total_investment)}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Valor de necessidade do investimento:</b>{' '}
                {formatCurrency(required_investment)}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Breve descrição do projeto:</b> <br /> {private_proposal_description}
              </BlockValue>
            </Box>
          </Box>
        </Box>
        {renderIncentives()}
        {attachments.length ? (
          <Box
            display="flex"
            marginBottom="20px"
            paddingBottom="20px"
            flexDirection="row"
            justifyContent="space-between">
            <Box marginRight="20px">
              <BlockTitle>anexos</BlockTitle>
              <Box display="flex" flexDirection="column">
                {attachments.map((atachment) => (
                  <Box key={atachment.original_name} marginBottom="10px" marginRight="10px">
                    <Chip
                      label={atachment.original_name}
                      size="small"
                      variant="outlined"
                      style={{
                        color: '#8C8C8C',
                        borderRadius: 10,
                        background: '#1E7BE10C',
                        borderColor: '#1E7BE10C',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}>
          Concluir cadastro
        </Button>
      </Box>
    </Container>
  )
}

export default ProposalConfirm
