import {Checkbox} from '@material-ui/core'
import {changeActiveColumns, changeActiveSort} from 'app/modules/Companies/redux'
import * as selectors from 'app/modules/Companies/redux/selectors'
import React, {useCallback, useRef, useState} from 'react'
import {ListGroup, Overlay, Popover} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import * as columnUtils from '../utils'
import {ActionIcon, OrderContainer} from './styles'

const StyledPopover = styled(Popover)`
  max-height: 300px;
  overflow-y: scroll;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
`

const TableHead = () => {
  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const dispatch = useDispatch()

  const popoverRef = useRef(null)

  const columns = useSelector(selectors.getCompaniesColumns)
  const orders = useSelector(selectors.companiesOrders)
  const activeOrder = useSelector(selectors.getActiveOrder)

  const onChangeOrdering = useCallback(
    ({columnKey}) => {
      let updatedOrder = 'ASC'

      if (columnKey === activeOrder?.key) {
        updatedOrder = activeOrder?.order === 'DESC' ? 'ASC' : 'DESC'
      }

      dispatch(
        changeActiveSort({
          key: columnKey,
          order: updatedOrder,
        }),
      )
    },
    [activeOrder, dispatch],
  )

  const handlePopoverClick = (event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }

  const onToogleColumn = useCallback(
    (selectedColumn, isActive) => {
      let updatedColumns = columns.active

      if (isActive) {
        updatedColumns = updatedColumns.filter(({key}) => key !== selectedColumn.key)
      } else {
        // updatedColumns = [...columns.active, selectedColumn]
        const columnIndex = columns.available.map(({key}) => key).indexOf(selectedColumn.key)

        updatedColumns.splice(columnIndex, 0, selectedColumn)
      }

      dispatch(changeActiveColumns(updatedColumns))
    },
    [columns.active, columns.available, dispatch],
  )

  const renderPopOverItem = useCallback(
    (column) => {
      const isActive = columnUtils.isColumnActive({
        column,
        stateColumns: columns,
      })

      return (
        <ListGroup.Item
          key={`k=${column.key}`}
          onClick={() => onToogleColumn(column, isActive)}
          style={{cursor: 'pointer', padding: '0.5rem'}}>
          <Checkbox checked={isActive} />
          {column.label}
        </ListGroup.Item>
      )
    },
    [columns, onToogleColumn],
  )

  const renderColumn = useCallback(
    ({key: columnKey, label}) => {
      const fieldOrder = columnUtils.fieldOrder({
        columnKey,
        availableOrders: orders,
      })

      if (fieldOrder) {
        const isOrderActive = activeOrder?.key === fieldOrder
        let chevron = 'down'

        if (activeOrder?.key === fieldOrder) {
          chevron = activeOrder?.order === 'DESC' ? 'down' : 'up'
        }

        return (
          <th key={columnKey} scope="col">
            <OrderContainer isOrderActive={isOrderActive} onClick={() => onChangeOrdering({columnKey})}>
              <span className="text-uppercase">{label}</span>
              <i className={`fas fa-chevron-${chevron}`}></i>
            </OrderContainer>
          </th>
        )
      }

      return (
        <th scope="col">
          <span className="text-uppercase">{label}</span>
        </th>
      )
    },
    [activeOrder, onChangeOrdering, orders],
  )

  return (
    <thead>
      <tr>
        {/* {columnUtils.shouldRenderColumn({ columnKey: "id", stateColumns: columns }) && (
          <th scope="col">
            <span className="text-uppercase">#</span>
          </th>
        )} */}
        {columnUtils.shouldRenderColumn({columnKey: 'social_reason', stateColumns: columns}) &&
          renderColumn({key: 'razao_social', label: 'Razão Social'})}
        {columnUtils.shouldRenderColumn({columnKey: 'fanstasy_name', stateColumns: columns}) &&
          renderColumn({key: 'nome_fantasia', label: 'Nome Fantasia'})}
        {columnUtils.shouldRenderColumn({columnKey: 'cnae_primary', stateColumns: columns}) &&
          renderColumn({key: 'cnae_primary', label: 'CNAE primário'})}
        {columnUtils.shouldRenderColumn({columnKey: 'foundation', stateColumns: columns}) &&
          renderColumn({key: 'foundation', label: 'Fundação'})}
        {columnUtils.shouldRenderColumn({columnKey: 'social_capital', stateColumns: columns}) &&
          renderColumn({key: 'capital_social', label: 'Capital Social'})}
        {columnUtils.shouldRenderColumn({columnKey: 'city', stateColumns: columns}) &&
          renderColumn({key: 'municipio', label: 'Município'})}
        {columnUtils.shouldRenderColumn({columnKey: 'uf', stateColumns: columns}) &&
          renderColumn({key: 'uf', label: 'UF'})}

        <th scope="col">
          <span className="text-uppercase">Ações</span>
        </th>
        <th scope="col" ref={popoverRef}>
          <ActionIcon className="fa fa-ellipsis-v" aria-hidden="true" onClick={handlePopoverClick} />
          <Overlay
            show={showPopover}
            ref={popoverRef}
            target={popoverTarget}
            container={popoverRef.current}
            placement="bottom"
            rootClose={true}
            onHide={() => setShowPopover(false)}>
            <StyledPopover id="popover-basic">
              <Popover.Content>
                <ListGroup variant="flush">{columns.available.map(renderPopOverItem)}</ListGroup>
              </Popover.Content>
            </StyledPopover>
          </Overlay>
        </th>
      </tr>
    </thead>
  )
}

export default TableHead
