import httpClient from '../../client'

import apiRoutes from '../constants'

const getActivities = async ({page, limit, ...rest}) => {
  try {
    const url = apiRoutes.GET_ACTIVITIES
    const {data} = await httpClient.get(url, {params: {limit, page, ...rest}})

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling companies with ${JSON.stringify({limit, page})}`, error)

    return error
  }
}

export default getActivities
