import React, {useEffect, useCallback, useState} from 'react'
import {useParams} from 'react-router-dom'
import {proposalService} from 'app/services'
import {useDispatch} from 'react-redux'

import BasicProposalInfo from './BasicInfo'
import ProposalLocation from './Location'
import ProposalContact from './Contact'
import ProposalAttachments from './Attachments'
import AddressAndData from './AddressAndData'
import {changeActualEntity} from 'app/modules/Cities/redux'

import DetailProposalSkeleton from './skeleton'
import Benefits from './Benefits'
import Relateds from './Relateds'

const DetailProposal = () => {
  const [loading, setLoading] = useState(false)
  const [proposal, setProposal] = useState()
  const [coordinates, setCoordinates] = useState(null)
  const dispatch = useDispatch()

  const {proposalId} = useParams()

  const fetchProposal = useCallback(async () => {
    try {
      setLoading(true)
      const data = await proposalService.getProposal(proposalId)
      let coordinates = []
      const statusMap = {
        completed: 'Ganha / Concretizada',
        lost: 'Perdida',
        open: 'Ativa',
      }
      if (data?.coordinates) {
        var statusDesc = statusMap[data.status]

        let jsonGeo = {
          geometry: {
            coordinates: [],
            type: 'Point',
          },
          properties: {
            id: data.id,
            name: data.name,
            type: 'proposal',
            status: data.status,
            status_desc: statusDesc,
          },
          type: 'Feature',
        }
        jsonGeo.geometry.coordinates.push(parseFloat(data.coordinates[0]))
        jsonGeo.geometry.coordinates.push(parseFloat(data.coordinates[1]))
        coordinates.push(jsonGeo)

        setCoordinates({type: 'FeatureCollection', features: coordinates})
      }
      setProposal(data)
      setLoading(false)
      dispatch(changeActualEntity({id: data.id, name: 'Oportunidade #' + data.id}))
    } catch (e) {
      setLoading(false)
    }
  }, [dispatch, proposalId])

  useEffect(() => {
    fetchProposal()
  }, [fetchProposal])

  if (loading) {
    return <DetailProposalSkeleton />
  }

  return proposal ? (
    <div className="d-flex flex-column container-citymatch-table-cards">
      <BasicProposalInfo {...proposal} />
      {proposal.coordinates && proposal.coordinates.length ? (
        <ProposalLocation {...proposal} newCoordinates={coordinates} />
      ) : null}
      {proposal.project ? <AddressAndData {...proposal} /> : null}
      {proposal.type === 'company' && <Relateds CNPJ={proposal?.project?.company?.federal_tax_id} />}
      {proposal.benefits.length ? <Benefits {...proposal} /> : null}
      <ProposalContact {...proposal} />
      {<ProposalAttachments {...proposal} />}
    </div>
  ) : null
}

export default DetailProposal
