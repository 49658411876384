import React from 'react'
import {Paper, ContainerHeader, DetailContainer} from '../styles'
import {useHistory} from 'react-router-dom'
import {proposalService} from 'app/services'

import Map from 'app/components/map/Map'
import {Box, Grid} from '@material-ui/core'
import {Button} from 'react-bootstrap'

import {Container} from '../styles'

const ProposaLocation = ({coordinates, location, onSubmit, submiting, handleDrawIndustrialArea}) => {
  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Paper id="location">
            <ContainerHeader>Área Industrial do Município</ContainerHeader>
            <DetailContainer style={{padding: 0}}>
              {coordinates && coordinates.length && (
                <Map
                  customStyle={{
                    borderRadius: 0,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    width: '100%',
                    margin: 0,
                  }}
                  coordinates={coordinates}
                  zoomIn={13}
                  terrain="satelite"
                  controlOnly="draw"
                  drawArea={location?.industrial_area?.geojson}
                  onChangeDraw={handleDrawIndustrialArea}
                />
              )}
            </DetailContainer>
          </Paper>
        </Grid>
        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ProposaLocation
