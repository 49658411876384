import React from 'react'

import Shimmer from 'react-loading-skeleton'

import {Container, TotalsCounter} from './styles'

const Skeleton = () => (
  <Container>
    <Shimmer width="100px" height="20px" />
    <TotalsCounter>
      <Shimmer width="80px" height="10px" />
    </TotalsCounter>
  </Container>
)

export default Skeleton
