import {Box, Checkbox} from '@material-ui/core'
import {customerService} from 'app/services'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import * as columnUtils from '../utils'

const Row = ({id, name, is_active, type, columns, filters = {}}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [active, setActive] = useState(is_active)

  const toggleActive = async (event) => {
    const lastChecked = active
    try {
      setActive(event.target.checked)
      await customerService.saveCustomer(id, {is_active: event.target.checked})
      enqueueSnackbar('Cliente atualizado', {
        variant: 'success',
      })
    } catch (err) {
      setActive(lastChecked)
      enqueueSnackbar(`Não foi possível atualizar o cliente ${name}, tente novamente!`, {
        variant: 'error',
      })
    }
  }

  const shouldRenderRow = () => {
    if (filters.name && !name.toLowerCase().includes(filters.name.toLowerCase())) {
      return false
    }
    if (filters.type && type !== filters.type) {
      return false
    }
    return true
  }

  return (
    shouldRenderRow() && (
      <tr>
        {columnUtils.shouldRenderColumn({columnKey: 'name', stateColumns: columns}) && (
          <td className="align-middle" style={{fontFamily: 'Nunito Sans', fontWeight: 'bold', fontSize: 14}}>
            {name}
          </td>
        )}
        {columnUtils.shouldRenderColumn({columnKey: 'type', stateColumns: columns}) && (
          <td className="align-middle" style={{fontFamily: 'Nunito Sans', fontWeight: 'bold', fontSize: 14}}>
            {type === 'city' ? 'Cidade' : type === 'state' ? 'Governo' : type === 'company' ? 'Empresa' : ''}
          </td>
        )}
        {columnUtils.shouldRenderColumn({columnKey: 'is_active', stateColumns: columns}) && (
          <td className="align-middle">
            <Checkbox checked={active} onChange={toggleActive} />
          </td>
        )}
        {columnUtils.shouldRenderColumn({columnKey: 'actions', stateColumns: columns}) && (
          <td className="align-middle">
            <Box display="flex" justifyContent="center">
              <Link to={`/manage-customers/${id}`}>
                <button type="button" className="btn btn-outline-primary">
                  Editar
                </button>
              </Link>
            </Box>
          </td>
        )}
      </tr>
    )
  )
}

export default Row
