import React from 'react'

const Icon = ({color, width}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : '42'} height="42" viewBox="0 0 128 128">
    <g>
      <path
        opacity="1"
        fill={color}
        d="M 41.5,16.5 C 42.498,45.3285 42.8314,74.3285 42.5,103.5C 36.428,105.023 30.428,106.857 24.5,109C 21.3307,109.978 18.4973,109.478 16,107.5C 15.3333,80.5 15.3333,53.5 16,26.5C 24.4304,22.8591 32.9304,19.5258 41.5,16.5 Z"
      />
    </g>
    <g>
      <path
        opacity="0.3"
        fill={color}
        d="M 52.5,16.5 C 59.6954,19.2316 67.0287,21.565 74.5,23.5C 74.6666,52.5019 74.5,81.5019 74,110.5C 66.9975,107.666 59.8308,105.333 52.5,103.5C 52.5,74.5 52.5,45.5 52.5,16.5 Z"
      />
    </g>
    <g>
      <path
        opacity="1"
        fill={color}
        d="M 102.5,17.5 C 105.685,17.0196 108.519,17.6863 111,19.5C 111.667,46.5 111.667,73.5 111,100.5C 102.524,104.216 93.857,107.55 85,110.5C 84.5,81.5019 84.3334,52.5019 84.5,23.5C 90.784,22.072 96.784,20.072 102.5,17.5 Z"
      />
    </g>
  </svg>
)

export default Icon
