import {Box} from '@material-ui/core'
import {Circular as CircularLoading} from 'app/components'
import {useFavorite} from 'app/hooks'
import React, {useCallback, useRef, useState} from 'react'
import {Button, Card, ListGroup, Overlay, Popover} from 'react-bootstrap'
import {Edit, Menu, Star} from 'react-feather'
import {useHistory} from 'react-router-dom'

import {Actions, Container, CustomerHeader, CustomerName, FavoriteIcon, StyledPopover} from './styles'

function CustomerCard({customer}) {
  const {name, id, is_favorite, has_permission_to_edit} = customer

  const popoverRef = useRef(null)
  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite: is_favorite,
    entity: 'customer',
    label: 'Cliente',
  })

  const handlePopoverClick = useCallback((event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }, [])

  const history = useHistory()

  const onDetailClick = useCallback(() => {
    history.push(`/customers/${id}`)
  }, [history, id])

  const goToCustomerDetails = useCallback(
    (customerId) => {
      history.push(`/customers/${customerId}`)
    },
    [history],
  )

  return (
    <Card
      style={{
        maxWidth: '485px',
        marginRight: '10px',
        marginLeft: '10px',
        marginBottom: '20px',
        padding: '23px 31px',
        borderRadius: 10,
        flex: '1 0 25%',
      }}>
      <Card.Header style={{borderBottom: 'none', padding: 0}} className="d-flex flex-wrap">
        <CustomerHeader>
          <div style={{width: '100%', display: 'flex', whiteSpace: 'nowrap', fontFamily: 'Nunito Sans'}}>
            <span style={{fontWeight: '600', color: '#000000'}}>{name}</span>
          </div>
          <Actions>
            {loadingFavorite ? (
              <CircularLoading />
            ) : (
              <FavoriteIcon
                aria-hidden="true"
                className="fa fa-star"
                isFavorite={isFavorite}
                onClick={() => handleFavoriteOpportunity()}
              />
            )}
            <i
              className="fa fa-ellipsis-h"
              style={{cursor: 'pointer'}}
              onClick={handlePopoverClick}
              aria-hidden="true"></i>
            <Overlay
              show={showPopover}
              ref={popoverRef}
              target={popoverTarget}
              container={popoverRef.current}
              placement="bottom"
              rootClose={true}
              onHide={() => setShowPopover(false)}>
              <StyledPopover>
                <Popover.Content>
                  <ListGroup variant="flush">
                    <ListGroup.Item onClick={() => goToCustomerDetails(id)}>
                      <Menu className="icon-menu" /> Ver detalhes
                    </ListGroup.Item>

                    <ListGroup.Item onClick={() => handleFavoriteOpportunity()}>
                      <Star className="icon-menu" />
                      {isFavorite ? 'Remover dos favoritos' : 'Salvar em favoritos'}
                    </ListGroup.Item>

                    {has_permission_to_edit && (
                      <ListGroup.Item onClick={() => history.push(`/customers/${id}/edit`)}>
                        <Edit className="icon-menu" />
                        Editar
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Popover.Content>
              </StyledPopover>
            </Overlay>
          </Actions>
        </CustomerHeader>
      </Card.Header>
      <Card.Body style={{padding: 0}}>
        <Box paddingLeft="10px" paddingRight="10px">
          <Container>
            <Button variant="primary" style={{float: 'right', marginTop: '25px'}} onClick={onDetailClick}>
              DETALHES
            </Button>
          </Container>
        </Box>
      </Card.Body>
    </Card>
  )
}

export default CustomerCard
