import React from 'react'

import {availableColums} from 'app/modules/ManageCustomer/redux'

import * as columnUtils from '../utils'

const TableHead = () => {
  const columns = availableColums

  return (
    <thead>
      <tr>
        {columnUtils.shouldRenderColumn({columnKey: 'name', stateColumns: columns}) && (
          <th scope="col">
            <span className="text-uppercase">Nome</span>
          </th>
        )}
        {columnUtils.shouldRenderColumn({columnKey: 'type', stateColumns: columns}) && (
          <th scope="col">
            <span className="text-uppercase">Tipo</span>
          </th>
        )}
        {columnUtils.shouldRenderColumn({columnKey: 'is_active', stateColumns: columns}) && (
          <th scope="col">
            <span className="text-uppercase">Ativo</span>
          </th>
        )}
      </tr>
    </thead>
  )
}

export default TableHead
