import {Box} from '@material-ui/core'
import getCities from 'app/services/cities/getCities'
import getStates from 'app/services/states/getStates'
import React, {useEffect, useState} from 'react'

import {Label} from './styles'

const StateCitySelect = ({
  changeState,
  changeCity,
  showCustomerOnly,
  city,
  cityProps,
  stateProps,
  disabled,
  width,
  placeholder,
  showSelects,
}) => {
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [loading, setLoading] = useState({
    state: true,
    city: false,
  })

  useEffect(() => {
    setLoading({...loading, state: true})
    getStates({
      limit: 30,
      only_customer: showCustomerOnly,
    })
      .then((res) => setStates(res.data))
      .then(() => setLoading({...loading, state: false}))
      .then(() => {
        if (city && showSelects) {
          onChangeState(city.state.id)
        }
      })
  }, [])

  const onChangeState = (stateId) => {
    setLoading({...loading, city: true})
    const selectedState = states.filter((state) => state.id === parseInt(stateId))[0]
    if (changeState && selectedState) {
      changeState(selectedState)
    }

    getCities({
      ufs: stateId,
      limit: 1000,
      only_customer: showCustomerOnly,
      sort: {name: 'asc'},
    })
      .then((res) => setCities(res.data))
      .then(() => setLoading({...loading, city: false}))
  }

  const onChangeCity = (cityId) => {
    const selectedCity = cities.filter((city) => city.id === parseInt(cityId))[0]
    if (changeCity && selectedCity) {
      changeCity(selectedCity)
    }
  }

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column" marginRight="22px" width={width}>
        {placeholder && placeholder.state ? null : <Label>Estado</Label>}

        {city && !showSelects ? (
          <span style={{fontSize: 16}}>{city.state.name}</span>
        ) : (
          <select
            className="form-control"
            name="state"
            value={stateProps}
            disabled={states.length === 0 || disabled}
            onChange={(ev) => onChangeState(ev.target.value)}>
            <option>
              {loading.state ? 'Aguarde...' : placeholder && placeholder.state ? placeholder.state : 'Selecionar'}
            </option>
            {states.map((state) => (
              <option key={state.id} value={state.id} selected={city && city.state.id === state.id}>
                {state.name}
              </option>
            ))}
          </select>
        )}
      </Box>
      <Box display="flex" flexDirection="column" width={width}>
        {placeholder && placeholder.city ? null : <Label>Município</Label>}
        {city && !showSelects ? (
          <span style={{fontSize: 16}}>{city.name}</span>
        ) : (
          <select
            className="form-control"
            name="city"
            value={cityProps}
            disabled={states.length === 0 || cities.length === 0 || disabled}
            onChange={(ev) => onChangeCity(ev.target.value)}>
            <option>
              {loading.city ? 'Aguarde...' : placeholder && placeholder.city ? placeholder.city : 'Selecionar'}
            </option>
            {cities.map((cit) => (
              <option key={cit.id} value={cit.id} selected={city && city.id === cit.id}>
                {cit.name}
              </option>
            ))}
          </select>
        )}
      </Box>
    </Box>
  )
}

export default StateCitySelect
