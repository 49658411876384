import React, {useState, useEffect} from 'react'
import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {Button, Form} from 'react-bootstrap'
import ReactInputMask from 'react-input-mask'

import {Container, Title, Label} from './styles'

import {statesService, citieService} from 'app/services'

const types = ['city', 'state', 'company']

const CreateNewCustomer = ({formik, onFinish}) => {
  const {
    handleChange,
    setFieldValue,
    values: {id, name, type, state, city, company},
  } = formik

  const [loading, setLoading] = useState(true)
  const [displayState, setDisplayState] = useState(false)
  const [displayCity, setDisplayCity] = useState(false)
  const [displayCompany, setDisplayCompany] = useState(false)
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(true)

  const [stateValue, setStateValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  useEffect(() => {
    if (id) {
      if (!loading) {
        if (type === 'state') {
          setStateValue(states.find((s) => s.id === state))
          setDisplayState(true)
          setDisplayCity(false)
          setDisplayCompany(false)
        }
        if (type === 'city') {
          setCityValue('')
          loadCities(state)
          setStateValue(states.find((s) => s.id === state))
          setDisplayState(true)
          setDisplayCity(true)
          setDisplayCompany(false)
        }
      }
      if (type === 'company') {
        setFieldValue('federal_tax_id', company.federal_tax_id)
        setDisplayState(false)
        setDisplayCity(false)
        setDisplayCompany(true)
      }
    }
  }, [loading])

  useEffect(() => {
    const loadStates = async () => {
      try {
        const {data} = await statesService.getStates()
        setStates(data)
        setLoading(false)
      } catch (error) {
        console.error('Error loading states:', error)
      }
    }

    loadStates()
  }, [])

  useEffect(() => {
    if (name && type && (state || !displayState) && (city || !displayCity) && (company.federal_tax_id  || !displayCompany) && !loading) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [name, type, state, city, company, displayCity, displayCompany, loading])

  const loadCities = async (state_id) => {
    try {
      setCities([])
      if (state_id) {
        const {data} = await citieService.getCities({
          limit: 800,
          name: '',
          ufs: state_id,
          sort: {name: 'asc'},
        })
        
        setCities(data)
        if (city) {
          setCityValue(data.find((c) => c.id === city))
        }
        setLoading(false)
      }
    } catch (error) {
      console.error('Error loading cities:', error)
    }
  }

  const handleTypeChange = (event) => {
    if (event.target.value === 'state') {
      setDisplayState(true)
      setDisplayCity(false)
      setDisplayCompany(false)
    }
    if (event.target.value === 'city') {
      setDisplayState(true)
      setDisplayCity(true)
      setDisplayCompany(false)
    }
    if (event.target.value === 'company') {
      setDisplayState(false)
      setDisplayCity(false)
      setDisplayCompany(true)
    }
    handleChange(event)
  }

  const handleStateChange = (event) => {
    loadCities(event.target.value)
    handleChange(event)
  }

  const handleCityChange = (event) => {
    handleChange(event)
  }

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" marginBottom="40px">
        <Box marginBottom="20px">
          <Title>{id ? "Editar cliente" : "Novo cliente"}</Title>
        </Box>
        {formik.errors && (
          <Box display="flex" flexDirection="column" marginBottom="40px">
            {Object.keys(formik.errors).map((key) => (
              <Label key={key} style={{ color: 'red' }}>
                {formik.errors[key]}
              </Label>
            ))}
          </Box>
        )}
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Nome</Label>
          <Form.Control
            placeholder="Ex.: Example example"
            name="name"
            onChange={handleChange}
            value={name}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Tipo</Label>
          <FormControl color="secondary">
            <InputLabel id="type-label">Tipo</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              name="type"
              value={type}
              onChange={handleTypeChange}
              style={{width: '100%'}}>
              {types.map((name) => (
                <MenuItem key={name} value={name}>
                  {name === 'city' ? 'Cidade' : name === 'state' ? 'Governo' : name === 'company' ? 'Company' : ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {displayState && (
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Estado</Label>
            <FormControl color="secondary">
              <InputLabel id="state-label">Estado</InputLabel>
              <Autocomplete
                value={stateValue}
                options={states}
                getOptionLabel={(state) => state.name}
                noOptionsText="Nenhum item encontrado"
                onChange={(event, value) => { 
                  event.target = { value: value?.id, name: "state" }; 
                  handleStateChange(event);
                  setStateValue(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Estado"
                    value={state}
                  />
                )}
              />
            </FormControl>
          </Box>
        )}

        {displayCity && (
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>Cidade</Label>
            <FormControl color="secondary">
              <InputLabel id="city-label">Cidade</InputLabel>
              <Autocomplete
                value={cityValue}
                options={cities}
                getOptionLabel={(city) => city.name}
                noOptionsText="Nenhum item encontrado"
                onChange={(event, value) => { 
                  event.target = { value: value?.id, name: "city" }; 
                  handleCityChange(event);
                  setCityValue(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    value={city}
                  />
                )}
              />
            </FormControl>
          </Box>
        )}

        {displayCompany && (
          <Box display="flex" flexDirection="column" marginBottom="40px">
            <Label>CNPJ</Label>
            <ReactInputMask
                autoComplete="off"
                className="form-control"
                name="company.federal_tax_id"
                id="company.federal_tax_id"
                value={company.federal_tax_id}
                onChange={handleChange}
                placeholder="99.999.999/9999-99"
                mask="99.999.999/9999-99"
              />
          </Box>
        )}
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button type="submit" onClick={onFinish} disabled={disableSubmit}>
          {id ? "Salvar" : "Cadastrar"}
        </Button>
      </Box>
    </Container>
  )
}

export default CreateNewCustomer
