import Map from 'app/components/map/Map'
import React from 'react'

import {ContainerHeader, DetailContainer, Paper} from '../styles'

const ProposaLocation = ({coordinates, name, state, newCoordinates}) => {
  return (
    <Paper id="location">
      <ContainerHeader>Localização do município</ContainerHeader>
      <DetailContainer style={{padding: 0}}>
        {coordinates && coordinates.length && (
          <Map
            coordinates={newCoordinates}
            centerMap={coordinates}
            zoomIn={13}
            noFitBounds
            customStyle={{
              borderRadius: 0,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              width: '100%',
              margin: 0,
            }}
          />
        )}
      </DetailContainer>
    </Paper>
  )
}

export default ProposaLocation
