import {Table} from 'app/modules/ManageUser/components'
import ProposalPagination from 'app/modules/ManageUser/components/Table/Pagination'
import {changeLimit, changePage} from 'app/modules/ManageUser/redux'
import {getCurrentLimit, getCurrentUsersPage} from 'app/modules/ManageUser/redux/selectors'
import {customerService, userService} from 'app/services'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

const DEFAULT_LIMIT = 25

const ListUsers = () => {
  const [users, setUsers] = useState()
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingCustomers, setLoadingCustomers] = useState(true)
  const {enqueueSnackbar} = useSnackbar()
  const [customers, setCustomers] = useState()

  const page = useSelector(getCurrentUsersPage)
  const limit = useSelector(getCurrentLimit)?.current ?? DEFAULT_LIMIT

  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true)

      const {data, total} = await userService.getListUsers({limit, page: parseInt(page) + 1})

      setTotal(total)
      setUsers(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setUsers([])

      enqueueSnackbar('Ocorreu um erro ao consultar as empresas', {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, limit, page])

  const fetchCustomers = useCallback(async () => {
    try {
      setLoadingCustomers(true)

      const {data} = await customerService.getListCustomers()

      setCustomers(data)
      setLoadingCustomers(false)
    } catch (error) {
      setLoadingCustomers(false)
      setCustomers([])

      enqueueSnackbar('Ocorreu um erro ao consultar os clientes', {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar])

  useEffect(() => {
    fetchCustomers()
    fetchUsers()
  }, [fetchCustomers, fetchUsers, page, limit])

  return (
    <>
      <div className="container-citymatch-table">
        <div className="table-responsive" style={{overflowX: 'auto'}}>
          <Table
            loading={loading}
            loadingCustomers={loadingCustomers}
            users={users}
            total={total}
            customers={customers}
          />
        </div>
        <ProposalPagination
          loading={loading}
          total={total}
          limit={limit}
          page={page}
          changeLimit={changeLimit}
          changePage={changePage}
        />
      </div>
    </>
  )
}

export default ListUsers
