import {Grid} from '@material-ui/core'
import React, {useCallback, useMemo} from 'react'

import {ContainerHeader, DetailContainer, Paper} from '../styles'

const DemographicData = ({demographicData}) => {
  const demoDataList = useMemo(() => {
    const listSize = demographicData.length
    if (listSize <= 0) {
      return []
    }

    const chunkSize = Math.ceil(listSize / 2)
    return arrayChunk(demographicData, chunkSize)
  }, [demographicData])

  function arrayChunk(array, chunkSize) {
    if (chunkSize <= 0) {
      return []
    }

    return array.reduce((acc, _, index) => {
      if (index % chunkSize === 0) {
        acc.push(array.slice(index, index + chunkSize))
      }
      return acc
    }, [])
  }

  return (
    <Paper>
      <ContainerHeader>Informações Sobre o Estado</ContainerHeader>
      <DetailContainer>
        <Grid container>
          {demoDataList.map((chunk, chunkIndex) => (
            <Grid key={chunkIndex} item lg={6} style={{display: 'flex', justifyContent: 'flex-start'}}>
              <table className="table demodata" style={{marginRight: '40px'}}>
                {chunk.map((demoData, demoIndex) => (
                  <tr key={demoIndex}>
                    <td>
                      <label>
                        <b style={{fontSize: 14}}>{demoData.label}</b>
                      </label>
                    </td>{' '}
                    <td style={{textAlign: 'right'}}>{demoData.value}</td>
                  </tr>
                ))}
              </table>
            </Grid>
          ))}
        </Grid>
      </DetailContainer>
    </Paper>
  )
}

export default DemographicData
