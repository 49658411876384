import {useMap} from 'app/hooks'
import objectPath from 'object-path'
import React, {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

import {AnimateLoading} from '../../_partials/controls'
// LayoutContext
import {useHtmlClassService} from '../_core/MetronicLayout'
import {Aside} from './aside/Aside'
import {QuickUser} from './extras/offcanvas/QuickUser'
import {ScrollTop} from './extras/ScrollTop'
import {Footer} from './footer/Footer'
// Import Layout components
import {Header} from './header/Header'
import {HeaderMobile} from './header-mobile/HeaderMobile'
import {LayoutInit} from './LayoutInit'
import {SubHeader} from './subheader/SubHeader'
import {SubHeaderCity} from './subheader/SubHeaderCity'
import {SubHeaderCompany} from './subheader/SubHeaderCompany'
import {SubHeaderCustomer} from './subheader/SubHeaderCustomer'
import {SubHeaderState} from './subheader/SubHeaderState'

export function Layout({children}) {
  const uiService = useHtmlClassService()

  const location = useLocation()

  const path = location.pathname.substring(1)

  const {changeViewMode, showMap, toogleMap, viewMode} = useMap(path)

  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    }
  }, [uiService])

  const showCityHeader = useMemo(() => {
    const routes = ['cities']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showStateHeader = useMemo(() => {
    const routes = ['states']
    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showCompanyHeader = useMemo(() => {
    const routes = ['companies']

    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const showCustomerHeader = useMemo(() => {
    const routes = ['manage-customers']

    return routes.includes(location.pathname.substring(1) || '')
  }, [location.pathname])

  const onMapClick = () => {
    toogleMap()
  }

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      <AnimateLoading />

      {/*begin::Main*/}
      <HeaderMobile />

      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}>
              {layoutProps.subheaderDisplay &&
                (showCityHeader ? (
                  <SubHeaderCity
                    showMap={showMap}
                    viewMode={viewMode}
                    onMapClick={onMapClick}
                    onChangeViewMode={(newMode) => changeViewMode(newMode)}
                  />
                ) : showStateHeader ? (
                  <SubHeaderState
                    showMap={showMap}
                    viewMode={viewMode}
                    onMapClick={onMapClick}
                    onChangeViewMode={(newMode) => changeViewMode(newMode)}
                  />
                ) : showCompanyHeader ? (
                  <SubHeaderCompany
                    showMap={showMap}
                    viewMode={viewMode}
                    onMapClick={onMapClick}
                    onChangeViewMode={(newMode) => changeViewMode(newMode)}
                  />
                ) : showCustomerHeader ? (
                  <SubHeaderCustomer
                    showMap={showMap}
                    viewMode={viewMode}
                    onMapClick={onMapClick}
                    onChangeViewMode={(newMode) => changeViewMode(newMode)}
                  />
                ) : (
                  <SubHeader
                    showMap={showMap}
                    viewMode={viewMode}
                    onMapClick={onMapClick}
                    onChangeViewMode={(newMode) => changeViewMode(newMode)}
                  />
                ))}
              {/*begin::Entry*/}
              {layoutProps.contentExtended && (
                <>
                  {layoutProps.asideDisplay && (
                    <div className="d-lg-flex flex-row-fluid">
                      <Aside />
                      <div className="content-wrapper">{children}</div>
                    </div>
                  )}

                  {!layoutProps.asideDisplay && <>{children}</>}
                </>
              )}

              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {layoutProps.asideDisplay && (
                      <div className="d-lg-flex flex-row-fluid">
                        <Aside />
                        <div className="content-wrapper flex-row-fluid">{children}</div>
                      </div>
                    )}
                    {!layoutProps.asideDisplay && <>{children}</>}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      {/* <QuickPanel /> */}
      <ScrollTop />
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  )
}
