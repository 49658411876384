import {createSelector} from '@reduxjs/toolkit'

export const getPoliticiansSelector = (state) => state.politicians

export const getCurrentPoliticiansPage = createSelector(getPoliticiansSelector, ({page}) => page)

export const getPoliticiansColumns = createSelector(getPoliticiansSelector, ({columns}) => columns)

export const hasLoadedOrdering = createSelector(
  getPoliticiansSelector,
  ({filters}) => !!filters.ordering?.orders?.length,
)

export const PoliticiansOrders = createSelector(getPoliticiansSelector, ({filters}) => filters.ordering.orders)

export const getActiveOrder = createSelector(getPoliticiansSelector, ({filters}) => filters.ordering.activeSort)

export const showMap = createSelector(getPoliticiansSelector, ({universalSearch}) => universalSearch.showMap)

export const universalSearch = createSelector(getPoliticiansSelector, ({universalSearch}) => universalSearch)

export const getCurrentLimit = createSelector(getPoliticiansSelector, ({limit}) => limit)

export const getSearchParams = createSelector(getPoliticiansSelector, ({universalSearch}) => ({
  hasAppliedFilters: universalSearch.hasAppliedFilters,
  searchParams: universalSearch.searchParams,
  showOnlyFavorites: universalSearch.showOnlyFavorites,
}))
