import React from 'react'

const Icon = ({color, width}) => (
  <svg
    id="icon_detalhes"
    data-name="icon/detalhes"
    xmlns="http://www.w3.org/2000/svg"
    width={width ? width : '42'}
    height="42"
    viewBox="0 0 42 42">
    <path id="bound" d="M0,0H42V42H0Z" fill="none" />
    <g id="shapes" transform="translate(4 5.931)">
      <path
        id="check"
        d="M4.881,9.763a1.606,1.606,0,0,1-1.139-.488L.488,6.02a1.573,1.573,0,0,1,0-2.278,1.572,1.572,0,0,1,2.278,0L4.881,5.858l5.37-5.37a1.573,1.573,0,0,1,2.278,0,1.573,1.573,0,0,1,0,2.278L6.02,9.275A1.606,1.606,0,0,1,4.881,9.763Z"
        transform="translate(0 20.375)"
        fill={color}
        opacity="0.3"
      />
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M0,15.052V5.094A5.094,5.094,0,0,1,5.094,0h23.77a5.094,5.094,0,0,1,5.094,5.094V20.375q0,.008,0,.017L34,20.375v7.786a1.7,1.7,0,0,1-2.9,1.2l-3.893-3.893h-8.56A12.739,12.739,0,0,0,0,15.052Zm6.792-8.26a1.7,1.7,0,0,0,0,3.4H22.072a1.7,1.7,0,1,0,0-3.4Z"
        transform="translate(0)"
        fill={color}
      />
    </g>
  </svg>
)

export default Icon
