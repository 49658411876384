const BASE_URL = 'companies'

export default {
  GET_COMPANY: `/${BASE_URL}/`,
  GET_COMPANIES: `/${BASE_URL}`,
  GET_ACTIVITIES: '/company-activities',
  GET_PARTNERS: '/partners/suggestions',
  GET_CONNECTIONS: 'partners/connections',
  GET_INFORMATION: '/partners/information',
}
