import {getNodeEnv} from 'lib/get-env'

const nodeEnv = getNodeEnv()

export const isProductionEnv = () => nodeEnv === 'production'
export const isLocalEnv = () => ['development', 'dev', 'local'].includes(nodeEnv)
export const isTestEnv = () => nodeEnv === 'test'
export const isLocalTestEnv = () => isLocalEnv() || isTestEnv()

export const getRealEnv = () => {
  let realEnv = 'unknown'
  if (isProductionEnv()) {
    realEnv = 'production'
  } else if (isTestEnv()) {
    realEnv = 'test'
  } else if (isLocalEnv()) {
    realEnv = 'local'
  }

  return realEnv
}
