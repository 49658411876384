const BASE_URL_USERS = 'users'
const BASE_URL_USER = 'user'

export default {
  GET_USER_BY_TOKEN: BASE_URL_USER,
  GET_USER_BY_ID: BASE_URL_USERS,
  GET_LIST_USERS: BASE_URL_USERS,
  GET_LOG_USERS: BASE_URL_USERS,
  POST_USERS: BASE_URL_USERS,
}
