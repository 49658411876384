import {format} from 'date-fns'
import React from 'react'
import {Button} from 'react-bootstrap'
import {useHistory} from 'react-router'
import {ContainerHeader, DetailContainer} from '../styles'
import {Container, Paper} from './styles'

const formatCurrency = (amount) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)

const AddressAndData = ({project, city}) => {
  const history = useHistory()

  return (
    <Container>
      <Paper>
        <ContainerHeader>
          <span>Dados da Empresa</span>
          <Button
            variant="outline-primary"
            style={{color: '#1E7BE1', borderColor: '#1E7BE1'}}
            onClick={() => {
              history.push('/companies/' + project.company.federal_tax_id)
            }}>
            VER EMPRESA
          </Button>
        </ContainerHeader>
        <DetailContainer>
          <div className="d-flex mb-5">
            <div>
              <span className="secundary-title">Razão Social</span>
              <br />
              <span className="text-description-footer-1">{project.company.name}</span>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <div style={{flex: 1}}>
              <span className="secundary-title">CNPJ</span>
              <br />
              <span className="text-description-footer-1">{project.company.federal_tax_id}</span>
            </div>
            <div style={{flex: 1}}>
              <span className="secundary-title">Capital Social</span>
              <br />
              <span className="text-description-footer-1">{formatCurrency(project.company.share_capital)}</span>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <div style={{flex: 1}}>
              <span className="secundary-title">Atividade Econômica primária (CNAE)</span>
              <br />
              <span className="text-description-footer-1">{project?.company?.main_activity_description ?? 'N/A'}</span>
            </div>
            <div style={{flex: 1}}>
              <span className="secundary-title">Data de fundação</span>
              <br />
              <span className="text-description-footer-1">
                {format(new Date(project?.company?.created_at), 'dd/MM/yyyy')}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-5">
            <div>
              <span className="secundary-title">Sede</span>
              <br />
              <span className="text-description-footer-1">{project?.company?.city?.name ?? 'N/A'}</span>
            </div>
          </div>
        </DetailContainer>
      </Paper>
      {project.company.zip ? (
        <Paper>
          <ContainerHeader>Endereço da Oportunidade</ContainerHeader>
          <DetailContainer>
            <div className="d-flex justify-content-between w-100 mb-5">
              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">CEP</span>
                <br />
                <span className="text-description-footer-1">{project.company.zip}</span>
              </div>

              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">Logradouro</span>
                <br />
                <span className="text-description-footer-1">{project.company.street}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 mb-5">
              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">Número</span>
                <br />
                <span className="text-description-footer-1">{project.company.number}</span>
              </div>

              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">Bairro</span>
                <br />
                <span className="text-description-footer-1">{project.company.neighborhood}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between w-100">
              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">UF</span>
                <br />
                <span className="text-description-footer-1">{project.company.city.state.uf}</span>
              </div>
              <div style={{width: '100%', textAlign: 'left'}}>
                <span className="secundary-title">Município</span>
                <br />
                <span className="text-description-footer-1">{project.company.city.name}</span>
              </div>
            </div>
          </DetailContainer>
        </Paper>
      ) : null}
    </Container>
  )
}

export default AddressAndData
