import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid} from '@material-ui/core'
import {AutoActivities, DateInterval} from 'app/components'
import StateCitySelect from 'app/components/form/StateCitySelect'
import {changeSearchParams, setAppliedFilters} from 'app/modules/Companies/redux'
import * as selectors from 'app/modules/Companies/redux/selectors'
import {changeActualZoom} from 'app/modules/Proposals/redux'
import React, {useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import {CleanFilterButton, Container, FormTitle} from './styles'

const marksInvestment = [
  {
    label: 'R$ 100.000,00',
    value: 100000,
  },
  {
    label: 'R$ 10.000.000,00',
    value: 10000000,
  },
]

const SearchPopoverCompany = ({onClose, style, ...rest}, ref) => {
  const dispatch = useDispatch()
  const [investmentStart, setInvestmentStart] = useState(null)
  const [investmentEnd, setInvestmentEnd] = useState(null)

  const {searchParams} = useSelector(selectors.getSearchParams)

  const [filters, setFilters] = useState({
    activities: searchParams.activities || [],

    share_capital_from: searchParams.share_capital_from || null,
    share_capital_to: searchParams.share_capital_to || null,

    open_at_from: searchParams.open_at_from || null,
    open_at_to: searchParams.open_at_to || null,

    postal_code: searchParams.postal_code || null,
    city_code: searchParams.city_code || null,
    name: searchParams.name || null,

    selectedCities: searchParams.selectedCities || [],
    selectedStates: searchParams.selectedStates || [],

    cnpj: searchParams.cnpj || null,
    trade_name: searchParams.trade_name || null,
    company_type: searchParams.company_type || null,
    type: searchParams.type || null,
    neighborhood: searchParams.neighborhood || null,
    number: searchParams.number || null,

    state: searchParams.state || null,
    import: searchParams.import || null,
    export: searchParams.export || null,
  })

  const {activities, selectedStates, selectedCities} = filters

  const handleChangeFilter = (ev) => {
    setFilters({...filters, [ev.target.name]: ev.target.checked})
  }

  const onRemove = (field, value) => {
    var array = [...filters[field]] // make a separate copy of the array
    setFilters({...filters, [field]: array.filter((item) => item !== value)})
  }

  const onSelect = (field, value) => {
    var array = [...filters[field]] // make a separate copy of the array
    var index = array.indexOf(value)

    if (index === -1) {
      array.push(value)
      setFilters({...filters, [field]: array})
    }
  }

  const onToggle = (checked, field, value) => {
    if (checked) {
      onSelect(field, value)
    } else {
      var array = [...filters[field]]
      setFilters({...filters, [field]: array.filter((item) => item !== value)})
    }
  }

  const applySearchFilter = () => {
    dispatch(changeSearchParams(filters))
    dispatch(setAppliedFilters(true))
    onClose()
  }

  const onClearFilters = () => {
    var reset = {
      investmentStart: [],
      investmentEnd: [],
      investimentRange: [],
      selectedCities: [],
      selectedStates: [],
      selectedStatus: [],
      selectedTypes: [],
      startDate: null,
      endDate: null,
    }
    setFilters(reset)
    dispatch(changeSearchParams(reset))
    dispatch(setAppliedFilters(false))
    dispatch(changeActualZoom(3))

    onClose()
  }

  const handleChangeState = (state) => {
    setFilters((curFilters) => ({...curFilters, state: state.uf, selectedStates: [state]}))
  }
  const handleChangeCity = (city) => {
    setFilters((curFilters) => ({...curFilters, city_code: city.code, selectedCities: [city]}))
  }
  return (
    <Container {...rest} style={style}>
      <FormTitle>Filtrar por:</FormTitle>
      <div className="dropdown-divider" />
      <>
        <Form className="popover-filters">
          <Grid container>
            <Grid item lg={6}>
              <Box display="flex" flex={1} flexDirection="column" width="100%" marginBottom="20px">
                <FormControl component="fieldset" className="form-fields">
                  <FormLabel component="legend">Razão Social</FormLabel>
                  <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                    <Form.Control
                      type="text"
                      value={filters.name}
                      placeholder="Razão Social"
                      onChange={(ev) => setFilters({...filters, name: ev.target.value})}
                      name="name"
                    />
                  </Box>
                </FormControl>
              </Box>

              <Box marginBottom="20px">
                <AutoActivities
                  onRemoveItem={(val) => onRemove('activities', val)}
                  onSelectItem={(val) => onSelect('activities', val)}
                  selectedItems={activities}
                />
              </Box>

              <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
                <Box display="flex" flex={1} flexDirection="column" width="100%">
                  <FormControl component="fieldset" className="form-fields">
                    <FormLabel component="legend">Capital Social</FormLabel>
                    <Box display="flex" flex={1} flexDirection="row" width="100%">
                      <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                        <Form.Control
                          type="number"
                          value={filters.share_capital_from}
                          placeholder="Inicial"
                          onChange={(ev) => setFilters({...filters, share_capital_from: ev.target.value})}
                          name="share_capital_from"
                        />
                      </Box>
                      <Box display="flex" flexDirection="column" flex={1} width="100%">
                        <Form.Control
                          disabled={filters.share_capital_from <= 0 || !filters.share_capital_from}
                          type="number"
                          value={filters.share_capital_to}
                          placeholder="Final"
                          onChange={(ev) => setFilters({...filters, share_capital_to: ev.target.value})}
                          name="share_capital_to"
                        />
                      </Box>
                    </Box>
                  </FormControl>
                </Box>
              </Box>

              <Box marginBottom="20px" width="100%">
                <DateInterval
                  title="Data de fundação"
                  initialDate={filters.open_at_from}
                  finalDate={filters.open_at_to}
                  onChangeFirstDate={(open_at_from) => setFilters({...filters, open_at_from: open_at_from})}
                  onChangeSecondDate={(open_at_to) => setFilters({...filters, open_at_to: open_at_to})}
                />
              </Box>

              <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
                <FormControl component="fieldset" className="form-checkbox">
                  <FormLabel component="legend">Comércio Exterior</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.import}
                          onChange={(ev) =>
                            ev.target.checked
                              ? setFilters({...filters, import: true})
                              : setFilters({...filters, import: false})
                          }
                          name="import"
                        />
                      }
                      label="Importação"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.export}
                          onChange={(ev) =>
                            ev.target.checked
                              ? setFilters({...filters, export: true})
                              : setFilters({...filters, export: false})
                          }
                          name="export"
                        />
                      }
                      label="Exportação"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box marginLeft="30px">
                <Box display="flex" flex={1} flexDirection="column" width="100%" marginBottom="20px">
                  <FormControl component="fieldset" className="form-fields">
                    <FormLabel component="legend">CNPJ</FormLabel>
                    <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                      <Form.Control
                        type="number"
                        value={filters.cnpj}
                        placeholder="CNPJ"
                        onChange={(ev) => setFilters({...filters, cnpj: ev.target.value})}
                        name="cnpj"
                      />
                    </Box>
                  </FormControl>
                </Box>

                <Box display="flex" flex={1} flexDirection="column" width="100%" marginBottom="20px">
                  <FormControl component="fieldset" className="form-fields">
                    <FormLabel component="legend">Nome Fantasia</FormLabel>
                    <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                      <Form.Control
                        type="text"
                        value={filters.trade_name}
                        placeholder="Razão Social"
                        onChange={(ev) => setFilters({...filters, trade_name: ev.target.value})}
                        name="trade_name"
                      />
                    </Box>
                  </FormControl>
                </Box>

                <Box marginBottom="20px">
                  <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                    <FormControl component="fieldset" className="form-fields">
                      <FormLabel component="legend">Localidade</FormLabel>
                      <StateCitySelect
                        width="100%"
                        showSelects={true}
                        city={filters.selectedCities?.[0]}
                        placeholder={{
                          state: 'UF',
                          city: 'Cidade',
                        }}
                        changeState={handleChangeState}
                        changeCity={handleChangeCity}
                      />
                    </FormControl>

                    <FormControl component="fieldset" className="form-fields" style={{marginTop: 20}}>
                      <Box display="flex" flex={1} flexDirection="row" width="100%">
                        <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                          <Form.Control
                            type="number"
                            value={filters.postal_code}
                            placeholder="CEP"
                            onChange={(ev) => setFilters({...filters, postal_code: ev.target.value})}
                            name="postal_code"
                          />
                        </Box>
                        <Box display="flex" flexDirection="column" flex={1} width="100%">
                          <Form.Control
                            type="number"
                            value={filters.number}
                            placeholder="Número"
                            onChange={(ev) => setFilters({...filters, number: ev.target.value})}
                            name="number"
                          />
                        </Box>
                      </Box>
                      <Box display="flex" flex={1} flexDirection="row" width="100%" style={{marginTop: 20}}>
                        <Box display="flex" flexDirection="column" flex={1} width="100%">
                          <Form.Control
                            type="neighborhood"
                            value={filters.neighborhood}
                            placeholder="Bairro"
                            onChange={(ev) => setFilters({...filters, neighborhood: ev.target.value})}
                            name="neighborhood"
                          />
                        </Box>
                      </Box>
                    </FormControl>
                  </Box>
                </Box>
                <Box marginBottom="20px">
                  <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                    <FormControl component="fieldset" className="form-fields">
                      <FormLabel component="legend">Tipo da empresa </FormLabel>
                      <Box display="flex" flexDirection="column" flex={1} width="100%">
                        <select
                          className="form-control"
                          name="type"
                          placeholder="Tipo da empresa "
                          value={filters.company_type}
                          onChange={(ev) => setFilters({...filters, company_type: ev.target.value})}>
                          <option value="1">Matriz</option>
                          <option value="2">Filial</option>
                        </select>
                      </Box>
                    </FormControl>
                  </Box>
                </Box>

                <Box marginBottom="20px">
                  <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                    <FormControl component="fieldset" className="form-fields">
                      <FormLabel component="legend">Porte</FormLabel>
                      <Box display="flex" flexDirection="column" flex={1} width="100%">
                        <select
                          className="form-control"
                          name="type"
                          placeholder="Porte"
                          value={filters.type}
                          onChange={(ev) => setFilters({...filters, type: ev.target.value})}>
                          <option value="00">Não Informado</option> <option value="01">Micro Empresa</option>
                          <option value="03">Pequenas e Médias Empresas</option>
                          <option value="05">Demais</option>
                        </select>
                      </Box>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box display="flex" flexDirection="column">
            <Form.Row style={{marginBottom: '10px'}}>
              <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end">
                <Box marginRight="20px">
                  <Button onClick={applySearchFilter} variant="primary">
                    Aplicar Filtros
                  </Button>
                </Box>
                <Button onClick={onClose} variant="secondary">
                  Cancelar
                </Button>
              </Box>
            </Form.Row>
            <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end">
              <CleanFilterButton onClick={onClearFilters}>Limpar todos os filtros</CleanFilterButton>
            </Box>
          </Box>
        </Form>
      </>
    </Container>
  )
}

export default SearchPopoverCompany
