import React from 'react'
import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  Input,
  ListSubheader,
} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'

import {Container, Title, Label} from './styles'

const permission = ['ROLE_ADMIN', 'ROLE_USER']

const CreateNewUser = ({formik, onFinish, customers}) => {
  const {
    handleChange,
    setFieldValue,
    values: {email, name, password, property, roles, customer},
  } = formik

  let ctype = ''
  let customer_options = []
  if (customers) {
    customers.forEach((customer) => {
      if (customer.type !== ctype) {
        ctype = customer.type
        customer_options.push(
          <ListSubheader>
            {customer.type === 'city' ? 'Cidade' : customer.type === 'state' ? 'Governo' : customer.type === 'company' ? 'Company' : ''}
          </ListSubheader>
        )
      }
      customer_options.push(
        <MenuItem key={customer.id} value={customer.id}>
          {customer.name}
        </MenuItem>,
      )
    })
  }

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" marginBottom="40px">
        <Box marginBottom="20px">
          <Title>Novo usuário</Title>
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Nome</Label>
          <Form.Control
            placeholder="Ex.: Example example"
            name="name"
            onChange={handleChange}
            value={name}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Email</Label>
          <Form.Control
            placeholder="Ex.: example@example.com.br"
            name="email"
            onChange={handleChange}
            value={email}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Senha</Label>
          <Form.Control
            placeholder="Ex.: Example"
            name="password"
            onChange={handleChange}
            value={password}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Telefone</Label>
          <Form.Control
            placeholder="Ex.: +5541995065196"
            name="property.phone"
            onChange={handleChange}
            value={property.phone}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Celular</Label>
          <Form.Control
            placeholder="Ex.: +5541995065196"
            name="property.mobile_phone"
            onChange={handleChange}
            value={property.mobile_phone}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Linkedin</Label>
          <Form.Control
            placeholder="Ex.: https://perfil.no.linkedin/"
            name="property.linkedin"
            onChange={handleChange}
            value={property.linkedin}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Facebook</Label>
          <Form.Control
            placeholder="Ex.: https://perfil.no.facebook/"
            name="property.facebook"
            onChange={handleChange}
            value={property.facebook}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Cliente</Label>
          <FormControl color="secondary">
            <InputLabel id="customer-label">Cliente</InputLabel>
            <Select
              labelId="customer-label"
              id="customer"
              name="customer"
              value={customer}
              onChange={handleChange}
              style={{width: '100%'}}>
              {customer_options}
            </Select>
          </FormControl>
        </Box>
        <FormControl color="secondary">
          <InputLabel id="demo-roles-label">Permissões</InputLabel>
          <Select
            labelId="roles-label"
            id="roles"
            multiple
            value={roles}
            onChange={(e) => {
              setFieldValue('roles', e.target.value)
            }}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            style={{width: '100%'}}>
            {permission.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={roles.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button type="submit" onClick={onFinish} disabled={!name}>
          Cadastrar
        </Button>
      </Box>
    </Container>
  )
}

export default CreateNewUser
