import React, {useState} from 'react'
import {useFormik} from 'formik'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {injectIntl} from 'react-intl'
import * as auth from '../_redux/authRedux'
import {requestPassword, changePassword} from '../_redux/authCrud'

import {CityMatchLogo} from 'app/components/images'

const initialValues = {
  email: '',
}

function ForgotPassword(props) {
  const {intl} = props
  const [isRequested, setIsRequested] = useState(false)

  const paramsx = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  let token = paramsx.token // "some_value

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  })

  const ChangetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    confirm_password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      requestPassword(values.email)
        .then((res) => {
          setIsRequested(true)
          setSubmitting(false)
        })
        .catch(() => {
          setIsRequested(false)
          setSubmitting(false)
          setStatus(intl.formatMessage({id: 'AUTH.VALIDATION.NOT_FOUND'}, {name: values.email}))
        })
    },
  })

  const formikChangePassword = useFormik({
    initialValues,
    validationSchema: ChangetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      if (values.password != values.confirm_password) {
        alert('As senhas precisam ser iguais.')
        return false
      }
      changePassword(token, values.password, values.confirm_password)
        .then((res) => {
          setIsRequested(true)
          setTimeout(() => {
            window.location.href = '/auth/login'
          }, 3000)
          setSubmitting(false)
        })
        .catch(() => {
          setIsRequested(false)
          setSubmitting(false)
          setStatus(intl.formatMessage({id: 'AUTH.VALIDATION.NOT_FOUND'}, {name: values.email}))
        })
    },
  })

  if (token) {
    return (
      <div className="login-form login-forgot" style={{display: 'block'}}>
        <div className="text-center mb-10 mb-lg-20">
          <CityMatchLogo />
          <h3 className="font-size-h1">Processo de alteração de senha</h3>
          <div className="text-muted font-weight-bold">Forneça sua nova senha</div>
        </div>
        <form
          onSubmit={formikChangePassword.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
          {formikChangePassword.status && !isRequested && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formikChangePassword.status}</div>
            </div>
          )}
          {isRequested && (
            <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
              <div className="alert-text font-weight-bold">
                Sua senha foi alterada com sucesso! Você será redirecionado para a tela de login em instântes
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
              placeholder="Digite sua nova senha"
              name="password"
              {...formikChangePassword.getFieldProps('password')}
            />
            {formikChangePassword.touched.password && formikChangePassword.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formikChangePassword.errors.password}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('confirm_password')}`}
              placeholder="Confirme sua senha"
              name="confirm_password"
              {...formikChangePassword.getFieldProps('confirm_password')}
            />
            {formikChangePassword.touched.confirm_password && formikChangePassword.errors.confirm_password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formikChangePassword.errors.confirm_password}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formikChangePassword.isSubmitting}>
              {intl.formatMessage({id: 'AUTH.FORGOT.SEND_BUTTON'})}
            </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                {intl.formatMessage({id: 'AUTH.FORGOT.CANCEL_BUTTON'})}
              </button>
            </Link>
          </div>
        </form>
      </div>
    )
  }

  return (
    <div className="login-form login-forgot" style={{display: 'block'}}>
      <div className="text-center mb-10 mb-lg-20">
        <CityMatchLogo />
        <h3 className="font-size-h1">
          {intl.formatMessage({
            id: 'AUTH.FORGOT.TITLE',
          })}
        </h3>
        <div className="text-muted font-weight-bold">
          {intl.formatMessage({
            id: 'AUTH.FORGOT.DESC',
          })}
        </div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
        {formik.status && !isRequested && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {isRequested && (
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
            <div className="alert-text font-weight-bold">
              Processo iniciado! Verifique sua caixa de entrada de email para seguir com o processo de troca de senha
            </div>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <input
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('email')}`}
            placeholder="email@citymatch.com"
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            id="kt_login_forgot_submit"
            type="submit"
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            disabled={formik.isSubmitting}>
            {intl.formatMessage({id: 'AUTH.FORGOT.SEND_BUTTON'})}
          </button>
          <Link to="/auth">
            <button
              type="button"
              id="kt_login_forgot_cancel"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
              {intl.formatMessage({id: 'AUTH.FORGOT.CANCEL_BUTTON'})}
            </button>
          </Link>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword))
