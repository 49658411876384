import httpClient from '../../client'

import apiRoutes from '../constants'

const getLogs = async (id) => {
  try {
    const {data} = await httpClient.get(`${apiRoutes.GET_LOG_USERS}/${id}/audits`)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    return error
  }
}

export default getLogs
