import CitieCards from 'app/components/cities/CitieCards/index'
import CitiesList from 'app/components/cities/Table/index'
import Map from 'app/components/map/Map'
import {resetExportCitiesCsv} from 'app/modules/Cities/redux'
import * as citieslSelectors from 'app/modules/Cities/redux/selectors'
import {citieService} from 'app/services'
import {useSnackbar} from 'notistack'
import Papa from 'papaparse'
import React, {useCallback, useEffect, useState} from 'react'
import Shimmer from 'react-loading-skeleton'
import {useDispatch, useSelector} from 'react-redux'
import {utils, write} from 'xlsx'

import {setAvailableOrdering} from '../../redux'

const ListCitiesPage = () => {
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const [cities, setCities] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalWithoutFilter, setTotalWithoutFilter] = useState(0)
  const [filters, setFilters] = useState({})
  const [coordinates, setCoordinates] = useState(null)
  const [drawnArea, setDrawnArea] = useState(null)
  const hasLoadedOrdering = useSelector(citieslSelectors.hasLoadedOrdering)
  const currentPage = useSelector(citieslSelectors.getCurrentCitiesPage)
  const {showMap, viewMode} = useSelector(citieslSelectors.universalSearch)
  const getExportToCsv = useSelector(citieslSelectors.getExportToCsv)
  const {hasAppliedFilters, searchParams, showOnlyFavorites} = useSelector(citieslSelectors.getSearchParams)
  const activeOrder = useSelector(citieslSelectors.getActiveOrder)

  const limit = useSelector(citieslSelectors.getCurrentLimit)

  const changeDrawnArea = (drawnArea) => {
    setDrawnArea(drawnArea)
  }

  const mountSorting = useCallback(
    (selectedOrder) => ({
      [`sort[${selectedOrder.key}]`]: selectedOrder.order.toLowerCase(),
    }),
    [],
  )

  const mountQueryParams = useCallback(
    (params) => {
      let queryParams = {
        limit: limit.current,
        show_demographic_data: 1,
      }

      if (showOnlyFavorites) {
        queryParams.only_favorites = 1
      }

      if (params.state && params.state.length) {
        queryParams.ufs = params.state.map((state) => state.id).join(',')
      }

      if (params.city && params.city.length) {
        queryParams.id = params.city.map((city) => city.id).join(',')
      }

      if (params.vocations && params.vocations.length) {
        queryParams.vocation = params.vocations.map((voc) => voc).join(',')
      }

      if (params.incentives && params.incentives.length) {
        queryParams.incentives = params.incentives.map((incentive) => incentive).join(',')
      }

      if (drawnArea) {
        queryParams.coordinates_polygon = drawnArea
      }

      const {state, city, vocations, incentives, ...rest_params} = params

      if (hasAppliedFilters) {
        queryParams = {
          ...rest_params,
          ...queryParams,
        }
      }

      if (activeOrder) {
        queryParams = {...queryParams, ...mountSorting(activeOrder)}
      }

      return queryParams
    },
    [activeOrder, hasAppliedFilters, limit, showOnlyFavorites, mountSorting, drawnArea],
  )

  const fetchCitiesGeolocation = useCallback(async () => {
    try {
      setLoading(true)
      let coordinates = []

      let queryParams = {
        page: currentPage,
        ...filters,
        ...mountQueryParams(searchParams),
        fields: 'id,code,name,latitude,longitude',
        limit: 6000,
      }

      const {data} = await citieService.getCities(queryParams)

      await data.forEach((feature) => {
        let jsonGeo = {
          geometry: {
            coordinates: [],
            type: 'Point',
          },
          properties: {
            id: feature.id,
            code: feature.code,
            name: feature.name,
            type: 'city',
            state_uf: feature.state?.uf,
          },
          type: 'Feature',
        }

        jsonGeo.geometry.coordinates.push(parseFloat(feature.longitude))
        jsonGeo.geometry.coordinates.push(parseFloat(feature.latitude))
        coordinates.push(jsonGeo)
      })

      setCoordinates({type: 'FeatureCollection', features: coordinates})
    } catch (error) {
      setCoordinates({type: 'FeatureCollection', features: []})
    } finally {
      setLoading(false)
    }
  }, [activeOrder, currentPage, enqueueSnackbar, filters, mountQueryParams, showOnlyFavorites, searchParams])

  const fetchCities = useCallback(async () => {
    try {
      setLoading(true)

      let queryParams = {
        page: currentPage,
        ...filters,
        ...mountQueryParams(searchParams),
      }

      const {data, total, total_without_filter, available_ordering: availableOrdering} = await citieService.getCities(
        queryParams,
      )

      setTotal(total)
      setTotalWithoutFilter(total_without_filter)
      setCities(data)
      setLoading(false)

      if (!hasLoadedOrdering) {
        dispatch(setAvailableOrdering(availableOrdering))
      }
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao consultar as cidades', {
        variant: 'error',
      })

      setCities([])
    } finally {
      setLoading(false)
    }
  }, [activeOrder, currentPage, enqueueSnackbar, filters, mountQueryParams, showOnlyFavorites, searchParams])

  const fetchCitiesAndDownloadCsv = useCallback(async () => {
    if (!getExportToCsv) return

    try {
      let queryParams = {
        format: 'csv',
        page: 1,
        ...filters,
        ...mountQueryParams(searchParams),
      }

      const data = await citieService.getCities(queryParams)

      if (data) {
        const parsedData = Papa.parse(data, {header: true})
        const workbook = utils.book_new()
        const worksheet = utils.json_to_sheet(parsedData.data)
        utils.book_append_sheet(workbook, worksheet, 'Cities')

        const buffer = write(workbook, {type: 'buffer', bookType: 'xlsx'})

        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `export-cities-${new Date().getTime()}.xlsx`)
        document.body.appendChild(link)
        dispatch(resetExportCitiesCsv())

        link.click()
        return data
      }
    } catch (error) {
      enqueueSnackbar('Não foi possível baixar o XLSx de cidades', {
        variant: 'error',
      })
    }
  }, [currentPage, enqueueSnackbar, filters, mountQueryParams, showOnlyFavorites, searchParams, getExportToCsv])

  useEffect(() => {
    fetchCities()
    fetchCitiesGeolocation()
  }, [fetchCities])

  useEffect(() => {
    fetchCitiesAndDownloadCsv()
  }, [fetchCitiesAndDownloadCsv])

  const renderVisualizationMode = () => {
    if (viewMode === 'card') {
      return <CitieCards loading={loading} page={currentPage} cities={cities} total={total} limit={limit} />
    }
    return (
      <CitiesList
        page={currentPage}
        cities={cities}
        total={total}
        totalWithoutFilter={totalWithoutFilter}
        loading={loading}
        limit={limit}
      />
    )
  }

  return (
    <>
      {showMap && loading && (
        <Shimmer className="w-100" height="500px" style={{marginBottom: '40px', borderRadius: '20px'}} />
      )}

      {showMap && coordinates && !loading && (
        <Map
          lg
          coordinates={coordinates}
          currentPage={currentPage}
          changeFilter={setFilters}
          loading={loading}
          changeDrawnArea={changeDrawnArea}
          mapType="cities"
        />
      )}

      <div className="d-flex flex-column">{renderVisualizationMode()}</div>
    </>
  )
}

export default ListCitiesPage
