import React, {useState} from 'react'
import {Box, Divider, Grid} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'
import Type from 'app/components/proposal/Type'
import IntlCurrencyInput from 'react-intl-currency-input'

import {Title, Container} from '../styles'
import ReactInputMask from 'react-input-mask'
import {LabelM0} from '../../NewProposal/City/Details/styles'

import StateCitySelect from 'app/components/form/StateCitySelect'

const ProposalDetailsAndData = ({
  type,
  name,
  status,
  project,
  handleChange,
  city,
  searchCompanyDocument,
  onSubmit,
  submiting,
}) => {
  const [link_with_city, setLinkWithCity] = useState(false)
  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>DETALHES DA OPORTUNIDADE</Title>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>Tipo da oportunidade</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Type type={type} width="36px" height="36px" iconSize="17px" />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>Status</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Box display="flex" flexDirection="column" width={300}>
            <select className="form-control" name="status" value={status} onChange={handleChange}>
              <option value="open">Ativa</option>
              <option value="completed">Ganha / Concretizada</option>
              <option value="lost">Perdida</option>
            </select>
          </Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="name">Nome</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="name"
            id="name"
            type="text"
            onChange={handleChange}
            value={name}
            placeholder="Ex.: Fábrica de Papel Celulose"
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flex={1} flexDirection="row" marginBottom="20px">
              <Box display="flex" flex={1}>
                <Form.Check
                  checked={link_with_city || city}
                  onChange={(ev) => {
                    setLinkWithCity(ev.target.checked)
                    handleChange({target: {name: 'city_id', value: null}})
                    handleChange({target: {name: 'city', value: null}})
                  }}
                  type="checkbox"
                  id="form-vincular-cidade"
                />
                <label
                  for="form-vincular-cidade"
                  style={{fontWeight: 'bold', fontSize: 16, color: '#1D1D1D', fontFamily: 'Nunito'}}>
                  Vincular oportunidade a um município
                </label>
              </Box>
            </Box>
            {(link_with_city || city) && (
              <Box marginBottom="10px">
                {' '}
                <StateCitySelect
                  changeCity={(city) =>
                    handleChange({
                      target: {
                        name: 'city_id',
                        value: city.id,
                      },
                    })
                  }
                  city={city}
                  showSelects></StateCitySelect>
              </Box>
            )}
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}></Grid>
        <Grid item lg={9}>
          <Divider style={{maxWidth: 600, marginBottom: 30}} />
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>DADOS DA EMPRESA</Title>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="project_company_federal_tax_id">CNPJ</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Box display="flex">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <ReactInputMask
                autoComplete="off"
                className="form-control"
                name="project_company_federal_tax_id"
                id="project_company_federal_tax_id"
                value={project.company.federal_tax_id}
                onChange={handleChange}
                placeholder="99.999.999/9999-99"
                mask="99.999.999/9999-99"
              />
            </Box>
            <Box>
              <Button variant="outline-primary" onClick={searchCompanyDocument}>
                Buscar Dados
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="project_company_share_capital">Capital Social</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <IntlCurrencyInput
            autoComplete="off"
            placeholder="Ex.: R$ 800.000,00"
            name="project_company_share_capital"
            id="project_company_share_capital"
            className="form-control"
            currency="BRL"
            style={{maxWidth: 300}}
            onChange={(ev, value) => handleChange({target: {value: value, name: 'project_company_share_capital'}})}
            config={{
              locale: 'pt-BR',
              formats: {
                number: {
                  BRL: {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                },
              },
            }}
            defaultValue={project.company.share_capital}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="project_company_name">Razão Social</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="project_company_name"
            id="project_company_name"
            type="text"
            onChange={handleChange}
            value={project.company.name}
            placeholder="Ex.: Fábrica de Papel Celulose"
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="project_company_main_activity">Atividade econômica (CNAE Primário)*</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="project_company_main_activity"
            id="project_company_main_activity"
            type="text"
            onChange={handleChange}
            value={project.company.main_activity}
            placeholder="6622300"
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ProposalDetailsAndData
