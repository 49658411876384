import httpClient from '../../client'

import apiRoutes from '../constants'

export const emailUser = async (body) => {
  const { data, status } = await httpClient
    .post(`${apiRoutes.POST_USERS}/email`, { ...body })
    .then((data) => data)
    .catch((error) => {
      if (error.response.status === 403) throw Error('Usuário com e-mail informado já existe')

      throw Error('Erro ao cadastrar o usuário. Tentar novamente')
    })

  return { data, status }
}
