import React, {useState} from 'react'

import {ErrorContainer} from './styles'

const ProposalLogo = ({borderRadius = '5px', name, url, height = '30px', width = '30px', showFirstLetter = false}) => {
  const [errorFetchingImage, setErrorFetchingImage] = useState(false)

  return (
    <div className="proposals" style={{width, height}}>
      {errorFetchingImage ? (
        <ErrorContainer borderRadius={borderRadius} height={height} width={width}>
          {showFirstLetter && name[0]}
        </ErrorContainer>
      ) : (
        <img
          loading="lazy"
          alt={name}
          className="rounded img-fluid"
          onError={() => setErrorFetchingImage(true)}
          src={url}
          height={height}
          width={width}
        />
      )}
    </div>
  )
}

export {ProposalLogo}
