import {Box, Grid} from '@material-ui/core'
import {Container, Subtitle, Title} from 'app/modules/Cities/Pages/EditCitie/styles'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Button} from 'react-bootstrap'

const LocationDisabled = ({handleEnableLocation}) => {
  const [loading, setLoading] = useState(false)

  const handleEnable = () => {
    if (!handleEnableLocation) {
      return
    }

    setLoading(true)
    handleEnableLocation()
    setLoading(false)
  }

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={2} direction="column" justify="center" alignItems="center">
        <Grid item sm={12} md={6}>
          <Box>
            <Title>LOCALIDADE NÃO HABILITADA</Title>
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <Box>
            <Subtitle>Antes de editar esta localidade ela precisa ser habilitada por um dos administradores.</Subtitle>
          </Box>
        </Grid>
        <Grid item sm={12} md={6}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box>
              <Button onClick={handleEnable} disabled={loading}>
                HABILITAR LOCALIDADE
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

LocationDisabled.defaultProps = {}
LocationDisabled.propTypes = {
  handleEnableLocation: PropTypes.func.isRequired,
}
export default LocationDisabled
