import './Map.css'

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import * as proposalSelectors from 'app/modules/Proposals/redux/selectors'
import {citieService, statesService} from 'app/services'
import configs from 'configs'
import debounce from 'lodash/debounce'
import mapboxgl from 'mapbox-gl'
import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import FavoriteControl from './FavoriteControl'
import StyleControl from './StyleControl'

mapboxgl.accessToken = 'pk.eyJ1Ijoia2ZmciIsImEiOiJja3B4M3U0cWkyMjVlMm9tdXR0a3NzMGViIn0.RZNMQocHQN2Z3_8kxAvO-A'

const Map = ({
  lg,
  changeFilter,
  coordinates,
  customStyle,
  zoomIn,
  terrain,
  controlOnly,
  // onChangeDraw,
  drawArea,
  centerMap,
  changeCenterMap,
  scrollZoomDisable,
  noFitBounds,
  changeTerrain,
  changeDrawnArea,
  mapType,
}) => {
  const {user} = useSelector((state) => state.auth, shallowEqual)
  const history = useHistory()
  const dispatch = useDispatch()
  const {actualZoom} = useSelector(proposalSelectors.getSearchParams)

  let polygonDraw = null

  const onChangeDraw = () => {
    if (!changeDrawnArea) return
    const data = polygonDraw.getAll()

    if (data.features[0]) {
      changeDrawnArea(JSON.stringify(data.features[0].geometry.coordinates[0]))
    } else {
      changeDrawnArea(null)
    }
  }

  // ta ruim mas e melhor do que ternario encadeado
  const lng = useMemo(() => {
    let pos = -49.43503565404518
    if (centerMap) {
      pos = centerMap[0]
    } else if (coordinates?.features?.length > 0) {
      pos = coordinates.features[0].geometry.coordinates[0]
    } else if (coordinates && coordinates[0]) {
      pos = coordinates[0]
    }

    return pos
  }, [centerMap, coordinates])
  // ta ruim mas e melhor do que ternario encadeado
  const lat = useMemo(() => {
    let pos = -22.013735810048374
    if (centerMap) {
      pos = centerMap[1]
    } else if (coordinates?.features?.length > 0) {
      pos = coordinates.features[0].geometry.coordinates[1]
    } else if (coordinates && coordinates[1]) {
      pos = coordinates[1]
    }

    return pos
  }, [centerMap, coordinates])

  const zoom = zoomIn !== undefined ? zoomIn : 3

  const map = useRef(null)
  const mapContainer = useRef(null)

  const changeCenter = (current) => {
    if (!changeCenterMap) return

    var canvas = current.getCanvas(),
      w = canvas.width,
      h = canvas.height,
      cUL = current.unproject([0, 0]).toArray(),
      cUR = current.unproject([w, 0]).toArray(),
      cLR = current.unproject([w, h]).toArray(),
      cLL = current.unproject([0, h]).toArray()

    const {centerLng, centerLat} = current.getCenter()
    var coordinatesx = [cUL, cUR, cLR, cLL, cUL, [centerLng, centerLat]]
    changeCenterMap(JSON.stringify(coordinatesx))
  }

  const changeCenterDelayed = debounce(changeCenter, 1000)

  const addProposalsLayer = useCallback(() => {
    if (drawArea) {
      map.current.addSource('industrial_area', {
        type: 'geojson',
        data: drawArea,
      })

      map.current.addLayer({
        id: 'industrial_area',
        type: 'fill',
        source: 'industrial_area', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5,
        },
      })
      // Add a black outline around the polygon.
      map.current.addLayer({
        id: 'outline-industrial',
        type: 'line',
        source: 'industrial_area',
        layout: {},
        paint: {
          'line-color': '#000',
          'line-width': 3,
        },
      })
    }

    ;[{status: 'completed'}, {status: 'open'}, {status: 'lost'}, {status: 'city'}].forEach((item_status) => {
      map.current.getSource('points' + item_status.status).setData({
        type: 'FeatureCollection',
        features: [],
      })
    })
    ;[{status: 'city'}].forEach((item_status) => {
      var features = coordinates.features.filter((fea) => fea.properties?.type && fea.properties.type === 'city')

      if (!features.length) {
        return
      }

      map.current.getSource('points' + item_status.status).setData({type: 'FeatureCollection', features: features})
    })
    ;[{status: 'completed'}, {status: 'open'}, {status: 'lost'}].forEach((item_status) => {
      var features = coordinates.features.filter(
        (fea) =>
          fea.properties?.type && fea.properties.type === 'proposal' && fea.properties.status == item_status.status,
      )
      if (!features.length) {
        return
      }

      map.current.getSource('points' + item_status.status).setData({type: 'FeatureCollection', features: features})
    })

    if (coordinates.features.length > 0 && !noFitBounds && mapType !== 'proposals') {
      const cooors = coordinates.features
        .filter((e) => e.geometry?.coordinates[0])
        .map((e) => e.geometry.coordinates)
        .values()

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(cooors[0], cooors[0])

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of cooors) {
        bounds.extend(coord)
      }

      map.current.fitBounds(bounds, {
        padding: 20,
        maxZoom: 10,
        linear: true,
        // zoom: map.current.getZoom(),
      })
    }
  })

  useEffect(() => {
    if (map.current) return
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style:
        terrain && terrain === 'satelite'
          ? 'mapbox://styles/kffr/ckrjtlot8107o17me4w6roehb'
          : 'mapbox://styles/kffr/ckrjtk48p2f8517pl5oz5glwa',
      // style: "mapbox://styles/kffr/ckrjrrnnz6xyo19oguraw3egj",
      center: [lng, lat],
      zoom: zoom,
      cooperativeGestures: true,
      scrollZoom: true,
    })

    var draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    })

    if (onChangeDraw) {
      polygonDraw = draw
      map.current.on('draw.create', onChangeDraw)
      map.current.on('draw.delete', onChangeDraw)
      map.current.on('draw.update', onChangeDraw)
    }

    map.current.addControl(draw, 'top-left')

    if (!controlOnly) {
      map.current.addControl(
        new FavoriteControl({
          callback: function (filter) {
            changeFilter(filter)
          },
        }),
        'top-left',
      )
    }

    map.current.addControl(
      new StyleControl({
        callback: function (filter) {
          if (filter && filter.is_vector === true) {
            var stateId = null
            var lastStateId = null
            var cityId = null
            var showState = false
            var layerId = null

            citieService.getBrGeojson().then((res) => {
              res.features = res.features.map((rr) => {
                rr.id = rr.properties.codarea
                return rr
              })

              var originalColor = '#0080ff'

              map.current.getSource('br').setData({
                type: 'geojson',
                // Use a URL for the value for the `data` property.
                data: res,
              })

              map.current.addLayer({
                id: 'br',
                type: 'fill',
                source: 'br', // reference the data source
                layout: {},
                paint: {
                  'fill-color': originalColor, // blue color fill
                  'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5],
                },
              })
              // Add a black outline around the polygon.
              map.current.addLayer({
                id: 'outline',
                type: 'line',
                source: 'br',
                layout: {},
                paint: {
                  'line-color': originalColor,
                  'line-width': 2,
                },
              })
            })

            map.current.on('mousemove', 'br', function (e) {
              map.current.getCanvas().style.cursor = 'pointer'

              if (e.features.length > 0) {
                if (stateId && stateId !== e.features[0].id) {
                  map.current.setFeatureState({source: 'br', id: stateId}, {hover: false})
                } else {
                  map.current.setFeatureState({source: 'br', id: e.features[0].id}, {hover: true})
                }
                stateId = e.features[0].id
                layerId = 'state-' + stateId
              }
            })

            map.current.on('mouseleave', 'br', function () {
              map.current.getCanvas().style.cursor = ''

              if (stateId !== null) {
                map.current.setFeatureState({source: 'br', id: stateId}, {hover: false})
              }
              stateId = null
              layerId = null
            })

            map.current.on('click', 'br', function (e) {
              if (lastStateId == stateId) return

              layerId = 'state-' + stateId

              statesService.getStateByCode(stateId).then((rr) => {
                changeFilter({ufs: rr.id, state: rr.uf})
              })

              citieService.getStateGeojson(stateId).then((res) => {
                if (lastStateId) {
                  map.current.removeSource('state-' + lastStateId)
                }

                lastStateId = stateId

                res.features = res.features.map((rr) => {
                  rr.id = rr.properties.codarea
                  return rr
                })
                let source
                if (map.current.getSource(layerId)) {
                  source = map.current.getSource(layerId)
                } else {
                  source = map.current.addSource(layerId, {
                    type: 'geojson',
                    data: {
                      type: 'FeatureCollection',
                      features: [],
                    },
                  })
                }

                try {
                  map.current.removeLayer(layerId)
                  map.current.removeLayer('outline-' + layerId)
                } catch (err) {
                  // empty
                }
                source.setData({
                  type: 'geojson',
                  // Use a URL for the value for the `data` property.
                  data: res,
                })

                map.current.addLayer({
                  id: layerId,
                  type: 'fill',
                  source: layerId, // reference the data source
                  layout: {},
                  paint: {
                    'fill-color': '#00346d', // blue color fill
                    'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.5],
                  },
                })
                // Add a black outline around the polygon.
                map.current.addLayer({
                  id: 'outline-' + layerId,
                  type: 'line',
                  source: layerId,
                  layout: {},
                  paint: {
                    'line-color': '#00346d',
                    'line-width': 1,
                  },
                })
              })

              map.current.on('mousemove', layerId, function (e) {
                map.current.getCanvas().style.cursor = 'pointer'
                if (e.features.length > 0) {
                  if (cityId && cityId !== e.features[0].id) {
                    map.current.setFeatureState({source: layerId, id: cityId}, {hover: false})
                  } else {
                    map.current.setFeatureState({source: layerId, id: e.features[0].id}, {hover: true})
                  }
                  cityId = e.features[0].id
                }
              })

              map.current.on('click', layerId, function (e) {
                cityId = e.features[0].id

                citieService.getCityByCode(cityId).then((rr) => {
                  changeFilter({
                    city: rr.id,
                    code: cityId,
                    city_code: cityId,
                  })
                })
              })
            })
          }
        },
      }),
      'top-left',
    )

    map.current.on('style.load', () => {
      map.current.addSource('br', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })
      ;[
        {pin: 'map_oportunidade_concluida', status: 'completed'},
        {pin: 'map_oportunidade_pendente', status: 'open'},
        {pin: 'map_oportunidade_perdida', status: 'lost'},
        {pin: 'custom_marker', status: 'city'},
      ].forEach((item_status) => {
        map.current.addSource('points' + item_status.status, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        })

        if (item_status.status == 'city') {
          map.current.addLayer({
            id: 'points' + item_status.status,
            type: 'circle',
            source: 'points' + item_status.status,
            paint: {
              // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 20px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              'circle-color': '#1e7be1',
            },
          })
        } else {
          // Add an image to use as a custom marker
          map.current.loadImage(`/logos/city-match/${item_status.pin}.png`, (error, image) => {
            if (error) throw error
            image.style = 'width: 30px'

            map.current.addImage('custom-marker-' + item_status.status, image)

            // Add a symbol layer
            map.current.addLayer({
              id: 'points' + item_status.status,
              type: 'symbol',
              source: 'points' + item_status.status,
              layout: {
                'icon-allow-overlap': true,
                'icon-image': 'custom-marker-' + item_status.status,
                'icon-offset': [Math.random() * 30, Math.random() * 30],
                // get the title name from the source's "title" property
                'text-field': ['get', 'title'],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 1.25],
                'text-anchor': 'top',
              },
            })
          })
        }

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })

        map.current.on('mouseenter', 'points' + item_status.status, function (e) {
          map.current.getCanvas().style.cursor = 'pointer'

          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice()

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          if (item_status.status === 'city') {
            const {code, name} = e.features[0].properties

            popup
              .setLngLat(coordinates)
              .setHTML(`<strong>Código:</strong> ${code} <br/><strong>Nome:</strong> ${name}`)
              .addTo(map.current)
          } else {
            const {id, name, status_desc} = e.features[0].properties

            popup
              .setLngLat(coordinates)
              .setHTML(
                `<strong>Oportunidade:</strong> ${id} <br/><strong>Titulo:</strong> ${name} <br/><strong>Status:</strong> ${status_desc}`,
              )
              .addTo(map.current)
          }
        })

        map.current.on('mouseleave', 'points' + item_status.status, () => {
          map.current.getCanvas().style.cursor = ''
          popup.remove()
        })

        map.current.on('click', 'points' + item_status.status, (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice()
          const {id} = e.features[0].properties

          window.location.href = `/${item_status.status === 'city' ? 'cities' : 'proposals'}/${id}`
        })
      })
      addProposalsLayer()
    })

    map.current.on('drag', () => {
      changeCenterDelayed(map.current)
    })

    map.current.on('zoom', () => {
      changeCenterDelayed(map.current)
    })

    map.current.addControl(new mapboxgl.FullscreenControl())
    map.current.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      }),
      'top-right',
    )

    const mapLogo = mapContainer?.current?.querySelector('a.mapboxgl-ctrl-logo')
    if (mapLogo) {
      mapLogo.style.backgroundImage = `url(${configs.assets.logo.main})`
      mapLogo.style.backgroundColor = configs.map.bgColor
    }
  })

  useEffect(() => {
    // console.log(map.current.getSource('pointscity'))
    // console.log('coordinates change', coordinates)

    var anySource =
      map.current.getSource('pointscompleted') ||
      map.current.getSource('pointsopen') ||
      map.current.getSource('pointslost') ||
      map.current.getSource('pointscity')
    // console.log(anySource)
    if (anySource && coordinates) {
      addProposalsLayer(coordinates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates])

  return (
    <div className={lg ? 'container-citymatch-table map' : 'map'} style={customStyle}>
      <div className="d-flex flex-column-fluid">
        <div
          className="d-flex flex-column-fluid flex-column bg-white"
          style={{position: 'relative', overflow: 'hidden'}}>
          <div
            ref={mapContainer}
            className={user?.customer && user.customer.id == 4 ? 'map-container celepar' : 'map-container'}
          />
        </div>
      </div>
    </div>
  )
}

export default Map
