import httpClient from '../../client'

import apiRoutes from '../constants'

const getCompany = async ({document}) => {
  try {
    const cnpj = typeof document == 'string' ? document.replace(/\D/g, '') : document
    const {data} = await httpClient.get(apiRoutes.GET_COMPANIES + '/' + cnpj)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling companies with`, error)

    return error
  }
}

export default getCompany
