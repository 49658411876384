import React from 'react'
import {Route, Switch} from 'react-router-dom'

import ListPoliticiansPage from './ListPoliticians'

const CitiesPage = () => {
  return (
    <Switch>
      <Route path="/politicians" exact component={ListPoliticiansPage} />
    </Switch>
  )
}

export default CitiesPage
