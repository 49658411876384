import httpClient from '../../client'

import apiRoutes from '../constants'

const updateUser = async (id, body) => {
  try {
    let {data} = await httpClient.put(`${apiRoutes.POST_USERS}/${id}`, body)
    return data
  } catch (error) {
    throw Error(error.response.data.message)
  }
}

export default updateUser
