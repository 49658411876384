import React from 'react'

import {Container, IconContainer, Icon, Label} from './styles'

function Type({iconSize = '8px', height = '20px', type, width = '20px'}) {
  const config = {
    city: {
      icon: 'fas fa-university',
      label: 'Pública',
    },
    company: {
      icon: 'fas fa-building',
      label: 'Privada',
    },
  }[type]

  return (
    <Container type={type}>
      <IconContainer type={type} height={height} width={width} style={{display: 'flex', alignItems: 'center'}}>
        <Icon className={config.icon} iconSize={iconSize} />
      </IconContainer>
      <Label type={type}>{config.label}</Label>
    </Container>
  )
}

export default Type
