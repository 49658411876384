import React from 'react'
import {Paper, DetailContainer, Image, FavoriteIcon} from '../styles'
import Status from 'app/components/proposal/Status'
import Type from 'app/components/proposal/Type'
import BottomDetailIcons from './BottomDetailIcons'

import {useFavorite} from 'app/hooks'
import {Circular as CircularLoading, ProposalLogo} from 'app/components'
import {Hash, Link, User} from 'react-feather'

const BasicProposalInfo = ({
  id,
  name,
  description,
  status,
  type,
  city,
  customer,
  is_favorite,
  logoUrl = '',
  lost_at,
  completed_at,
  ...rest
}) => {
  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'proposal',
    label: 'Proposta',
  })

  return (
    <Paper>
      <DetailContainer>
        <div className="d-flex justify-content-between mb-8">
          <div className="d-flex justify-content-start">
            <ProposalLogo borderRadius="50%" name={name} url={logoUrl} showFirstLetter height="92px" width="92px" />
            <div className="ml-4" style={{width: '100%', maxWidth: 570}}>
              <div className="d-flex align-items-top">
                <span className="title-proposal w-100">{name}</span>
                {loadingFavorite ? (
                  <CircularLoading />
                ) : (
                  <FavoriteIcon
                    aria-hidden="true"
                    className="fa fa-star"
                    isFavorite={isFavorite}
                    onClick={() => handleFavoriteOpportunity()}
                  />
                )}
              </div>
              <div className="w-100 mt-2">
                <ul className="proposal-links">
                  <li>
                    <Hash /> <span>{id}</span>
                  </li>
                  <li>
                    <User /> <span>Cadastrada por {customer.name}</span>
                  </li>
                  {city ? (
                    <li>
                      <Link />{' '}
                      <span>
                        Vinculada a{' '}
                        <a href={`/cities/${city.id}`}>
                          {city.name} - {city.state.uf}
                        </a>
                      </span>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex w-25 align-items-center">
            <div className="w-50">
              <p className="secundary-title">Tipo</p>
              {type && <Type type={type} width="36px" height="36px" iconSize="17px" />}
            </div>
            <div className="w-50">
              <p className="secundary-title">Status</p>
              <Status
                status={status}
                lg
                width="36px"
                height="36px"
                iconSize="17px"
                c
                lostAt={lost_at}
                completedAt={completed_at}
              />
            </div>
          </div>
        </div>
        <div className="w-100 mt-3">
          <p className="secundary-title">Descrição</p>
          <p className="description-proposal">{description}</p>
        </div>
        <hr style={{marginTop: '2rem'}} />
        <BottomDetailIcons city={city} {...rest} />
      </DetailContainer>
    </Paper>
  )
}

export default BasicProposalInfo
