import React, {useEffect, useState, useCallback} from 'react'
import {useSnackbar} from 'notistack'

import {userService} from 'app/services'

import {TableLogs} from '../../components'
import {useParams} from 'react-router'
import {Box} from '@material-ui/core'
import {shallowEqual, useSelector} from 'react-redux'

const ListLogs = () => {
  const [logs, setLogs] = useState()
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const {enqueueSnackbar} = useSnackbar()
  const {id} = useParams()

  const {userId} = useSelector(
    ({auth}) => ({
      userId: auth.user.id,
    }),
    shallowEqual,
  )

  const fetchLogs = useCallback(async () => {
    try {
      setLoading(true)

      const {data, total} = await userService.getLogs(id ?? userId)

      setTotal(total)
      setLogs(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setLogs([])

      enqueueSnackbar('Ocorreu um erro ao buscar logs!', {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, id, userId])

  const fetchExportLogs = useCallback(async () => {
    try {
      await userService.exportLogs(id ?? userId)
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao exportar logs', {
        variant: 'error',
      })
    }
  }, [enqueueSnackbar, id, userId])

  useEffect(() => {
    fetchLogs()
  }, [fetchLogs])

  return (
    <>
      <div className="container-citymatch-table">
        <Box alignContent="right" display="grid">
          <div className="btn btn-light-primary font-weight-bold ml-auto" onClick={fetchExportLogs}>
            EXPORTAR
          </div>
        </Box>
        <div className="table-responsive" style={{overflowX: 'auto'}}>
          <TableLogs loading={loading} logs={logs} total={total} />
        </div>
      </div>
    </>
  )
}

export default ListLogs
