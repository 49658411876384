import React from 'react'
import {Route, Switch} from 'react-router-dom'
import ListCustomers from './ListCustomers'
import NewCustomer from './NewCustomer'

const ManagePage = () => {
  return (
    <Switch>
      <Route path="/manage-customers" exact component={ListCustomers} />
      <Route path="/manage-customers/new" exact component={NewCustomer} />
      <Route path="/manage-customers/:id" exact component={NewCustomer} />
    </Switch>
  )
}

export default ManagePage
