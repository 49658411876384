import httpClient from '../../client'

import apiRoutes from '../constants'

export const inactivateMany = async (idArray) => {
  try {
    await httpClient.post(`${apiRoutes.POST_USERS}/inactivateMany`, idArray)
  } catch (error) {
    throw Error(error.message)
  }
}
