import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Paper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  max-width: 49%;
  width: 100%;
`

export const StyledSpan = styled.span`
  font: normal normal bold 14px/19px Nunito;
`
