import authActions from 'app/modules/Auth/_redux/actionTypes'
import * as auth from 'app/modules/Auth/_redux/authRedux'
import authReducer from 'app/modules/Auth/_redux/reducer'
import citiesReducer from 'app/modules/Cities/redux'
import companiesReducer from 'app/modules/Companies/redux'
import usersReducer from 'app/modules/ManageUser/redux'
import politiciansReducer from 'app/modules/Politicians/redux'
import proposalsReducer from 'app/modules/Proposals/redux'
import statesReducer from 'app/modules/States/redux'
import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

const appReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  companies: companiesReducer,
  proposals: proposalsReducer,
  politicians: politiciansReducer,
  cities: citiesReducer,
  states: statesReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === authActions.Logout) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export function* rootSaga() {
  yield all([auth.saga()])
}
