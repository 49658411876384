import React from 'react'
import {Route, Switch} from 'react-router-dom'

import ListConnections from './ListConnections'

const CitiesPage = () => {
  return (
    <Switch>
      <Route path="/corporate-connections" exact component={ListConnections} />
    </Switch>
  )
}

export default CitiesPage
