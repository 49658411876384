import React from 'react'

const Icon = ({color}) => (
  <svg
    id="icon_tipo"
    data-name="icon/tipo"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42">
    <path id="bound" d="M0,0H42V42H0Z" fill="none" />
    <g id="shapes" transform="translate(4.332 4.332)">
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M5.883,0V11.765a5.883,5.883,0,0,0,5.883,5.883H23.531v1.6c0,2.773-1.505,4.278-4.278,4.278H4.278C1.505,23.531,0,22.026,0,19.253V4.278C0,1.505,1.505,0,4.278,0Z"
        transform="translate(0 9.805)"
        fill={color}
      />
      <path
        id="Rectangle_19_Copy_3"
        data-name="Rectangle 19 Copy 3"
        d="M4.278,0H19.253c2.773,0,4.278,1.505,4.278,4.278V19.253c0,2.773-1.505,4.278-4.278,4.278H4.278C1.505,23.531,0,22.026,0,19.253V4.278C0,1.505,1.505,0,4.278,0Z"
        transform="translate(9.804)"
        fill={color}
        opacity="0.3"
      />
    </g>
  </svg>
)

export default Icon
