import styled from 'styled-components'

const getColors = (type) => {
  const config = {
    city: {
      background: '#38383833',
      font: '#383838',
      icon: '#383838',
    },
    company: {
      background: '#2C6EB533',
      font: '#2C6EB5',
      icon: '#00428B',
    },
  }

  return config[type]
}

export const Container = styled.div`
  align-items: center;
  display: flex;
`

export const Icon = styled.div`
  font-size: ${({iconSize}) => iconSize};
`

export const IconContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: ${({height}) => height};
  width: ${({width}) => width};
  color: ${({type}) => getColors(type).font};
  background-color: ${({type}) => getColors(type).background};
  margin-right: 10px;
`

export const Label = styled.span`
  color: ${({type}) => getColors(type).font};
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
`
