import ListCompanies from 'app/modules/Companies/pages/ListCompanies'

import React from 'react'

import {Paper, ContainerHeader} from '../styles'

const CityCompanies = ({code, name, state}) => {
  return (
    <Paper className="city-politicians">
      <ContainerHeader>
        Principais Empresas em {name} ({new Date().getFullYear()})
      </ContainerHeader>

      <ListCompanies
        noMap
        hideSearch
        isDetailPage
        defaultFilters={{'city_code': code, 'state': state?.uf, 'sort[share_capital]': 'desc'}}
        defaultSort={{importance: 'desc'}}
        list={true}></ListCompanies>
    </Paper>
  )
}

export default CityCompanies
