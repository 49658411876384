import httpClient from '../../client'
import apiRoutes from '../constants'

const defaultConfig = {}
const getListCustomers = async (config) => {
  const {limit, page, format, sort, name, ...rest} = config ?? defaultConfig
  try {
    let sortQuery = ''

    if (sort) {
      sortQuery = Object.keys(sort)
        .map((key) => `sort[${key}]=${sort[key]}`)
        .join('&')
    }

    const params = {format, limit, page, name, ...rest}

    const {data} = await httpClient.get(`${apiRoutes.GET_LIST_CUSTOMERS}${sortQuery ? `?${sortQuery}` : ''}`, {
      params,
    })

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling getListCustomers with ${JSON.stringify({limit, page, name})}`, error)
    return error
  }
}

export default getListCustomers
