import React, {useState, useMemo} from 'react'
import {Form} from 'react-bootstrap'
import {Box, Collapse} from '@material-ui/core'
import {format, parseISO} from 'date-fns'

import {InputLabel, Label} from './styles'

const DateInterval = ({initialDate = null, finalDate = null, title, onChangeFirstDate, onChangeSecondDate}) => {
  const [showInputs, setShowInputs] = useState(true)
  const [firstDate, setFirstDate] = useState(initialDate)
  const [secondDate, setSecondDate] = useState(finalDate)

  const maxFinalDate = useMemo(() => format(new Date(), 'yyyy-MM-dd'), [])

  const maxStartDate = useMemo(() => {
    if (secondDate) {
      return format(parseISO(secondDate), 'yyyy-MM-dd')
    }
    return maxFinalDate
  }, [maxFinalDate, secondDate])

  const onSelectFirstDate = (e) => {
    onChangeFirstDate(e.target.value)
    setFirstDate(e.target.value)
  }

  const onSelectSecondDate = (e) => {
    onChangeSecondDate(e.target.value)
    setSecondDate(e.target.value)
  }

  return (
    <Box alignItems="start" display="flex" flexDirection="column" flex={1} width="100%">
      <Box alignItems="start" display="flex" flexDirection="row" justifyContent="space-between" flex={1} width="100%">
        <Label>{title}</Label>
        <i className={`fas fa-chevron-${showInputs ? 'down' : 'up'}`} onClick={() => setShowInputs(!showInputs)} />
      </Box>
      <Box width="100%">
        <Collapse in={showInputs}>
          <Box display="flex" flex={1} flexDirection="column" width="100%">
            <Box display="flex" flexDirection="column" flex={1} marginBottom="5px" width="100%">
              <InputLabel>Inicio</InputLabel>
              <Form.Control
                type="date"
                onChange={onSelectFirstDate}
                placeholder="10/02/2020"
                value={firstDate}
                max={maxStartDate}
              />
            </Box>
            <Box display="flex" flexDirection="column" flex={1} width="100%">
              <InputLabel>Fim</InputLabel>
              <Form.Control
                disabled={!firstDate}
                type="date"
                onChange={onSelectSecondDate}
                placeholder="20/02/2020"
                value={secondDate}
                max={maxFinalDate}
              />
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

export default DateInterval
