import React, {useState, useCallback, useEffect} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {CircularProgress, TextField} from '@material-ui/core'

import {companiesService} from 'app/services'

import {Column} from './styles'

const AutoCompletePartner = ({onSelectPartner, onRemoveState}) => {
  const [loading, setLoading] = useState(false)
  const [partners, setPartners] = useState([])
  const [inputText, setInputText] = useState('')
  const [open, setOpen] = useState(false)

  const getOptionLabel = ({name}) => `${name}`

  const onChange = (_, value) => {
    setInputText(value)
    onSelectPartner(value)
  }

  const fetchPartners = useCallback(async (text) => {
    try {
      setLoading(true)
      const partners = await companiesService.getPartners({name: text})

      if (partners.length > 0) {
        setPartners(partners)
      } else {
        setPartners([])
      }

      setLoading(false)
    } catch (e) {
      setPartners([])
      setLoading(false)
    }
  }, [])

  const onInputChange = (event) => {
    if (event && event.target.value !== 0) {
      fetchPartners(event.target.value)
    }
  }

  return (
    <Column>
      <Autocomplete
        size="small"
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        getOptionLabel={getOptionLabel}
        options={partners}
        onChange={onChange}
        onInputChange={onInputChange}
        noOptionsText="Nenhum item encontrado"
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              placeholder: 'Digite um nome',
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Column>
  )
}

export default AutoCompletePartner
