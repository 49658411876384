import {createSelector} from '@reduxjs/toolkit'

export const getCompaniesSelector = (state) => state.companies

export const getCurrentCompaniesPage = createSelector(getCompaniesSelector, ({page}) => page)

export const getCompaniesColumns = createSelector(getCompaniesSelector, ({columns}) => columns)

export const hasLoadedOrdering = createSelector(getCompaniesSelector, ({filters}) => !!filters.ordering?.orders?.length)

export const companiesOrders = createSelector(getCompaniesSelector, ({filters}) => filters.ordering.orders)

export const getActiveOrder = createSelector(getCompaniesSelector, ({filters}) => filters.ordering.activeSort)

export const showMap = createSelector(getCompaniesSelector, ({universalSearch}) => universalSearch.showMap)

export const universalSearch = createSelector(getCompaniesSelector, ({universalSearch}) => universalSearch)

export const getCurrentLimit = createSelector(getCompaniesSelector, ({limit}) => limit)

export const getSearchParams = createSelector(getCompaniesSelector, ({universalSearch}) => ({
  hasAppliedFilters: universalSearch.hasAppliedFilters,
  searchParams: universalSearch.searchParams,
  showOnlyFavorites: universalSearch.showOnlyFavorites,
}))
