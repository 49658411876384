import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Button, Form } from 'react-bootstrap'
import IntlCurrencyInput from 'react-intl-currency-input'

import { Container, Title, Label } from './styles'

const ProposalDetails = ({ formik, onNextStep, onPreviousStep }) => {
  const {
    handleChange,
    setFieldValue,
    values: { required_investment, total_investment, private_proposal_description, project_category },
  } = formik

  const categories = useMemo(
    () => [
      {
        label: 'Implantação',
        value: 'implantation',
      },
      {
        label: 'Expansão',
        value: 'expansion',
      },
      {
        label: 'Reativação',
        value: 'reactivation',
      },
      {
        label: 'Diversificação',
        value: 'diversification',
      },
      {
        label: 'Busca por parceiros/investidores',
        value: 'search_partner_investor',
      },
    ],
    []
  )

  const onChange = (e) => {
    const { checked } = e.target

    setFieldValue('link_with_city', checked)
  }

  return (
    <Container style={{ padding: 0 }}>
      <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
        <Box marginBottom="40px">
          <Title>Descrição do projeto</Title>
        </Box>
        <Box display="flex" flex={1} flexDirection="column">
          <Box marginBottom="10px">
            <Label>Categoria da oportunidade*</Label>
          </Box>
          <Box display="flex" flex={1} flexDirection="column" marginBottom="20px">
            {categories.map((category) => (
              <Box display="flex" key={category.value} marginBottom="15px">
                <Form.Check
                  name="project_category"
                  onChange={handleChange}
                  type="radio"
                  id={`radio-${category.value}`}
                  value={category.value}
                />
                <label
                  for={`radio-${category.value}`}
                  style={{ color: '#1D1D1D', fontSize: '14px', fontFamily: 'Nunito Sans' }}
                >
                  {category.label}
                </label>
              </Box>
            ))}
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" marginBottom="20px">
          <Box marginBottom="10px" marginRight="20px">
            <Box alignItems="flex-end" display="flex" flex={1} flexDirection="row">
              <Box display="flex" flex={1} flexDirection="column">
                <Form.Label>
                  <b>Valor total do investimento a ser realizado (R$)</b>
                </Form.Label>
                <IntlCurrencyInput
                  autoComplete="off"
                  placeholder="Ex.: R$ 800.000,00"
                  name="total_investment"
                  className="form-control"
                  currency="BRL"
                  config={{
                    locale: 'pt-BR',
                    formats: {
                      number: {
                        BRL: {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      },
                    },
                  }}
                  onChange={(ev, value) => handleChange({ target: { value, name: 'total_investment' } })}
                  value={total_investment}
                />
              </Box>
            </Box>
          </Box>
          <Box marginBottom="10px">
            <Box alignItems="flex-end" display="flex" flex={1} flexDirection="row">
              <Box display="flex" flex={1} flexDirection="column">
                <Form.Label>
                  <b>Valor de necessidade do investimento (R$)</b>
                </Form.Label>
                <IntlCurrencyInput
                  autoComplete="off"
                  placeholder="Ex.: R$ 800.000,00"
                  name="required_investment"
                  className="form-control"
                  currency="BRL"
                  config={{
                    locale: 'pt-BR',
                    formats: {
                      number: {
                        BRL: {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      },
                    },
                  }}
                  onChange={(ev, value) => handleChange({ target: { value, name: 'required_investment' } })}
                  value={required_investment}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flex={1} flexDirection="column">
          <Label>Breve descrição do projeto*</Label>
          <Form.Label>
            Descrever os objetivos do projeto; os produtos e tecnologias envolvidos; o local de instalação. Inserir
            outras informações que a empresa julgar convenientes. (Máx. 500 caracteres.)
          </Form.Label>
          <Form.Control
            autoComplete="off"
            as="textarea"
            name="private_proposal_description"
            onChange={handleChange}
            value={private_proposal_description}
            style={{ width: '100%' }}
            maxLength={500}
            rows={5}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>
        <Button onClick={onNextStep} disabled={!project_category || !private_proposal_description}>
          Próximo passo ⇀
        </Button>
      </Box>
    </Container>
  )
}

export default ProposalDetails
