import packageJson from '../../../../package.json'

const STORAGE_KEY = `@CityMatch/${packageJson.version}`

const STORAGE_KEYS = {
  CITIES: `${STORAGE_KEY}/cities`,
  STATES: `${STORAGE_KEY}/states`,
  TOKEN: `${STORAGE_KEY}/token`,
  PROPOSALS: `${STORAGE_KEY}/proposals`,
  POLITICIANS: `${STORAGE_KEY}/politicians`,
  COMPANIES: `${STORAGE_KEY}/companies`,
  USERS: `${STORAGE_KEY}/users`,
  CUSTOMERS: `${STORAGE_KEY}/customers`,
}

export default {
  STORAGE_KEY,
  STORAGE_KEYS,
}
