import styled from 'styled-components'
import {Popover} from 'react-bootstrap'

export const ProposalColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const LogoContainer = styled.div`
  margin-right: 13px;
`

export const ProposalName = styled.span`
  color: #00428b;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  max-width: 370px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const FavoriteIcon = styled.i`
  color: ${({isFavorite}) => (isFavorite ? '#FFA800' : '#C9C9C9')};
  cursor: pointer;
`

export const StyledPopover = styled(Popover)`
  width: 400px;
  overflow-y: scroll;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;

  .popover-body {
    padding: 0px !important;
  }
`
