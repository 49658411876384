import React from 'react'
import {IconDetail, BottomIcons, IConContainer} from '../styles'

import {format} from 'date-fns'
import {DollarSign} from 'react-feather'

const DEFAULT_STYLE = {
  color: '#383838',
}

const formatCurrency = (amount) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)

const humanizeDate = (date) => {
  const months = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  }

  var dateObj = new Date(date)
  var month = dateObj.getUTCMonth() + 1 //months from 1-12
  var day = dateObj.getUTCDate()
  var year = dateObj.getUTCFullYear()

  return `${day} de ${months[month]} de ${year}`
}

const BottomDetailIcons = ({created_at, city, project, benefits}) => {
  return (
    <BottomIcons>
      <IConContainer>
        <IconDetail className="fa fa-calendar fa-2x" aria-hidden="true" />
        <div>
          <span className="secundary-title">Data de criação</span>
          <br />
          <span className="text-description-footer-1">{humanizeDate(created_at)}</span>
        </div>
      </IConContainer>
      {city ? (
        <IConContainer>
          <IconDetail className="fa fa-map-marker fa-2x" aria-hidden="true" />
          <div>
            <span className="secundary-title">Localização</span>
            <br />
            <span className="text-description-footer-1">
              {city.name} - {city.state.uf}
            </span>
          </div>
        </IConContainer>
      ) : null}
      {project ? (
        <IConContainer>
          <IconDetail className="fa fa-file-text fa-2x" aria-hidden="true" />
          <div>
            <span className="secundary-title">Categoria</span>
            <br />
            <span className="text-description-footer-1">{project.category_description}</span>
          </div>
        </IConContainer>
      ) : null}
      {project ? (
        <IConContainer>
          <IconDetail className="fa fa-database fa-2x" aria-hidden="true" />
          <div>
            <span className="secundary-title">Investimento necessário</span>
            <br />
            <span className="text-description-footer-1">{formatCurrency(project.required_investment)}</span>
          </div>
        </IConContainer>
      ) : null}
      {project ? (
        <IConContainer>
          <IconDetail className="fa fa-money fa-2x" aria-hidden="true" />
          <div>
            <span className="secundary-title">Investimento total</span>
            <br />
            <span className="text-description-footer-1">{formatCurrency(project.total)}</span>
          </div>
        </IConContainer>
      ) : null}
      {benefits.length ? (
        <IConContainer>
          <IconDetail className="fa fa-money fa-2x" aria-hidden="true" />
          <div>
            <span className="secundary-title">Incentivos disponíveis ao investidor</span>
            <br />
            <span className="text-description-footer-1">{benefits.map((bn) => bn.type_description).join(' · ')}</span>
          </div>
        </IConContainer>
      ) : null}
    </BottomIcons>
  )
}

export default BottomDetailIcons
