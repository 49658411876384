import httpClient from '../../client'

import apiRoutes from '../constants'

const acceptContract = async ({customer}) => {
  try {
    const {data} = await httpClient.put(`customers/${customer}/${apiRoutes.ACCEPT_CONTRACT}`)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    return error
  }
}

export default acceptContract
