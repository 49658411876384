import React, {Component} from 'react'
import {Button} from 'react-bootstrap'
import {Container, LogoContainer, Message} from './styles'

import {CityMatchLogo} from 'app/components'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({hasError: true})

    console.error(error, errorInfo)
  }

  refreshPage = () => {
    window.location.reload()
  }

  render() {
    const {hasError} = this.state

    if (hasError) {
      return (
        <Container>
          <LogoContainer>
            <CityMatchLogo height="220px" />
          </LogoContainer>
          <Message>
            Ocorreu um erro ao processar sua solicitaçao.
            <br /> Por favor, tente novamente.
          </Message>
          <Button variant="primary" style={{backgroundColor: '#1E7BE1'}} onClick={this.refreshPage}>
            Atualizar Página
          </Button>
        </Container>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
