import ListCompanies from 'app/modules/Companies/pages/ListCompanies'

import React from 'react'

import {Paper, ContainerHeader} from '../styles'

const CityCompanies = ({name, uf}) => {
  return (
    <Paper className="state-politicians">
      <ContainerHeader>
        Principais Empresas no {name} ({new Date().getFullYear()})
      </ContainerHeader>

      <ListCompanies
        noMap
        hideSearch
        isDetailPage
        defaultFilters={{'state': uf, 'sort[share_capital]': 'desc'}}
        defaultSort={{importance: 'desc'}}
        list={true}></ListCompanies>
    </Paper>
  )
}

export default CityCompanies
