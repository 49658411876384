import {Box} from '@material-ui/core'
import ConexoesSocietariasIcone from 'app/modules/Companies/components/Icon/ConexoesSocietariasIcone'
import React from 'react'
import {Button} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'

import {ContainerHeader, DetailContainer} from '../styles'
import {Container, Paper} from './styles'

const AddressAndData = ({
  email,
  city,
  phone,
  socios,
  partners,
  street_type,
  street_name,
  street_number,
  postal_code,
}) => {
  socios = socios || []
  partners = partners || []

  const hasSocios = socios.length > 0
  const hasPartners = partners.length > 0

  const history = useHistory()

  return (
    <Container>
      <Paper>
        <ContainerHeader>
          <span>Quadro Societário</span>
        </ContainerHeader>

        {!hasSocios && (
          <DetailContainer>
            <Box>
              <Box flex={1}>
                <h3 className="secundary-title">Dado indisponível</h3>
              </Box>
              <Box flex={1}>
                <p>
                  Em conformidade com a LGPD, informações sensíveis podem não ser exibidas quando não estão disponíveis.
                  Se algum campo estiver em branco, isso significa que a informação correspondente não foi fornecida
                  pelos dados disponíveis.
                </p>
              </Box>
            </Box>
          </DetailContainer>
        )}

        {hasSocios && (
          <DetailContainer>
            <Box display={'flex'}>
              <Box flex={1}>
                <span className="secundary-title">NOME</span>
              </Box>
              <Box flex={1}>
                <span className="secundary-title">QUALIFICAÇÃO</span>
              </Box>
              <Box flex={1}>
                <span className="secundary-title">CONEXÕES SOCIETÁRIAS</span>
              </Box>
            </Box>

            {socios.map((socio, kk) => (
              <Box
                key={socio.cnpj}
                display={'flex'}
                style={{borderBottom: '1px solid #E4E4E4', paddingBottom: 10, paddingTop: 10}}>
                <Box
                  flex={1}
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    fontFamily: 'Nunito Sans',
                    fontSize: 16,
                    fontWeight: 'normal',
                  }}>
                  <span>{socio.nome_socio}</span>
                  <br />
                  <span
                    className="text-description-footer-1"
                    style={{
                      color: '#007BFF',
                    }}>
                    {socio.documento_socio}
                  </span>
                </Box>

                <Box
                  flex={1}
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    fontFamily: 'Nunito Sans',
                    fontSize: 16,
                    fontWeight: 'normal',
                  }}>
                  <span style={{textTransform: 'uppercase'}}>{partners[kk]?.qualification?.text}</span>
                </Box>

                <Box
                  flex={1}
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    fontFamily: 'Nunito Sans',
                    fontSize: 16,
                    fontWeight: 'normal',
                  }}>
                  <ConexoesSocietariasIcone
                    style={{cursor: 'pointer'}}
                    title={'Abrir Conexões Societárias de ' + socio.nome_socio}
                    color="#1e7be1"
                    onClick={() =>
                      history.push(
                        '/corporate-connections?name=' + socio.nome_socio + '&document=' + socio.documento_socio,
                      )
                    }
                  />
                </Box>
              </Box>
            ))}
          </DetailContainer>
        )}
      </Paper>
      <Paper>
        <ContainerHeader>Contatos</ContainerHeader>
        <DetailContainer>
          <div
            className="d-flex justify-content-between w-100 mb-5"
            style={{borderBottom: '1px solid #E4E4E4', paddingBottom: 10, paddingTop: 10}}>
            <div style={{width: '100%', textAlign: 'left'}}>
              <span className="secundary-title">Email</span>
              <br />
              <span className="text-description-footer-1">{email}</span>
            </div>

            <div style={{width: '100%', textAlign: 'left'}}>
              <span className="secundary-title">Telefones</span>
              <br />
              {phone ? (
                <span className="text-description-footer-1">
                  ({phone[1].ddd}) {phone[1].phone}
                </span>
              ) : null}
            </div>
          </div>
          <div style={{width: '100%', borderBottom: '1px solid #E4E4E4', paddingBottom: 10, paddingTop: 10}}>
            <span className="secundary-title">Endereço</span>
            <p>
              {street_type} {street_name}, {street_number}, {city} - CEP: {postal_code}
            </p>
          </div>
        </DetailContainer>
      </Paper>
    </Container>
  )
}

export default AddressAndData
