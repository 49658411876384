import React from 'react'
import moment from 'moment'
import {Container, Icon, Label, IconContainer} from './styles'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const Status = ({iconSize = '8px', height = '20px', type, status, width = '20px', lg, lostAt, completedAt}) => {
  const getStatus = () => (status === 'open' ? 'Ativa' : status == 'lost' ? 'Perdida' : 'Ganha / Concretizada')

  return (
    <Container status={status}>
      <IconContainer type={type} style={{display: 'flex', alignItems: 'center'}}>
        <Icon status={status} iconSize={iconSize} height={height} width={width}>
          <div style={lg ? {width: 16, height: 16, borderWidth: 5} : {}} />
        </Icon>
      </IconContainer>

      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="language-panel-tooltip">
            {status == 'completed' && completedAt
              ? `Concretizada em ${moment(completedAt).format('DD/MM/YYYY hh:mm')}`
              : null}
            {status == 'lost' && lostAt ? `Perdida em ${moment(lostAt).format('DD/MM/YYYY hh:mm')}` : null}
            {status == 'open' && (completedAt || lostAt) ? `Oportunidade foi re-ativada` : null}
            {status == 'open' && !(completedAt || lostAt) ? `Oportunidade ativa` : null}
          </Tooltip>
        }>
        <Label status={status}>{getStatus()}</Label>
      </OverlayTrigger>
    </Container>
  )
}

export default Status
