import {Box} from '@material-ui/core'
import StateCitySelect from 'app/components/form/StateCitySelect'
import * as proposalsSelectors from 'app/modules/Proposals/redux/selectors'
import getCities from 'app/services/cities/getCities'
import {useSnackbar} from 'notistack'
import React, {useCallback, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import ReactInputMask from 'react-input-mask'
import {useSelector} from 'react-redux'

import {Container, Label, Title} from './styles'

const SelectProposalType = ({formik, onNextStep, onPreviousStep}) => {
  const {
    handleChange,
    setFieldValue,
    values: {city, name, incentives, type, address},
  } = formik
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setLoading] = useState(false)
  const availableIncentives = useSelector(proposalsSelectors.getAvailaleBenefits)

  const onChange = (incentive) => (e) => {
    const {checked} = e.target
    const updatedIncentives = {...incentives}

    if (checked) {
      updatedIncentives[incentive] = ''
    } else {
      delete updatedIncentives[incentive]
    }

    setFieldValue('incentives', updatedIncentives)
  }

  const isSelected = useCallback((incentive) => Object.keys(incentives).some((i) => i === incentive), [incentives])

  const renderIncentive = (incetive) => {
    return (
      <Box display="flex" key={incetive.type} marginBottom="15px">
        <Form.Check
          checked={isSelected(incetive.type)}
          onChange={onChange(incetive.type)}
          id={`checkbox-${incetive.type}`}
          type="checkbox"
        />
        <label
          htmlFor={`checkbox-${incetive.type}`}
          style={{color: '#8C8C8C', fontSize: '16px', fontFamily: 'Nunito', marginLeft: 10}}>
          {incetive.description}
        </label>
      </Box>
    )
  }

  const searchCep = useCallback(
    async (e) => {
      try {
        const postalCode = e.target.value

        const cleanPostalCode = postalCode?.match(/\d+/g)?.join('')

        setFieldValue('address.zip', postalCode)

        if (cleanPostalCode?.length === 8) {
          setLoading(true)

          const response = await (await fetch(`https://viacep.com.br/ws/${cleanPostalCode}/json`)).json()

          if (response.erro) {
            enqueueSnackbar('Nenhum CEP encontrado', {
              variant: 'warning',
            })
          } else {
            setFieldValue('address.street', response.logradouro)
            setFieldValue('address.neighborhood', response.bairro)

            getCities({code: response.ibge}).then((res) => {
              setFieldValue('address.city_id', res.data[0])
              setLoading(false)
            })
          }
          setLoading(false)
        }
      } catch (error) {
        enqueueSnackbar('Ocorreu um erro ao buscar o endereco', {
          variant: 'warning',
        })
      }
    },
    [enqueueSnackbar, setFieldValue],
  )

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" marginBottom="40px">
        <Box marginBottom="20px">
          <Title>Localização da oportunidade</Title>
        </Box>
        <Box display="flex" flexDirection="column" marginBottom="40px">
          <Label>Nome</Label>
          <Form.Label>Como esta oportunidade deve se chamar?</Form.Label>
          <Form.Control
            placeholder="Ex.: Fábrica de Papel Celulose"
            name="name"
            onChange={handleChange}
            value={name}
            autoComplete="off"
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Label style={{marginBottom: 25}}>Escolha o Município onde deseja cadastrar a Oportunidade</Label>
          <StateCitySelect showCustomerOnly changeCity={(cityId) => setFieldValue('city', cityId)}></StateCitySelect>
        </Box>
        {type === 'city' && (
          <Box mt="20px">
            <Box display="flex" gridGap={'15px'}>
              <Box display="flex" flex={1} flexDirection="column" marginBottom="40px">
                <Form.Label>
                  <b>CEP</b>
                </Form.Label>
                <ReactInputMask
                  autoComplete="off"
                  className="form-control"
                  name="address.zip"
                  onChange={searchCep}
                  disabled={loading}
                  value={address.zip}
                  required
                  placeholder="01212-312"
                  mask="99999-999"
                />
              </Box>
              <Box display="flex" flex={3} flexDirection="column" marginBottom="40px">
                <Label>Bairro</Label>
                <Form.Control
                  placeholder="Bairro"
                  name="address.neighborhood"
                  onChange={handleChange}
                  required
                  value={address.neighborhood}
                  autoComplete="off"
                />
              </Box>
            </Box>
            <Box display="flex" gridGap={'15px'}>
              <Box display="flex" flex={3} flexDirection="column" marginBottom="40px">
                <Label>Rua</Label>
                <Form.Control
                  placeholder="Rua"
                  name="address.street"
                  onChange={handleChange}
                  required
                  value={address.street}
                  autoComplete="off"
                />
              </Box>
              <Box display="flex" flex={1} flexDirection="column" marginBottom="40px">
                <Label>Número</Label>
                <Form.Control
                  placeholder="Número"
                  name="address.number"
                  required
                  onChange={handleChange}
                  value={address.number}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <Box>
        <Title>Incentivos da oportunidade</Title>
      </Box>

      <Box display="flex" flexDirection="column">
        <Label style={{marginBottom: 30}}>Incentivos disponíveis ao Investidor</Label>
        <Box display="flex" flexDirection="column">
          {availableIncentives.map(renderIncentive)}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>
        <Button onClick={onNextStep} disabled={!name || !city || !Object.keys(incentives).length}>
          Próximo passo ⇀
        </Button>
      </Box>
    </Container>
  )
}

export default SelectProposalType
