import {createSelector} from '@reduxjs/toolkit'

export const getProposalsSelector = (state) => state.proposals

export const getCurrentProposalsPage = createSelector(getProposalsSelector, ({page}) => page)

export const getProposalsColumns = createSelector(getProposalsSelector, ({columns}) => columns)

export const hasLoadedOrdering = createSelector(getProposalsSelector, ({filters}) => !!filters.ordering?.orders?.length)

export const proposalsOrders = createSelector(getProposalsSelector, ({filters}) => filters.ordering.orders)

export const getActiveOrder = createSelector(getProposalsSelector, ({filters}) => {
  return filters.ordering.activeSort
})

export const showMap = createSelector(getProposalsSelector, ({universalSearch}) => universalSearch.showMap)

export const universalSearch = createSelector(getProposalsSelector, ({universalSearch}) => universalSearch)

export const getSearchParams = createSelector(getProposalsSelector, ({universalSearch}) => ({
  actualZoom: universalSearch.actualZoom,
  hasAppliedFilters: universalSearch.hasAppliedFilters,
  searchParams: universalSearch.searchParams,
  centerMap: universalSearch.centerMap,
  showOnlyFavorites: universalSearch.showOnlyFavorites,
}))

export const getCurrentLimit = createSelector(getProposalsSelector, ({limit}) => limit)

export const getAvailaleBenefits = createSelector(getProposalsSelector, ({newProposal}) => newProposal.benefits)
