import React, {useEffect, useState, useCallback} from 'react'
import {useParams} from 'react-router'
import {companiesService} from 'app/services'
import DetailCompanySkeleton from './skeleton'

import BasicInfo from './BasicInfo'
import Location from './Location'
import AddressAndData from './AddressAndData'
import Attachments from './Attachments'
import {useDispatch} from 'react-redux'
import {changeActualEntity} from 'app/modules/Cities/redux'
import Relateds from 'app/modules/Companies/pages/DetailCompany/Relateds'
import CityContact from './Contact'

function DetailCompany() {
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState()
  const dispatch = useDispatch()

  const {companyId} = useParams()

  const fetchCompany = useCallback(async () => {
    try {
      setLoading(true)
      const data = await companiesService.getCompany({document: companyId})
      setCompany(data)
      setLoading(false)

      dispatch(
        changeActualEntity({
          name: data.business_name,
          id: data.id,
        }),
      )
    } catch (e) {
      setLoading(false)
    }
  }, [companyId])

  useEffect(() => {
    fetchCompany()
  }, [fetchCompany])

  if (loading) {
    return <DetailCompanySkeleton />
  }

  return (
    <div className="d-flex flex-column container-citymatch-table-cards">
      {company && <BasicInfo {...company} />}
      {company && <Location {...company} />}
      {company && <AddressAndData {...company} />}
      {company && <Relateds CNPJ={company.cnpj} />}

      {/* <CountyInfo {...company} /> */}
      {/* {company ? <CityPoliticians cityId={company.id} showHeaderCity /> : null} */}
      {company?.info?.contacts && (
        <CityContact
          contacts={company.info.contacts.map((contact) => {
            contact['linkedin'] = contact['site']
            return contact
          })}
        />
      )}

      {company?.info?.multimedia && <Attachments multimedia={company.info.multimedia} />}
    </div>
  )
}

export default DetailCompany
