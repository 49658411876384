import httpClient from '../../client'

import apiRoutes from '../constants'

const getNotifications = async (category) => {
  try {
    const {data} = await httpClient.get(apiRoutes.GET_NOTIFICATIONS, {params: {category, is_read: 0}})

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    return error
  }
}

export default getNotifications
