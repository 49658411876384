import React, {useCallback, useEffect, useState} from 'react'
import {Box, Grid} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'
import * as proposalsSelectors from 'app/modules/Proposals/redux/selectors'
import {useSelector} from 'react-redux'

import {Title, Container} from 'app/modules/Cities/Pages/EditCitie/styles'

import getBenefits from 'app/services/benefits/getBenefits'
import {getVocations} from 'app/services/location'
import PropTypes from 'prop-types'

const LocationVocation = ({title, location, onSubmit, submiting, handleChangeRegionalVocation}) => {
  const [allbenefits, setAllBenefits] = useState([])

  useEffect(() => {
    getVocations().then((res) => setAllBenefits(res))
  }, [])

  const isSelected = useCallback(
    (incentive) => location.vocations.some((i) => (i.description ? i.description === incentive : i === incentive)),
    [location],
  )

  const renderIncentive = (incetive) => {
    return (
      <Box display="flex" flexDirection="column" key={incetive.type} marginBottom="15px">
        <Box display="flex">
          <Form.Check
            checked={isSelected(incetive)}
            onChange={() => handleChangeRegionalVocation(incetive)}
            id={`checkbox-${incetive}`}
            type="checkbox"
          />
          <label
            for={`checkbox-${incetive}`}
            style={{color: '#8C8C8C', fontSize: '16px', fontFamily: 'Nunito', marginLeft: 10}}>
            {incetive}
          </label>
        </Box>
        {/* <Box>{isSelected(incetive.type) && <Form.Control
          autoComplete="off"
          as="textarea"
          required
          placeholder='Descrição do incentivo'
          onChange={(ev) => handleChangeBenefits(incetive, { description: ev.target.value })}
          value={getDescription(incetive)}
          style={{ maxWidth: 600 }}
          rows={3}
        />} </Box> */}
      </Box>
    )
  }

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>{title}</Title>
          </Box>
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">{allbenefits?.map(renderIncentive)}</Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

LocationVocation.defaultProps = {}
LocationVocation.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  submiting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleChangeRegionalVocation: PropTypes.func.isRequired,
}

export default LocationVocation
