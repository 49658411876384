import styled from 'styled-components'
import {Popover} from 'react-bootstrap'

export const ProposalName = styled.div`
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  overflow: hidden;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
  margin-bottom: 5px;
  height: 40px;

  text-align: left;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`

export const InfoContainer = styled.div`
  display: flex;
  max-width: 90%;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 19px;
`

export const IconDetail = styled.span`
  font-size: 18px;
  margin-right: 5px;
  color: #007bff33;
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4rem;
`

export const Bottom = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 14px;
    color: #8c8c8c;
    margin-right: 10px;
    a {
      font-size: 14px;
      text-decoration: underline;
      color: #8c8c8c;
      display: block;
    }
  }
`

export const LogoContainer = styled.div`
  margin-right: 13px;
`

export const FavoriteIcon = styled.i`
  color: ${({isFavorite}) => (isFavorite ? '#FFA800' : '#C9C9C9')};
  cursor: pointer;
`

export const StyledPopover = styled(Popover)`
  overflow-y: scroll;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;

  .popover-body {
    padding: 0px !important;
  }
`
