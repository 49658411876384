import styled from 'styled-components'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  padding-bottom: 50px;
`

export const BlockTitle = styled.span`
  color: #1e7be1;
  font-family: 'Nunito', sans-serif;
  font-size: 10.5px;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
`

export const BlockValue = styled.span`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
`

export const Title = styled.span`
  color: #1e7be1;
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: bold;
`

export const Label = styled.label`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: bold;
`
