import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ListLogs from './ListLogs'
import ListUsers from './ListUsers'
import NewUser from './NewUser'
import { EditUser } from './EditUser'

const ManagePage = () => {
  return (
    <Switch>
      <Route path="/manage-users" exact component={ListUsers} />
      <Route path="/manage-users/new" exact component={NewUser} />
      <Route path="/manage-users/logs/:id" exact component={ListLogs} />
      <Route path="/manage-users/edit/:id" exact component={EditUser} />
    </Switch>
  )
}

export default ManagePage
