import httpClient from '../../client'

import apiRoutes from '../constants'

const getBenefits = async () => {
  try {
    const {data} = await httpClient.get(apiRoutes.GET_BENEFITS)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling benefits`, error)

    return error
  }
}

export default getBenefits
