import React, {useState, useEffect, useCallback} from 'react'
import {Button, Form} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {Box} from '@material-ui/core'

import {cleanSearchParams, changeSearchParams, setAppliedFilters} from 'app/modules/Cities/redux'
import * as selectors from 'app/modules/Cities/redux/selectors'

import {Slider} from 'app/components'

import {CleanFilterButton, Container, FormTitle, FormContainer, Switch} from './styles'

const marksPopulation = [
  {
    label: '2.000',
    value: 2000,
  },
  {
    label: '10.500',
    value: 10500,
  },
]

const marksPopulationDensity = [
  {
    label: '2.800',
    value: 2.8,
  },
  {
    label: '5.000',
    value: 15000,
  },
]

const marksIdhm = [
  {
    label: '0%',
    value: 0,
  },
  {
    label: '100%',
    value: 100,
  },
]

const marksResidentPopulation = [
  {
    label: '5.000',
    value: 5000,
  },
  {
    label: '10500',
    value: 10500,
  },
]

const marksSus = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '200',
    value: 200,
  },
]

const marksArea = [
  {
    label: '300000 km2 ',
    value: 300000,
  },
  {
    label: '120000 km2',
    value: 120000,
  },
]

const marksPopulationBusy = [
  {
    label: '10%',
    value: 10,
  },
  {
    label: '80%',
    value: 80,
  },
]

const marksInvestment = [
  {
    label: '5.000',
    value: 5.0,
  },
  {
    label: '10500',
    value: 10500,
  },
]

const SearchPopover = ({onClose, style, ...rest}, ref) => {
  const dispatch = useDispatch()

  const {searchParams} = useSelector(selectors.getSearchParams)

  const {
    average_salary,
    busy_people,
    nominal_monthly,
    schooling_rate,
    ideb,
    elementary_school_enrollment,
    elementary_school_teachers,
    high_school_teachers,
    high_schools,
    elementary_schools,
  } = searchParams

  const [forceReMount, setReForceMount] = useState(false)

  useEffect(() => {
    setReForceMount(false)
  }, [forceReMount])

  const onChangeInvestmentRange = useCallback((_, value) => {
    // setInvestmentRange(value);
  }, [])

  const onCommitInvestmentRange = (_, value) => {}

  const cleanSearchFilters = () => {
    dispatch(cleanSearchParams())
    setReForceMount(true)
  }

  const applySearchFilter = () => {
    dispatch(changeSearchParams(searchParams))
    dispatch(setAppliedFilters(true))
  }

  const closePopover = useCallback(() => onClose(), [])

  const onChangeAverageSalary = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        average_salary: !average_salary,
      }),
    )
  }

  const onChangeBusyPeople = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        busy_people: !busy_people,
      }),
    )
  }

  const onChangeNominalMonthly = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        nominal_monthly: !nominal_monthly,
      }),
    )
  }
  const onChangeSchoolingRate = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        schooling_rate: !schooling_rate,
      }),
    )
  }

  const onChangeIdeb = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        ideb: !ideb,
      }),
    )
  }
  const onChangeElementarySchoolEnrollment = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        ideb: !ideb,
      }),
    )
  }

  const onChangeElementarySchoolTeachers = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        elementary_school_teachers: !elementary_school_teachers,
      }),
    )
  }

  const onChangeHighSchoolTeachers = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        high_school_teachers: !high_school_teachers,
      }),
    )
  }

  const onChangeHighSchools = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        high_schools: !high_schools,
      }),
    )
  }

  const onChangeElementarySchools = () => {
    dispatch(
      changeSearchParams({
        ...searchParams,
        elementary_schools: !elementary_schools,
      }),
    )
  }

  return (
    <Container ref={ref} {...rest} style={style} placement="left">
      <FormTitle>Filtrar por:</FormTitle>
      <div className="dropdown-divider" />
      <>
        {forceReMount ? null : (
          <>
            <Form style={{display: 'flex', justifyContent: 'space-between'}}>
              <FormContainer>
                <Form.Row>
                  {/* <AutoCompleteState
                onRemoveState={onRemoveState}
                onSelectState={onSelectState}
                selectedStates={selectedStates}
              /> */}
                </Form.Row>
                <Form.Row>
                  <Box display="flex" flex={1} marginBottom="20px">
                    <Slider
                      label="População"
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[2000, 15000]}
                      module
                      min={0}
                      max={20000}
                      marks={marksPopulation}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
                <Form.Row></Form.Row>
                <Form.Row>
                  <Box display="flex" flex={1} marginBottom="20px">
                    <Slider
                      label="Densidade populacional"
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[2800, 4500]}
                      module
                      min={0}
                      max={5000}
                      marks={marksPopulationDensity}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
                <Form.Row>
                  <Box display="flex" flex={1} marginBottom="20px">
                    <Slider
                      label="IDHM"
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[0, 10]}
                      module
                      min={0}
                      max={100}
                      marks={marksIdhm}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
                <Form.Row>
                  <Box display="flex" flex={1} marginBottom="20px">
                    <Slider
                      label="População residente"
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[2000, 10500]}
                      module
                      min={0}
                      max={15000}
                      marks={marksResidentPopulation}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
                <Form.Row>
                  <Switch
                    id="average_salary"
                    type="switch"
                    label="Salário médio mensal"
                    value={average_salary}
                    onChange={onChangeAverageSalary}
                  />
                </Form.Row>
                <Form.Row>
                  <Switch
                    id="busy_people"
                    type="switch"
                    label="Pessoal ocupado"
                    value={busy_people}
                    onChange={onChangeBusyPeople}
                  />
                </Form.Row>
                <Form.Row>
                  <Box display="flex" flex={1} marginBottom="20px">
                    <Slider
                      label="População ocupada"
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[10, 80]}
                      module
                      min={0}
                      max={100}
                      marks={marksPopulationBusy}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
              </FormContainer>
              <FormContainer display="flex" style={{flexWrap: 'wrap'}}>
                <Form.Row>
                  <Switch
                    id="nominal_monthly"
                    type="switch"
                    label="Rendimento nominal mensal"
                    value={nominal_monthly}
                    onChange={onChangeNominalMonthly}
                  />
                </Form.Row>
                <Form.Row>
                  <Switch
                    id="schooling_rate"
                    type="switch"
                    label="Taxa de escolarização de 6 a 14 anos de idade"
                    value={schooling_rate}
                    onChange={onChangeSchoolingRate}
                  />
                </Form.Row>
                <Form.Row>
                  <Switch
                    id="ideb"
                    type="switch"
                    label="IDEB - anos finais do ensino fundamental (Rede pública)"
                    value={ideb}
                    onChange={onChangeIdeb}
                  />
                </Form.Row>
                <Form.Row>
                  <Switch
                    id="elementary_school_enrollment"
                    type="switch"
                    label="Matrículas no ensino fundamental"
                    value={elementary_school_enrollment}
                    onChange={onChangeElementarySchoolEnrollment}
                  />
                </Form.Row>
                <Form.Row>
                  <Box display="flex" flex={1} marginBottom="20px" width="100%">
                    <Slider
                      label="Matrículas no ensino médio"
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[3500, 20000]}
                      module
                      min={0}
                      max={20000}
                      marks={marksSus}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
                <Form.Row>
                  <Switch
                    id="elementary_school_teachers"
                    type="switch"
                    label="Docentes de ensino fundamental"
                    value={elementary_school_teachers}
                    onChange={onChangeElementarySchoolTeachers}
                  />
                </Form.Row>
                <Form.Row>
                  <Switch
                    id="high_school_teachers"
                    type="switch"
                    label="Docentes de ensino médio"
                    value={high_school_teachers}
                    onChange={onChangeHighSchoolTeachers}
                  />
                </Form.Row>
                <Form.Row>
                  <Switch
                    type="switch"
                    label="Escolas de ensino fundamental"
                    value={elementary_schools}
                    id="elementary_schools"
                    onChange={onChangeElementarySchools}
                  />
                  <Box display="flex" flex={1} marginBottom="20px" width="100%">
                    <Slider
                      label=""
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[0, 200]}
                      module
                      min={0}
                      max={1000}
                      marks={marksInvestment}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
                <Form.Row>
                  <Switch
                    type="switch"
                    label="Escolas de ensino médio"
                    id="high_schools"
                    value={high_schools}
                    onChange={onChangeHighSchools}
                  />
                </Form.Row>
              </FormContainer>
              <FormContainer display="flex" style={{flexWrap: 'wrap'}}>
                <Form.Row style={{width: '100%'}}>
                  <Box display="flex" flex={1} marginBottom="20px" width="100%">
                    <Slider
                      label="PIB per capita"
                      getAriaValueText={(value) =>
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(value)
                      }
                      valueLabelFormat={(value) =>
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(value)
                      }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[100, 5000]}
                      module
                      min={100}
                      max={20000}
                      marks={marksInvestment}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
                <Form.Row>
                  <Switch type="switch" label="Percentual das receitas oriundas de fontes externas" />
                </Form.Row>
                <Form.Row>
                  <Switch type="switch" label="Total de receitas realizadas" />
                </Form.Row>
                <Form.Row>
                  <Switch type="switch" label="Total de despesas empenhadas" />
                </Form.Row>
                <Form.Row>
                  <Switch type="switch" label="Mortalidade infantil" />
                </Form.Row>
                <Form.Row>
                  <Switch type="switch" label="Estabelecimento de saúde - SUS" />
                  <Box display="flex" flex={1} marginBottom="20px" width="100%">
                    <Slider
                      label=""
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[0, 200]}
                      module
                      min={0}
                      max={1000}
                      marks={marksSus}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row>
                {/* <Form.Row style={{ width: "100%" }}>
                  <Switch type="switch" label="Área territorial" />
                  <Box display="flex" marginBottom="20px" width="100%">
                    <Slider
                      label=""
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      defaultValue={[70000, 350000]}
                      module
                      min={20000}
                      max={400000}
                      marks={marksArea}
                      //   value={investmentRange}
                      onChange={onChangeInvestmentRange}
                      onChangeCommitted={onCommitInvestmentRange}
                    />
                  </Box>
                </Form.Row> */}
                <Form.Row>
                  <Switch type="switch" label="Arborização de vias públicas" />
                </Form.Row>
                <Form.Row>
                  <Switch type="switch" label="Urbanização de vias públicas" />
                </Form.Row>
              </FormContainer>
            </Form>
            <Box display="flex" flexDirection="column">
              <Form.Row style={{marginBottom: '10px'}}>
                <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end">
                  <Box marginRight="20px">
                    <Button onClick={applySearchFilter} variant="primary">
                      Aplicar Filtros
                    </Button>
                  </Box>
                  <Button onClick={closePopover} variant="secondary">
                    Cancelar
                  </Button>
                </Box>
              </Form.Row>
              <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end" width="100%">
                <CleanFilterButton onClick={cleanSearchFilters}>Limpar todos os filtros</CleanFilterButton>
              </Box>
            </Box>
          </>
        )}
      </>
    </Container>
  )
}

export default React.forwardRef(SearchPopover)
