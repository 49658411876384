import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TotalsCounter = styled.div`
  color: #8c8c8c;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
`

export const LimitContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 50px;
  height: 20px;
`

export const LimitLabel = styled.span`
  color: #8c8c8c;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  margin-right: 10px;
`
