import React, {Fragment, useState} from 'react'
import {authService} from 'app/services'
import {Modal, Box, Checkbox} from '@material-ui/core'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useSnackbar} from 'notistack'
import {useFormik} from 'formik'
import * as auth from 'app/modules/Auth/_redux/authRedux.js'

const TermsModal = () => {
  const [check, setCheck] = useState(false)
  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setLoading] = useState(false)
  const {user, terms} = useSelector(
    ({auth}) => ({
      user: auth,
      terms: auth.terms,
    }),
    shallowEqual,
  )

  const initialValues = {
    checked: false,
  }

  const onSubmit = async ({setSubmitting}) => {
    try {
      setLoading(true)
      const response = await authService.termsCheck({
        customer: user.user.customer.id,
      })

      if (response === '') {
        dispatch(auth.actions.terms(true))
        setLoading(false)
      } else {
        throw new Error('Erro ao aceitar termo!')
      }
    } catch (error) {
      enqueueSnackbar('Erro ao aceitar termo!', {
        variant: 'warning',
      })

      setLoading(false)
      setSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  return (
    <Fragment>
      <Modal style={{display: 'grid'}} open={!terms}>
        <Box
          width="100%"
          maxWidth="701px"
          minHeight="90vh"
          bgcolor="#ffffff"
          m="auto"
          p="20px"
          borderRadius="15px"
          display="flex"
          flexDirection="column">
          <object
            style={{flex: 1}}
            width="100%"
            data="https://api-v2.citymatch.com.br/contracts/lgpd.pdf"
            type="application/pdf"
          />
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
            <Box>
              <Checkbox
                color="secondary"
                onChange={(e) => {
                  setCheck(e.target.checked)
                }}
              />{' '}
              <span>Aceito termos e condições</span>
            </Box>
            <Box display="grid">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 m-auto my-3 mx-4"
                {...formik.getFieldProps('checked')}
                disabled={!check || formik.isSubmitting}>
                <span>Continuar</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default TermsModal
