import React, {useState, useRef, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Popover, Overlay, ListGroup} from 'react-bootstrap'

import styled from 'styled-components'

import * as selectors from 'app/modules/Proposals/redux/selectors'
import {changeActiveColumns, changeActiveSort} from 'app/modules/Proposals/redux'

import {ActionIcon, OrderContainer} from './styles'

import * as columnUtils from '../utils'
import {Checkbox} from '@material-ui/core'

const StyledPopover = styled(Popover)`
  overflow-y: scroll;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  width: 300px;

  .popover-body {
    padding: 0px !important;
    padding-left: 1rem !important;
  }
`

const TableHead = () => {
  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const dispatch = useDispatch()

  const popoverRef = useRef(null)

  const columns = useSelector(selectors.getProposalsColumns)
  const orders = useSelector(selectors.proposalsOrders)
  const activeOrder = useSelector(selectors.getActiveOrder)

  const onChangeOrdering = useCallback(
    ({columnKey}) => {
      let updatedOrder = 'ASC'

      if (columnKey === activeOrder?.key) {
        updatedOrder = activeOrder?.order === 'DESC' ? 'ASC' : 'DESC'
      }

      dispatch(
        changeActiveSort({
          key: columnKey,
          order: updatedOrder,
        }),
      )
    },
    [activeOrder, dispatch],
  )

  const handlePopoverClick = (event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }

  const onToogleColumn = useCallback(
    (selectedColumn, isActive) => {
      let updatedColumns = [...columns.active]

      if (isActive) {
        updatedColumns = updatedColumns.filter(({key}) => key !== selectedColumn.key)
      } else {
        const columnIndex = columns.available.map(({key}) => key).indexOf(selectedColumn.key)

        updatedColumns.splice(columnIndex, 0, selectedColumn)
      }

      dispatch(changeActiveColumns(updatedColumns))
    },
    [columns.active, columns.available, dispatch],
  )

  const renderPopOverItem = useCallback(
    (column) => {
      const isActive = columnUtils.isColumnActive({
        column,
        stateColumns: columns,
      })

      return (
        <ListGroup.Item
          key={`k=${column.key}`}
          onClick={() => onToogleColumn(column, isActive)}
          style={{cursor: 'pointer', padding: '0.5rem'}}>
          <Checkbox checked={isActive} />
          {column.label}
        </ListGroup.Item>
      )
    },
    [columns, onToogleColumn],
  )

  const renderColumn = useCallback(
    ({key: columnKey, label}) => {
      const fieldOrder = columnUtils.fieldOrder({
        columnKey,
        availableOrders: orders,
      })

      if (fieldOrder) {
        const isOrderActive = activeOrder?.key === fieldOrder
        let chevron = 'down'

        if (activeOrder?.key === fieldOrder) {
          chevron = activeOrder?.order === 'DESC' ? 'down' : 'up'
        }

        return (
          <th key={columnKey} scope="col">
            <OrderContainer isOrderActive={isOrderActive} onClick={() => onChangeOrdering({columnKey})}>
              <span className="text-uppercase">{label}</span>
              {isOrderActive ? (
                <i className={`fas fa-chevron-${chevron}`}></i>
              ) : (
                <i className={`inactive fas fa-chevron-${chevron}`}></i>
              )}
            </OrderContainer>
          </th>
        )
      }

      return (
        <th scope="col">
          <span className="text-uppercase">{label}</span>
        </th>
      )
    },
    [activeOrder, onChangeOrdering, orders],
  )

  return (
    <thead>
      <tr>
        {columnUtils.shouldRenderColumn({columnKey: 'id', stateColumns: columns}) &&
          renderColumn({key: 'id', label: '#'})}
        {columnUtils.shouldRenderColumn({columnKey: 'project_company_name', stateColumns: columns}) &&
          renderColumn({key: 'project_company_name', label: 'Nome do Projeto'})}
        {columnUtils.shouldRenderColumn({columnKey: 'type', stateColumns: columns}) &&
          renderColumn({key: 'type', label: 'Tipo'})}
        {columnUtils.shouldRenderColumn({columnKey: 'project_total', stateColumns: columns}) &&
          renderColumn({key: 'project_total', label: 'Investimento'})}
        {columnUtils.shouldRenderColumn({columnKey: 'city', stateColumns: columns}) &&
          renderColumn({key: 'city', label: 'Município'})}
        {columnUtils.shouldRenderColumn({columnKey: 'uf', stateColumns: columns}) &&
          renderColumn({key: 'uf', label: 'UF'})}
        {columnUtils.shouldRenderColumn({columnKey: 'created_at', stateColumns: columns}) &&
          renderColumn({key: 'created_at', label: 'Criada Em'})}
        {columnUtils.shouldRenderColumn({columnKey: 'status', stateColumns: columns}) &&
          renderColumn({key: 'status', label: 'Status'})}
        <th scope="col">
          <span className="text-uppercase">Ações</span>
        </th>
        <th scope="col" ref={popoverRef}>
          <ActionIcon className="fa fa-ellipsis-v" aria-hidden="true" onClick={handlePopoverClick} ref={popoverRef} />
          <Overlay
            show={showPopover}
            target={popoverTarget}
            container={popoverRef.current}
            placement="bottom"
            rootClose={true}
            onHide={() => setShowPopover(false)}>
            <StyledPopover id="popover-basic">
              <Popover.Content>
                <ListGroup variant="flush">{columns.available.map(renderPopOverItem)}</ListGroup>
              </Popover.Content>
            </StyledPopover>
          </Overlay>
        </th>
      </tr>
    </thead>
  )
}

export default TableHead
