import styled from 'styled-components'

export const Title = styled.text`
  color: #1e7be1;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
`

export const Subtitle = styled.span`
  color: #8c8c8c;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
`

export const ActionText = styled.text`
  color: #1e7be1;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
`

export const Label = styled.label`
  font-family: 'Nunito', sans-serif;
  color: #1d1d1d;
  font-weight: bold;
  margin: 0;
`

export const Paper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 2rem;
`

export const ContainerHeader = styled.div`
  color: black;
  font: normal normal bold 24px/32px Nunito;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  border-bottom: 1px solid #c9c9c980;
`

export const ContainerHeaderTabs = styled.div`
  color: black;
  font: normal normal bold 24px/32px Nunito;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  border-bottom: 1px solid #c9c9c980;
  & button {
    min-height: 78px;
  }
`

export const DetailContainer = styled.div`
  padding: 25px 35px;
  padding-bottom: 45px;
`

export const FavoriteIcon = styled.i`
  color: ${({isFavorite}) => (isFavorite ? '#FFA800' : '#C9C9C9')};
  cursor: pointer;
`

export const Image = styled.img`
  width: 180px !important;
  height: 180px !important;
  border: 1px solid #007bff;
`

export const CarouselImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 10px;
`

const injectJustify = ({justify}) => (justify ? justify : 'space-between')
const injectWidth = ({width}) => (width ? width : '100%')
const injectDisplay = ({display}) => (display ? display : 'flex')

export const Container = styled.div`
  display: ${injectDisplay};
  justify-content: ${injectJustify};
  align-items: center;
  width: ${injectWidth};
  margin-top: 1rem;
`

export const BottomItem = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: ${injectWidth};
  margin-top: 1rem;
  color: #383838;
  text-align: center;
`

export const BottomItemValue = styled.span`
  font-size: 20px;
  font-family: 'Nunito Sans';
  font-weight: bold;
  color: #00428b;
  text-transform: uppercase;
  text-align: left;
`

export const IcentiveContainer = styled.div`
  background: #d4e2f0 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 29px;
  padding: 5px 12px;
  font-family: 'Nunito Sans', sans-serif;
  color: #1d1d1d;
  margin-right: 10px;
  width: fit-content;
`

export const FileUploadLabel = styled.span`
  color: #919191;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
`

export const FileUploadWarning = styled.span`
  color: #8c8c8c;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
`

export const Icon = styled.i`
  color: #1e7be133;
  font-size: 50px;
`

export const LabelAdd = styled.p`
  font-weight: bold;
  color: #1e7be1;
  font-size: 14px;
  margin: 0;
`

export const TitleAttachments = styled.span`
  font-weight: bold;
  color: #383838;
  font-size: 14px;
  margin: 0 11px 0 0;
`
export const SubTitleAttachments = styled.span`
  font-weight: normal;
  color: #8c8c8c;
  font-size: 12px;
  margin: 0;
`
export const TitleVideo = styled.p`
  font-weight: normal;
  color: #1d1d1d;
  font-size: 14px;
  margin: 0;
`
export const SubTitleVideo = styled.p`
  font-weight: normal;
  color: #8c8c8c;
  font-size: 10.5px;
  margin: 0;
`
export const IconExclude = styled.div`
  & button {
    background-color: #ffffff;
    border: solid 2px #8c8c8c;
    border-radius: 50%;
    width: 29.25px;
    height: 29.25px;
    display: grid;
    align-items: center;
    align-content: center;
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 1;
    &:hover {
      background-color: #ffffff;
    }
  }
`

export const IconPlay = styled.div`
  & button {
    background-color: #ffffff;
    width: 32px;
    height: 32px;
    display: grid;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    &:hover {
      background-color: #ffffff;
    }
  }
`
export const Opacity = styled.div`
  background-color: #6e6e6eab;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
`
