import React, {useRef, useEffect, useState} from 'react'
import {useSelector, connect, useDispatch} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout'
import * as auth from './authRedux'
import * as selectors from './selectors'

import {userService} from 'app/services'

function AuthInit(props) {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const authToken = useSelector(selectors.getAuthToken)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await userService.getUserByToken()
          dispatch(props.setUser(user))
          dispatch(auth.actions.twofactor(true))

          if (user.customer?.background_banner) {
            document.body.style.backgroundImage = `url(${user.customer.background_banner})`
          }
        }
      } catch (error) {
        console.error('Error fetching initial user profile', error)

        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (authToken) {
      requestUser()
    } else {
      dispatch(props.setUser(undefined))
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connect(null, auth.actions)(AuthInit)
