import {Box, Chip} from '@material-ui/core'
import * as proposalsSelectors from 'app/modules/Proposals/redux/selectors'
import React, {useCallback, useMemo} from 'react'
import {Button} from 'react-bootstrap'
import {useSelector} from 'react-redux'

import {BlockTitle, BlockValue, Container, Title} from './styles'

const formatCurrency = (amount, multiply) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(multiply ? multiply * amount : amount)

const ProposalConfirm = ({formik, onPreviousStep}) => {
  const availableIncentives = useSelector(proposalsSelectors.getAvailaleBenefits)

  const {
    values: {attachments, incentives, name, city, contact},
  } = formik

  const findIncentiveByKey = useCallback((key) => availableIncentives.find(({type}) => type === key), [
    availableIncentives,
  ])

  const getIncentiveDescription = useCallback(
    (key) => {
      return findIncentiveByKey(key).description || ''
    },
    [findIncentiveByKey],
  )

  const getIncentiveValue = useCallback((key) => incentives[key].description, [incentives])
  const getTerrenoValue = useCallback((key) => incentives['terreno'][key], [incentives])

  const incentivesKeys = useMemo(() => Object.keys(incentives), [incentives])

  const renderIncentives = useCallback(() => {
    const incentivesKeys = Object.keys(incentives)

    return incentivesKeys.map((key) => (
      <Box
        borderBottom="1px solid #BEDDFF"
        display="flex"
        marginBottom="20px"
        paddingBottom="20px"
        flexDirection="row"
        key={key}>
        {key === 'terreno' ? (
          <>
            <Box width="100%">
              <BlockTitle>sobre terreno</BlockTitle>
              <Box display="flex" flexDirection="column">
                <BlockValue>
                  <b style={{fontWeight: 'bold'}}>Identificação:</b> {getTerrenoValue('identification')}
                </BlockValue>
                {/* <BlockValue>
                        <b style={{ fontWeight: 'bold' }}>Área:</b> {getTerrenoValue('area')}
                     </BlockValue> */}
                <BlockValue>
                  <b style={{fontWeight: 'bold'}}>Valor estimado:</b>{' '}
                  {formatCurrency(getTerrenoValue('estimated_value'))}
                </BlockValue>
                <BlockValue>
                  <b style={{fontWeight: 'bold'}}>Restrições:</b> {getTerrenoValue('restrictions') || 'Não Informado'}
                </BlockValue>
                <BlockValue>
                  <b style={{fontWeight: 'bold'}}>Link:</b> {getTerrenoValue('url_map') || 'Não Informado'}
                </BlockValue>
              </Box>
            </Box>

            <Box width="100%">
              <BlockTitle>detalhamento</BlockTitle>
              <Box display="flex" flexDirection="column">
                <BlockValue>{getTerrenoValue('description')}</BlockValue>
              </Box>
            </Box>
          </>
        ) : (
          <Box key={key}>
            <BlockTitle>Sobre {getIncentiveDescription(key)}</BlockTitle>
            <Box display="flex" flexDirection="column">
              <BlockValue>{getIncentiveValue(key)}</BlockValue>
            </Box>
          </Box>
        )}
      </Box>
    ))
  }, [getIncentiveDescription, getIncentiveValue, incentives])

  return (
    <Container style={{padding: 0}}>
      <Box display="flex" flexDirection="column" flex={1} Chip marginBottom="20px">
        <Box marginBottom="40px">
          <Title>Revisão de dados</Title>
        </Box>
        <Box
          borderBottom="1px solid #BEDDFF"
          display="flex"
          flexDirection="column"
          marginBottom="20px"
          paddingBottom="20px">
          <BlockTitle>Tipo da oportunidade</BlockTitle>
          <BlockValue>Pública</BlockValue>
        </Box>
        <Box
          borderBottom="1px solid #BEDDFF"
          display="flex"
          marginBottom="20px"
          paddingBottom="20px"
          flexDirection="row"
          justifyContent="space-between">
          <Box width="100%">
            <BlockTitle>Detalhes da oportunidade</BlockTitle>
            <Box display="flex" flexDirection="column">
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Nome:</b> {name}
              </BlockValue>
              <BlockValue>
                <b style={{fontWeight: 'bold'}}>Município cadastrado:</b> {city.name} - {city.state.uf}
              </BlockValue>
            </Box>
          </Box>
          <Box width="100%">
            <BlockTitle>INCENTIVOS DISPONÍVEIS AO INVESTIDOR</BlockTitle>
            <Box display="flex" flexDirection="column">
              {incentivesKeys.map((key) => (
                <BlockValue key={key}>
                  <b style={{textTransform: 'capitalize'}}>{key}</b>
                </BlockValue>
              ))}
            </Box>
          </Box>
        </Box>

        {renderIncentives()}
        {attachments.length ? (
          <Box
            display="flex"
            marginBottom="20px"
            paddingBottom="10px"
            flexDirection="row"
            justifyContent="space-between">
            <Box marginRight="20px">
              <BlockTitle>anexos</BlockTitle>
              <Box display="flex" flexDirection="column">
                {attachments.map((atachment) => (
                  <Box key={atachment.original_name} marginBottom="10px" marginRight="10px">
                    <Chip
                      label={atachment.original_name}
                      size="small"
                      variant="outlined"
                      style={{
                        color: '#8C8C8C',
                        borderRadius: 10,
                        background: '#1E7BE10C',
                        borderColor: '#1E7BE10C',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        ) : null}

        <Box width="100%">
          <BlockTitle>Contato</BlockTitle>
          <Box display="flex" flexDirection="column">
            <BlockValue>
              <b style={{fontWeight: 'bold'}}>Nome para contato:</b> {contact.name}
            </BlockValue>
            <BlockValue>
              <b style={{fontWeight: 'bold'}}>Cargo:</b> {contact.role}
            </BlockValue>
            <BlockValue>
              <b style={{fontWeight: 'bold'}}>Celular:</b> {contact.cellphone}
            </BlockValue>
            <BlockValue>
              <b style={{fontWeight: 'bold'}}>Telefone:</b> {contact.phone}
            </BlockValue>
            <BlockValue>
              <b style={{fontWeight: 'bold'}}>E-mail:</b> {contact.email}
            </BlockValue>
            <BlockValue>
              <b style={{fontWeight: 'bold'}}>Site:</b> {contact.site}
            </BlockValue>
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.submitForm()
          }}>
          Concluir cadastro
        </Button>
      </Box>
    </Container>
  )
}

export default ProposalConfirm
