import {Box} from '@material-ui/core'
import ListCustomers from 'app/modules/ManageCustomer/pages/ListCustomers'
import {changeSearchParams, cleanSearchParams, setAppliedFilters} from 'app/modules/ManageCustomer/redux'
import {CustomerContext} from 'app/modules/ManageCustomer/state'
import getListCustomers from 'app/services/customer/getListCustomers'
import React, {useCallback, useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {useDispatch} from 'react-redux'

import {CleanFilterButton, Container, FormContainer, FormTitle} from './styles'

const SearchPopover = React.forwardRef(({onClose, style, ...rest}, ref) => {
  const {setCustomers} = CustomerContext()

  const [filterByName, setFilterByName] = useState('')
  const [forceReMount, setReForceMount] = useState(false)
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useState({})

  useEffect(() => {
    setReForceMount(false)
  }, [forceReMount])

  const handleChangeFilterByName = (event) => {
    const {value} = event.target
    setFilterByName(value)
  }

  const cleanSearchFilters = () => {
    setFilterByName('')
    setReForceMount(true)
    setSearchParams({})
    dispatch(cleanSearchParams())
  }

  const applySearchFilter = async () => {
    const newSearchParams = {...searchParams, name: filterByName.trim()}
    setSearchParams(newSearchParams)
    dispatch(changeSearchParams(newSearchParams))
    dispatch(setAppliedFilters(true))

    try {
      const response = await getListCustomers(newSearchParams)
      setCustomers(response.data || [])
    } catch (error) {
      console.error('Error fetching customers:', error)
    }
  }

  const closePopover = useCallback(() => onClose(), [onClose])

  return (
    <Container ref={ref} {...rest} style={style} placement="left">
      <FormTitle>Filtrar por:</FormTitle>
      <div className="dropdown-divider" />
      {forceReMount ? null : (
        <>
          <Form style={{display: 'flex', justifyContent: 'space-between'}}>
            <FormContainer>
              <Form.Row>
                <Box display="flex" flex={1} marginBottom="20px">
                  <Form.Control
                    type="text"
                    placeholder="Digite um nome..."
                    value={filterByName}
                    onChange={handleChangeFilterByName}
                  />
                </Box>
              </Form.Row>
            </FormContainer>
          </Form>
          <Box display="flex" flexDirection="column">
            <Form.Row style={{marginBottom: '10px'}}>
              <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end">
                <Box marginRight="20px">
                  <Button onClick={applySearchFilter} variant="primary">
                    Aplicar Filtros
                  </Button>
                </Box>
                <Button onClick={closePopover} variant="secondary">
                  Cancelar
                </Button>
              </Box>
            </Form.Row>
            <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end" width="100%">
              <CleanFilterButton onClick={cleanSearchFilters}>Limpar todos os filtros</CleanFilterButton>
            </Box>
          </Box>
        </>
      )}
    </Container>
  )
})

export default SearchPopover
