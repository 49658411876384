import {createSelector} from '@reduxjs/toolkit'

export const getUsersSelector = (state) => state.users

export const getCurrentUsersPage = createSelector(getUsersSelector, ({page}) => page)

export const getUsersColumns = createSelector(getUsersSelector, ({columns}) => columns)

export const hasLoadedOrdering = createSelector(getUsersSelector, ({filters}) => !!filters.ordering?.orders?.length)

export const usersOrders = createSelector(getUsersSelector, ({filters}) => filters.ordering.orders)

export const getActiveOrder = createSelector(getUsersSelector, ({filters}) => filters.ordering.activeSort)

export const showMap = createSelector(getUsersSelector, ({universalSearch}) => universalSearch.showMap)

export const universalSearch = createSelector(getUsersSelector, ({universalSearch}) => universalSearch)

export const getCurrentLimit = createSelector(getUsersSelector, ({limit}) => limit)

export const getSearchParams = createSelector(getUsersSelector, ({universalSearch}) => ({
  hasAppliedFilters: universalSearch.hasAppliedFilters,
  searchParams: universalSearch.searchParams,
  showOnlyFavorites: universalSearch.showOnlyFavorites,
}))
