import {Box, Divider, Grid} from '@material-ui/core'
import {AttachFile, InsertPhoto, PictureAsPdf} from '@material-ui/icons'
import React, {Fragment} from 'react'
import {Button, Form} from 'react-bootstrap'

import {Container, Title} from '../styles'

const formatCurrency = (amount) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)

const CityRevision = ({city, location, onSubmit, submiting}) => {
  return (
    <Container style={{padding: 0}}>
      <Box marginBottom="60px" maxWidth="654px" m="auto" width="100%">
        <Grid container direction="column">
          <Box>
            <Box marginBottom="5px">
              <Title>INFORMAÇÕES BÁSICAS SOBRE O MUNICÍPIO</Title>
            </Box>
            <Box display="flex" width="fit-content" flexDirection="column" mb="20px">
              <Form.Label>
                {city.name} / {city.state.uf}
              </Form.Label>
            </Box>

            <Divider />
          </Box>

          <BlockDetail title="INFORMAÇÕES BÁSICAS" items={[{label: 'Resumo', value: location.description}]} />

          <BlockDetail
            title="VOCAÇÃO REGIONAL"
            items={[
              {
                label: 'Vocações',
                value: location.vocations.length
                  ? location.vocations.map((v) => (v.description ? v.description : v)).join(', ')
                  : 'Nenhuma selecionada',
              },
            ]}
          />

          {location.benefits.length > 0 && (
            <BlockDetailBenefityList title="INCENTIVOS DADO PELO MUNICÍPIO" items={location.benefits} />
          )}

          <BlockDetail
            title="CONTATO"
            items={[
              {label: 'Nome para contato', value: location.contacts[0]?.name},
              {label: 'Cargo', value: location.contacts[0]?.responsibility},
              {label: 'Telefone', value: location.contacts[0]?.phone},
              {label: 'E-mail', value: location.contacts[0]?.email},
              {label: 'Site', value: location.contacts[0]?.linkedin},
            ]}
          />

          {location.city_hall && (
            <BlockDetail
              title="Sede da Prefeitura"
              items={[
                {label: 'CEP', value: location.city_hall?.zip},
                {label: 'Logradouro', value: location.city_hall?.street},
                {label: 'Número', value: location.city_hall?.number},
                {label: 'Bairro', value: location.city_hall?.neighborhood},
                {label: 'Cidade', value: location.city_hall?.city?.name},
                {label: 'UF', value: location.city_hall?.city?.state?.uf},
              ]}
            />
          )}

          {/*location?.industrial_area?.geojson && (
            <BlockDetail
              title="Área Industrial"
              items={[{label: 'GeoJSON da área', value: location.industrial_area.geojson}]}
            />
          )*/}

          {location.multimedia?.length > 0 && (
            <Box pt="30px">
              <Box mb="22px">
                <Title>ANEXOS</Title>
              </Box>
              <Box display="flex" gridGap="15px" flexWrap="wrap" justifyContent="space-between">
                {location.multimedia.map((file, i) => (
                  <Tip key={i} description={file.name} />
                ))}
              </Box>
            </Box>
          )}

          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Container>
  )
}

const BlockDetail = ({title, items}) => {
  return (
    <Fragment>
      <Box pt="30px" pb="30px">
        <Box mb="5px">
          <Title>{title}</Title>
        </Box>

        {items?.map((item, i) => (
          <Detail key={i} {...item} />
        ))}
      </Box>
      <Divider />
    </Fragment>
  )
}

const BlockDetailBenefityList = ({title, items}) => {
  return (
    <Fragment>
      <Box pt="30px" pb="30px">
        <Box mb="5px">
          <Title>{title}</Title>
        </Box>

        {items?.map((item, i) => (
          <Detail key={i} label={item.type.toUpperCase()} value={item.description} />
        ))}
      </Box>
      <Divider />
    </Fragment>
  )
}

const BlockDetailBenefity = ({title, items}) => {
  return items.map((item, i) => {
    if (item.type === 'terreno') {
      return (
        <Fragment key={i}>
          <Box pt="30px" pb="30px">
            <Box mb="5px">
              <Title>SOBRE O TERRENO</Title>
            </Box>
            <Detail label="Identificação" value={item.identification} />
            <Detail label="Área'" value={item.area} />
            <Detail
              label="Valor Estimado"
              value={item.estimated_value.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}
            />
            <Detail label="Restrições" value={item.restrictions} />
            <Detail label="URL Mapa" value={item.url_map} />
          </Box>
          <Divider />
          <Box pt="30px" pb="30px">
            <Box mb="5px">
              <Title>DETALHAMENTO</Title>
            </Box>
            <Detail value={item.description} />
          </Box>
          <Divider />
        </Fragment>
      )
    }

    return (
      <Fragment key={i}>
        <Box pt="30px" pb="30px">
          <Box mb="5px">
            <Title>SOBRE INCENTIVO {item.type.toUpperCase()}</Title>
          </Box>
          <Detail value={item.description} />
        </Box>
        <Divider />
      </Fragment>
    )
  })
}

const Detail = ({label, value}) => {
  const valueIsArray = Array.isArray(value)
  const valueIsString = typeof value === 'string'

  const valueString = valueIsString ? value : undefined
  const valueArray = valueIsArray ? JSON.stringify(value) : undefined
  const valueOther = !valueIsArray && !valueIsString ? JSON.stringify(value) : undefined

  const hasValue = !!(valueArray || valueString || valueOther)

  const separator = hasValue ? ':' : null
  const val = valueString ?? valueArray ?? valueOther
  return (
    <Box
      style={{
        color: '#1D1D1D',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '14px',
      }}>
      <b>
        {label}
        {separator}&nbsp;
      </b>
      {val}
    </Box>
  )
}

const Tip = ({description}) => {
  return (
    <Box
      padding="2px 10px"
      width="fit-content"
      fontSize="12px"
      borderRadius="10px"
      display="flex"
      alignItems="center"
      style={{backgroundColor: '#1E7BE10C'}}>
      {(function () {
        switch (description.split('.')[description.split('.').length - 1]) {
          case 'pdf':
            return <PictureAsPdf />
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'bmp':
          case 'gif':
            return <InsertPhoto />
          case 'doc':
          case 'docx':
          case 'xls':
          case 'xlsx':
          case 'txt':
            return <AttachFile />
          default:
            return <AttachFile />
        }
      })()}
      <p style={{margin: '0 0 0 4px'}}>{description}</p>
    </Box>
  )
}

export default CityRevision
