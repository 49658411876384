import React, {useCallback, useEffect, useState} from 'react'
import {Paper, ContainerHeader, DetailContainer} from '../styles'
import {useSnackbar} from 'notistack'
import {useDispatch, useSelector} from 'react-redux'
import * as proposalSelectors from 'app/modules/Proposals/redux/selectors'
import {ProposalTable} from 'app/components/proposal/Table'
import {setAvailableOrdering} from '../../../redux'
import {proposalService} from 'app/services'

const Relateds = ({CNPJ}) => {
  const [proposals, setProposals] = useState([])
  const [loadingProposals, setLoadingProposals] = useState(false)
  const [total, setTotal] = useState(0)
  const currentPage = useSelector(proposalSelectors.getCurrentProposalsPage)
  const hasLoadedOrdering = useSelector(proposalSelectors.hasLoadedOrdering)
  const {hasAppliedFilters, searchParams, showOnlyFavorites} = useSelector(proposalSelectors.getSearchParams)
  const limit = useSelector(proposalSelectors.getCurrentLimit)
  const activeOrder = useSelector(proposalSelectors.getActiveOrder)
  const [filters] = useState({})
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const mountSorting = (selectedOrder) => ({
    [`sort[${selectedOrder.key}]`]: selectedOrder.order.toLowerCase(),
  })

  const mountQueryParams = (params) => {
    let queryParams = {
      limit: limit.current,
    }

    if (showOnlyFavorites) {
      queryParams.only_favorites = 1
    }

    if (hasAppliedFilters) {
      if (params.endDate) {
        queryParams.end_date = params.endDate
      }

      if (params.startDate) {
        queryParams.start_date = params.startDate
      }

      if (params.selectedStates?.length) {
        queryParams.ufs = params.selectedStates.map(({id}) => id).join(',')
      }

      if (params.selectedCities?.length) {
        queryParams.city = params.selectedCities.map(({id}) => id).join(',')
      }

      if (params.selectedStatus?.length) {
        queryParams.status = params.selectedStatus.join(',')
      }

      if (params.selectedTypes?.length) {
        queryParams.type = params.selectedTypes.join(',')
      }

      var range = []

      if (params.investmentStart > 0) {
        range.push(params.investmentStart)
      }

      if (params.investmentEnd > 0) {
        range.push(params.investmentEnd)
      }

      if (range.length) {
        queryParams.investimentRange = range.join(',')
      }
    }

    if (activeOrder) {
      queryParams = {...queryParams, ...mountSorting(activeOrder)}
    }

    return queryParams
  }

  const fetchProposals = useCallback(async () => {
    try {
      setLoadingProposals(true)

      let queryParams = {
        page: currentPage,
        company_federal_tax_id: CNPJ.replace(/[^a-zA-Z0-9]/g, ''),
        ...filters,
        ...mountQueryParams(searchParams),
      }

      const {data, total, available_ordering: availableOrdering} = await proposalService.getProposals(queryParams)

      setTotal(total)
      console.log(data)
      setProposals(data)
      setLoadingProposals(false)

      if (!hasLoadedOrdering) {
        dispatch(setAvailableOrdering(availableOrdering))
      }
    } catch (error) {
      setLoadingProposals(false)
      setProposals([])

      enqueueSnackbar('Ocorreu um erro ao consultar as oportunidades', {
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeOrder,
    currentPage,
    dispatch,
    enqueueSnackbar,
    filters,
    hasAppliedFilters,
    limit,
    showOnlyFavorites,
    searchParams,
  ])

  useEffect(() => {
    fetchProposals()
  }, [activeOrder, fetchProposals])

  return (
    <Paper>
      <ContainerHeader>Oportunidades Relacionadas</ContainerHeader>
      <DetailContainer>
        {proposals?.length >= 1 ? (
          <ProposalTable
            limit={limit}
            loading={loadingProposals}
            page={currentPage}
            proposals={proposals}
            total={total}
          />
        ) : (
          <h3>Sem oportunidades relacionadas</h3>
        )}
      </DetailContainer>
    </Paper>
  )
}

export default Relateds
