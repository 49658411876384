import React, {useCallback, useRef, useState} from 'react'
import {Popover, Card, Button, Overlay, ListGroup} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import Type from 'app/components/proposal/Type'
import {Edit, Menu, Star} from 'react-feather'

import {Box} from '@material-ui/core'

import {useFavorite} from 'app/hooks'
import {Circular as CircularLoading} from 'app/components'

import {
  CitieHeader,
  CitieUf,
  Actions,
  MayorContainer,
  MayorName,
  DescriptionContainer,
  Container,
  StyledSpan,
  TaxDiv,
  QuantityDiv,
  FavoriteIcon,
  StyledPopover,
  CounterContainer,
} from './styles'

function CardCitie({citie}) {
  const {
    name,
    id,
    state: {uf},
    mayor,
    demographic_data,
    location,
    featured_image,
    is_favorite,
    counters: {
      proposals: {public: publicCounter, private: privateCounter},
    },
    has_permission_to_edit,
  } = citie

  const popoverRef = useRef(null)
  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite: is_favorite,
    entity: 'city',
    label: 'Cidade',
  })

  const handlePopoverClick = useCallback((event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }, [])

  const history = useHistory()

  const onDetailClick = useCallback(() => {
    history.push(`/cities/${id}`)
  }, [history, id])

  const goToCitieDetails = useCallback(
    (citieId) => {
      history.push(`/cities/${citieId}`)
    },
    [history],
  )

  const findDemographicInfo = useCallback(
    (key, defaultParam = 'Não Informado', label = '') => {
      const info = demographic_data?.find(({indicator}) => indicator === key)?.value
      return info ? `${info}${label}` : `${defaultParam}${label}`
    },
    [demographic_data],
  )

  const formatCurrency = (amount, multiply) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      notation: 'compact',
    }).format(multiply ? multiply * amount : amount)

  const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

  return (
    <Card
      style={{
        maxWidth: '485px',
        marginRight: '10px',
        marginLeft: '10px',
        marginBottom: '20px',
        padding: '23px 31px',
        borderRadius: 10,
        flex: '1 0 25%',
      }}>
      <Card.Header style={{borderBottom: 'none', padding: 0}} className="d-flex flex-wrap">
        <CitieHeader>
          <div style={{width: '100', display: 'flex', whiteSpace: 'nowrap', fontFamily: 'Nunito Sans'}}>
            <span style={{fontWeight: '600', color: '#000000'}}>{name}</span>
            <CitieUf>
              <span>{uf}</span>
            </CitieUf>
          </div>
          <Actions>
            {loadingFavorite ? (
              <CircularLoading />
            ) : (
              <FavoriteIcon
                aria-hidden="true"
                className="fa fa-star"
                isFavorite={isFavorite}
                onClick={() => handleFavoriteOpportunity()}
              />
            )}
            <i
              className="fa fa-ellipsis-h"
              style={{cursor: 'pointer'}}
              onClick={handlePopoverClick}
              aria-hidden="true"></i>
            <Overlay
              show={showPopover}
              ref={popoverRef}
              target={popoverTarget}
              container={popoverRef.current}
              placement="bottom"
              rootClose={true}
              onHide={() => setShowPopover(false)}>
              <StyledPopover>
                <Popover.Content>
                  <ListGroup variant="flush">
                    <ListGroup.Item onClick={() => goToCitieDetails(id)}>
                      <Menu className="icon-menu" /> Ver detalhes
                    </ListGroup.Item>

                    <ListGroup.Item onClick={() => handleFavoriteOpportunity()}>
                      <Star className="icon-menu" />
                      {isFavorite ? 'Remover dos favoritos' : 'Salvar em favoritos'}
                    </ListGroup.Item>

                    {has_permission_to_edit && (
                      <ListGroup.Item onClick={() => history.push(`/cities/${id}/edit`)}>
                        <Edit className="icon-menu" />
                        Editar
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Popover.Content>
              </StyledPopover>
            </Overlay>
          </Actions>
        </CitieHeader>
        <MayorContainer>Prefeito(a)</MayorContainer>
        <MayorName>
          {mayor ? mayor.name : 'Não Informado'} {mayor ? ` - ${mayor.political_party.abbreviation}` : null}
        </MayorName>
        {featured_image ? <Card.Img variant="top" src={featured_image} style={{borderRadius: '10px'}} /> : null}
      </Card.Header>
      <Card.Body style={{padding: 0}}>
        <CounterContainer>
          {location ? (
            <>
              <DescriptionContainer>{location.description}</DescriptionContainer>
              <hr style={{borderTop: '1px solid #E3E3E3', marginTop: 20, marginBottom: 20}} />
            </>
          ) : (
            <hr style={{borderTop: '1px solid #E3E3E3', marginTop: 0, marginBottom: 20}} />
          )}

          <MayorContainer>Oportunidades</MayorContainer>
          <Container justify="space-between">
            <Box display="flex" alignItems="center">
              <Type type="company" height="36px" width="36px" iconSize="15px" />
              <QuantityDiv color="#2C6EB533" style={{marginLeft: '10px'}}>
                {privateCounter}
              </QuantityDiv>
            </Box>
            <Box display="flex" alignItems="center">
              <Type type="city" height="36px" width="36px" iconSize="15px" />
              <QuantityDiv color="#38383833" style={{color: '#383838', marginLeft: '10px'}}>
                {publicCounter}
              </QuantityDiv>
            </Box>
          </Container>
        </CounterContainer>
        <Box display="flex" flexDirection="column">
          <Box marginBottom="8px">
            <MayorContainer>Incentivos disponíveis ao investidor</MayorContainer>
          </Box>
          {location && location.benefits ? (
            <Box display="flex" flexDirection="row">
              {location &&
                location.benefits.map((tax) => (
                  <TaxDiv key={tax} title={tax.description}>
                    <i className="fa fa-check" aria-hidden="true" style={{color: '#BEDDFF'}}></i>
                    <span style={{marginLeft: '0.5rem'}}>{tax.type.toUpperCase()}</span>
                  </TaxDiv>
                ))}
            </Box>
          ) : (
            <Box display="flex" flexDirection="row">
              Nenhum benefício disponível
            </Box>
          )}
        </Box>
        <hr />
        <Box paddingLeft="10px" paddingRight="10px">
          <Container>
            <StyledSpan>População</StyledSpan>
            <span style={{color: '#1D1D1D', fontSize: 14, fontFamily: 'Nunito Sans'}}>
              {formatNumber(findDemographicInfo('populacao_estimada'))} habitantes
            </span>
          </Container>
          <Container>
            <StyledSpan>IDEB</StyledSpan>
            <span style={{color: '#1D1D1D', fontSize: 14, fontFamily: 'Nunito Sans'}}>
              {findDemographicInfo('taxa_escolarizacao')}
            </span>
          </Container>
          <Container>
            <StyledSpan>IDHM</StyledSpan>
            <span style={{color: '#1D1D1D', fontSize: 14, fontFamily: 'Nunito Sans'}}>
              {findDemographicInfo('idhm')}
            </span>
          </Container>
          <Container>
            <StyledSpan>Total de despesas</StyledSpan>
            <span style={{color: '#1D1D1D', fontSize: 14, fontFamily: 'Nunito Sans', textTransform: 'uppercase'}}>
              {formatCurrency(findDemographicInfo('total_despesas', '0'), 1000)}
            </span>
          </Container>
          <Container>
            <StyledSpan>Total de receitas</StyledSpan>
            <span style={{color: '#1D1D1D', fontSize: 14, fontFamily: 'Nunito Sans', textTransform: 'uppercase'}}>
              {formatCurrency(findDemographicInfo('total_receitas', '0'), 1000)}
            </span>
          </Container>
          <Container>
            <StyledSpan>PIB per capita</StyledSpan>
            <span style={{color: '#1D1D1D', fontSize: 14, fontFamily: 'Nunito Sans', textTransform: 'uppercase'}}>
              {formatCurrency(findDemographicInfo('pib_per_capta', '0'))}
            </span>
          </Container>
          {/*<Container>
            <StyledSpan>Quantidade de empresas ativas</StyledSpan>
            <span style={{ color: '#1D1D1D', fontSize: 14, fontFamily: 'Nunito Sans' }}></span>
			  </Container>*/}
          <Container>
            <StyledSpan>Vocação regional</StyledSpan>
            <span style={{color: '#1D1D1D', fontSize: 14, fontFamily: 'Nunito Sans'}}>
              {location?.vocations
                ? location?.vocations
                    ?.filter((i) => i.description)
                    .map((i) => i.description)
                    .join(', ')
                : 'Não Informada'}
            </span>
          </Container>
        </Box>
        <Button variant="primary" style={{float: 'right', marginTop: '25px'}} onClick={onDetailClick}>
          DETALHES
        </Button>
      </Card.Body>
    </Card>
  )
}

export default CardCitie
