import httpClient from '../../client'

import apiRoutes from '../constants'

const getCities = async ({limit, page, format, sort, ...rest}) => {
  try {
    var sortQuery = ''

    if (sort) {
      sortQuery = Object.keys(sort)
        .map((key) => {
          return `sort[${key}]=${sort[key]}`
        })
        .join('&')
    }

    const {data} = await httpClient.get(apiRoutes.GET_CITIES + (sortQuery ? '?' + sortQuery : ''), {
      params: {format, limit, page, ...rest},
    })

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling proposals with ${JSON.stringify({limit, page})}`, error)
    return error
  }
}

export default getCities
