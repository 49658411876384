import CardSkeleton from 'app/components/cities/CitieCards/CardSkeleton'
import Map from 'app/components/map/Map'
import ProposalPagination from 'app/components/proposal/Pagination'
import {changeLimit, changePage} from 'app/modules/Companies/redux'
import * as companiesSelectors from 'app/modules/Companies/redux/selectors'
import {companiesService, proposalService} from 'app/services'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Table} from '../../components'
import {setAvailableOrdering} from '../../redux'
import CompanyCard from './CompanyCard'

const ListCompanies = ({defaultFilters, list, isDetailPage, noMap}) => {
  const [companies, setCompanies] = useState()
  const [total, setTotal] = useState(0)
  const [totalWithoutFilter, setTotalWithoutFilter] = useState(0)
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState(defaultFilters || {})
  const {enqueueSnackbar} = useSnackbar()
  const hasLoadedOrdering = useSelector(companiesSelectors.hasLoadedOrdering)
  const currentPage = useSelector(companiesSelectors.getCurrentCompaniesPage)
  const activeOrder = useSelector(companiesSelectors.getActiveOrder)
  const {showMap, viewMode} = useSelector(companiesSelectors.universalSearch)
  const dispatch = useDispatch()
  const {hasAppliedFilters, searchParams, showOnlyFavorites} = useSelector(companiesSelectors.getSearchParams)

  const limit = useSelector(companiesSelectors.getCurrentLimit)

  const mountSorting = useCallback(
    (selectedOrder) => ({
      [`sort[${selectedOrder.key}]`]: selectedOrder.order.toLowerCase(),
    }),
    [],
  )

  const mountQueryParams = useCallback(
    (params) => {
      if (isDetailPage) {
        return
      }

      let queryParams = {
        limit: limit.current,
        // [`sort[share_capital]`]:'desc'
      }

      if (showOnlyFavorites) {
        queryParams.only_favorites = 1
      }

      if (hasAppliedFilters) {
        queryParams = {
          ...queryParams,
          ...params,
        }

        if (params?.activities && params.activities.length) {
          queryParams.activities = params.activities.join(',')
        }
      }

      if (activeOrder) {
        queryParams = {...queryParams, ...mountSorting(activeOrder)}
      }

      return queryParams
    },
    [activeOrder, hasAppliedFilters, limit, showOnlyFavorites, mountSorting],
  )

  const fetchCompanies = useCallback(async () => {
    try {
      setLoading(true)
      let queryParams = {
        page: currentPage,
        limit: limit.current,
        ...filters,
        ...mountQueryParams(searchParams),
      }

      const {
        data,
        total,
        total_without_filter,
        available_ordering: availableOrdering,
      } = await companiesService.getCompanies(queryParams)

      setTotalWithoutFilter(total_without_filter)
      setTotal(total)
      setCompanies(data)
      setLoading(false)

      if (!hasLoadedOrdering) {
        dispatch(setAvailableOrdering(availableOrdering))
      }
    } catch (error) {
      setLoading(false)
      setCompanies([])

      enqueueSnackbar('Ocorreu um erro ao consultar as empresas', {
        variant: 'error',
      })
    }
  }, [
    activeOrder,
    currentPage,
    dispatch,
    enqueueSnackbar,
    filters,
    hasAppliedFilters,
    limit,
    showOnlyFavorites,
    searchParams,
    mountQueryParams,
  ])

  useEffect(() => {
    fetchCompanies()
  }, [activeOrder, fetchCompanies])

  return (
    <>
      {showMap &&
        (noMap ? null : (
          <Map lg service={proposalService} currentPage={currentPage} changeFilter={setFilters} loading={loading} />
        ))}

      {viewMode === 'card' && !list ? (
        <div className="container-citymatch-table-cards">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row flex-wrap" style={{justifyContent: 'space-between'}}>
              {loading
                ? Array.from({length: 9}).map((_, index) => <CardSkeleton key={index} />)
                : companies?.map((res, index) => <CompanyCard key={index} {...res} />)}
            </div>

            {total > 0 && !loading && (
              <ProposalPagination
                actualLenght={companies ? companies.length : 0}
                limit={limit}
                loading={loading}
                page={currentPage}
                total={total}
                changePage={changePage}
                changeLimit={changeLimit}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="container-citymatch-table">
          <div className="table-responsive" style={{overflowX: 'auto'}}>
            <Table loading={loading} companies={companies} total={total} totalWithoutFilter={totalWithoutFilter} />
          </div>

          {total > 0 && !loading && (
            <ProposalPagination
              actualLenght={companies ? companies.length : 0}
              limit={limit}
              loading={loading}
              page={currentPage}
              total={total}
              changePage={changePage}
              changeLimit={changeLimit}
            />
          )}
        </div>
      )}
    </>
  )
}

export default ListCompanies
