import React, {useMemo} from 'react'

const TEXT_WARNING =
  'Lembrando que este trecho e apenas um exemplo e pode e deve ser modificado segundo as necessidades da sua infraestrutura.'

const InstructionsIFrame = ({url}) => {
  return (
    <>
      <h4>Escolhendo o Local</h4>
      <p>
        Decida onde você deseja armazenar seu arquivo HTML em sua infraestrutura. Isso pode ser em um servidor web, um
        serviço de hospedagem de arquivos ou até mesmo em um repositório online, dependendo de suas necessidades.
      </p>

      <h4>Template de arquivo</h4>
      <p>
        Utilize o exemplo de código abaixo para criar a pagina contendo o iFrame que ira carregar a página da Vitrine
        Econômica no seu site
      </p>
      <p>{TEXT_WARNING}</p>
      <pre>
        {`
        <!doctype html>
        <html lang="br">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Vitrine Econômica</title>
          
            <style>
              html, body {
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
              iframe {
              width: 100%;
              height: 100%;
              border: none;
            }
            </style>
          </head>
          <body>
            <iframe src="${url}" frameborder="0" width="100%" height="100%"></iframe>
          </body>
        </html>
        `}
      </pre>

      <h4>Upload do Arquivo</h4>
      <p>
        Utilize a ferramenta de upload fornecida pelo serviço escolhido para enviar seu arquivo HTML. Geralmente, essa
        opção está disponível no painel de controle do serviço ou através de um cliente de FTP, se estiver usando um
        servidor.
      </p>

      <h4>Configuração de Permissões (Se Necessário)</h4>
      <p>
        Certifique-se de que as permissões de leitura e execução estejam configuradas corretamente, especialmente se
        estiver usando um servidor web. Isso garantirá que o seu arquivo HTML seja acessível aos visitantes.
      </p>
    </>
  )
}

const InstructionsNginx = ({url}) => {
  return (
    <>
      <h4>Configuração do Proxy Reverso</h4>
      <p>
        Abra o arquivo de configuração do Nginx. O caminho pode variar, mas geralmente é encontrado em{' '}
        <i>/etc/nginx/nginx.conf</i> ou <i>/etc/nginx/sites-available/default</i>.
      </p>
      <p>Dentro do bloco server, adicione a seguinte configuração para definir o proxy reverso:</p>
      <p>{TEXT_WARNING}</p>
      <pre>
        {`
        server {
          listen 80;
          server_name seudominio.com.br;
          location / {
              proxy_pass ${url};
              proxy_set_header Host $host;
              proxy_set_header X-Real-IP $remote_addr;
              proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
              proxy_set_header X-Forwarded-Proto $scheme;
          }
        }
        `}
      </pre>
      <p>
        Certifique-se de substituir <i>seudominio.com.br</i> pelos valores específicos do seu ambiente.
      </p>
    </>
  )
}

const InstructionsApache = ({url}) => {
  if (!url) {
    return null
  }

  const parsed = new URL(url)
  const protocol = parsed.protocol
  const host = parsed.host

  return (
    <>
      <h4>Ativação do Módulo Proxy</h4>
      <p>
        O Apache precisa ter o módulo proxy ativado. Execute os seguintes comandos para ativar os módulos necessários:
        <pre>sudo a2enmod proxy sudo a2enmod proxy_http</pre>
      </p>

      <h4>Configuração do Proxy Reverso</h4>
      <p>
        Abra o arquivo de configuração do Apache. O caminho pode variar, mas geralmente é encontrado em{' '}
        <i>/etc/apache2/sites-available/000-default.conf</i> ou <i>/etc/httpd/conf/httpd.conf</i>.
      </p>

      <p>
        Dentro do bloco <i>VirtualHost</i>, adicione as seguintes linhas para definir o proxy reverso:
      </p>
      <p>{TEXT_WARNING}</p>
      <pre>
        {`
        <VirtualHost *:80>
          ServerName seudominio.com.br
          <Location>
            ProxyPass "${url}"
            ProxyPassReverse "${url}"
            ProxyPassReverseCookieDomain "${host}" "seudominio.com.br"
            ProxyPassReverseCookiePath "${protocol}//${host}/" "/"
          </Location>
        </VirtualHost>
        `}
      </pre>
      <p>
        Certifique-se de substituir <i>seudominio.com.br</i> pelos valores específicos do seu ambiente.
      </p>
    </>
  )
}

/**
 * @param {string} showcaseUrl
 * @return {{title:string, node:Node}[]}
 */
const useShowcaseSnippets = (showcaseUrl) => {
  return useMemo(() => {
    if (!showcaseUrl) {
      return []
    }

    return [
      {title: 'iFrame', node: <InstructionsIFrame url={showcaseUrl} />},
      {title: 'Nginx', node: <InstructionsNginx url={showcaseUrl} />},
      {title: 'Apache', node: <InstructionsApache url={showcaseUrl} />},
    ]
  }, [showcaseUrl])
}

export default useShowcaseSnippets
