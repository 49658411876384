import {Popover} from 'react-bootstrap'
import styled from 'styled-components'

export const CustomerHeader = styled.div`
  width: 100%;
  display: flex;
  text-transform: uppercase;
  color: #00428b;
  font-family: 'Nunito', sans-serif;
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 6vw;
  justify-content: space-around;
`

export const CustomerName = styled.div`
  text-align: left;
  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0px;
  color: #1d1d1d;
  text-transform: uppercase;
  margin-bottom: 20px;
`

const injectJustify = ({justify}) => (justify ? justify : 'space-between')

export const Container = styled.div`
  display: flex;
  justify-content: ${injectJustify};
  align-items: center;
  width: 100%;
  margin-top: 1rem;
`

export const FavoriteIcon = styled.i`
  color: ${({isFavorite}) => (isFavorite ? '#FFA800' : '#C9C9C9')};
  cursor: pointer;
`

export const StyledPopover = styled(Popover)`
  width: 400px;
  overflow-y: scroll;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;

  .popover-body {
    padding: 0px !important;
  }
`
