import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {connect, shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import * as auth from '../_redux/authRedux'
import {injectIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'

import {CityMatchLogo} from 'app/components/images'
import {useSnackbar} from 'notistack'
import {authService} from 'app/services'

const initialValues = {
  token: '',
}

function TwoFactor(props) {
  const {intl} = props
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()
  const history = useHistory()

  const {isAuthorized} = useSelector(
    ({auth}) => ({
      isAuthorized: auth.authToken != null,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!isAuthorized) history.push('/auth')
  }, [isAuthorized, history])

  const [loading, setLoading] = useState(false)

  const TwoFactorSchema = Yup.object().shape({
    token: Yup.string()
      .matches(/^[0-9]+$/, 'Digite apenas números')
      .min(4, 'Mínimo de 4 digitos')
      .max(4, 'Máximo de 4 digitos')
      .required('Envie seu token!'),
  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const onSubmit = async ({token}, {setSubmitting}) => {
    try {
      setLoading(true)

      const response = await authService.twofactorCheck({
        code: token,
      })

      if (response['2fa']) {
        dispatch(auth.actions.twofactor(true))
        setLoading(false)
        history.push('/')
      } else {
        throw new Error('AUTH.LOGIN.INVALID_TWOFACTOR')
      }
    } catch (error) {
      enqueueSnackbar(
        intl.formatMessage({
          id: error.message || 'AUTH.LOGIN.INVALID_TWOFACTOR',
        }),
        {
          variant: 'warning',
        },
      )

      setSubmitting(false)

      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: TwoFactorSchema,
    onSubmit,
  })

  return (
    <div className="login-form login-forgot" style={{display: 'block'}}>
      <div className="text-center mb-10 mb-lg-20">
        <CityMatchLogo />
        <h3 className="font-size-h1">Dupla autenticação</h3>
        <div className="text-muted font-weight-bold">Coloque o código de autenticação enviado por e-mail</div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <input
            type="number"
            step="0.01"
            className={`input_number form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('token')}`}
            name="token"
            {...formik.getFieldProps('token')}
          />
          {formik.touched.token && formik.errors.token ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.token}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            id="kt_login_forgot_submit"
            type="submit"
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            disabled={formik.isSubmitting}>
            <span>{intl.formatMessage({id: 'AUTH.FORGOT.SEND_BUTTON'})}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          <Link to="/auth">
            <button
              type="button"
              id="kt_login_forgot_cancel"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
              {intl.formatMessage({id: 'AUTH.FORGOT.CANCEL_BUTTON'})}
            </button>
          </Link>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(TwoFactor))
