import React from 'react'

import {Paper, ContainerHeader, DetailContainer, BottomItemValue} from '../styles'
import {Button} from 'react-bootstrap'
import Type from 'app/components/proposal/Type'
import {Box} from '@material-ui/core'
import {useLocation, useHistory} from 'react-router-dom'

const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

const LinkedProposals = ({ranking, id, code, state}) => {
  const history = useHistory()

  return (
    <Paper>
      <ContainerHeader>
        Oportunidades vinculadas
        {ranking ? (
          <span style={{ fontSize: 18 }}>
            Ranking de Atratividade de Investimentos: <span style={{ fontWeight: 'normal' }}>Posição</span>{' '}
            {ranking.ranking.position}{' '}
            <span style={{ fontWeight: 'normal' }}>de {formatNumber(ranking.ranking.total_of_cities)}</span>{' '}
          </span>
        ) : null}
      </ContainerHeader>
      <DetailContainer>
        <table className="table city">
          <thead>
            <tr>
              <th scope="col">
                <span className="text-muted"></span>
              </th>
              <th scope="col">
                <span
                  className="d-flex justify-content-center text-center"
                  style={{color: '#8C8C8C', fontFamily: 'Nunito', fontSize: 15, fontWeight: 'normal'}}>
                  Quantidade
                </span>
              </th>
              <th scope="col">
                <span
                  className="d-flex justify-content-center text-center"
                  style={{color: '#8C8C8C', fontFamily: 'Nunito', fontSize: 15, fontWeight: 'normal'}}>
                  Oportunidades
                  <br />
                  Ativas
                </span>
              </th>
              <th scope="col">
                <span
                  className="d-flex justify-content-center text-center"
                  style={{color: '#8C8C8C', fontFamily: 'Nunito', fontSize: 15, fontWeight: 'normal'}}>
                  Oportunidades
                  <br />
                  Ganhas / Concretizadas
                </span>
              </th>
              <th scope="col">
                <span
                  className="d-flex justify-content-center text-center"
                  style={{color: '#8C8C8C', fontFamily: 'Nunito', fontSize: 15, fontWeight: 'normal'}}>
                  Ranking
                  <br />
                  Estadual
                </span>
              </th>
              <th scope="col">
                <span
                  className="d-flex justify-content-center text-center"
                  style={{color: '#8C8C8C', fontFamily: 'Nunito', fontSize: 15, fontWeight: 'normal'}}>
                  Ranking
                  <br />
                  Nacional
                </span>
              </th>
              <th scope="col">
                <span className="text-muted"></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle">
                <Box display="flex" alignItems="center">
                  <Box
                    style={{
                      backgroundColor: '#D4E2F0',
                      marginRight: 10,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    borderRadius="5px"
                    width="46px"
                    height="46px">
                    <i className="fas fa-building" style={{color: '#2C6EB5'}}></i>
                  </Box>

                  <label>
                    <b>Oportunidades Privadas</b>
                  </label>
                </Box>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>
                  {formatNumber(ranking.proposals.private.total)}
                </BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>{formatNumber(ranking.proposals.private.open)}</BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>
                  {formatNumber(ranking.proposals.private.completed)}
                </BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>
                  {formatNumber(ranking.proposals.private.ranking.state)}
                  <span style={{verticalAlign: 'super', fontSize: 8}}>o</span>
                </BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>
                  {formatNumber(ranking.proposals.private.ranking.national)}
                  <span style={{verticalAlign: 'super', fontSize: 8}}>o</span>
                </BottomItemValue>
              </td>
              <td className="align-middle text-center" style={{padding: 0}}>
                <Button
                  onClick={() => history.push(`/proposals#filters=type:company&ufs:${state.id}&city:${id}`)}
                  variant="primary">
                  VER OPORTUNIDADES
                </Button>
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <Box display="flex" alignItems="center">
                  <Box
                    style={{
                      backgroundColor: '#D4E2F0',
                      marginRight: 10,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    borderRadius="5px"
                    width="46px"
                    height="46px"
                  >
                    <i className="fas fa-university" style={{ color: '#2C6EB5' }}></i>
                  </Box>
                  <label>
                    <b>Oportunidades Públicas</b>
                  </label>
                </Box>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>{formatNumber(ranking.proposals.public.total)}</BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>{formatNumber(ranking.proposals.public.open)}</BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>
                  {formatNumber(ranking.proposals.public.completed)}
                </BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{fontSize: 15}}>
                  {formatNumber(ranking.proposals.public.ranking.state)}
                  <span style={{verticalAlign: 'super', fontSize: 8}}>o</span>
                </BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{ fontSize: 15 }}>
                  {formatNumber(ranking.proposals.public.ranking.state)}
                  <span style={{ verticalAlign: 'super', fontSize: 8 }}>o</span>
                </BottomItemValue>
              </td>
              <td className="align-middle text-center">
                <BottomItemValue style={{ fontSize: 15 }}>
                  {formatNumber(ranking.proposals.public.ranking.national)}
                  <span style={{ verticalAlign: 'super', fontSize: 8 }}>o</span>
                </BottomItemValue>
              </td>
              <td className="align-middle text-center" style={{ padding: 0 }}>
                <Button
                  variant="primary"
                  disabled={ranking.proposals.public.total === 0}
                  onClick={() => history.push(`/proposals#filters=type:city&ufs:${state.id}&city:${id}`)}
                >
                  VER OPORTUNIDADES
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </DetailContainer>
    </Paper>
  )
}

export default LinkedProposals
