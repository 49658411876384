import {Popover} from 'react-bootstrap'
import styled from 'styled-components'

const injectColor = ({isOrderActive}) => (isOrderActive ? '#1E7BE1' : '#6c757d')

export const OrderContainer = styled.div`
  color: ${injectColor};
  cursor: pointer;
  text-transform: uppercase;
  i {
    color: ${injectColor};
  }
  span {
    margin-right: 5px;
  }
`

export const ActionIcon = styled.i`
  color: #1e7be1;
  cursor: pointer;
`

export const StyledPopover = styled(Popover)`
  max-height: 300px;
  overflow-y: scroll;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
`
