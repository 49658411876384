import React from 'react'
import {Route, Switch} from 'react-router-dom'

import DetailState from './DetailState'
import EditState from './EditState'
import ListStates from './ListStates'

const StatesPage = () => {
  return (
    <Switch>
      <Route path="/states" exact component={ListStates} />
      <Route path="/states/:stateId" exact component={DetailState} />
      <Route path="/states/:stateId/edit" exact component={EditState} />
    </Switch>
  )
}

export default StatesPage
