import httpClient from '../../client'

const getPoliticalOffices = async ({limit, page, ...rest}) => {
  try {
    const {data} = await httpClient.get('/political-offices', {
      params: {limit, page, ...rest},
    })

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling political offices with ${JSON.stringify({limit, page})}`, error)
    return error
  }
}

export default getPoliticalOffices
