import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {connect} from 'react-redux'
import {Link, useLocation, useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {injectIntl} from 'react-intl'
import * as auth from '../_redux/authRedux'
import changePassword from '../../../services/auth/changePassword/index'

import {CityMatchLogo} from 'app/components/images'

import routes from './routes'

const initialValues = {
  password: '',
  confirmPassword: '',
}

function ChangePassword({intl}) {
  const [isRequested, setIsRequested] = useState(false)

  const history = useHistory()

  const {search} = useLocation()
  const query = new URLSearchParams(search)

  const tokenUrl = query.get('')

  useEffect(() => {
    if (!tokenUrl) {
      history.replace(routes.LOGIN)
    }
  }, [history, tokenUrl])

  const NewPasswordSchema = Yup.object().shape({
    password: Yup.string().required(),
  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const onSubmit = async ({password, confirmPassword}, {setSubmitting, setStatus}) => {
    changePassword(password, confirmPassword)
      .then(() => setIsRequested(true))
      .catch(() => {
        setIsRequested(false)
        setSubmitting(false)
        setStatus(intl.formatMessage({id: 'AUTH.VALIDATION.NOT_FOUND'}, {name: password}))
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: NewPasswordSchema,
    onSubmit,
  })

  return (
    <div className="login-form login-forgot" style={{display: 'block'}}>
      <div className="text-center mb-10 mb-lg-20">
        <CityMatchLogo />
        <h3 className="font-size-h1">
          {intl.formatMessage({
            id: 'AUTH.FORGOT.TITLE',
          })}
        </h3>
        <div className="text-muted font-weight-bold">Digite sua nova senha</div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="form-group fv-plugins-icon-container">
          <input
            type="password"
            className={`form-control form-control-solid mb-3 h-auto py-5 px-6 ${getInputClasses('email')}`}
            placeholder="nova senha"
            name="newPassword"
            {...formik.getFieldProps('newPassword')}
          />
          <input
            type="password"
            className={`form-control form-control-solid mb-3 h-auto py-5 px-6 ${getInputClasses('email')}`}
            placeholder="confirmar nova senha"
            name="password"
            {...formik.getFieldProps('passord')}
          />
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            id="kt_login_forgot_submit"
            type="submit"
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
            disabled={formik.isSubmitting}>
            {intl.formatMessage({id: 'AUTH.FORGOT.SEND_BUTTON'})}
          </button>
          <Link to="/auth">
            <button
              type="button"
              id="kt_login_forgot_cancel"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
              {intl.formatMessage({id: 'AUTH.FORGOT.CANCEL_BUTTON'})}
            </button>
          </Link>
        </div>
      </form>
    </div>
  )
}

export default injectIntl(connect(null, auth.actions)(ChangePassword))
