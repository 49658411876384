import React from 'react'
import {Route, Switch} from 'react-router-dom'
import EditCustomer from './EditCustomer'

const CustomerPage = () => {
  return (
    <Switch>
      <Route path="/customer" exact component={EditCustomer} />
    </Switch>
  )
}

export default CustomerPage
