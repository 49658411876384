import React, { Fragment } from 'react'
import { Box, Divider, Grid } from '@material-ui/core'
import { Button, Form } from 'react-bootstrap'

import { Title, Container } from '../styles'
import { AttachFile, InsertPhoto, PictureAsPdf } from '@material-ui/icons'

const formatCurrency = (amount) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount)

const ProposalRevision = ({ proposal, onSubmit, submiting }) => {
  return (
    <Container style={{ padding: 0 }}>
      <Box marginBottom="60px" maxWidth="654px" m="auto" width="100%">
        <Grid container direction="column">
          <Box>
            <Box marginBottom="5px">
              <Title>TIPO DA OPORTUNIDADE</Title>
            </Box>
            <Box display="flex" width="fit-content" flexDirection="column" mb="20px">
              <Form.Label>{proposal.type === 'company' ? 'Privada' : 'Pública'}</Form.Label>
            </Box>
            <Divider />
          </Box>

          <BlockDetail
            title="DETALHES DA OPORTUNIDADE"
            items={[
              { label: 'Nome', value: proposal.name },
              {
                label: 'Vinculada a',
                value: proposal.city ? `${proposal.city.name} - ${proposal.city.state.uf}` : null,
              },
            ]}
          />

          {proposal.benefits.length && (
            <BlockDetailBenefityList title="INCENTIVOS DISPONÍVEIS AO INVESTIDOR" items={proposal.benefits} />
          )}

          {proposal.benefits.length && <BlockDetailBenefity title="BENEFÍCIOS" items={proposal.benefits} />}

          {proposal.project && (
            <BlockDetail
              title="DADOS DA EMPRESA"
              items={[
                { label: 'Razao Social', value: proposal.project.company.name },
                {
                  label: 'Capital Social',
                  value: proposal.project?.company?.share_capital
                    ? formatCurrency(proposal.project.company.share_capital)
                    : null,
                },
                { label: 'CNPJ', value: proposal.project?.company?.federal_tax_id },
                {
                  label: 'Atividade Econômica (CNAE Primário)',
                  value: proposal.project?.company?.main_activity_description,
                },
              ]}
            />
          )}

          {proposal.project && (
            <BlockDetail
              title="ENDEREÇO DA OPORTUNIDADE"
              items={[
                { label: 'CEP', value: proposal.project.company?.address?.zip },
                { label: 'Número', value: proposal.project.company?.address?.number },
                { label: 'Bairro', value: proposal.project.company?.address?.neighborhood },
                { label: 'Estado', value: proposal.project?.company?.address?.state?.uf },
                { label: 'Município', value: proposal.project.company?.address?.city?.name },
              ]}
            />
          )}

          <BlockDetail
            title="CONTATO"
            items={[
              { label: 'Nome para contato', value: proposal.contacts[0]?.name },
              { label: 'Cargo', value: proposal.contacts[0]?.responsibility },
              { label: 'Telefone', value: proposal.contacts[0]?.phone },
              { label: 'E-mail', value: proposal.contacts[0]?.email },
              { label: 'Site', value: proposal.contacts[0]?.linkedin },
            ]}
          />

          {proposal.project && (
            <BlockDetail
              title="DESCRIÇÃO DO PROJETO"
              items={[
                {
                  label: 'Categoria da oportunidade',
                  value: proposal.project.category_description
                    ? proposal.project.category_description
                    : proposal.project.category,
                },
                {
                  label: 'Valor total do investimento a ser realizado',
                  value: proposal.project?.total ? formatCurrency(proposal.project?.total) : null,
                },
                {
                  label: 'Valor de necessidade do investimento',
                  value: proposal.project?.required_investment
                    ? formatCurrency(proposal.project?.required_investment)
                    : null,
                },
                { label: 'Breve descrição do projeto', value: proposal.project?.description },
              ]}
            />
          )}

          <Box pt="30px">
            <Box mb="22px">
              <Title>ANEXOS</Title>
            </Box>
            <Box display="flex" gridGap="15px" flexWrap="wrap" justifyContent="space-between">
              {proposal.multimedia.map((file) => (
                <Tip description={file.name} />
              ))}
            </Box>
          </Box>

          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Container>
  )
}

const BlockDetail = ({ title, items }) => {
  return (
    <Fragment>
      <Box pt="30px" pb="30px">
        <Box mb="5px">
          <Title>{title}</Title>
        </Box>

        {items?.map((item) => (
          <Detail {...item} />
        ))}
      </Box>
      <Divider />
    </Fragment>
  )
}

const BlockDetailBenefityList = ({ title, items }) => {
  return (
    <Fragment>
      <Box pt="30px" pb="30px">
        <Box mb="5px">
          <Title>{title}</Title>
        </Box>

        {items?.map((item) => (
          <Detail label={item.type.toUpperCase()} />
        ))}
      </Box>
      <Divider />
    </Fragment>
  )
}

const BlockDetailBenefity = ({ title, items }) => {
  return items.map((item) => {
    if (item.type === 'terreno') {
      return (
        <Fragment>
          <Box pt="30px" pb="30px">
            <Box mb="5px">
              <Title>SOBRE O TERRENO</Title>
            </Box>
            <Detail label="Identificação" value={item.identification} />
            <Detail label="Área'" value={item.area} />
            <Detail
              label="Valor Estimado"
              value={item.estimated_value.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}
            />
            <Detail label="Restrições" value={item.restrictions} />
            <Detail label="URL Mapa" value={item.url_map} />
          </Box>
          <Divider />
          <Box pt="30px" pb="30px">
            <Box mb="5px">
              <Title>DETALHAMENTO</Title>
            </Box>
            <Detail value={item.description} />
          </Box>
          <Divider />
        </Fragment>
      )
    }

    return (
      <Fragment>
        <Box pt="30px" pb="30px">
          <Box mb="5px">
            <Title>SOBRE INCENTIVO {item.type.toUpperCase()}</Title>
          </Box>
          <Detail value={item.description} />
        </Box>
        <Divider />
      </Fragment>
    )
  })
}

const Detail = ({ label, value }) => {
  return (
    <Box
      style={{
        color: '#1D1D1D',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '14px',
      }}
    >
      <b>
        {label}
        {label && value ? ':' : null}{' '}
      </b>{' '}
      {value}
    </Box>
  )
}

const Tip = ({ description }) => {
  return (
    <Box
      padding="2px 10px"
      width="fit-content"
      fontSize="12px"
      borderRadius="10px"
      display="flex"
      alignItems="center"
      style={{ backgroundColor: '#1E7BE10C' }}
    >
      {(function () {
        switch (description.split('.')[description.split('.').length - 1]) {
          case 'pdf':
            return <PictureAsPdf />
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'bmp':
          case 'gif':
            return <InsertPhoto />
          case 'doc':
          case 'docx':
          case 'xls':
          case 'xlsx':
          case 'txt':
            return <AttachFile />
          default:
            return <AttachFile />
        }
      })()}
      <p style={{ margin: '0 0 0 4px' }}>{description}</p>
    </Box>
  )
}

export default ProposalRevision
