import httpClient from '../../client'

import apiRoutes from '../constants'

const getStates = async (searchParams) => {
  try {
    const {data} = await httpClient.get(apiRoutes.GET_STATES, {
      params: searchParams,
    })

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling states with ${JSON.stringify(searchParams)}`, error)
    return error
  }
}

export default getStates
