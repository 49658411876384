import React from 'react'
import { useSelector } from 'react-redux'
import Shimmer from 'react-loading-skeleton'
import * as companiesSelectors from 'app/modules/Companies/redux/selectors'

import TableHead from './Header'
import TableRow from './Row'
import SkeletonRow from './Row/skeleton'

const SKELETON_ITEMS = 10

const Table = ({ loading, companies, total, totalWithoutFilter }) => {
  const columns = useSelector(companiesSelectors.getCompaniesColumns)

  const renderCompany = (company) => <TableRow key={`k=${company.id}`} {...company} columns={columns} />

  return (
    <>
      {loading ? (
        <Shimmer width="370px" height="21px" />
      ) : (
        <div className="filter-info">
          {totalWithoutFilter === total ? (
            <p>Total de empresas ativas na base: {Intl.NumberFormat('pt-BR').format(totalWithoutFilter)}</p>
          ) : (
            <p>
              Total de empresas filtradas na base: {Intl.NumberFormat('pt-BR').format(total)} /{' '}
              {Intl.NumberFormat('pt-BR').format(totalWithoutFilter)}
            </p>
          )}
        </div>
      )}

      <table className="table position-relative" style={{ minHeight: '150px' }}>
        <TableHead />

        {total === 0 && !loading ? (
          <p className="text-center position-absolute filter-info w-100 pt-10">Nenhuma empresa encontrada</p>
        ) : (
          <tbody>
            {loading
              ? Array.from({ length: SKELETON_ITEMS }).map((_, index) => <SkeletonRow key={index} />)
              : companies?.map(renderCompany)}
          </tbody>
        )}
      </table>
    </>
  )
}

export { Table }
