import React, {useEffect, useState, useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useSnackbar} from 'notistack'
import {useFormik} from 'formik'
import {Box} from '@material-ui/core'
import {Button} from 'react-bootstrap'
import {changeActualEntity} from 'app/modules/Cities/redux'

import {customerService} from 'app/services'

import ContactAddressIcon from 'app/components/images/Icons/ContactAddress'

import {Column, FormContainer, Row, SidePanel, SidePanelItem, SidePanelTitle, SuccessIcon} from './styles'

import * as CustomerSteps from './Customer'
import {useHistory} from 'react-router-dom'

const steps = {
  NEW_CUSTOMER: 'NEW_CUSTOMER',
  SUCCESS: 'SUCCESS',
}

const NewCustomer = (props) => {
  const {enqueueSnackbar} = useSnackbar()

  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(steps.NEW_CUSTOMER)
  const [filledSteps, setFilledSteps] = useState([])
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(changeActualEntity({id: 'new', name: 'Novo cliente'}))
  }, [dispatch])

  const validateCNPJ = (cnpj) => {
    var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
    var c = String(cnpj).replace(/[^\d]/g, '')
    
    if(c.length !== 14)
        return false

    if(/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
  }

  const validateForm = useCallback((values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Nome é obrigatório';
    }

    if (!values.type) {
      errors.type = 'Tipo é obrigatório';
    }

    if ((values.type === 'state' || values.type === 'city') && !values.state) {
      errors.state = 'Estado é obrigatório';
    }

    if (values.type === 'city' && !values.city) {
      errors.city = 'Cidade é obrigatório';
    }

    if (values.type === 'company') {
      if (!values.company.federal_tax_id){
        errors.company = 'CNPJ é obrigatório';
      }

      if (validateCNPJ(values.company.federal_tax_id) === false) {
        errors.company = 'CNPJ inválido';
      }
    }

    return errors;
  }, []);

  const onSubmit = useCallback(
    async (values) => {
      try {
        values.company.federal_tax_id = values.company.federal_tax_id.replace(/[^\d]/g, '');
        const errors = validateForm(values);
        if (Object.keys(errors).length === 0) {
          let data;
          if (props.match.params.id) {
            data = await customerService.saveCustomer(props.match.params.id, {
              name: values.name,
              type: values.type,
              state: values.state,
              city: values.city,
              company: {
                federal_tax_id: values.company.federal_tax_id,
              },
            });
          } else {
            data = await customerService.createCustomer({
              name: values.name,
              type: values.type,
              state: values.state,
              city: values.city,
              company: {
                federal_tax_id: values.company.federal_tax_id,
              },
            });
          }

          if (data) {
            setCurrentStep(steps.SUCCESS);
            enqueueSnackbar('Cliente salvo com sucesso!', {
              variant: 'success',
            });
          } else {
            throw data;
          }
        } else {
          formikCustomer.setErrors(errors);
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar],
  )

  const [initialValues, setInitialValues] = useState({
    id: 0,
    name: '',
    type: '',
    state: 0,
    city: 0,
    company: {
      federal_tax_id: '',
    },
  });

  useEffect(() => {
    if (props.match.params.id && loading) {
      const fetchCustomerData = async () => {
        try {
          const customerData = await customerService.getCustomerById(props.match.params.id);
          const state_id = customerData.states[0]?.id ? customerData.states[0]?.id : (customerData.cities[0]?.state_id ?? 0);
          
          setInitialValues({
            id: customerData.id,
            name: customerData.name,
            type: customerData.type,
            state: state_id,
            city: customerData.cities[0]?.id ?? 0,
            company: {
              federal_tax_id: customerData.company?.federal_tax_id ?? '',
            },
          });
          setLoading(false)
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
      };
      fetchCustomerData();
    } else {
      setLoading(false)
    }
  }, [props.match.params.id, enqueueSnackbar, initialValues, loading]);

  const formikCustomer = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  })

  const sections = useMemo(() => {
    return [
      {
        icon: (color) => <ContactAddressIcon color={color} />,
        title: 'Informações básicas',
        step: steps.NEW_CUSTOMER,
      },
    ]
  }, [])

  const renderSideItem = (item, index) => {
    const color = item.step === currentStep || filledSteps.includes(item.step) ? '#1E7BE1' : '#C9C9C9'

    return (
      <SidePanelItem active={item.step === currentStep}>
        <div style={{marginRight: '20px', width: '35px', height: '45px'}}>{item.icon(color)}</div>
        <Column>
          <SidePanelTitle active={item.step === currentStep || filledSteps.includes(item.step)}>
            {item.title}
          </SidePanelTitle>
        </Column>
      </SidePanelItem>
    )
  }

  const renderForm = () => {
    switch (currentStep) {
      case steps.NEW_CUSTOMER:
        return <CustomerSteps.Basic formik={formikCustomer} />
      default:
        return null
    }
  }

  return (
    <form className="d-flex flex-column bg-white roundeds" style={{minHeight: '868px'}}>
      {currentStep === steps.SUCCESS ? (
        <Box alignItems="center" display="flex" flex={1} flexDirection="column" height="500px" justifyContent="center">
          <Box marginBottom="20px">
            <Box marginBottom="20px">
              <SuccessIcon className="far fa-check-circle" />
            </Box>
            Cliente salvo com sucesso
          </Box>
          <Box marginBottom="30px">
            <Button
              onClick={() => {
                formikCustomer.resetForm()
                setCurrentStep(steps.NEW_CUSTOMER)
              }}>
              Cadastrar novo cliente
            </Button>
          </Box>

          <Box>
            <Button
              onClick={() => {
                formikCustomer.resetForm()
                setFilledSteps([])
                history.push('/manage-customers')
              }}
              variant="outline-primary">
              Ver clientes
            </Button>
          </Box>
        </Box>
      ) : (
        <Row>
          <SidePanel>{sections.map(renderSideItem)}</SidePanel>
          <FormContainer>
            {!loading && (
              <form onSubmit={formikCustomer.handleSubmit}>{renderForm()}</form>
            )}
          </FormContainer>
        </Row>
      )}
    </form>
  )
}

export default NewCustomer
