import {Form} from 'react-bootstrap'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 5px 15px #00000029;
  border-radius: 10px;
  color: #1e7be1;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 307px;
  min-height: 300px;
  z-index: 3;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const CleanFilterButton = styled.div`
  color: #1e7be1;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 1.2px;
  font-size: 12px;
  font-weight: bold;
  color: #1e7be1;
  opacity: 1;
`

export const FormTitle = styled.h6`
  color: #1e7be1;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
`

const injectDisplay = ({display}) => (display ? display : 'block')

export const FormContainer = styled.div`
  border-right: 1px solid #ebedf3;
  display: ${injectDisplay};
  padding: 2rem;
  width: 250px;
`

export const Switch = styled(Form.Check)`
  text-align: left;
  // font: normal normal normal 16px/20px Nunito;
  color: #1d1d1d;
`
