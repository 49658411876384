import styled from 'styled-components'

export const Label = styled.label`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  text-align: left;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
