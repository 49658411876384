import {Box} from '@material-ui/core'
import {Container, Title} from 'app/modules/Proposals/pages/NewProposal/Contact/styles'
import {userService} from 'app/services'
import {CELLPHONE_MASK, EMAIL_MASK, PHONE_MASK, SITE_MASK} from 'lib/masks'
import React, {useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import InputMask from 'react-input-mask'

const ProposalContact = ({formik, onNextStep, onPreviousStep}) => {
  const {
    handleChange,
    setFieldValue,
    values: {contact},
  } = formik
  const [loading, setLoading] = useState(false)
  const [validSite, setValidSite] = useState(true)
  const [validEmail, setValidEmail] = useState(false)

  const getMyUserData = () => {
    const regexSanitizePhone = /^\+?\d{2}\s?0?/
    userService.getUserByToken().then((res) => {
      handleChange({target: {value: res.name, name: 'contact.name'}})
      handleChange({target: {value: res.email, name: 'contact.email'}})
      handleChange({
        target: {value: (res?.property?.mobile_phone ?? '').replace(regexSanitizePhone, ''), name: 'contact.cellphone'},
      })
      handleChange({
        target: {value: (res?.property?.phone ?? '').replace(regexSanitizePhone, ''), name: 'contact.phone'},
      })
      handleChange({target: {value: res?.property?.linkedin, name: 'contact.site'}})
    })
  }

  const validateSite = (value) => () => {
    if (!value) {
      setValidSite(true)
    } else {
      setValidSite(SITE_MASK.test(value))
    }
  }

  const validateEmail = (value) => {
    if (!value) return
    if (!EMAIL_MASK.test(value) && validEmail === true) {
      setValidEmail(false)
      return
    }
    if (EMAIL_MASK.test(value) && validEmail === false) setValidEmail(true)
  }

  return (
    <Container style={{padding: 0}}>
      <Box marginBottom="30px">
        <Title>Contato da oportunidade</Title>
      </Box>

      <Box display="flex" flex={null} flexDirection="row" marginBottom="30px">
        <Box display="flex">
          <Form.Check name="my_contact_info" id="my_contact_info" type="checkbox" onChange={() => getMyUserData()} />
          <label
            htmlFor="my_contact_info"
            style={{color: '#8C8C8C', fontSize: 14, fontFamily: 'Nunito Sans', cursor: 'pointer'}}>
            Adicionar contato do meu usuário.
          </label>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" marginBottom="30px">
        <Box display="flex" flexDirection="column" marginRight="20px" width="100%">
          <Form.Label>
            <b>Nome para contato*</b>
          </Form.Label>
          <Form.Control
            autoComplete="off"
            name="contact.name"
            onChange={handleChange}
            value={contact.name}
            placeholder="Ex.: João da Silva Santos"
          />
        </Box>
        <Box display="flex" width="100%">
          <Box display="flex" flex={1} flexGrow={2} flexDirection="column" marginRight="20px">
            <Form.Label>
              <b>Celular*</b>
            </Form.Label>
            <InputMask
              autoComplete="off"
              className="form-control"
              name="contact.cellphone"
              onChange={handleChange}
              value={contact.cellphone}
              placeholder="Ex.: (41) 99813-6874"
              mask={CELLPHONE_MASK}
            />
          </Box>
          <Box display="flex" flex={1} flexGrow={2} flexDirection="column">
            <Form.Label>
              <b>Telefone</b>
            </Form.Label>
            <InputMask
              autoComplete="off"
              className="form-control"
              name="contact.phone"
              onChange={handleChange}
              value={contact.phone}
              placeholder="Ex.: (41) 9813-6874"
              mask={PHONE_MASK}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" flex={1} flexDirection="row" marginBottom="30px">
        <Box display="flex" flexDirection="column" marginRight="30px" width="100%">
          <Form.Label>
            <b>Cargo para contato*</b>
          </Form.Label>
          <Form.Control
            autoComplete="off"
            name="contact.role"
            onChange={handleChange}
            value={contact.role}
            placeholder="Ex.: Secretario de Infra-estrutura"
          />

          <Box display="flex" flexDirection="column" width="100%" marginTop="30px">
            <Form.Label>
              <b>Site</b>
            </Form.Label>
            <Form.Control
              autoComplete="off"
              name="contact.site"
              onBlur={validateSite(contact.site)}
              onChange={handleChange}
              value={contact.site}
              placeholder="Ex.: https://exemplo.com.br"
              type="url"
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          <Form.Label>
            <b>E-mail*</b>
          </Form.Label>
          <Form.Control
            autoComplete="off"
            name="contact.email"
            onBlur={validateEmail(contact.email)}
            onChange={handleChange}
            value={contact.email}
            placeholder="Ex.: exemplo@mail.com.br"
            type="email"
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={onPreviousStep} variant="outline-primary">
          ↽ Voltar
        </Button>
        <Button
          onClick={onNextStep}
          disabled={
            loading ||
            !contact.name ||
            !contact.email ||
            !contact.cellphone ||
            !contact.role ||
            !validEmail ||
            !validSite
          }>
          Próximo passo ⇀
        </Button>
      </Box>
    </Container>
  )
}

export default ProposalContact
