import styled from 'styled-components'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const SidePanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  border-right: 1px solid #f7f7f7;
  padding: 50px 32px;
  min-width: 380px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 2;
  padding: 50px 32px;
  padding-right: 50px;
`

export const SidePanelItem = styled.div`
  align-items: center;
  display: flex;
  background-color: ${({active}) => (active ? '#f4f9fe' : 'inherit')};
  height: 46px;
  padding-top: 21px;
  padding-bottom: 21px;
  flex: 1;
  padding-left: 30px;
  max-height: 100px;
  border-radius: 5px;
  position: relative;
  &:after {
    content: '';
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid ${({active}) => (active ? '#f4f9fe' : 'transparent')};
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: -19px;
  }
`

export const SidePanelTitle = styled.span`
  color: ${({ active }) => (active ? '#00428B' : '#C9C9C9')};
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 3px;
`

const injectStepColor = ({active, isFilled}) => {
  if (!isFilled) {
    return active ? '#007BFF' : '#C9C9C9'
  }
  return '#52C448'
}

export const SidePanelStep = styled.span`
  color: ${injectStepColor};
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
`

export const Icon = styled.i`
  color: ${({ active }) => (active ? '#007BFF' : '#C9C9C9')};
  font-size: 31px;
`

export const SuccessIcon = styled.i`
  color: #52c448;
  font-size: 150px;
`
