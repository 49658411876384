import React, {useCallback, useState, useRef, useEffect} from 'react'
import {format} from 'date-fns'
import {Popover, Overlay, ListGroup} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import {ProposalLogo, Circular as CircularLoading} from 'app/components'
import Status from 'app/components/proposal/Status'
import Type from 'app/components/proposal/Type'

import {FavoriteIcon, ProposalColumn, ProposalName, LogoContainer, StyledPopover} from './styles'

import {useUpdateOpportunity, useFavorite} from 'app/hooks'

import * as columnUtils from '../utils'
import {Actions} from '../../ProposalCards/ProposalCard/styles'
import {Button} from '@material-ui/core'
import {CheckCircle, XCircle, Circle, Edit, Menu, MoreHorizontal, Star, Trash} from 'react-feather'

const Row = ({
  created_at,
  id,
  name,
  status: proposalStatus,
  type,
  is_favorite,
  location,
  multimedia = [],
  project,
  columns,
  city,
  lost_at,
  completed_at,
  has_permission_to_edit,
  reloadList,
}) => {
  const popoverRef = useRef(null)

  const history = useHistory()

  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const logoUrl = '/media/images/logo-city.png'

  const cityName = city ? city?.name : 'N/A'
  const uf = city ? city?.state?.uf : 'N/A'

  const {action: handleOpportunityStatus, loading: loadingUpdate, status: updatedStatus} = useUpdateOpportunity({
    id,
    status: proposalStatus,
    callback: reloadList,
  })

  useEffect(() => {
    setShowPopover(false)
  }, [loadingUpdate])

  const {action: handleFavoriteOpportunity, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'proposal',
    label: 'Proposta',
  })

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount)

  const goToProposalDetails = useCallback(
    (proposalId) => {
      history.push(`/proposals/${proposalId}`)
    },
    [history],
  )

  const handlePopoverClick = (event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }

  return (
    <tr>
      {columnUtils.shouldRenderColumn({
        columnKey: 'id',
        stateColumns: columns,
      }) && (
        <th className="align-middle" scope="row" style={{color: '#8C8C8C', fontWeight: 'bold'}}>
          {id}
        </th>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'project_company_name',
        stateColumns: columns,
      }) && (
        <td className="align-middle text-uppercase id">
          <ProposalColumn>
            <LogoContainer>
              <ProposalLogo name={name} url={logoUrl} />
            </LogoContainer>
            <ProposalName onClick={() => goToProposalDetails(id)}>{name}</ProposalName>
          </ProposalColumn>
        </td>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'type',
        stateColumns: columns,
      }) && (
        <td className="align-middle">
          <Type type={type} />
        </td>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'project_total',
        stateColumns: columns,
      }) && (
        <td className="align-middle font-bold">
          {project && project.total ? formatCurrency(project.total) : 'Não Informado'}
        </td>
      )}
      {columnUtils.shouldRenderColumn({
        columnKey: 'city',
        stateColumns: columns,
      }) && <td className="align-middle">{cityName || 'Não Informado'}</td>}
      {columnUtils.shouldRenderColumn({
        columnKey: 'uf',
        stateColumns: columns,
      }) && <td className="align-middle">{uf || 'Não Informado'}</td>}
      {columnUtils.shouldRenderColumn({
        columnKey: 'created_at',
        stateColumns: columns,
      }) && <td className="align-middle">{format(new Date(created_at), 'dd/MM/yyyy') || 'Não Informado'}</td>}
      {columnUtils.shouldRenderColumn({
        columnKey: 'status',
        stateColumns: columns,
      }) && (
        <td className="align-middle">
          {loadingUpdate ? (
            <CircularLoading />
          ) : (
            <Status status={updatedStatus} completedAt={completed_at} lostAt={lost_at} />
          )}
        </td>
      )}
      <td className="align-middle">
        <Actions style={{textAlign: 'center'}}>
          <Button onClick={handlePopoverClick} ref={popoverRef} className="button-icon">
            <MoreHorizontal style={{color: '#C9C9C9'}} />
          </Button>
          <Overlay
            show={showPopover}
            target={popoverTarget}
            container={popoverRef.current}
            placement="bottom"
            rootClose={true}
            onHide={() => setShowPopover(false)}>
            <StyledPopover>
              <Popover.Content>
                <ListGroup variant="flush">
                  <ListGroup.Item onClick={() => goToProposalDetails(id)}>
                    <Menu className="icon-menu" /> Ver detalhes
                  </ListGroup.Item>

                  {has_permission_to_edit && (
                    <ListGroup.Item onClick={() => handleOpportunityStatus()}>
                      {updatedStatus === 'open' ? (
                        <CheckCircle className="icon-menu" />
                      ) : (
                        <Circle className="icon-menu" />
                      )}
                      {updatedStatus === 'open' ? 'Concluir oportunidade' : 'Re-abrir oportunidade'}
                    </ListGroup.Item>
                  )}

                  {has_permission_to_edit && (
                    <ListGroup.Item onClick={() => handleOpportunityStatus('lost')} style={{color: 'red'}}>
                      <XCircle className="icon-menu lost" style={{color: 'red'}} />
                      {'Marcar como perdida'}
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item onClick={() => handleFavoriteOpportunity()}>
                    <Star className="icon-menu" />
                    {isFavorite ? 'Remover dos favoritos' : 'Salvar em favoritos'}
                  </ListGroup.Item>

                  {has_permission_to_edit && (
                    <ListGroup.Item onClick={() => history.push(`/proposals/${id}/edit`)}>
                      <Edit className="icon-menu" />
                      Editar
                    </ListGroup.Item>
                  )}

                  {has_permission_to_edit && (
                    <ListGroup.Item onClick={() => handleOpportunityStatus('delete')}>
                      <Trash className="icon-menu" /> Excluir
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Popover.Content>
            </StyledPopover>
          </Overlay>
        </Actions>
      </td>
      <td className="align-middle">
        {loadingFavorite ? (
          <CircularLoading />
        ) : (
          <FavoriteIcon
            aria-hidden="true"
            className="fa fa-star"
            isFavorite={isFavorite}
            onClick={() => handleFavoriteOpportunity()}
          />
        )}
      </td>
    </tr>
  )
}

export default Row
