import httpClient from '../../client'

import apiRoutes from '../constants'

const getProposals = async ({limit, page, format, ...rest}) => {
  try {
    const url = format ? `${apiRoutes.GET_PROPOSALS}.${format}` : apiRoutes.GET_PROPOSALS
    const {data} = await httpClient.get(url, {params: {limit, page, ...rest}})

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling proposals with ${JSON.stringify({limit, page})}`, error)

    return error
  }
}

export default getProposals
