import httpClient from '../../client'
import apiRoutes from '../constants'

/**
 * @param {number} stateId
 * @return {Promise<*>}
 */
const getShowcase = async (stateId) => {
  try {
    const url = `${apiRoutes.GET_STATES}/${stateId}/showcase`
    const {data} = await httpClient.get(url)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling state showcase with ${JSON.stringify(stateId)}`, error)
    return error
  }
}

export default getShowcase
