import configs from 'configs'
import objectPath from 'object-path'
import React, {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

import {toAbsoluteUrl} from '../../../_helpers'
import {useHtmlClassService} from '../../_core/MetronicLayout'
import {HeaderMenuWrapper} from './header-menu/HeaderMenuWrapper'
import {Topbar} from './Topbar'

export function Header() {
  const uiService = useHtmlClassService()
  const {user} = useSelector((state) => state.auth, shallowEqual)

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display'),
    }
  }, [uiService])

  return (
    <>
      {/*begin::Header*/}
      <div className={`header ${layoutProps.headerClasses}`} id="kt_header" {...layoutProps.headerAttributes}>
        {/*begin::Container*/}
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
          {/* begin::Left */}
          <div className="d-flex align-items-stretch mr-3">
            {/* begin::Header Logo */}
            <div className="header-logo">
              <Link to="/">
                <img className=" max-h-40px" alt="Logo" src={configs.assets.logo.main} />
              </Link>
            </div>
            {/* end::Header Logo */}
            {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          </div>
          {/* end::Left */}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  )
}
