import ProposalPagination from 'app/components/proposal/Pagination'
import {changeLimit, changePage} from 'app/modules/States/redux'
import React from 'react'

import CardSkeleton from './CardSkeleton'
import StateCard from './StateCard'

const StateCards = ({limit, page, total, states, loading}) => {
  const renderState = (state) => <StateCard state={state} key={state.id} />
  return (
    <div className="container-citymatch-table-cards">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row flex-wrap" style={{justifyContent: 'space-between'}}>
          {loading ? Array.from({length: 9}).map((_, index) => <CardSkeleton key={index} />) : states?.map(renderState)}
        </div>
        <ProposalPagination
          actualLenght={states.length}
          limit={limit}
          loading={loading}
          page={page}
          total={total}
          changePage={changePage}
          changeLimit={changeLimit}
        />
      </div>
    </div>
  )
}

export default StateCards
