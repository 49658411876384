import {Box, FormControl, FormLabel} from '@material-ui/core'
import {AutoCompleteBenefits, AutoCompleteCity, AutoCompleteState, AutoCompleteVocations} from 'app/components'
import {changeSearchParams, changeSelectedFilters, cleanSearchParams, setAppliedFilters} from 'app/modules/Cities/redux'
import * as selectors from 'app/modules/Cities/redux/selectors'
import {changeActualZoom} from 'app/modules/Proposals/redux'
import React, {useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import {CleanFilterButton, Container, FormTitle, Switch} from './styles'

const CitySearchPopover = ({onClose, style, ...rest}, ref) => {
  const dispatch = useDispatch()

  const listOfFilters = {
    city_state: 'Estados',
    vocations: 'Vocação Regional',
    incentives: 'Incentivos',
    liberdade_economica: 'Liberdade econômica',
    name: 'Nome',
    code: 'Código do município',
    populacao_estimada: 'População estimada',
    ideb: 'IDEB',
    idhm: 'IDHM',
    salario_medio_mensal: 'Salário Médio Mensal',
    percentual_rendimento_nominal: 'Rendimento nominal',
    total_despesas: 'Total de despesas (R$)',
    total_receitas: 'Total de receitas (R$)',
    percentual_receita_externa: 'Percentual de receita externa',
    pib_per_capta: 'PIB per capita',
    pessoal_ocupado: 'Pessoal Ocupado',
    populacao_ocupada: 'População Ocupada',
    arborizacao: 'Arborização',
    urbanizacao: 'Urbanização',
    densidade_demografica: 'Densidade Demográfica',
    taxa_escolarizacao: 'Taxa de Escolarização',
    anos_iniciais_ensino_fundamental: 'Anos iniciais (Ensino Fundamental)',
    matriculas_ensino_fundamental: 'Matrículas (Ensino Fundamental)',
    matriculas_ensinoMedio: 'Matrículas (Ensino Médio)',
    docentes_ensino_fundamental: 'Docentes (Ensino Fundamental)',
    docentes_ensino_medio: 'Docentes (Ensino Médio)',
    escolas_ensino_fundamental: 'Escolas (Ensino Fundamental)',
    escolas_ensino_medio: 'Escolas (Ensino Médio)',
    mortalidade_infantil: 'Mortalidade Infantil',
    internacao_diarreia: 'Internação por diarreia',
    estabelecimentos_sus: 'Estabelecimentos SUS',
    unidade_territorial: 'Unidade Territorial',
    esgotamento_sanitario: 'Esgotamento Sanitário Adequado',
    bioma: 'Bioma',
  }

  const complexFilters = ['city_state', 'vocations', 'incentives', 'liberdade_economica']

  const filterTypes = {
    bioma: 'text',
    name: 'text',
    code: 'text',
    city: 'array',
    state: 'array',
    incentives: 'array',
    vocations: 'array',
  }

  const {searchParams, selectedFilters} = useSelector(selectors.getSearchParams)

  const [filtersChanged, setFiltersChanged] = useState(
    Object.keys(selectedFilters).length
      ? selectedFilters
      : {
          state: [],
          city: [],
          vocations: [],
          incentives: [],
        },
  )

  let filtersSelectedDefault = {}

  Object.keys(searchParams).map((item) => {
    item = item.replace('_start', '').replace('_end', '')
    return (filtersSelectedDefault[item] = true)
  })

  const [showFilter, setShowFilter] = useState(Object.keys(filtersSelectedDefault).length ? filtersSelectedDefault : {})
  const handleChangeFilter = (ev) => {
    setFiltersChanged({...filtersChanged, [ev.target.name]: ev.target.value})
  }

  const removeChangeFilter = (filter) => {
    const removeKey = (key, {[key]: _, ...rest}) => rest

    setFiltersChanged((prev) => removeKey(`${filter}`, prev))
    setFiltersChanged((prev) => removeKey(`${filter}_start`, prev))
    setFiltersChanged((prev) => removeKey(`${filter}_end`, prev))

    setShowFilter((prev) => removeKey(`${filter}`, prev))
  }

  const columns = [...Array(4).keys()].map((c) => Object.keys(listOfFilters).filter((_, i) => i % 4 === c))

  const applySearchFilter = () => {
    let newFilters = {}

    Object.keys(filtersChanged).map((originalItem) => {
      let item = originalItem.replace('_start', '').replace('_end', '')
      newFilters[item] = null
    })

    Object.keys(newFilters).map((filter) => {
      var val = filtersChanged[`${filter}`] || null
      var start = filtersChanged[`${filter}_start`] || null
      var end = filtersChanged[`${filter}_end`] || null

      if (val) {
        newFilters[filter] = val
      } else if (start && !end) {
        newFilters[filter] = start
      } else {
        newFilters[filter] = start + ',' + end
      }

      return newFilters
    })

    console.log(newFilters)

    dispatch(changeSearchParams(newFilters))
    dispatch(changeSelectedFilters(filtersChanged))
    dispatch(setAppliedFilters(true))
    onClose()
  }

  const onClearFilters = () => {
    dispatch(cleanSearchParams())
    dispatch(changeActualZoom(3))

    setFiltersChanged({
      state: [],
      city: [],
      vocations: [],
      incentives: [],
    })

    for (var item in showFilter) {
      showFilter[item] = false
    }

    setShowFilter(showFilter)
  }

  const onRemove = (field, value) => {
    var array = [...filtersChanged[field]]
    setFiltersChanged({...filtersChanged, [field]: array.filter((item) => item.id !== value)})
  }

  const onSelect = (field, value) => {
    var array = [...filtersChanged[field]]
    var index = array.indexOf(value)
    if (index === -1) {
      array.push(value)
      setFiltersChanged({...filtersChanged, [field]: array})
    }
  }

  const onCheck = (field, value) => {
    const removeKey = (key, {[key]: _, ...rest}) => rest

    if (!value) {
      removeChangeFilter(field)
    } else {
      setFiltersChanged({...filtersChanged, [field]: value})
    }
  }

  const onRemoveFromId = (field, value) => {
    var array = [...filtersChanged[field]]
    setFiltersChanged({...filtersChanged, [field]: array.filter((item) => item !== value)})
  }

  return (
    <Container {...rest} style={style}>
      <FormTitle>Filtrar por:</FormTitle>
      <div className="dropdown-divider" />
      <>
        <Form style={{display: 'flex'}} className="popover-filters">
          {columns.map((res, kk) => (
            <Box
              key={kk}
              style={{borderRight: kk === 3 ? '1px solid #fff' : '1px solid #EBEBEB', padding: 20, width: '100%'}}>
              {res
                .filter((filter) => complexFilters.indexOf(filter) !== -1)
                .map((filter) =>
                  filter === 'city_state' ? (
                    <>
                      <Box display="flex" flex={1} flexDirection="column" width="100%">
                        <AutoCompleteState
                          onRemoveState={(val) => onRemove('state', val)}
                          onSelectState={(val) => onSelect('state', val)}
                          selectedStates={filtersChanged['state'] || []}
                        />
                      </Box>

                      <Box display="flex" flex={1} flexDirection="column" width="100%" marginBottom="20px">
                        <AutoCompleteCity
                          onRemoveCity={(val) => onRemove('city', val)}
                          onSelectCity={(val) => onSelect('city', val)}
                          selectedCities={filtersChanged['city'] || []}
                          selectedStates={filtersChanged['state'] || []}
                        />
                      </Box>
                    </>
                  ) : filter === 'vocations' ? (
                    <Box display="flex" flex={1} flexDirection="column" width="100%" marginBottom="20px">
                      <AutoCompleteVocations
                        onRemoveItem={(val) => onRemoveFromId('vocations', val)}
                        onSelectItem={(val) => onSelect('vocations', val)}
                        selectedItems={filtersChanged['vocations'] || []}
                      />
                    </Box>
                  ) : filter === 'incentives' ? (
                    <Box display="flex" flex={1} flexDirection="column" width="100%" marginBottom="20px">
                      <AutoCompleteBenefits
                        onRemoveItem={(val) => onRemoveFromId('incentives', val)}
                        onSelectItem={(val) => onSelect('incentives', val)}
                        selectedItems={filtersChanged['incentives'] || []}
                      />
                    </Box>
                  ) : filter === 'liberdade_economica' ? (
                    <Box display="flex" flex={1} flexDirection="column" width="100%" marginBottom="20px">
                      <Switch
                        type="switch"
                        label="Aprovou Lei de Liberdade Econômica?"
                        id="liberdade_economica"
                        name="liberdade_economica"
                        value={showFilter[filter]}
                        checked={filtersChanged[`${filter}`]}
                        onChange={(val) => {
                          removeChangeFilter(filter)
                          onCheck('liberdade_economica', val.target.checked)
                        }}
                      />
                    </Box>
                  ) : null,
                )}

              {res
                .filter((filter) => complexFilters.indexOf(filter) === -1)
                .map((filter, yy) => (
                  <Box key={yy} display="flex" flexDirection="column" flex={1} marginBottom="20px">
                    <Box display="flex" flex={1} flexDirection="column" width="100%">
                      <FormControl component="fieldset" className="form-fields">
                        <FormLabel component="legend" style={{marginBottom: '0.5rem'}}>
                          <Switch
                            type="switch"
                            label={listOfFilters[filter]}
                            id={filter}
                            name={filter}
                            value={showFilter[filter]}
                            checked={showFilter[filter]}
                            onChange={(ev) => {
                              removeChangeFilter(filter)
                              setShowFilter({...showFilter, [ev.target.name]: ev.target.checked})
                            }}
                          />
                        </FormLabel>

                        {showFilter[filter] ? (
                          <Box display="flex" flex={1} flexDirection="row" width="100%">
                            {filterTypes[filter] && filterTypes[filter] === 'text' ? (
                              <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                                <Form.Control
                                  type="text"
                                  onChange={handleChangeFilter}
                                  name={`${filter}`}
                                  value={filtersChanged[`${filter}`]}
                                  placeholder={`${listOfFilters[filter]}`}
                                  min={0}
                                />
                              </Box>
                            ) : (
                              <>
                                <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                                  <Form.Control
                                    type="number"
                                    onChange={handleChangeFilter}
                                    name={`${filter}_start`}
                                    value={filtersChanged[`${filter}_start`]}
                                    placeholder={`${listOfFilters[filter]} inicial`}
                                    min={0}
                                  />
                                </Box>
                                <Box display="flex" flexDirection="column" flex={1} width="100%">
                                  <Form.Control
                                    disabled={
                                      filtersChanged[`${filter}_start`] <= 0 || !filtersChanged[`${filter}_start`]
                                    }
                                    type="number"
                                    onChange={handleChangeFilter}
                                    name={`${filter}_end`}
                                    value={filtersChanged[`${filter}_end`]}
                                    placeholder={`${listOfFilters[filter]} final`}
                                    min={filtersChanged[`${filter}_start`] || 0}
                                  />
                                </Box>
                              </>
                            )}
                          </Box>
                        ) : null}
                      </FormControl>
                    </Box>
                  </Box>
                ))}
            </Box>
          ))}
        </Form>

        <Box display="flex" flexDirection="column">
          <Form.Row style={{marginBottom: '10px'}}>
            <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end">
              <Box marginRight="20px" marginTop="-50px">
                <Button onClick={applySearchFilter} variant="primary">
                  Aplicar Filtros
                </Button>
              </Box>
              <Box marginRight="70px" marginTop="-50px">
                <Button onClick={onClose} variant="secondary">
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Form.Row>
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            justifyContent="flex-end"
            marginRight="100px"
            marginTop="-20px">
            <CleanFilterButton onClick={onClearFilters}>Limpar todos os filtros</CleanFilterButton>
          </Box>
        </Box>
      </>
    </Container>
  )
}

export default CitySearchPopover
