import httpClient from '../../client'

const getPoliticalParties = async ({limit, page, ...rest}) => {
  try {
    const {data} = await httpClient.get('/political-parties', {
      params: {limit, page, ...rest},
    })

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling political parties with ${JSON.stringify({limit, page})}`, error)
    return error
  }
}

export default getPoliticalParties
