import React, {useEffect, useState, useCallback} from 'react'
import {useSelector} from 'react-redux'
import {useSnackbar} from 'notistack'
import {politiciansService} from 'app/services'
import PoliticianList from 'app/components/politicians/Table/index'
import {Box, Grid} from '@material-ui/core'

import * as politiciansSelectors from 'app/modules/Politicians/redux/selectors'

import {ContainerHeader} from 'app/modules/Cities/Pages/DetailCitie/styles'
import {Paper} from '@material-ui/core'
import {Button, Form} from 'react-bootstrap'
import StateCitySelect from 'app/components/form/StateCitySelect'
import getPoliticalOffices from 'app/services/politicians/getPoliticalOffices'
import getPoliticalParties from 'app/services/politicians/getPoliticalParties'

const ListPoliticiansPage = ({defaultFilters, defaultSort, hideSearch, isDetailPage}) => {
  const {enqueueSnackbar} = useSnackbar()

  const [politicians, setPoliticians] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState({})
  const [filters, setFilters] = useState(defaultFilters || {})
  const [sorts, setDefaultSorts] = useState(defaultSort || {})
  const [politicalOffices, setPoliticalOffices] = useState([])
  const [parties, setParties] = useState([])

  const currentPage = useSelector(politiciansSelectors.getCurrentPoliticiansPage)

  const limit = useSelector(politiciansSelectors.getCurrentLimit)

  const fetchPoliticians = useCallback(
    async (searches) => {
      try {
        setLoading(true)
        const {data, total} = await politiciansService.getPoliticians({
          limit: limit.current,
          page: currentPage,
          ...searches,
          ...filters,
          ...sorts,
        })

        if (!data.length) {
          enqueueSnackbar('Nenhum político encontrada', {
            variant: 'warning',
          })
          setLoading(false)
          return
        }

        setTotal(total)
        setPoliticians(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setPoliticians([])

        enqueueSnackbar('Ocorreu um erro ao consultar os políticos', {
          variant: 'error',
        })
      }
    },
    [enqueueSnackbar, limit, currentPage],
  )

  useEffect(() => {
    fetchPoliticians(search)
  }, [fetchPoliticians])

  useEffect(() => {
    getPoliticalOffices({
      limit: limit.current,
      page: currentPage,
    }).then((res) => setPoliticalOffices(res.data))

    getPoliticalParties({
      limit: limit.current,
      page: currentPage,
    }).then((res) => setParties(res.data))
  }, [currentPage, limit])

  return (
    <>
      {hideSearch ? null : (
        <div className="d-flex flex-column container-citymatch-table-cards">
          <Paper className="city-politicians" style={{borderRadius: 10, marginBottom: 30}}>
            <ContainerHeader>Busca por gestores públicos</ContainerHeader>
            <Grid container>
              <Grid item lg={3}></Grid>
              <Grid item lg={6}>
                <Box style={{marginTop: 40, marginBottom: 40}}>
                  <Box style={{marginBottom: 30}}>
                    <Form.Control
                      autoComplete="off"
                      placeholder="Buscar gestor público pelo nome"
                      name="name"
                      onChange={(ev) => setSearch({...search, name: ev.target.value})}
                      value={search.name}
                    />
                  </Box>

                  <Box display="flex" style={{marginBottom: 30}}>
                    <Box display="flex" flexDirection="column" marginRight="22px" width="100%">
                      <select
                        className="form-control"
                        name="political_office"
                        placeholder="Cargo do gestor"
                        value={search.political_office}
                        onChange={(ev) => setSearch({...search, political_office: ev.target.value})}
                        disabled={!politicalOffices.length}>
                        <option>Cargo do gestor</option>

                        {politicalOffices.map((po) => (
                          <option value={po.id}>{po.description}</option>
                        ))}
                      </select>
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%">
                      <select
                        className="form-control"
                        name="party"
                        placeholder="Partido do gestor"
                        value={search.party}
                        onChange={(ev) => setSearch({...search, party: ev.target.value})}
                        disabled={!parties.length}>
                        <option>Partido do gestor</option>
                        {parties.map((pa) => (
                          <option value={pa.id}>{pa.abbreviation}</option>
                        ))}
                      </select>
                    </Box>
                  </Box>

                  <Box style={{marginBottom: 30}}>
                    <StateCitySelect
                      width="100%"
                      placeholder={{
                        state: 'UF',
                        city: 'Cidade',
                      }}
                      cityProps={search.city}
                      stateProps={search.state}
                      changeState={(state) => setSearch({...search, state: state.id})}
                      changeCity={(city) => setSearch({...search, city: city.id})}></StateCitySelect>
                  </Box>

                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Button
                      variant="outline-primary"
                      disabled={!Object.keys(search).length}
                      onClick={() => {
                        setSearch({
                          name: '',
                          political_office: '',
                          city: '',
                          state: '',
                          party: '',
                        })
                        fetchPoliticians({})
                      }}>
                      LIMPAR
                    </Button>
                    <Button
                      onClick={() => {
                        fetchPoliticians(search)
                      }}
                      disabled={!Object.keys(search).length}>
                      INICIAR BUSCA
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3}></Grid>
            </Grid>
          </Paper>
        </div>
      )}

      <div className="d-flex flex-column">
        <PoliticianList page={currentPage} politicians={politicians} total={total} loading={loading} limit={limit} />
      </div>
    </>
  )
}

export default ListPoliticiansPage
