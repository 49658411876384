import React, {useCallback, useRef, useState} from 'react'

import {format} from 'date-fns'

import {ProposalLogo} from 'app/components'

import {FavoriteIcon, ProposalColumn, ProposalName, LogoContainer} from './styles'

import {Popover, Overlay, ListGroup} from 'react-bootstrap'

import * as columnUtils from '../utils'

import {useFavorite} from 'app/hooks'
import moment from 'moment'
import {useHistory} from 'react-router'
import {StyledPopover} from 'app/components/proposal/Table/Header/styles'
import {Edit, Menu, Star} from 'react-feather'

const Row = (props) => {
  const {
    open_at,
    id,
    business_name: fanstasy_name,
    name: social_reason,
    is_favorite,
    main_activity: cnae_primary,
    city,
    state,
    share_capital,
    url = '/media/images/logo-city.png',
    columns,
    has_permission_to_edit,
  } = props

  const {action: handleFavoriteCompany, loading: loadingFavorite, isFavorite} = useFavorite({
    id,
    is_favorite,
    entity: 'company',
    label: 'Empresa',
  })

  const formatCurrency = (amount) => new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(amount)

  const popoverRef = useRef(null)
  const history = useHistory()

  const [showPopover, setShowPopover] = useState(false)
  const [popoverTarget, setPopoverTarget] = useState(null)

  const goToCitieDetails = useCallback(
    (id) => {
      history.push(`/companies/${id}`)
    },
    [history],
  )

  const handlePopoverClick = useCallback((event) => {
    setShowPopover((prevState) => !prevState)
    setPopoverTarget(event.target)
  }, [])

  return (
    <tr>
      {/* {columnUtils.shouldRenderColumn({ columnKey: "id", stateColumns: columns }) && (
        <th className="align-middle" scope="row">
          {id.substr(0, 8)}
        </th>        
      )}       */}
      {columnUtils.shouldRenderColumn({columnKey: 'social_reason', stateColumns: columns}) && (
        <td className="align-middle text-uppercase id">
          <ProposalColumn>
            <LogoContainer>
              <ProposalLogo name={social_reason} url={url} />
            </LogoContainer>
            <ProposalName onClick={() => goToCitieDetails(id)}>{social_reason}</ProposalName>
          </ProposalColumn>
        </td>
      )}
      {columnUtils.shouldRenderColumn({columnKey: 'fanstasy_name', stateColumns: columns}) && (
        <td className="align-middle" style={{fontFamily: 'Nunito Sans', fontWeight: 'bold', fontSize: 14}}>
          {fanstasy_name}
        </td>
      )}
      {columnUtils.shouldRenderColumn({columnKey: 'cnae_primary', stateColumns: columns}) && (
        <td className="align-middle">{cnae_primary.code}</td>
      )}
      {columnUtils.shouldRenderColumn({columnKey: 'foundation', stateColumns: columns}) && (
        <td className="align-middle">{moment(open_at).format('DD/MM/YYYY')}</td>
      )}
      {columnUtils.shouldRenderColumn({columnKey: 'social_capital', stateColumns: columns}) && (
        <td className="align-middle">{formatCurrency(share_capital)}</td>
      )}
      {columnUtils.shouldRenderColumn({columnKey: 'city', stateColumns: columns}) && (
        <td className="align-middle">{city}</td>
      )}
      {columnUtils.shouldRenderColumn({columnKey: 'uf', stateColumns: columns}) && (
        <td className="align-middle">{state}</td>
      )}

      <td className="align-middle">
        <i className="fa fa-ellipsis-h" aria-hidden="true" onClick={handlePopoverClick} style={{cursor: 'pointer'}} />
        <Overlay
          show={showPopover}
          ref={popoverRef}
          target={popoverTarget}
          container={popoverRef.current}
          placement="bottom"
          rootClose={true}
          onHide={() => setShowPopover(false)}>
          <StyledPopover>
            <Popover.Content>
              <ListGroup variant="flush">
                <ListGroup.Item onClick={() => goToCitieDetails(id)}>
                  <Menu className="icon-menu" /> Ver detalhes
                </ListGroup.Item>

                <ListGroup.Item onClick={() => handleFavoriteCompany()}>
                  <Star className="icon-menu" />
                  {isFavorite ? 'Remover dos favoritos' : 'Salvar em favoritos'}
                </ListGroup.Item>

                {
                  <ListGroup.Item onClick={() => history.push(`/companies/${id}/edit`)}>
                    <Edit className="icon-menu" />
                    Editar
                  </ListGroup.Item>
                }
              </ListGroup>
            </Popover.Content>
          </StyledPopover>
        </Overlay>
      </td>

      <td className="align-middle">
        {loadingFavorite ? (
          <div className="spinner-border text-primary spinner-border-sm" role="status">
            <span className="sr-only">Carregando...</span>
          </div>
        ) : (
          <FavoriteIcon
            aria-hidden="true"
            className="fa fa-star"
            isFavorite={isFavorite}
            onClick={() => handleFavoriteCompany()}
          />
        )}
      </td>
    </tr>
  )
}

export default Row
