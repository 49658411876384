import {Grid} from '@material-ui/core'
import FilePreview from 'app/components/files/FilePreview'
import {useSnackbar} from 'notistack'
import React, {useMemo, useState} from 'react'

const UploadGaleriaPreview = ({setLoading, onDeleteImage, imagesCitie}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [deleteQueue, setDeleteQueue] = useState({})

  const addDeleting = (imageId) => {
    setDeleteQueue((prevState) => ({
      ...prevState,
      [imageId]: true,
    }))
  }

  const removeDeleting = (imageId) => {
    setDeleteQueue((prevState) => {
      const newState = {...prevState}
      delete newState[imageId]

      return newState
    })
  }

  const handleDelete = async (image) => {
    if (onDeleteImage) {
      const imageId = image.id
      addDeleting(imageId)

      try {
        await onDeleteImage(image)
      } catch (ex) {
        enqueueSnackbar(ex.message, {
          variant: 'error',
        })
      }

      removeDeleting(imageId)
    }
  }

  const images = useMemo(
    () =>
      imagesCitie.map((img) => {
        const prunePath = {...img}
        if (!prunePath.url || prunePath.url.indexOf('http') !== 0) {
          prunePath.url = img.path
        }
        return prunePath
      }),
    [imagesCitie],
  )

  return (
    <Grid container direction="row" justify="center" wrap="wrap" spacing={2} className="gallery-preview">
      {images.map((image) => {
        const isDeleting = !!deleteQueue[image.id]

        return (
          <Grid key={image.id} item>
            <FilePreview isDeleting={isDeleting} onDelete={handleDelete} file={image} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default UploadGaleriaPreview
