import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #383838;
  padding-bottom: 10px;
  font: normal normal 14px/19px Nunito;
  letter-spacing: 0px;
`

export const StyledSpan = styled.span`
  font: normal normal bold 14px/19px Nunito;
`
