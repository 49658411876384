import {Grid} from '@material-ui/core'
import React, {useCallback} from 'react'

import {Paper, ContainerHeader, DetailContainer} from '../styles'

const CountyInfo = ({demographic_data}) => {
  const formatCurrency = (amount, multiply) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      notation: 'compact',
    }).format(multiply ? multiply * amount : amount)

  const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

  const findDemographicInfo = useCallback(
    (key, defaultParam = 'Não Informado', label = '') => {
      const info = demographic_data?.find(({indicator}) => indicator === key)?.value
      return info ? `${info}${label}` : `${defaultParam}${label}`
    },
    [demographic_data],
  )

  return (
    <Paper>
      <ContainerHeader>Informações Sobre o Município</ContainerHeader>
      <DetailContainer>
        <Grid container>
          <Grid item lg={6} style={{display: 'flex', justifyContent: 'flex-start'}}>
            <table className="table demodata" style={{marginRight: '40px'}}>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>População estimada</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('populacao_estimada', 0))} habitantes
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>IDEB</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('taxa_escolarizacao', 0))} </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>IDHM</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('idhm', 0))} </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Salário Médio Mensal</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('salario_medio_mensal', 0))} salários mínimos
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Rendimento nominal</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('percentual_rendimento_nominal', 0))}%{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Total de despesas </b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatCurrency(findDemographicInfo('total_despesas', 0), 1000)} </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Total de receitas </b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatCurrency(findDemographicInfo('total_receitas', 0), 1000)} </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Percentual de receita externa</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('percentual_receita_externa', 0))}%{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>PIB per capita</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatCurrency(findDemographicInfo('pib_per_capta', 0))} </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Pessoal Ocupado</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('pessoal_ocupado', 0))} pessoas</td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>População Ocupada</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('populacao_ocupada', 0))}% </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Arborização</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('arborizacao', 0))}% </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Urbanização</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('urbanizacao', 0))}% </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Densidade Demográfica</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('densidade_demografica', 0))} hab/km²
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item lg={6} style={{display: 'flex', justifyContent: 'flex-start'}}>
            <table className="table demodata" style={{marginLeft: '40px'}}>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Taxa de Escolarização</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('taxa_escolarizacao', 0))}% </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Anos iniciais (Ensino Fundamental)</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('anos_iniciais_ensino_fundamental', 0))} anos
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Matrículas (Ensino Fundamental)</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('matriculas_ensino_fundamental', 0))} matrículas{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Matrículas (Ensino Médio)</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('matriculas_ensinoMedio', 0))} matrículas{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Docentes (Ensino Fundamental)</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('docentes_ensino_fundamental', 0))} docentes
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Docentes (Ensino Médio)</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('docentes_ensino_medio', 0))} docentes{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Escolas (Ensino Fundamental)</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('escolas_ensino_fundamental', 0))} escolas{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Escolas (Ensino Médio)</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('escolas_ensino_medio', 0))} escolas
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Mortalidade Infantil</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('mortalidade_infantil', 0))} óbitos por mil nascidos vivos{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Internação por diarreia</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('internacao_diarreia', 0))} internações por mil habitantes
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Estabelecimentos SUS</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>
                  {formatNumber(findDemographicInfo('estabelecimentos_sus', 0))} estabelecimentos{' '}
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Unidade Territorial</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('unidade_territorial', 0))} km² </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Esgotamento Sanitário Adequado</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{formatNumber(findDemographicInfo('esgotamento_sanitario', 0))}% </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <b style={{fontSize: 14}}>Bioma</b>
                  </label>
                </td>{' '}
                <td style={{textAlign: 'right'}}>{findDemographicInfo('bioma', 0)}</td>
              </tr>
            </table>
          </Grid>
        </Grid>
      </DetailContainer>
    </Paper>
  )
}

export default CountyInfo
