import React from 'react'
import {Paper, ContainerHeader, DetailContainer} from '../styles'

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

const ProposalAttachments = ({multimedia}) => {
  const grouped = groupBy(multimedia, 'type')
  return (
    <Paper>
      <ContainerHeader>Arquivos Sobre a Oportunidade</ContainerHeader>
      <DetailContainer>
        {grouped['image'] ? (
          <div>
            <span className="secundary-title">Fotos</span>
            <br />
            <br />
            <ul className="image-files">
              {grouped['image'].map((file) => (
                <a href={file.path} target="_blank">
                  <li style={{backgroundImage: `url(${file.path})`, backgroundSize: 'cover'}}></li>
                </a>
              ))}
            </ul>
          </div>
        ) : (
          <big>Oportunidade sem arquivos cadastrados</big>
        )}
      </DetailContainer>
    </Paper>
  )
}

export default ProposalAttachments
