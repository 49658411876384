import httpClient from '../../client'

import apiRoutes from '../constants'

const exportLogs = async (id) => {
  try {
    const {data} = await httpClient({
      url: `${apiRoutes.GET_LOG_USERS}/${id}/audits-pdf`,
      method: 'GET',
      responseType: 'blob',
    })

    if (data) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.pdf')
      document.body.appendChild(link)
      link.click()

      return data
    }

    throw data
  } catch (error) {
    return error
  }
}

export default exportLogs
