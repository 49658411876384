import {Tooltip} from '@material-ui/core'
import {CircularProgress, TextField} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {statesService} from 'app/services'
import React, {useCallback, useEffect, useState} from 'react'

import {Column, Label, SelectedListContainer, SelectedListItem} from './styles'

const AutoCompleteState = ({onSelectState, onRemoveState, selectedStates}) => {
  const [loading, setLoading] = useState(true)
  const [states, setStates] = useState([])
  const [inputText, setInputText] = useState('')
  const [open, setOpen] = useState(false)

  const getOptionLabel = ({name, uf}) => `${name} - ${uf}`

  const onChange = (_, value) => {
    const isAdded = selectedStates.find(({code}) => value?.code === code)

    if (!isAdded && value) {
      onSelectState(value)
      setInputText('')
    }
  }

  const onInputChange = (event) => {
    if (event) {
      setInputText(event.target?.value ? event.target?.value : '')
    }
  }

  const searchStates = useCallback(async () => {
    try {
      const {data} = await statesService.getStates({limit: 30})

      setStates(data)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    searchStates()
  }, [searchStates])

  return (
    <Column>
      <Label>Estado (s)</Label>
      <Autocomplete
        size="small"
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        getOptionLabel={getOptionLabel}
        options={states}
        inputValue={inputText}
        onChange={onChange}
        onInputChange={onInputChange}
        noOptionsText="Nenhum item encontrado"
        loading={loading}
        getOptionDisabled={(selected) => selectedStates?.some((state) => state.code === selected.code)}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              placeholder: 'Ex: Minas Gerais',
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <SelectedListContainer>
        {selectedStates.map(({id, uf}) => (
          <SelectedListItem key={id}>
            {uf}
            <i
              className="far fa-times-circle"
              onClick={() => onRemoveState(id)}
              style={{
                background: '#EAEAEA',
                overflow: 'hidden',
                borderRadius: '50%',
                border: 'none',
              }}
            />
          </SelectedListItem>
        ))}
      </SelectedListContainer>
    </Column>
  )
}

export default AutoCompleteState
