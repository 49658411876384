import React from 'react'
import {Paper, ContainerHeader, DetailContainer} from '../styles'
import {proposalService} from 'app/services'

import Map from 'app/components/map/Map'

const Location = ({coordinates, name, state}) => {
  return (
    <Paper id="location">
      <ContainerHeader>Localização da empresa</ContainerHeader>
      <DetailContainer style={{padding: 0}}>
        {coordinates && coordinates.length && (
          <Map
            customStyle={{
              borderRadius: 0,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              width: '100%',
              margin: 0,
            }}
            service={proposalService}
            coordinates={{
              type: 'FeatureCollection',
              features: [
                {
                  geometry: {
                    coordinates: [parseFloat(coordinates[1]), parseFloat(coordinates[0])],
                    type: 'Point',
                  },
                  type: 'Feature',
                },
              ],
            }}
            zoomIn={13}
          />
        )}
      </DetailContainer>
    </Paper>
  )
}

export default Location
