import React from 'react'

const Icon = ({color, width}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : '42'} height="42" viewBox="0 0 42 42">
    <g id="icon_endereco-contato" data-name="icon/endereco-contato" transform="translate(-4)">
      <path id="bound" d="M0,0H42V42H0Z" transform="translate(4)" fill="none" />
      <g id="shapes" transform="translate(8.29 3.5)">
        <path
          id="Rectangle_161_Copy"
          data-name="Rectangle 161 Copy"
          d="M0,0H3.5A5.25,5.25,0,0,1,8.75,5.25v24.5A5.25,5.25,0,0,1,3.5,35H0Z"
          transform="translate(24.5)"
          fill={color}
          opacity="0.3"
        />
        <path
          id="Combined_Shape"
          data-name="Combined Shape"
          d="M1.75,0h21A5.25,5.25,0,0,1,28,5.25v24.5A5.25,5.25,0,0,1,22.75,35h-21A1.75,1.75,0,0,1,0,33.25V1.75A1.75,1.75,0,0,1,1.75,0ZM14,15.75a3.5,3.5,0,1,0-3.5-3.5A3.5,3.5,0,0,0,14,15.75ZM5.251,25.374c-.021.284.463.876.707.876h16.06a.729.729,0,0,0,.73-.875c-.285-5.367-4.109-7.875-8.764-7.875C9.394,17.5,5.627,20.154,5.251,25.374Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
)

export default Icon
