import storageKeys from './constants'

const setKey = (key, value) => localStorage.setItem(key, value)

const getKey = (key) => localStorage.getItem(key)

const clear = () => localStorage.clear()

export default {
  clear,
  getKey,
  setKey,
  storageKeys,
}
