import {changeViewMode as changeCitiesViewMode, toogleMap as toogleCitieslMap} from 'app/modules/Cities/redux'
import * as citiesSelectors from 'app/modules/Cities/redux/selectors'
import {changeViewMode as changeCompaniesViewMode, toogleMap as toggleCompaniesMap} from 'app/modules/Companies/redux'
import {changeViewMode as changeProposalsViewMode, toogleMap as toogleProposalMap} from 'app/modules/Proposals/redux'
import * as proposalSelectors from 'app/modules/Proposals/redux/selectors'
import {changeViewMode as changeStatesViewMode, toogleMap as toogleStateslMap} from 'app/modules/States/redux'
import * as statesSelectors from 'app/modules/States/redux/selectors'
import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

const DEAFULT_MODULE = 'proposals'

const useMap = (moduleName) => {
  const dispatch = useDispatch()

  const config = useMemo(() => {
    return {
      proposals: {
        actions: {
          changeViewMode: changeProposalsViewMode,
          toogleMap: toogleProposalMap,
        },
        selector: proposalSelectors.universalSearch,
      },
      cities: {
        actions: {
          changeViewMode: changeCitiesViewMode,
          toogleMap: toogleCitieslMap,
        },
        selector: citiesSelectors.universalSearch,
      },
      states: {
        actions: {
          changeViewMode: changeStatesViewMode,
          toogleMap: toogleStateslMap,
        },
        selector: statesSelectors.universalSearch,
      },
      comapanies: {
        actions: {
          changeViewMode: changeCompaniesViewMode,
          toogleMap: toggleCompaniesMap,
        },
        selector: citiesSelectors.universalSearch,
      },
    }
  }, [])

  const {actions, selector} = useMemo(() => {
    if (config[moduleName]) {
      return {
        actions: config[moduleName].actions,
        selector: config[moduleName].selector,
      }
    }
    return {
      actions: config[DEAFULT_MODULE].actions,
      selector: config[DEAFULT_MODULE].selector,
    }
  }, [moduleName, config])

  const {viewMode, showMap} = useSelector(selector)

  const changeViewMode = useCallback(
    (viewMode) => {
      dispatch(actions.changeViewMode(viewMode))
    },
    [actions, dispatch],
  )

  const toogleMap = useCallback(() => {
    dispatch(actions.toogleMap())
  }, [actions, dispatch])

  return useMemo(
    () => ({
      changeViewMode,
      showMap,
      toogleMap,
      viewMode,
    }),
    [changeViewMode, showMap, toogleMap, viewMode],
  )
}

export default useMap
