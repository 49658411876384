import Map from 'app/components/map/Map'
import StateCards from 'app/components/states/StateCards'
import StateTable from 'app/components/states/Table'
import {resetExportStatesCsv} from 'app/modules/States/redux'
import * as statesSelectors from 'app/modules/States/redux/selectors'
import {stateService} from 'app/services'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

const ListStatesPage = () => {
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const [states, setStates] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [filters, setFilters] = useState({})
  const [coordinates, setCoordinates] = useState(null)

  const currentPage = useSelector(statesSelectors.getCurrentStatesPage)
  const {showMap, viewMode} = useSelector(statesSelectors.universalSearch)
  const getExportToCsv = useSelector(statesSelectors.getExportToCsv)
  const {hasAppliedFilters, searchParams, showOnlyFavorites} = useSelector(statesSelectors.getSearchParams)
  const activeOrder = useSelector(statesSelectors.getActiveOrder)
  const limit = useSelector(statesSelectors.getCurrentLimit)

  const mountSorting = useCallback(
    (selectedOrder) => ({
      [`sort[${selectedOrder.key}]`]: selectedOrder.order.toLowerCase(),
    }),
    [],
  )

  const mountQueryParams = useCallback(
    (params) => {
      let queryParams = {
        limit: limit.current,
        show_demographic_data: 1,
      }

      if (showOnlyFavorites) {
        queryParams.only_favorites = 1
      }

      if (params.state && params.state.length) {
        queryParams.ufs = params.state.map((state) => state.id).join(',')
      }

      if (params.city && params.city.length) {
        queryParams.id = params.city.map((city) => city.id).join(',')
      }

      if (params.vocations && params.vocations.length) {
        queryParams.vocation = params.vocations.map((voc) => voc).join(',')
      }

      if (params.incentives && params.incentives.length) {
        queryParams.incentives = params.incentives.map((incentive) => incentive).join(',')
      }

      const {state, city, vocations, incentives, ...rest_params} = params

      if (hasAppliedFilters) {
        queryParams = {
          ...rest_params,
          ...queryParams,
        }

        if (activeOrder) {
          queryParams = {...queryParams, ...mountSorting(activeOrder)}
        }
      }

      return queryParams
    },
    [activeOrder, hasAppliedFilters, limit, showOnlyFavorites, mountSorting],
  )

  const fetchStates = useCallback(async () => {
    try {
      setLoading(true)

      let queryParams = {
        page: currentPage,
        ...filters,
        ...mountQueryParams(searchParams),
      }

      const {data, total} = await stateService.getStates(queryParams)

      if (!data.length) {
        enqueueSnackbar('Nenhum estado encontrado', {
          variant: 'warning',
        })
      }

      const coordinates = data.map((state) => {
        return {
          geometry: {
            coordinates: state.coordinates,
            type: 'Point',
          },
          properties: {
            id: state.id,
            code: state.code,
            name: state.name,
            type: 'city',
            state_uf: state.uf,
          },
          type: 'Feature',
        }
      })

      setTotal(total)
      setStates(data)
      setCoordinates({type: 'FeatureCollection', features: coordinates})
      setLoading(false)
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao consultar os estados', {
        variant: 'error',
      })

      setStates([])
    } finally {
      setLoading(false)
    }
  }, [currentPage, enqueueSnackbar, filters, mountQueryParams, showOnlyFavorites, searchParams])

  const fetchCitiesAndDownloadCsv = useCallback(async () => {
    if (!getExportToCsv) return

    try {
      let queryParams = {
        format: 'csv',
        page: 1,
        ...filters,
        ...mountQueryParams(searchParams),
      }

      const data = await stateService.getStates(queryParams)

      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `export-cities-${new Date().getTime()}.csv`)
        document.body.appendChild(link)
        dispatch(resetExportStatesCsv())

        link.click()
        return data
      }
    } catch (error) {
      enqueueSnackbar('Não foi possível baixar o CSV de cidades', {
        variant: 'error',
      })
    }
  }, [currentPage, enqueueSnackbar, filters, mountQueryParams, showOnlyFavorites, searchParams, getExportToCsv])

  useEffect(() => {
    fetchStates()
  }, [fetchStates])

  useEffect(() => {
    fetchCitiesAndDownloadCsv()
  }, [fetchCitiesAndDownloadCsv])

  const renderVisualizationMode = () => {
    if (viewMode === 'card') {
      return <StateCards loading={loading} page={currentPage} total={total} limit={limit} states={states} />
    }
    return <StateTable loading={loading} page={currentPage} total={total} limit={limit} states={states} />
  }

  return (
    <>
      {showMap && coordinates && (
        <Map lg coordinates={coordinates} currentPage={currentPage} changeFilter={setFilters} loading={loading} />
      )}
      <div className="d-flex flex-column">{renderVisualizationMode()}</div>
    </>
  )
}

export default ListStatesPage
