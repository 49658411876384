import styled from 'styled-components'

export const Paper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 2rem;
`

export const ContainerHeader = styled.div`
  color: black;
  font: normal normal bold 24px/32px Nunito;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  border-bottom: 1px solid #c9c9c980;
`

export const DetailContainer = styled.div`
  padding: 25px 35px;
  padding-bottom: 45px;
`

export const FavoriteIcon = styled.i`
  color: ${({isFavorite}) => (isFavorite ? '#FFA800' : '#C9C9C9')};
  cursor: pointer;
`

export const Image = styled.img`
  width: 180px !important;
  height: 180px !important;
  border: 1px solid #007bff;
`

export const CarouselImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 10px;
`

const injectJustify = ({justify}) => (justify ? justify : 'space-between')
const injectWidth = ({width}) => (width ? width : '100%')
const injectDisplay = ({display}) => (display ? display : 'flex')

export const Container = styled.div`
  display: ${injectDisplay};
  justify-content: ${injectJustify};
  align-items: center;
  width: ${injectWidth};
  margin-top: 1rem;
`

export const BottomItem = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: ${injectWidth};
  margin-top: 1rem;
  color: #383838;
  text-align: center;
`

export const BottomItemValue = styled.span`
  font-size: 20px;
  font-family: 'Nunito Sans';
  font-weight: bold;
  color: #00428b;
  text-transform: uppercase;
  text-align: left;
`

export const IcentiveContainer = styled.div`
  background: #d4e2f0 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 29px;
  padding: 5px 12px;
  font-family: 'Nunito Sans', sans-serif;
  color: #1d1d1d;
  width: fit-content;
  margin-right: 10px;
`
