import styled from 'styled-components'

export const Paper = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 2rem;
`

export const ContainerHeader = styled.div`
  color: black;
  height: 50px;
  font: normal normal bold 24px/32px Nunito Sans;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem;
  border-bottom: 1px solid #eaeaea;
`

export const DetailContainer = styled.div`
  padding: 25px 35px 51px 35px;
  & .container-citymatch-table {
    padding: 0;
  }
`

export const Image = styled.img`
  width: 92px !important;
  height: 92px !important;
`

export const IconDetail = styled.span`
  margin-right: 15px;
  color: #007bff33;
  font-size: 28px;
`

export const BottomIcons = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: left;
`

export const IConContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 50px;
`

export const FavoriteIcon = styled.i`
  color: ${({isFavorite}) => (isFavorite ? '#FFA800' : '#C9C9C9')};
  cursor: pointer;
`
