import httpClient from '../../client'

import apiRoutes from '../constants'

const getProposal = async (proposalId) => {
  try {
    const url = `${apiRoutes.GET_PROPOSAL}/${proposalId}`
    const {data} = await httpClient.get(url)
    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling proposal with ${JSON.stringify(proposalId)}`, error)

    return error
  }
}

export default getProposal
