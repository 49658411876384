import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Checkbox, FormControlLabel, FormLabel, FormControl, FormGroup, TextField } from '@material-ui/core'

import { cleanSearchParams, changeSearchParams, setAppliedFilters } from 'app/modules/Proposals/redux'
import * as selectors from 'app/modules/Proposals/redux/selectors'

import { AutoCompleteState, AutoCompleteCity, Slider, StatusSelect, DateInterval } from 'app/components'

import { CleanFilterButton, Container, FormTitle } from './styles'

const marksInvestment = [
  {
    label: 'R$ 100.000,00',
    value: 100000,
  },
  {
    label: 'R$ 10.000.000,00',
    value: 10000000,
  },
]

const SearchPopover = ({onClose, style, ...rest}, ref) => {
  const dispatch = useDispatch()
  const [investmentStart, setInvestmentStart] = useState(null)
  const [investmentEnd, setInvestmentEnd] = useState(null)

  const { searchParams } = useSelector(selectors.getSearchParams)

  const [filters, setFilters] = useState({
    investmentStart: searchParams.investmentStart || [],
    investmentEnd: searchParams.investmentEnd || [],
    investimentRange: searchParams.investimentRange || [],
    selectedCities: searchParams.selectedCities || [],
    selectedStates: searchParams.selectedStates || [],
    selectedStatus: searchParams.selectedStatus || [],
    selectedTypes: searchParams.selectedTypes || [],
    startDate: searchParams.startDate || null,
    endDate: searchParams.endDate || null,
  })

  const {selectedCities, selectedStates, selectedStatus, selectedTypes, startDate, endDate} = filters

  const handleChangeFilter = (ev) => {
    setFilters({...filters, [ev.target.name]: ev.target.checked})
  }

  const onRemove = (field, value) => {
    var array = [...filters[field]] // make a separate copy of the array
    setFilters({ ...filters, [field]: array.filter((item) => item.id !== value) })
  }

  const onSelect = (field, value) => {
    var array = [...filters[field]] // make a separate copy of the array
    var index = array.indexOf(value)
    if (index === -1) {
      array.push(value)
      setFilters({...filters, [field]: array})
    }
  }

  const onToggle = (checked, field, value) => {
    if (checked) {
      onSelect(field, value)
    } else {
      var array = [...filters[field]]
      setFilters({...filters, [field]: array.filter((item) => item !== value)})
    }
  }

  const applySearchFilter = () => {
    dispatch(changeSearchParams(filters))
    dispatch(setAppliedFilters(true))
    onClose()
  }

  const onClearFilters = () => {
    dispatch(cleanSearchParams())
    onClose()
  }

  return (
    <Container {...rest} style={style}>
      <FormTitle>Filtrar por:</FormTitle>
      <div className="dropdown-divider" />
      <>
        <Form className="popover-filters">
          <Box marginBottom="20px" marginTop="20px">
            <AutoCompleteState
              onRemoveState={(val) => onRemove('selectedStates', val)}
              onSelectState={(val) => onSelect('selectedStates', val)}
              selectedStates={selectedStates}
            />
          </Box>
          <Box marginBottom="20px">
            <AutoCompleteCity
              onRemoveCity={(val) => onRemove('selectedCities', val)}
              onSelectCity={(val) => onSelect('selectedCities', val)}
              selectedCities={selectedCities}
              selectedStates={selectedStates}
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
            <Box display="flex" flex={1} flexDirection="column" width="100%">
              <FormControl component="fieldset" className="form-fields">
                <FormLabel component="legend">Investimento</FormLabel>
                <Box display="flex" flex={1} flexDirection="row" width="100%">
                  <Box display="flex" flexDirection="column" flex={1} marginRight="15px" width="100%">
                    <Form.Control
                      type="number"
                      value={filters.investmentStart}
                      placeholder="Inicial"
                      onChange={(ev) => setFilters({...filters, investmentStart: ev.target.value})}
                      name="project_investiment_start"
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" flex={1} width="100%">
                    <Form.Control
                      disabled={filters.investmentStart <= 0 || !filters.investmentStart}
                      type="number"
                      value={filters.investmentEnd}
                      placeholder="Final"
                      onChange={(ev) => setFilters({...filters, investmentEnd: ev.target.value})}
                      name="project_investiment_end"
                    />
                  </Box>
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
            <FormControl component="fieldset" className="form-checkbox">
              <FormLabel component="legend">Tipo</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedTypes.indexOf('city') !== -1}
                      value="city"
                      onChange={(ev) => onToggle(ev.target.checked, 'selectedTypes', 'city')}
                      name="type_city"
                    />
                  }
                  label="Pública"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedTypes.indexOf('company') !== -1}
                      value="company"
                      onChange={(ev) => onToggle(ev.target.checked, 'selectedTypes', 'company')}
                      name="type_company"
                    />
                  }
                  label="Privada"
                />
              </FormGroup>
            </FormControl>
          </Box>

          <Box display="flex" flexDirection="column" flex={1} marginBottom="20px">
            <FormControl component="fieldset" className="form-checkbox">
              <FormLabel component="legend">Status</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedStatus.indexOf('open') !== -1}
                      onChange={(ev) => onToggle(ev.target.checked, 'selectedStatus', 'open')}
                      name="status_open"
                    />
                  }
                  label="Ativa"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedStatus.indexOf('lost') !== -1}
                      onChange={(ev) => onToggle(ev.target.checked, 'selectedStatus', 'lost')}
                      name="status_lost"
                    />
                  }
                  label="Perdida"
                />
                <FormControlLabel
                  style={{ width: '100%' }}
                  control={
                    <Checkbox
                      checked={selectedStatus.indexOf('completed') !== -1}
                      onChange={(ev) => onToggle(ev.target.checked, 'selectedStatus', 'completed')}
                      name="status_completed"
                    />
                  }
                  label="Ganha / Concretizada"
                />
              </FormGroup>
            </FormControl>
          </Box>

          <Box marginBottom="20px" width="100%">
            <DateInterval
              title="Data de criação"
              initialDate={filters.startDate}
              finalDate={endDate}
              onChangeFirstDate={(startDate) => setFilters({ ...filters, startDate: startDate })}
              onChangeSecondDate={(endDate) => setFilters({ ...filters, endDate: endDate })}
            />
          </Box>

          <Box display="flex" flexDirection="column">
            <Form.Row style={{ marginBottom: '10px' }}>
              <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end">
                <Box marginRight="20px">
                  <Button onClick={applySearchFilter} variant="primary">
                    Aplicar Filtros
                  </Button>
                </Box>
                <Button onClick={onClose} variant="secondary">
                  Cancelar
                </Button>
              </Box>
            </Form.Row>
            <Box display="flex" flexDirection="row" flex={1} justifyContent="flex-end">
              <CleanFilterButton onClick={onClearFilters}>Limpar todos os filtros</CleanFilterButton>
            </Box>
          </Box>
        </Form>
      </>
    </Container>
  )
}

export default SearchPopover
