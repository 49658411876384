import {Box, Divider, Grid} from '@material-ui/core'
import {Add} from '@material-ui/icons'
import StateCitySelect from 'app/components/form/StateCitySelect'
import {LabelM0} from 'app/modules/Proposals/pages/NewProposal/City/Details/styles'
import React, {useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import InputMask from 'react-input-mask'

import {Container, LabelAdd, Title} from '../styles'

const CityContact = ({title, location: {contacts, city_hall}, handleChange, searchZip, onSubmit, submiting}) => {
  const [contact, setContact] = useState({
    contact: {
      phone: [],
      cellPhone: [],
    },
    address: {},
  })

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>CONTATO PRINCIPAL</Title>
          </Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="location_contact_name">Contato direto</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="location_contact_name"
            id="location_contact_name"
            type="text"
            value={contacts[0]?.name}
            onChange={handleChange}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="location_contact_responsibility">Cargo/função</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="location_contact_responsibility"
            id="location_contact_responsibility"
            type="text"
            value={contacts[0]?.responsibility}
            onChange={handleChange}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="location_contact_phone">Telefone</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <InputMask
            autoComplete="off"
            className="form-control"
            name="location_contact_phone"
            required
            value={contacts[0]?.phone}
            onChange={handleChange}
            placeholder="(99) 9999 9999"
            mask="(99) 9999 9999"
            style={{maxWidth: 200}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="location_contact_mobile_phone">Celular 1</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <InputMask
            autoComplete="off"
            className="form-control"
            name="location_contact_mobile_phone"
            value={contacts[0]?.mobile_phone}
            onChange={handleChange}
            placeholder="(99) 99999 9999"
            mask="(99) 99999 9999"
            style={{maxWidth: 200}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="location_contact_mobile_phone_2">Celular 2</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <InputMask
            autoComplete="off"
            className="form-control"
            name="location_contact_mobile_phone_2"
            value={contacts[0]?.mobile_phone_2}
            onChange={handleChange}
            placeholder="(99) 99999 9999"
            mask="(99) 99999 9999"
            style={{maxWidth: 200}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="location_contact_email">E-mail</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="location_contact_email"
            id="location_contact_email"
            type="email"
            value={contacts[0]?.email}
            onChange={handleChange}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="location_contact_linkedin">Site</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="location_contact_linkedin"
            id="location_contact_linkedin"
            type="text"
            value={contacts[0]?.linkedin}
            onChange={handleChange}
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Divider />
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>{title}</Title>
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="location_cityhall_zip">CEP</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <InputMask
            autoComplete="off"
            className="form-control"
            name="location_cityhall_zip"
            id="location_cityhall_zip"
            value={city_hall?.zip}
            onChange={(ev) => {
              handleChange(ev)
              searchZip(ev.target.value)
            }}
            placeholder="99.999-999"
            mask="99.999-999"
            style={{maxWidth: 135}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="location_cityhall_street">Logradouro</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="location_cityhall_street"
            id="location_cityhall_street"
            type="text"
            onChange={handleChange}
            value={city_hall?.street}
            placeholder="Ex.: Rua Arnaldo"
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="location_cityhall_number">Número</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="location_cityhall_number"
            id="location_cityhall_number"
            type="text"
            onChange={handleChange}
            value={city_hall?.number}
            placeholder=""
            style={{maxWidth: 100}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0 htmlFor="location_cityhall_neighborhood">Bairro</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="location_cityhall_neighborhood"
            id="location_cityhall_neighborhood"
            type="text"
            onChange={handleChange}
            value={city_hall?.neighborhood}
            placeholder=""
            style={{maxWidth: 400}}
          />
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>UF</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <LabelM0>{city_hall?.city?.state?.uf ? city_hall?.city?.state?.uf : city_hall?.state?.uf}</LabelM0>
        </Grid>

        <Grid
          item
          lg={3}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>
          <LabelM0>Município</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <LabelM0>{city_hall?.city?.name}</LabelM0>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Divider />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CityContact
