import {Box, Grid} from '@material-ui/core'
import {Container, Title} from 'app/modules/Cities/Pages/EditCitie/styles'
import {LabelM0, LargeHelper} from 'app/modules/Proposals/pages/NewProposal/City/Details/styles'
import React from 'react'
import {Button, Form} from 'react-bootstrap'

import UploadGaleria from '../../../../../components/files/UploadGaleria'
import UploadGaleriaPreview from '../../../../../components/files/UploadGaleriaPreview'
import UploadLogo from '../../../../../components/files/UploadLogo'

const formatNumber = (amount) => new Intl.NumberFormat('pt-BR').format(amount)

const CityBasicInfo = ({
  id,
  name,
  mayor,
  state,
  location,
  companies,
  responsible,
  handleChange,
  handleChangeLogo,
  handleAddImages,
  onDeleteGalleryItem,
  onSubmit,
  submiting,
  setLoading,
  loading,
  wiki_description,
  userList,
  imagesToUpload,
  citie, // preciso do endereco completo das imagens aqui, e isso so esta dispponivel no citie
}) => {
  const customerId = location?.customer?.id

  return (
    <Container style={{padding: 0}}>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px">
            <Title>INFORMAÇÕES BÁSICAS SOBRE O MUNICÍPIO</Title>
          </Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="name">Responsável pela edição</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <select
            className="form-control"
            style={{maxWidth: 600}}
            name="responsible"
            id="responsible"
            onChange={handleChange}>
            <option value="">Selecionar</option>
            {userList.map((user) => (
              <option
                key={user.id}
                value={user.id}
                selected={responsible && (responsible == user.id || responsible.id == user.id)}>
                {user.name} - {user.email}
              </option>
            ))}
          </select>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="name">Nome do Município</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="name"
            id="name"
            type="text"
            value={name}
            disabled
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="state">UF</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="state"
            id="state"
            type="text"
            value={state.uf}
            disabled
            style={{maxWidth: 100}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="state">Resumo sobre o Município</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="description"
            as="textarea"
            rows={5}
            style={{maxWidth: 600}}
            value={location?.description ? location?.description : wiki_description}
            onChange={handleChange}
            placeholder="Ex.: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus aliquam nunc a diam aliquet vestibulum. Praesent rhoncus sem leo, lobortis cursus dolor rutrum a. Nulla eget elit eget magna vulputate venenatis ut ac nulla. Nulla commodo lacus lacus, eget ultricies orci bibendum quis. Phasellus tincidunt neque libero, eget congue massa efficitur sit amet. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Proin sollicitudin est sit amet ullamcorper convallis."
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="total_companies">Empresas Ativas</LabelM0>
        </Grid>
        <Grid item lg={9} style={{display: 'flex', alignItems: 'center'}}>
          <Form.Control
            autoComplete="off"
            name="total_companies"
            id="total_companies"
            type="text"
            value={companies?.total ? formatNumber(companies.total) : 0}
            disabled
            style={{maxWidth: 160, marginRight: 15}}
          />
          <div className="show-helper">
            <LargeHelper>!</LargeHelper>
            <div
              className="description"
              style={{color: '#383838', fontSize: 12, marginLeft: 5, fontFamily: 'Nunito Sans', lineHeight: '14px'}}>
              <strong>Campo não editável</strong> <br />
              <span>Preenchimento automático - Banco de Dados CityMatch</span>
            </div>
          </div>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <div style={{maxWidth: 600}}>
            <UploadLogo
              title="LOGOMARCA DO MUNICÍPIO"
              currentLogo={location?.logo}
              customerId={customerId}
              handleChange={handleChangeLogo}
              maxFileSize={10}
            />
          </div>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <div style={{maxWidth: 600}}>
            <UploadGaleria
              title="IMAGENS DO MUNICÍPIO"
              subtitle="Para o carrossel de imagens do município."
              imagesLocation={[]}
              customerId={customerId}
              handleChange={handleAddImages}
              maxFileSize={20}
            />
          </div>
        </Grid>
        <Grid item lg={12}>
          <UploadGaleriaPreview
            setLoading={setLoading}
            onDeleteImage={onDeleteGalleryItem}
            imagesCitie={[...(citie?.location?.images ?? []), ...imagesToUpload]}
          />
        </Grid>

        <Grid item lg={3}>
          {' '}
        </Grid>
        <Grid item lg={9}>
          <Box marginBottom="20px" marginTop="20px">
            <Title>SOBRE O PREFEITO</Title>
          </Box>
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="mayor_name">Nome usual</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="mayor_name"
            id="mayor_name"
            type="text"
            value={mayor?.name}
            disabled
            style={{maxWidth: 600}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="mayor_party">Partido</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="mayor_party"
            id="mayor_party"
            type="text"
            value={mayor?.political_party?.abbreviation}
            disabled
            style={{maxWidth: 200}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          <LabelM0 htmlFor="mayor_ellected_in">Eleito em</LabelM0>
        </Grid>
        <Grid item lg={9}>
          <Form.Control
            autoComplete="off"
            name="mayor_ellected_in"
            id="mayor_ellected_in"
            type="text"
            value={mayor?.meta?.electionYear}
            disabled
            style={{maxWidth: 200}}
          />
        </Grid>

        <Grid item lg={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}></Grid>
        <Grid item lg={9}>
          <Box display="flex" marginTop="70px" marginBottom="70px">
            <Box mr="20px" style={{maxWidth: 300, width: '100%'}}>
              <Button variant="outline-primary" onClick={() => onSubmit(true)}>
                SALVAR E CONTINUAR EDIÇÃO
              </Button>
            </Box>
            <Box>
              <Button onClick={() => onSubmit(false)} disabled={submiting}>
                SALVAR E CONCLUIR EDIÇÃO
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default CityBasicInfo
