import React from 'react'

import CitieCard from './CitieCard'
import CardSkeleton from './CardSkeleton'
import ProposalPagination from 'app/components/proposal/Pagination'
import {changePage, changeLimit} from 'app/modules/Cities/redux'

const CitieCards = ({limit, page, total, cities, loading}) => {
  const renderCitie = (citie) => <CitieCard citie={citie} key={citie.id} />
  return (
    <div className="container-citymatch-table-cards">
      <div className="d-flex flex-column">
        <div className="d-flex flex-row flex-wrap" style={{justifyContent: 'space-between'}}>
          {loading ? Array.from({length: 9}).map((_, index) => <CardSkeleton key={index} />) : cities?.map(renderCitie)}
        </div>
        <ProposalPagination
          actualLenght={cities.length}
          limit={limit}
          loading={loading}
          page={page}
          total={total}
          changePage={changePage}
          changeLimit={changeLimit}
        />
      </div>
    </div>
  )
}

export default CitieCards
