import React from 'react'
import {useSelector} from 'react-redux'

import * as selectors from 'app/modules/Politicians/redux/selectors'

import TableHead from './Header'
import TableRow from './Row'
import SkeletonRow from './Row/skeleton'
import ProposalPagination from 'app/components/proposal/Pagination'
import {changePage, changeLimit} from 'app/modules/Politicians/redux'

const SKELETON_ITEMS = 10

const PoliticiansTable = ({limit, loading, page, politicians, total}) => {
  const columns = useSelector(selectors.getPoliticiansColumns)

  const renderPolitician = (politician) => <TableRow key={`k=${politician.id}`} {...politician} columns={columns} />

  return (
    <div className="container-citymatch-table">
      <div className="table-responsive" style={{overflowX: 'auto'}}>
        <table className="table">
          <TableHead />
          <tbody>
            {loading
              ? Array.from({length: SKELETON_ITEMS}).map((_, index) => <SkeletonRow key={index} />)
              : politicians?.map(renderPolitician)}
          </tbody>
        </table>
      </div>
      <ProposalPagination
        actualLenght={politicians.length}
        limit={limit}
        loading={loading}
        page={page}
        total={total}
        changePage={changePage}
        changeLimit={changeLimit}
      />
    </div>
  )
}

export default PoliticiansTable
