import httpClient from '../../client'

import apiRoutes from '../constants'

const getCitie = async (citieId) => {
  try {
    const url = `${apiRoutes.GET_CITIE}/${citieId}?show_demographic_data=1`
    const {data} = await httpClient.get(url)

    if (data) {
      return data
    }

    throw data
  } catch (error) {
    console.log(`Error calling citie with ${JSON.stringify(citieId)}`, error)
    return error
  }
}

export default getCitie
