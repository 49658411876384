import styled from 'styled-components'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  padding-bottom: 50px;
`

export const Title = styled.span`
  color: #1e7be1;
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: bold;
`

export const SubTitle = styled.span`
  color: #1e7be1;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: bold;
`

export const Label = styled.label`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: bold;
`

export const LabelM0 = styled.label`
  color: #1d1d1d;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0 !important;
`

export const LargeHelper = styled.label`
  background: #1e7be1;
  color: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0;
  float: left;
`
