import React, {useEffect, useState, useCallback} from 'react'
import {Tabs, Tab, Box} from '@material-ui/core'
import {useParams} from 'react-router'
import {companiesService} from 'app/services'
import DetailCompanySkeleton from './skeleton'

import Logo from './Logo'
import Attachments from '../../../Proposals/pages/EditProposal/ProposalAttachments'
import {useDispatch} from 'react-redux'
import {changeActualEntity} from 'app/modules/Cities/redux'

import {ContainerHeaderTabs, Paper} from './styles'
import General from './General'
import Partner from './Partner'
import Opportunity from './Opportunity'
import Contact from './Contact'
import BasicInfo from '../DetailCompany/BasicInfo'
import {useSnackbar} from 'notistack'
import {useHistory} from 'react-router-dom'
import getCities from 'app/services/cities/getCities'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

function DetailCompany() {
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState()
  const [companyInfo, setCompanyInfo] = useState({
    logo: null,
    description: '',
    contacts: [],
    multimedia: [],
  })

  const {enqueueSnackbar} = useSnackbar()
  const [tabNext, setTabNext] = useState(0)
  const [submiting, setSubmiting] = useState(false)
  const history = useHistory()

  const dispatch = useDispatch()

  const {companyId} = useParams()

  const [tab, setTab] = useState(0)

  const fetchCompany = useCallback(async () => {
    try {
      setLoading(true)
      const data = await companiesService.getCompany({document: companyId})
      setCompany(data)
      if (data.info) {
        setCompanyInfo(data.info)
      }

      setLoading(false)

      dispatch(
        changeActualEntity({
          name: data.business_name,
          id: data.id,
        }),
      )
    } catch (e) {
      setLoading(false)
    }
  }, [companyId, dispatch])

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  const handleAddAttachment = (attachment) => {
    const mult = companyInfo.multimedia
    mult.push(attachment)
    setCompanyInfo({...companyInfo, multimedia: mult})
  }

  const removeAttachment = (attachmentId) => {
    const mult = companyInfo.multimedia.filter((file) => file.id !== attachmentId)
    setCompanyInfo({...companyInfo, multimedia: mult})
  }

  const searchZip = useCallback(
    async (zip) => {
      try {
        const postalCode = zip

        const cleanPostalCode = postalCode?.match(/\d+/g)?.join('')

        if (cleanPostalCode?.length === 8) {
          const response = await (await fetch(`https://viacep.com.br/ws/${cleanPostalCode}/json`)).json()

          if (response.erro) {
            enqueueSnackbar('CEP não encontrado', {
              variant: 'warning',
            })
          } else {
            handleChangeForm({target: {name: 'location_cityhall_street', value: response.logradouro}})
            handleChangeForm({target: {name: 'location_cityhall_neighborhood', value: response.bairro}})

            const city = await getCities({code: response.ibge})
            handleChangeForm({target: {name: 'location_cityhall_city', value: city.data[0]}})
            handleChangeForm({target: {name: 'location_cityhall_city_id', value: city.data[0].id}})
          }
        }
      } catch (error) {
        enqueueSnackbar('Ocorreu um erro ao buscar o endereco', {
          variant: 'warning',
        })
      }
    },
    [enqueueSnackbar],
  )

  useEffect(() => {
    fetchCompany()
  }, [fetchCompany])

  const handleChangeForm = (ev) => {
    const fieldName = ev.target.name
    var value = ev.target.value

    switch (fieldName) {
      case 'company_contact_email':
      case 'company_contact_linkedin':
      case 'company_contact_mobile_phone':
      case 'company_contact_mobile_phone_2':
      case 'company_contact_name':
      case 'company_contact_phone':
      case 'company_contact_responsibility':
        var complexField = fieldName.replace('company_contact_', '')

        if (
          ['company_contact_mobile_phone', 'company_contact_mobile_phone_2', 'company_contact_phone'].indexOf(
            fieldName,
          ) !== -1
        ) {
          value = value.replace(/\D/g, '')
        }

        const contacts = companyInfo.contacts.length ? companyInfo.contacts : [{[complexField]: value}]

        if (complexField == 'linkedin') {
          setCompanyInfo((stt) => ({
            ...stt,
            contacts: contacts.map((contact) => {
              contact[complexField] = value
              contact['site'] = value
              return contact
            }),
          }))
        } else {
          setCompanyInfo((stt) => ({
            ...stt,
            contacts: contacts.map((contact) => {
              contact[complexField] = value
              return contact
            }),
          }))
        }

        break
      default:
        setCompanyInfo((st) => ({
          ...st,
          [fieldName]: value,
        }))
    }
  }

  const handleChangeLogo = (path) => {
    console.log(path)
    setCompanyInfo((st) => ({
      ...st,
      logo: path,
    }))
  }

  const handleAddImages = (image) => {
    const mult = company?.info?.multimedia || []
    mult.push(image)
    setCompanyInfo((st) => ({
      ...st,
      multimedia: mult,
    }))
  }

  const onSubmit = useCallback(
    async (next) => {
      try {
        if (next) {
          if (tabNext < 5) {
            setTabNext(tabNext + 1)
            setTab(tab + 1)
          }
        }
        setSubmiting(true)

        var data = await companiesService.updateInfo(companyId, companyInfo)

        if (data) {
          setCompany((stt) => ({
            ...stt,
            info: companyInfo,
          }))
          if (!next) {
            enqueueSnackbar('Empresa atualizada com sucesso!', {
              variant: 'success',
            })
            history.push('/companies/' + companyId)
          }
        } else {
          throw data
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } finally {
        setSubmiting(false)
      }
    },
    [enqueueSnackbar, company, companyInfo, tabNext, setTabNext, fetchCompany],
  )

  if (loading) {
    return <DetailCompanySkeleton />
  }

  if (!company) return <h1>Empresa não encontrada!</h1>

  return (
    <div className="d-flex flex-column container-citymatch-table-cards">
      <BasicInfo {...company} />

      <Paper>
        <ContainerHeaderTabs>
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Logomarca" className="tabicon" />
            <Tab label="Contatos" className="tabicon" />
            <Tab label="Anexos" className="tabicon" />
          </Tabs>
        </ContainerHeaderTabs>
        <TabPanel value={tab} index={0}>
          <Logo
            {...company}
            handleChangeLogo={handleChangeLogo}
            onSubmit={onSubmit}
            submiting={submiting}
            setLoading={setLoading}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Contact
            {...company}
            info={companyInfo}
            handleChange={handleChangeForm}
            onSubmit={onSubmit}
            submiting={submiting}
            setLoading={setLoading}
            searchZip={searchZip}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Attachments
            multimedia={company?.info?.multimedia ? company?.info?.multimedia : []}
            handleChange={handleChangeForm}
            onSubmit={onSubmit}
            submiting={submiting}
            handleAddAttachment={handleAddAttachment}
            removeAttachment={removeAttachment}
            hiddenButton
          />
        </TabPanel>
      </Paper>
    </div>
  )
}

export default DetailCompany
