import httpClient from '../../client'

import apiRoutes from '../constants'

const saveCustomer = async (customer, body) => {
  try {
    const {data, status} = await httpClient.put(`${apiRoutes.POST_SAVE}/${customer}`, body)

    if (status === 204) {
      return 'Sucesso'
    }

    throw data
  } catch (error) {
    throw Error(error.response.data.message)
  }
}

export default saveCustomer
