import React from 'react'

import Shimmer from 'react-loading-skeleton'

import {ProposalColumn, ProposalName, LogoContainer} from './styles'

const Skeleton = () => (
  <tr>
    <th className="align-middle" scope="row">
      <Shimmer width="10px" height="20px" />
    </th>
    <th className="align-middle" scope="row">
      <ProposalColumn>
        <LogoContainer>
          <Shimmer width="30px" height="30px" />
        </LogoContainer>
        <ProposalName>
          <Shimmer width="370px" height="21px" />
        </ProposalName>
      </ProposalColumn>
    </th>
    <th className="align-middle" scope="row">
      <Shimmer width="90px" height="21px" />
    </th>
    <th className="align-middle" scope="row">
      <Shimmer width="40px" height="21px" />
    </th>
    <th className="align-middle" scope="row">
      <Shimmer width="60px" height="21px" />
    </th>
    <th className="align-middle" scope="row">
      <Shimmer width="20px" height="21px" />
    </th>
    <th className="align-middle" scope="row">
      <Shimmer width="70px" height="21px" />
    </th>
    <th className="align-middle" scope="row">
      <Shimmer width="90px" height="28px" />
    </th>
    <th className="align-middle" scope="row">
      <Shimmer width="25px" height="10px" />
    </th>
    <th className="align-middle" scope="row">
      <Shimmer width="25px" height="10px" />
    </th>
  </tr>
)

export default Skeleton
