import React from 'react'

const Icon = ({color, width}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : '42'} height="42" viewBox="0 0 42 42">
    <g id="icon_revisar" data-name="icon/revisar" transform="translate(-1193 -344)">
      <path id="bound" d="M0,0H42V42H0Z" transform="translate(1193 344)" fill="none" />
      <g id="shapes" transform="translate(1195.71 346.71)">
        <path
          id="Ellipse_50"
          data-name="Ellipse 50"
          d="M35.9,17.952A17.952,17.952,0,1,1,17.952,0,17.952,17.952,0,0,1,35.9,17.952Z"
          fill={color}
        />
        <g id="Double-check_1" data-name="Double-check 1" transform="translate(4.742 4.742)">
          <path id="Shape" d="M0,0H27.1V27.1H0Z" fill="none" />
          <path
            id="Path_94_Copy"
            data-name="Path 94 Copy"
            d="M1.989,13.151A1.129,1.129,0,1,1,.269,11.688L9.866.4a1.129,1.129,0,0,1,1.61-.113L16.557,4.8a1.129,1.129,0,1,1-1.5,1.688L10.838,2.74Z"
            transform="matrix(-1, 0, 0, -1, 25.403, 19.195)"
            fill="#fff"
            opacity="0.3"
          />
          <path
            id="Path_94"
            data-name="Path 94"
            d="M1.989,13.151A1.129,1.129,0,1,1,.269,11.688L9.866.4a1.129,1.129,0,0,1,1.61-.113L16.557,4.8a1.129,1.129,0,1,1-1.5,1.688L10.838,2.74Z"
            transform="matrix(-1, 0, 0, -1, 19.758, 20.323)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Icon
