import React from 'react'
import {Slider, withStyles, Tooltip} from '@material-ui/core'

import {Column, Label} from './styles'

const CustomSlider = withStyles({
  root: {
    color: '#1E7BE1',
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
      color: 'red',
      fontSize: '16px',
    },
  },
  markLabel: {
    'color': '#8C8C8C',
    'fontFamily': "'Nunito', sans-serif",
    'transform': 'translateX(-100%)',
    '&[data-index="0"]': {
      transform: 'none',
    },
  },
})(Slider)

const ValueLabelComponent = (props) => {
  const {children, open, value} = props

  return (
    <Tooltip arrow open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

const RangeSlider = ({
  defaultValue = 0,
  label,
  step,
  getAriaValueText,
  min,
  max,
  marks,
  valueLabelFormat,
  onChange,
  onChangeCommitted,
  value,
}) => {
  return (
    <Column>
      <Column>
        <Label>{label}</Label>
      </Column>
      <Column>
        <CustomSlider
          defaultValue={defaultValue}
          getAriaValueText={getAriaValueText}
          valueLabelDisplay="auto"
          step={step}
          min={min}
          max={max}
          marks={marks}
          valueLabelFormat={valueLabelFormat}
          value={value}
          onChange={onChange}
          onChangeCommitted={onChangeCommitted}
          ValueLabelComponent={ValueLabelComponent}
        />
      </Column>
    </Column>
  )
}

export default RangeSlider
