import CustomerCards from 'app/components/customers/CustomerCards/CustomerCard'
import CustomerPagination from 'app/components/customers/Pagination'
import Map from 'app/components/map/Map'
import * as customersSelectors from 'app/modules/ManageCustomer/redux/selectors'
import {customerService} from 'app/services'
import {useSnackbar} from 'notistack'
import React, {useCallback, useEffect, useState} from 'react'
import Shimmer from 'react-loading-skeleton'
import {useDispatch, useSelector} from 'react-redux'

import {Table} from '../../components'
import {changeLimit, changePage, setAvailableOrdering} from '../../redux'
import {CustomerContext} from '../../state'

const ListCustomers = ({searchParams}) => {
  const {enqueueSnackbar} = useSnackbar()
  const dispatch = useDispatch()

  const {customers, setCustomers} = CustomerContext()

  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [totalWithoutFilter, setTotalWithoutFilter] = useState(0)
  const [filters, setFilters] = useState({searchParams: {}})

  const [coordinates, setCoordinates] = useState(null)
  const [drawnArea, setDrawnArea] = useState(null)
  const hasLoadedOrdering = useSelector(customersSelectors.hasLoadedOrdering)

  const [currentPage, setCurrentPage] = useState(1)
  const {showMap, viewMode} = useSelector(customersSelectors.universalSearch)
  const activeOrder = useSelector(customersSelectors.getActiveOrder)
  const {hasAppliedFilters} = useSelector(customersSelectors.getSearchParams)
  const limit = useSelector(customersSelectors.getCurrentLimit)

  useEffect(() => {
    return () => setCustomers([])
  }, [])

  const changeDrawnArea = (drawnArea) => {
    setDrawnArea(drawnArea)
  }

  const mountSorting = useCallback(
    (selectedOrder) => ({
      [`sort[${selectedOrder.key}]`]: selectedOrder.order.toLowerCase(),
    }),
    [],
  )

  const mountQueryParams = useCallback(() => {
    let queryParams = {
      page: currentPage,
      limit: limit,
      show_demographic_data: 1,
    }

    if (searchParams && searchParams.name) {
      queryParams.name = searchParams.name.trim()
    }

    if (drawnArea) {
      queryParams.coordinates_polygon = drawnArea
    }

    if (activeOrder) {
      queryParams = {...queryParams, ...mountSorting(activeOrder)}
    }

    return queryParams
  }, [activeOrder, currentPage, limit, mountSorting, drawnArea, searchParams])

  const fetchCustomers = useCallback(async () => {
    try {
      setLoading(true)

      const queryParams = mountQueryParams()

      const {
        data,
        total,
        total_without_filter,
        available_ordering: availableOrdering,
      } = await customerService.getListCustomers(queryParams)

      setTotal(total)
      setTotalWithoutFilter(total_without_filter)
      setCustomers(data || [])
      setLoading(false)

      if (availableOrdering) {
        dispatch(setAvailableOrdering(availableOrdering))
      }
    } catch (error) {
      enqueueSnackbar('Ocorreu um erro ao consultar os clientes', {
        variant: 'error',
      })

      setCustomers([])
    } finally {
      setLoading(false)
    }
  }, [dispatch, enqueueSnackbar, mountQueryParams])

  useEffect(() => {
    fetchCustomers()
  }, [fetchCustomers, searchParams])

  const handleNameFilterChange = (name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchParams: {
        ...prevFilters.searchParams,
        name,
      },
    }))
  }

  useEffect(() => {}, [searchParams])

  const renderVisualizationMode = () => {
    if (viewMode === 'card') {
      return <CustomerCards loading={loading} page={currentPage} customers={customers} total={total} limit={limit} />
    }
    return (
      <Table
        page={currentPage}
        customers={customers}
        total={total}
        totalWithoutFilter={totalWithoutFilter}
        loading={loading}
        limit={limit}
        changeLimit={(newLimit) => dispatch(changeLimit(newLimit))}
        changePage={(newPage) => setCurrentPage(newPage)}
        filters={searchParams}
        onNameFilterChange={handleNameFilterChange}
      />
    )
  }

  return (
    <>
      {showMap && loading && (
        <Shimmer className="w-100" height="500px" style={{marginBottom: '40px', borderRadius: '20px'}} />
      )}

      {showMap && !loading && (
        <Map
          lg
          coordinates={coordinates}
          currentPage={currentPage}
          changeFilter={setFilters}
          loading={loading}
          mapType="customers"
        />
      )}

      <div className="d-flex flex-column">{renderVisualizationMode()}</div>
    </>
  )
}

export default ListCustomers
