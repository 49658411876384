import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

export const LogoContainer = styled.div`
  margin-bottom: 10px;
`

export const Message = styled.p`
  color: #000000;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`
