import React, {useState, useCallback, useEffect} from 'react'

import {useSnackbar} from 'notistack'

import clsx from 'clsx'

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import getConnectionInfo from 'app/services/companies/getConnectionInfo'
import ConexoesSocietariasIcone from 'app/modules/Companies/components/Icon/ConexoesSocietariasIcone'

const useStyles = makeStyles((theme) => ({
  buttonClose: {
    position: 'absolute',
    right: 0,
    top: 24,
  },
  cardcontent: {
    'padding': 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardTitle: {
    marginTop: '10px',
  },
  cardSectionDivider: {
    backgroundColor: 'rgba(220, 224, 230, 0.3)',
    paddingLeft: 18,
    paddingTop: 10,
    paddingBottom: 10,
  },
  contentRoot: {
    padding: 30,
  },
  paddingCard: {
    padding: 20,
  },
  sectionList: {
    maxHeight: '500px',
    overflow: 'auto',
  },
}))

const CorporateConnectionDetails = ({connectionInfo, onClose, show, fetchConnections}) => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()

  const [connectionDetails, setConnectionDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(show)

  const searchConnectionDetails = useCallback(async () => {
    try {
      const {data} = await getConnectionInfo(connectionInfo)
      console.log(data)
      setConnectionDetails(data)
      setLoading(false)
    } catch (error) {
      enqueueSnackbar('Não foi possivel consultar os detalhes da conexão selecionada', {variant: 'error'})

      setLoading(false)
      setShowModal(false)
    }
  }, [connectionInfo, enqueueSnackbar])

  useEffect(() => {
    if (connectionInfo) {
      searchConnectionDetails()
    }
  }, [connectionInfo, searchConnectionDetails])

  return (
    <Paper className={classes.paper}>
      <Card>
        <Button className={classes.buttonClose} onClick={onClose}>
          <CloseIcon />
        </Button>
        {loading ? (
          <CardContent className={classes.cardcontent}>
            <Box display="flex" justifyContent="center">
              <Box alignItems="center" display="flex" height="200px">
                <CircularProgress size={20} />
              </Box>
            </Box>
          </CardContent>
        ) : (
          <CardContent className={classes.cardcontent}>
            <Box className={clsx(classes.paddingCard, classes.cardTitle)}>
              <Typography variant="h4">{connectionDetails.name}</Typography>
            </Box>
            <Divider />
            {connectionDetails?.companies && (
              <>
                <Box className={clsx(classes.cardSectionDivider)}>
                  <Typography variant="h5">Empresas</Typography>
                </Box>
                <Box className={classes.sectionList}>
                  <List component="ul">
                    {connectionDetails?.companies.map((company, index) => (
                      <>
                        <ListItem key={company.document}>
                          <ListItemText primary={company.name} secondary={company.document} />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                </Box>
              </>
            )}
            <Divider />
            {connectionDetails?.direct_partners && (
              <>
                <Box className={clsx(classes.cardSectionDivider)}>
                  <Typography variant="h5">Sócios Diretos</Typography>
                </Box>
                <Box className={classes.sectionList}>
                  <List component="ul">
                    {connectionDetails?.direct_partners.map((partner) => (
                      <>
                        <ListItem key={partner.document}>
                          <ListItemText primary={partner.name} secondary={partner.document} />
                          <ConexoesSocietariasIcone
                            style={{cursor: 'pointer'}}
                            title={'Abrir Conexões Societárias de ' + partner.name}
                            color="#1e7be1"
                            onClick={() => {
                              fetchConnections({
                                name: partner.name,
                                document: partner.document,
                                showCompanies: 1,
                              })
                              onClose()
                            }}
                          />
                        </ListItem>
                        <Divider />
                      </>
                    ))}
                  </List>
                </Box>
              </>
            )}
          </CardContent>
        )}
      </Card>
    </Paper>
  )
}

export default CorporateConnectionDetails
