import React, {useState, useCallback, useEffect} from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {CircularProgress, TextField} from '@material-ui/core'

import {companiesService} from 'app/services'

import {Label, Column, SelectedListContainer, SelectedListItem} from './styles'

const AutoCompleteCity = ({selectedItem, onSelectItem, onRemoveItem, selectedItems}) => {
  const [activities, setActivities] = useState([])
  const [inputText, setInputText] = useState(selectedItem?.name ?? '')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  selectedItems = selectedItems || []

  const getOptionLabel = ({code, text}) => `${code} - ${text}`

  const onChange = (_, value) => {
    const isAdded = value?.id ? selectedItems.indexOf(value?.id) : false

    if (isAdded === -1 && value?.id) {
      onSelectItem(value.id)
      setInputText('')
    }
  }

  const onInputChange = (event) => {
    if (event) {
      setInputText(event.target?.value ? event.target?.value : '')
    }
  }

  const getOptionSelected = (option, value) => option.name === value.name

  const searchActivities = useCallback(async () => {
    setLoading(true)
    try {
      const {data} = await companiesService.getActivities({
        term: inputText,
        limit: 1500,
      })

      setActivities(data)
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    searchActivities()
  }, [searchActivities])

  return (
    <Column>
      <Label>Ativitidade Econômica</Label>
      <Autocomplete
        size="small"
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={activities}
        loading={loading}
        inputValue={inputText ? inputText : ''}
        noOptionsText="Nenhum item encontrado"
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionDisabled={(selected) => selectedItems.indexOf(selected.id) !== -1 || selected === selectedItem}
        renderInput={(params) => (
          <TextField
            {...params}
            color="secondary"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              placeholder: 'Escolher…',
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {selectedItems.length ? (
        <SelectedListContainer>
          {activities
            .filter(({id}) => selectedItems.indexOf(id) !== -1)
            .map(({id, code}) => (
              <SelectedListItem key={id}>
                {code}
                <i className="far fa-times-circle" style={{cursor: 'pointer'}} onClick={() => onRemoveItem(id)} />
              </SelectedListItem>
            ))}
        </SelectedListContainer>
      ) : null}
    </Column>
  )
}

export default AutoCompleteCity
