import React, {useState, useCallback} from 'react'
import {Link} from 'react-router-dom'
import {useSelector, shallowEqual, connect, useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {ModalProgressBar} from '../../../_metronic/_partials/controls'
import * as auth from '../Auth'
import * as reduxAuth from '../Auth/_redux/authRedux'
import {Button} from 'react-bootstrap'
import updateUser from 'app/services/user/updateUser'
import {useSnackbar} from 'notistack'
import {filesService} from 'app/services'
import {useDropzone} from 'react-dropzone'
import CircularLoading from 'app/components/loading/Circular'
import {Box, Icon} from '@material-ui/core'

function PersonaInformation(props) {
  // Fields
  const [loading, setloading] = useState(false)
  const user = useSelector((state) => state.auth.user, shallowEqual)
  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar()

  // UI Helpers
  const initialValues = {
    name: user.name,
    photo: user.photo,
    phone: user.property.phone,
    mobile_phone: user.property.mobile_phone,
    email: user.email,
  }

  const Schema = Yup.object().shape({
    name: Yup.string().required('Nome completo é obrigatório'),
    phone: Yup.string(),
    mobile_phone: Yup.string().required('Celular é obrigatório'),
    email: Yup.string().email('Fomato inválido').required('E-mail é obrigatório'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      saveUser(values, setStatus, setSubmitting)
    },
    onReset: (values, {resetForm}) => {
      resetForm()
    },
  })

  const onDrop = useCallback(
    async ([file]) => {
      try {
        if (file.size > 5000000) throw Error('O tamanho de cada arquivo é limitado a 5 MB.')

        setloading(true)

        const fileResponse = await filesService.sendFile({file})

        if (fileResponse?.file) {
          formik.setFieldValue('photo', fileResponse.file.path)
        } else {
          throw new Error('Erro ao carregar imagem!')
        }
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        })
      } finally {
        setloading(false)
      }
    },
    [enqueueSnackbar, formik],
  )

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDrop,
  })

  // Methods
  const saveUser = async (values, setStatus, setSubmitting) => {
    try {
      setloading(true)

      let updateBody = {
        name: values.name,
        email: values.email,
        photo: values.photo,
        property: {
          phone: values.phone,
          mobile_phone: values.mobile_phone,
        },
      }

      let objUser = await updateUser(user.id, updateBody)

      dispatch(reduxAuth.actions.setUser(objUser))

      enqueueSnackbar('Informações pessoais atualizadas!', {
        variant: 'success',
      })

      setloading(false)
      setSubmitting(false)
    } catch (err) {
      enqueueSnackbar('Ops, ocorreu um erro ao atualizar seu perfil, tente novamente!', {
        variant: 'error',
      })
      setloading(false)
      setSubmitting(false)
    }
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  return (
    <form className="card card-custom card-stretch" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">Informações pessoais</h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">Atualize suas informações pessoais</span>
        </div>
        <div className="card-toolbar">
          <Button
            type="submit"
            className="btn btn-success mr-2"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}>
            Salvar alterações
            {formik.isSubmitting}
          </Button>
          <Link to="/user-profile/profile-overview" className="btn btn-secondary">
            Fechar
          </Link>
        </div>
      </div>

      <div className="form">
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">Informação do usuário</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
            <Box
              alignItems="center"
              borderRadius="5px"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100px"
              width="100px"
              className="attachtment-dragger">
              <input {...getInputProps()} />
              <Box alignItems="center" display="flex" flexDirection="column">
                <Box alignItems="center" display="flex" flexDirection="column" position={'relative'}>
                  <Box
                    bgcolor="#E4E6EF"
                    width={'25px'}
                    display={'grid'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    height="25px"
                    p="5px"
                    borderRadius={'50%'}
                    position={'absolute'}
                    right={-5}
                    top={-5}
                    zIndex={1}>
                    <i className="fa fa-pen icon-sm text-muted"></i>
                  </Box>
                  {formik.values.photo && (
                    <Box
                      bgcolor="#E4E6EF"
                      width={'25px'}
                      display={'grid'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      height="25px"
                      p="5px"
                      borderRadius={'50%'}
                      position={'absolute'}
                      right={-5}
                      bottom={-5}
                      zIndex={1}
                      onClick={() => formik.setFieldValue('photo', null)}>
                      <i className="ki ki-bold-close icon-xs text-muted"></i>
                    </Box>
                  )}
                  {loading ? (
                    <>
                      <CircularLoading />
                    </>
                  ) : formik.values.photo ? (
                    <Box
                      {...getRootProps()}
                      width={100}
                      borderRadius={5}
                      height={100}
                      style={{
                        backgroundImage: `url(${formik.values.photo})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}></Box>
                  ) : (
                    <Box
                      {...getRootProps()}
                      width={100}
                      height={100}
                      display={'grid'}
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <Box>
                        <Icon className="fas fa-file-upload" />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Nome completo</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Nome completo"
                className={`form-control form-control-lg form-control-solid ${getInputClasses('name')}`}
                name="name"
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="invalid-feedback">{formik.errors.name}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mt-10 mb-6">Informações de contato</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Celular</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  placeholder="+55 (41) 9 9999-9999"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses('mobile_phone')}`}
                  name="mobile_phone"
                  {...formik.getFieldProps('mobile_phone')}
                />
              </div>
              {formik.touched.mobile_phone && formik.errors.mobile_phone ? (
                <div className="invalid-feedback display-block">{formik.errors.mobile_phone}</div>
              ) : null}
              <span className="form-text text-muted">Nunca compartilharemos seu telefone com mais ninguém.</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Telefone</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  placeholder="+55 (41) 3033-6566"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses('phone')}`}
                  name="phone"
                  {...formik.getFieldProps('phone')}
                />
              </div>
              {formik.touched.phone && formik.errors.phone ? (
                <div className="invalid-feedback display-block">{formik.errors.phone}</div>
              ) : null}
              <span className="form-text text-muted">Nunca compartilharemos seu telefone com mais ninguém.</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">E-mail</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <input
                  type="email"
                  placeholder="E-mail"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses('email')}`}
                  name="email"
                  {...formik.getFieldProps('email')}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback display-block">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Form */}
    </form>
  )
}

export default connect(null, auth.actions)(PersonaInformation)
