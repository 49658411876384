import React from 'react'
import { useSelector } from 'react-redux'
import Shimmer from 'react-loading-skeleton'
import * as selectors from 'app/modules/Proposals/redux/selectors'

import Pagination from '../Pagination'
import TableHead from './Header'
import TableRow from './Row'
import SkeletonRow from './Row/skeleton'
import { changePage, changeLimit } from 'app/modules/Proposals/redux'

const SKELETON_ITEMS = 10

const ProposalTable = ({ limit, loading, page, proposals, total, totalWithoutFilter, reloadList }) => {
  const columns = useSelector(selectors.getProposalsColumns)

  const renderProposal = (proposal) => (
    <TableRow key={`k=${proposal.id}`} {...proposal} columns={columns} reloadList={reloadList} />
  )

  return (
    <div className="container-citymatch-table">
      {loading ? (
        <Shimmer width="370px" height="21px" />
      ) : (
        <div className="filter-info">
          {totalWithoutFilter === total ? (
            <p>Total de propostas ativas na base: {Intl.NumberFormat('pt-BR').format(totalWithoutFilter)}</p>
          ) : (
            <p>
              Total de propostas filtradas na base: {Intl.NumberFormat('pt-BR').format(total)} /{' '}
              {Intl.NumberFormat('pt-BR').format(totalWithoutFilter)}
            </p>
          )}
        </div>
      )}

      <div className="table-responsive" style={{ overflowX: 'auto', minHeight: '150px' }}>
        <table className="table position-relative">
          <TableHead />

          {total === 0 && !loading ? (
            <p className="text-center position-absolute filter-info w-100 pt-10">Nenhuma proposta encontrada</p>
          ) : (
            <tbody>
              {loading
                ? Array.from({ length: SKELETON_ITEMS }).map((_, index) => <SkeletonRow key={index} />)
                : proposals?.map(renderProposal)}
            </tbody>
          )}
        </table>
      </div>

      {total > 0 && !loading && (
        <Pagination
          actualLenght={proposals.length}
          limit={limit}
          loading={loading}
          page={page}
          total={total}
          changePage={changePage}
          changeLimit={changeLimit}
        />
      )}
    </div>
  )
}

export {ProposalTable}
